<template>
    <v-dialog
        persistent
        @click:outside="confirmClose"
        @keydown.esc="confirmClose"
        v-model="show"
        max-width="500px"
    >
        <v-card>
            <v-card-title class="primary white--text">
                <span class="headline">{{ job.name }} | Notes</span>
            </v-card-title>

            <v-card-text class="pb-0 mb-0">
                <v-container>
                    <v-progress-circular
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                        transition="fade-transition"
                        class="mb-6"
                        v-if="loading"
                    ></v-progress-circular>
                    <v-timeline v-else align-top dense class="timeline">
                        <transition-group type="transition" name="flip-list">
                            <v-timeline-item
                                class="pb-2"
                                v-for="journal in journals.data"
                                :key="journal.key ? journal.key : journal.id"
                                color="primary"
                                small
                            >
                                <journal
                                    :job="job"
                                    :journal="journal"
                                    @dirty="markAsDirty"
                                    @saved="savedJournal"
                                    @destroyed="removeJournalOfList"
                                ></journal>
                            </v-timeline-item>
                        </transition-group>

                        <v-timeline-item
                            hide-dot
                            class="add-new-step pb-2 mb-0 mt-2"
                        >
                            <v-btn
                                v-if="
                                    journals.current_page < journals.last_page
                                "
                                class="float-left mr-3"
                                color="primary"
                                text
                                outlined
                                @click="loadMore()"
                            >
                                Load more
                            </v-btn>

                            <v-btn
                                class="float-left"
                                color="primary"
                                text
                                outlined
                                @click="add()"
                            >
                                {{ addButtonTitle }}
                            </v-btn>
                        </v-timeline-item>
                    </v-timeline>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmClose">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import getTemporaryId from "Mixins/getTemporaryId.mixin";
import journal from "Components/jobs/journal";

export default {
    components: { journal },
    data: data,
    mixins: [getTemporaryId],
    props: {
        show: Boolean,
        job: Object,
    },
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        addButtonTitle() {
            return this.journals.data.length >= 1
                ? "Add another note"
                : "Add note";
        },
    },
};

function data() {
    return {
        isDirty: [],
        journals: [],
        loading: false,
        deletingJournal: null,
    };
}

function getMethods() {
    return {
        getJournals: getJournals,
        loadMore: loadMore,
        close: close,
        confirmClose: confirmClose,
        add: add,
        smoothScrollDown: smoothScrollDown,
        markAsDirty: markAsDirty,
        savedJournal: savedJournal,
        removeJournalOfList: removeJournalOfList,
    };

    function getJournals(page = 1) {
        const vm = this;

        if (page === 1) {
            vm.loading = true;
            Vue.backendApi()
                .jobs(vm.job.id)
                .journals()
                .index()
                .then(success, fail);
        } else {
            Vue.backendApi()
                .jobs(vm.job.id)
                .journals()
                .index(page)
                .then(success, fail);
        }

        function success(response) {
            if (page === 1) {
                vm.loading = false;
                Vue.set(vm, "journals", response.data);
            } else {
                response.data.data.forEach((newJournal) => {
                    vm.journals.data.push(newJournal);
                });
                vm.journals.current_page = response.data.current_page;
            }
        }

        function fail(error) {
            console.log("An error occurred trying to get job journals");
            vm.loading = false;
        }
    }

    function loadMore() {
        const page = parseInt(this.journals.current_page) + 1;
        this.getJournals(page);
    }

    function close() {
        const vm = this;
        vm.isDirty = [];
        vm.$emit("close");
    }

    function confirmClose() {
        if (this.isDirty.length === 0) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function add() {
        this.journals.data.push({
            id: this.$getTemporaryId(),
        });
        this.smoothScrollDown();
    }

    function smoothScrollDown() {
        Vue.nextTick(function () {
            const dialog = document.getElementsByClassName("v-dialog")[0];
            dialog.scroll({
                top: parseInt(dialog.scrollHeight),
                left: 0,
                behavior: "smooth",
            });
        });
    }

    function markAsDirty(journal) {
        let hasId = this.isDirty.includes(journal.id);
        if (!hasId) {
            this.isDirty.push(journal.id);
        }
    }

    function savedJournal(journal, savedJournal) {
        const vm = this;
        let journalIndex = vm.journals.data.findIndex((myJournal) => {
            return myJournal.id === journal.id;
        });

        vm.journals.data.splice(journalIndex, 1, savedJournal);
        vm.isDirty = vm.isDirty.filter((id) => id !== journal.id);
        vm.$emit("update-feedback-meeting");
    }

    function removeJournalOfList(journal, delay = 0) {
        const vm = this;
        setTimeout(() => {
            let journalIndex = vm.journals.data.findIndex((myJournal) => {
                return myJournal.id === journal.id;
            });
            vm.journals.data.splice(journalIndex, 1);

            if (vm.journals.data.length === 0) {
                vm.$emit("remove-feedback-meeting");
            }
        }, delay);
    }
}

function getWatchers() {
    return {
        job: {
            handler: getJobJournals,
            immediate: true,
        },
    };

    function getJobJournals() {
        this.getJournals.apply(this);
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-timeline {
    margin-left: -40px;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

.flip-list-move {
    transition: transform 0.5s;
}

.flip-list-enter-active,
.flip-list-leave-active {
    transition: opacity 0.5s;
}

.flip-list-enter,
.flip-list-leave-to {
    opacity: 0;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.noEditing {
    opacity: 0.5;
    transition: all 0.5s linear;

    &:hover {
        opacity: 1;
    }
}

::v-deep .v-btn > .v-btn__content .v-icon {
    transition: all 0.5s;
}

::v-deep .v-btn > .v-btn__content .v-icon.trash-btn:hover {
    color: #fb4242;
}
::v-deep .v-btn > .v-btn__content .v-icon.save-btn:hover {
    color: #4caf50;
}

::v-deep
    .theme--light.v-text-field--filled:not(.v-input--is-focused):not(
        .v-input--has-state
    )
    > .v-input__control
    > .v-input__slot:hover {
    background: rgba(0, 0, 0, 0.06);
}

::v-deep .theme--light.v-input--is-disabled.card-textarea textarea {
    color: rgba(0, 0, 0, 0.7);
}
</style>
