<template>
    <div class="editable-item">
        <div v-if="meta_.canEdit && isEditing">
            <component
                v-bind:is="meta_.editor"
                v-model="value_"
                :meta="meta"
                @change="notifyChange"
                :originalValue="originalValue"
            ></component>
        </div>
        <component
            v-else
            v-bind:is="meta_.viewer"
            :value="value_"
            :meta="meta"
        ></component>
    </div>
</template>

<script>
import defaultViewer from "Components/overview/utils/editable-item-default-viewer";
import textField from "Components/overview/utils/editable-item-input";
import datePicker from "Components/overview/utils/editable-item-date-picker";
import dateTimePicker from "Components/overview/utils/editable-item-date-time-picker";
import textList from "Components/overview/utils/editable-item-text-list";
import arrayList from "Components/overview/utils/editable-item-array-list";
import timePicker from "Components/overview/utils/editable-item-time-picker";
import durationPicker from "Components/overview/utils/editable-item-duration-picker";

export default {
    name: "editable-item",
    data: data,
    watch: getWatched(),
    props: getProps(),
    methods: getMethods(),
    components: {
        textField,
        datePicker,
        dateTimePicker,
        textList,
        defaultViewer,
        arrayList,
        timePicker,
        durationPicker,
    },
    computed: getComputed(),
};

function getMethods() {
    return {
        notifyChange: notifyChange,
    };

    function notifyChange(event) {
        if (event && event.target && event.target.value) {
            this.$emit("change", event.target.value);
        } else {
            this.$emit("change", event);
        }
    }
}
function getProps() {
    return {
        meta: Object,
        isEditing: Boolean,
        value: [String, Number, Array, Object],
    };
}
function getWatched() {
    return {
        isEditing: isEditing,
        value: {
            handler: value,
            immediate: true,
        },
    };

    function isEditing() {
        const vm = this;
        if (this.isEditing === true) {
            vm.$emit("edit-started");
        }
    }

    function value() {
        this.value_ = this.value;
    }
}

function getComputed() {
    return {
        meta_: meta_,
    };

    function meta_() {
        var m = this.meta;
        if (this.meta.canEdit && !this.meta.editor) {
            m.editor = "text-field";
        }
        if (!this.meta.viewer) {
            m.viewer = "default-viewer";
        }
        return m;
    }
}
function data() {
    return {
        value_: "",
        originalValue: this.value,
    };
}
</script>

<style scoped>
input,
select {
    border-style: revert;
    background-color: revert;
    border-radius: revert;
}

select {
    -moz-appearance: revert;
    -webkit-appearance: revert;
}

input {
    width: 100%;
    line-height: 1.5;
    padding: 1px;
    padding-left: 5px;
}

div.editable-item {
    min-height: 24px;
}
</style>
