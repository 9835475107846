<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="500px"
    >
        <v-card class="mx-auto text-left" tile>
            <v-card-title
                v-if="item.isJobCollection"
                :class="{ 'pb-0': !item.serial_number }"
            >
                <span class="job-collection">Job collection </span> |
                {{ item.name }}
            </v-card-title>
            <v-card-title v-else :class="{ 'pb-0': !item.serial_number }">
                Job | {{ item.name }}
            </v-card-title>
            <v-card-subtitle
                v-if="item.serial_number"
                class="pb-0"
                :class="{ 'pt-2': item.isJobCollection }"
            >
                {{ item.serial_number }}
            </v-card-subtitle>
            <v-list>
                <v-subheader>Actions</v-subheader>
                <v-list-item :to="`/app/sales-orders/${item.sales_order_id}`">
                    <v-list-item-icon>
                        <v-icon>mdi-newspaper-variant-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Sales Order</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="$can('read-sales-report') && !item.isJobCollection"
                    :to="`/app/sales-order-reports/job-cost-calculations/${item.id}`"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-cash-usd-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title
                            >Job Cost Calculations</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="!item.isJobCollection"
                    @click="$emit('open-reports', item)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-chart-areaspline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Reports</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="item.isJobCollection"
                    @click="$emit('build-job-collection', item)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-hammer-wrench</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Build</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-else @click="$emit('edit-job', item)">
                    <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-if="item.isJobCollection && existsExpandedJobCollection"
                    @click="$emit('collapse', item)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-chevron-up</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Hide Jobs</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-else-if="item.isJobCollection"
                    @click="$emit('expand', item)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Show Jobs</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-else-if="item.deleted_at || isTrashing"
                    @click="$emit('restore', item)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-backup-restore</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Unarchive</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    v-else
                    @click="($event) => $emit('destroy', $event, item)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-archive</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Archive</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
import permissions from "Mixins/permissions.mixin";

export default {
    mixins: [permissions],

    data() {
        return {
            show: true,
        };
    },

    props: {
        item: Object,
        isTrashing: Boolean,
        existsExpandedJobCollection: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.job-collection {
    border-radius: 10px;
    padding: 0px 8px;
    background-color: #7ca6ad;
    color: white;
    margin-right: 8px;
    margin-left: -8px;
}
</style>
