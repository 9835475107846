<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="12" md="7" lg="5">
                    <process-list @selected="setActiveProcess"></process-list>
                </v-col>
                <v-col cols="12" md="5" lg="7">
                    <process-report
                        v-if="$route.params.id"
                        :process="activeProcess"
                    >
                    </process-report>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import processList from "./process-list";
import processReport from "./process-report";

export default {
    name: "process-layout-vuetify",
    components: { pageHeaderVuetify, processList, processReport },
    data() {
        return {
            activeProcess: {},
        };
    },
    methods: {
        setActiveProcess(process) {
            this.activeProcess = process;
        },
    },
};
</script>
