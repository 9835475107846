import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {},
        menuOptions: [],
        processes: [],
        errors: [],
        messages: [],
        unavailableReasons: [],
        machineDowntimes: [],
    },
    mutations: {
        user(state, payload) {
            Vue.set(state, "user", payload);
        },
        unavailableReasons(state, payload) {
            Vue.set(state, "unavailableReasons", payload);
        },
        machineDowntimes(state, payload) {
            Vue.set(state, "machineDowntimes", payload);
        },
        menuOptions(state, payload) {
            Vue.set(state, "menuOptions", payload);
        },
        processes(state, payload) {
            Vue.set(state, "processes", payload);
        },
        addError(state, payload) {
            state.errors.push(payload);
        },
        clearErrors(state) {
            state.errors.splice(0, state.errors.length);
        },
        addMessage(state, payload) {
            state.messages.push(payload);
        },
        clearMessages(state) {
            state.messages.splice(0, state.messages.length);
        },
        storeMachineDowntime(state, machineDowntime) {
            let machineDownTimes =
                state.machineDowntimes[machineDowntime.station_id];
            if (!machineDownTimes) {
                Vue.set(state.machineDowntimes, machineDowntime.station_id, [
                    machineDowntime,
                ]);
                return;
            }

            let machineDowntimeIndex = machineDownTimes.findIndex(
                (downTime) => downTime.id === machineDowntime.id
            );
            if (machineDowntimeIndex === -1) {
                machineDownTimes.unshift(machineDowntime);
            } else {
                machineDownTimes.splice(
                    machineDowntimeIndex,
                    1,
                    machineDowntime
                );
            }
        },
        destroyMachineDowntime(state, machineDowntime) {
            let machineDownTimes =
                state.machineDowntimes[machineDowntime.station_id];
            let machineDowntimeIndex = machineDownTimes.findIndex(
                (downTime) => downTime.id === machineDowntime.id
            );
            machineDownTimes.splice(machineDowntimeIndex, 1);
        },
    },
    getters: {
        user: (state) => () => state.user,
        unavailableReasons: (state) => () => state.unavailableReasons,
        machineDowntimes: (state) => () => state.machineDowntimes,
        menuOptions: (state) => () => state.menuOptions,
        processes: (state) => () => state.processes,
        errors: (state) => () => state.errors,
        messages: (state) => () => state.messages,
    },
    actions: {
        getUser(context) {
            return new Promise((resolve, reject) => {
                const axiosInstance = axios.create({});
                axiosInstance.get("/api/user").then(success, fail);

                function success(response) {
                    context.commit("user", response.data);
                    resolve();
                }

                function fail(error) {
                    reject(error);
                }
            });
        },
    },
});
