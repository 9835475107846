export default function (value) {
    const duration = Vue.moment.duration(parseInt(value), "seconds");
    if (Vue.moment.isDuration(duration)) {
        let hours = duration.hours();
        let minutes = duration.minutes();
        return `${hours}h${minutes}m`;
    } else {
        return "";
    }
}
