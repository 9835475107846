export default {
    methods: {
        $checkStepHours(hours) {
            const pattern = /^[0-9]{1,13}(\.[0-9]{1,2})?$/;
            if (pattern.test(hours)) {
                return true;
            }
            return "Invalid format";
        },

        $checkEstimatedHours(steps) {
            for (const step of steps) {
                let estimatedHours = step.estimated_hours;
                let isValid =
                    estimatedHours !== "" &&
                    this.$checkStepHours(estimatedHours) === true;

                if (!isValid) {
                    return false;
                }
            }
            return true;
        },
    },
};
