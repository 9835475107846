<template>
    <v-data-table
        :headers="headers"
        :items="jobs"
        class="elevation-1"
        :loading="loading"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title
                    >{{ process.name }} Report - Job Detail</v-toolbar-title
                >
            </v-toolbar>
        </template>
        <template v-slot:item.all_hours="{ item }">
            {{ item.all_hours | round }}
        </template>
        <template v-slot:item.estimated_hours="{ item }">
            {{ item.estimated_hours | round }}
        </template>
        <template v-slot:item.percent_complete="{ item }">
            {{ item.percent_complete | round | percent }}
        </template>
    </v-data-table>
</template>

<script>
import round from "Filters/round";
import percent from "Filters/percent";

export default {
    data: data,
    watch: getWatchers(),
    props: {
        process: Object,
    },
    filters: { round, percent },
};

function data() {
    return {
        headers: [
            {
                text: "Job name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actual hours",
                align: "center",
                sortable: false,
                value: "all_hours",
                class: "grey--text text--darken-3",
            },
            {
                text: "Estimated hours",
                align: "center",
                sortable: false,
                value: "estimated_hours",
                class: "grey--text text--darken-3",
            },
            {
                text: "Percent complete",
                align: "center",
                sortable: false,
                value: "percent_complete",
                class: "grey--text text--darken-3",
            },
        ],
        jobs: [],
        loading: false,
    };
}

function getWatchers() {
    return {
        "$route.params.id": {
            handler: getProcess,
            immediate: true,
        },
    };

    function getProcess() {
        const vm = this;
        vm.loading = true;

        Vue.backendApi()
            .reports()
            .processes(vm.$route.params.id)
            .jobs()
            .then(success, fail);

        function success(response) {
            Vue.set(vm, "jobs", response.data);
            vm.loading = false;
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get process report");
        }
    }
}
</script>
