import { Drag, DropList } from "vue-easy-dnd";
import hoursControl from "Components/overview/building/machine/shift/machine-shift-hours-control";
import overlappingModal from "Components/overview/building/machine/shift/jobs/work-downtime-overlapping-modal.vue";

export default {
    components: {
        hoursControl,
        Drag,
        DropList,
        overlappingModal,
    },

    props: {
        shift: Object,
        machine: Object,
        currentMachineDowntime: Object,
        hasDowntime: Boolean,
        machineDowntimes: Array,
    },

    data() {
        return {
            selectedJobHistory: null,
            selectedJobHistoryMenu: null,
            jobHistories: [],
            removing: [],
            showOnlyOneJob: true,
            draggingJob: false,
            overlapping: null,
            isCtrlKeyPressed: false,
        };
    },

    watch: {
        selectedJobHistory: function () {
            const vm = this;
            let isSelectedJobHistory = vm.selectedJobHistory ? true : false;
            vm.$emit("showingHoursControl", isSelectedJobHistory);
            vm.showOnlyOneJob = !isSelectedJobHistory;
        },

        selectedJobHistoryMenu: function () {
            const vm = this;
            let isSelectedJobHistoryMenu = vm.selectedJobHistoryMenu
                ? true
                : false;
            vm.$emit("showingHoursControl", isSelectedJobHistoryMenu);
        },
    },

    methods: {
        groupedJobHistoryByJob: function () {
            let jobHistories = [];
            let seenJobHistory = {};

            this.shift.job_history.forEach((jobHistory) => {
                if (!seenJobHistory[jobHistory.job_id]) {
                    let index =
                        jobHistories.push({
                            ...jobHistory,
                            shifts: [{ ...jobHistory }],
                        }) - 1;
                    seenJobHistory[jobHistory.job_id] = { index };
                } else {
                    let index = seenJobHistory[jobHistory.job_id].index;
                    jobHistories[index].shifts.push(jobHistory);
                }
            });
            return jobHistories;
        },

        /**
         * Save job history after job it been dropped on machine shift
         */
        onDropJob(drop) {
            const vm = this;
            let job = drop.data;
            if (hasJob(job)) {
                return;
            }

            let payload = {
                id: getPendingId(),
                job: job,
                job_id: job.id,
                station_shift_id: vm.shift.id,
                started_at: vm.shift.started_at,
                ended_at: vm.shift.ended_at,
            };
            vm.shift.job_history.splice(drop.addedIndex, 0, payload);
            vm.saveJobHistory.call(this, payload);

            function getPendingId() {
                return "pending-" + Math.floor(Math.random() * 2000);
            }

            function hasJob(job) {
                return (
                    vm.shift.job_history.findIndex(
                        (shift) => shift.job_id === job.id
                    ) !== -1
                );
            }
        },

        isSelected(id) {
            if (id === undefined || !this.selectedJobHistory) {
                return false;
            }
            return this.selectedJobHistory.job_id === id;
        },

        cancelHoursChanges() {
            this.selectedJobHistory = null;
        },

        showSetHours(jobHistory) {
            this.selectedJobHistory = jobHistory;
        },

        getChildPayload(index) {
            return this.jobHistories[index].job;
        },

        shouldAcceptDrop(item) {
            if (this.currentMachineDowntime) {
                return false;
            }
            const isEmployee = ["user", "admin"].includes(item.type);
            return item.isJobCollection || isEmployee ? false : true;
        },

        removeJobHistory(jobHistory) {
            const vm = this;
            vm.removing.push(jobHistory.id);

            for (const shiftJob of jobHistory.shifts) {
                this.removeShift(shiftJob);
            }
        },

        removeShift(shiftJob) {
            const vm = this;

            Vue.backendApi()
                .stationShift(vm.shift.id)
                .destroyJobHistory(shiftJob.id)
                .then(success, fail);

            function success(response) {
                vm.$eventHub.$emit("job-history-removed", shiftJob);
                vm.$spliceById(
                    vm.shift.job_history,
                    shiftJob.id,
                    (shift) => shift.id === shiftJob.id
                );

                if (vm.overlapping) {
                    vm.overlapping = null;
                }
            }

            function fail(error) {
                console.log("An error occurred trying to delete time");
            }
        },

        isRemoving(jobHistory) {
            return this.removing.some((id) => jobHistory.id === id);
        },

        addTime(jobHistory) {
            const vm = this;
            let payload = {
                station_shift_id: vm.shift.id,
                job_id: jobHistory.job_id,
                started_at: vm.shift.started_at,
                ended_at: vm.shift.ended_at
            };

            Vue.backendApi()
                .stationShift(vm.shift.id)
                .addJobToShift(payload.job_id, payload)
                .then(success, fail);

            function success(response) {
                vm.shift.job_history.push(response.data);
                vm.$eventHub.$emit("job-history-added", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to add time");
            }
        },

        updateStationShiftJob(shiftJob) {
            let shiftJobIndex = this.shift.job_history.findIndex((shift) => {
                return shift.id === shiftJob.id;
            });
            this.shift.job_history.splice(shiftJobIndex, 1, shiftJob);
        }
    },
};
