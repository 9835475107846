<template>
    <div>
        <div class="mb-3 shift-top">
            <span class="shift-time-summary">{{ shiftSummary }}</span>
            <div v-if="selectedJobHistories.length > 0" class="text-right">
                <span class="mr-2"><b>Selected work:</b></span>
                <button
                    class="btn btn-primary btn-sm"
                    @click="editBulkWorkModal = true"
                >
                    Edit
                </button>
                <button class="btn btn-danger btn-sm" @click="removeBulkJobHistory">
                    Delete
                </button>
            </div>
        </div>

        <edit-bulk-work-modal
            v-if="editBulkWorkModal"
            :shift="shift"
            :selected-job-histories="selectedJobHistories"
            @close="editBulkWorkModal = false"
            @updatedStationShiftJob="updateStationShiftJob"
        ></edit-bulk-work-modal>

        <drop-list
            :items="jobHistories"
            class="job-container"
            @insert="onDropJob"
            @reorder="$event.apply(jobHistories)"
            :accepts-data="shouldAcceptDrop"
        >
            <template v-slot:item="{ item: jobHistory }">
                <drag
                    class="job-item"
                    :data="jobHistory.job"
                    :class="{
                        removing: isRemoving(jobHistory),
                    }"
                    :key="jobHistory.job.id"
                    @dragstart="draggingJob = true"
                    @dragend="draggingJob = false"
                >
                    <machine-shift-job
                        :jobHistory="jobHistory"
                        v-model="selectedJobHistories"
                        @showModal="
                            (val, activator) =>
                                $emit('showModal', val, activator)
                        "
                        @removeJobHistory="removeJobHistory"
                        @addTime="addTime"
                        @removeTime="removeShift"
                        @updateStationShiftJob="updateStationShiftJob"
                    >
                    </machine-shift-job>
                </drag>
            </template>
            <template v-slot:feedback="{ data: job }">
                <div class="job-item ghost" :key="job.id">{{ job.name }}</div>
            </template>
        </drop-list>
        <overlapping-modal
            v-if="overlapping"
            :machineDowntime="overlapping.machineDowntime"
            :jobHistory="overlapping.jobHistory"
            @close="overlapping = null"
            @removeJobHistory="removeShift"
        >
        </overlapping-modal>
    </div>
</template>

<script>
import editBulkWorkModal from "Components/overview/building/machine/machine-modal/edit-bulk-work-modal";
import machineShiftJobListMixin from "Components/overview/building/machine/mixins/machine-shift-job-list.mixin";
import machineShiftJob from "Components/overview/building/machine/machine-modal/machine-shift-job";

export default {
    mixins: [machineShiftJobListMixin],

    components: { editBulkWorkModal, machineShiftJob },

    data() {
        return {
            selectedJobHistories: [],
            editBulkWorkModal: false,
        };
    },

    props: {
        shiftIndex: Number,
    },

    computed: {
        shiftSummary() {
            let shift = this.shift.station.schedule.shifts[this.shiftIndex];
            return `${shift.name} (${Vue.moment(this.shift.started_at).format(
                "h:mm a"
            )} - ${Vue.moment(this.shift.ended_at).format("h:mm a")})`;
        },
    },

    mounted: mounted,

    watch: {
        shift: {
            immediate: true,
            deep: true,
            handler: "showJobHistories",
        },
    },

    methods: {
        showJobHistories() {
            let jobHistoryByJob = this.groupedJobHistoryByJob();
            Vue.set(this, "jobHistories", jobHistoryByJob);
        },

        saveJobHistory(payload) {
            const vm = this;
            const jobHistoryId = payload.id;

            Vue.backendApi()
                .stationShift(vm.shift.id)
                .addJobToShift(payload.job_id, payload)
                .then(success, failure);

            function success(response) {
                replaceJobHistory();

                if (vm.hasDowntime) {
                    showDowntimeWarningIfExistsOverlapping();
                }

                if (vm.isCtrlKeyPressed) {
                    Vue.nextTick(function () {
                        vm.$eventHub.$emit("showJobHoursEditor", response.data);
                    });
                }

                function replaceJobHistory() {
                    let replaceIndex = payload.pending_id
                        ? vm.shift.job_history.findIndex(
                              (jobHistory) =>
                                  jobHistory.pending_id === jobHistoryId
                          )
                        : vm.shift.job_history.findIndex(
                              (jobHistory) => jobHistory.id === jobHistoryId
                          );

                    if (replaceIndex > -1) {
                        vm.shift.job_history.splice(
                            replaceIndex,
                            1,
                            response.data
                        );
                    }
                    vm.$eventHub.$emit("job-history-added", response.data);
                }

                function showDowntimeWarningIfExistsOverlapping() {
                    let lastDowntimeIndex = vm.machineDowntimes.length - 1;
                    let downtime = vm.machineDowntimes[lastDowntimeIndex];
                    let downtimeEnd = Vue.moment(downtime.ended_at);
                    let shiftTimeStart = Vue.moment(payload.started_at);
                    let existsOverlapping =
                        shiftTimeStart.isBefore(downtimeEnd);

                    if (existsOverlapping) {
                        vm.overlapping = {
                            machineDowntime: downtime,
                            jobHistory: response.data,
                        };
                    }
                }
            }

            function failure(error) {
                let index = vm.shift.job_history.findIndex(
                    (jobHistory) => jobHistory.id === jobHistoryId
                );
                vm.shift.job_history.splice(index, 1);
            }
        },

        removeBulkJobHistory() {
            for (const jobHistory of this.selectedJobHistories) {
                this.removeJobHistory(jobHistory);
            }
            this.selectedJobHistories = [];
        },
    },
};

function mounted() {
    const vm = this;

    window.addEventListener("keydown", function (e) {
        if (e.ctrlKey) {
            vm.isCtrlKeyPressed = true;
        }
    });

    window.addEventListener("keyup", function (e) {
        if (e.key === "Control") {
            vm.isCtrlKeyPressed = false;
        }
    });
}
</script>

<style scoped lang="scss">
.job-container {
    min-height: 22px;
}

.shift-time-summary {
    font-style: italic;
    border-bottom: 1px solid #999;
    font-size: 12px;
}

.editing {
    border: 1px dashed red;
}

.removing {
    opacity: 0.5;
    text-decoration: line-through;
}

.job-item {
    position: relative;
    padding: 2px;

    &.ghost {
        background-color: rgb(255, 220, 220);
        border: 2px dashed black;
    }
}

.shift-top {
    height: 31px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
