<template>
    <v-dialog
        persistent
        @click:outside="confirmClose"
        @keydown.esc="confirmClose"
        v-model="show"
        max-width="500px"
    >
        <v-card>
            <v-card-title class="primary white--text">
                <span class="headline">
                    {{ formTitle }}
                </span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" sm="8" md="8">
                            <v-text-field
                                v-model="job.name"
                                label="Name"
                                @input="isDirty = true"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-menu
                                v-model="showDueAtPicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="itemDueAt"
                                        label="Due In"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        @input="isDirty = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="job.due_at"
                                    @input="
                                        showDueAtPicker = false;
                                        isDirty = true;
                                    "
                                    @change="dateChanged"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <blockers-editor
                                :blockers="job.blockers"
                                @addBlocker="addBlocker"
                                @deleteBlocker="deleteBlocker"
                            >
                            </blockers-editor>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="confirmClose">Cancel </v-btn>
                <v-btn :color="isDirty ? 'primary' : ''" @click="save"
                    >Save</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import blockersEditor from "Components/jobs/blockers-editor";

export default {
    components: { blockersEditor },

    data() {
        return {
            show: true,
            isDirty: false,
            showDueAtPicker: false,
        };
    },

    props: {
        job: Object,
        jobIndexes: Object,
    },

    computed: {
        formTitle() {
            return this.jobIndexes ? "Edit Job" : "New Job";
        },

        itemDueAt() {
            const dateFormatted = Vue.moment(this.job.due_at);
            if (dateFormatted.isValid()) {
                return dateFormatted.format("DD / MM / YYYY");
            } else {
                return "";
            }
        },
    },

    methods: {
        save() {
            const vm = this;

            if (vm.job.id === "new-job-id") {
                Vue.backendApi().jobs().store(vm.job).then(success, fail);
            } else {
                Vue.backendApi()
                    .jobs(vm.job.id)
                    .store(vm.job)
                    .then(success, fail);
            }

            function success(response) {
                vm.$emit("saved", response.data);
                vm.close();
            }

            function fail(error) {
                console.log("An error occurred trying to save a job");
                console.log(error);
            }
        },

        confirmClose() {
            if (!this.isDirty) {
                this.close();
                return;
            }

            if (confirm("Unsaved changes!\nDo you really want to close?")) {
                this.close();
            }
        },

        close() {
            this.isDirty = false;
            this.$emit("close");
        },

        addBlocker(blocker) {
            this.job.blockers.push(blocker);
        },

        deleteBlocker(blockerIndex) {
            this.job.blockers.splice(blockerIndex, 1);
        },

        dateChanged(date) {
            this.job.due_at = date + " 00:00:00";
        },
    },
};
</script>
