<template>
    <div class="job-item d-flex align-items-center">
        <v-checkbox
            v-model="selected"
            dense
            hide-details
            class="p-0 m-0"
        ></v-checkbox>

        <router-link
            :to="`/app/jobs/${jobHistory.job.id}/reports/job-processes`"
        >
            {{ jobHistory.job.name }}
        </router-link>

        <div class="ml-auto">
            <serial-number>{{ jobHistory.job.serial_number }}</serial-number>
            <type-of-hours :job-shifts="jobHistory.shifts"></type-of-hours>
        </div>

        <div v-if="jobHistory.created_at" class="history-options-box">
            <i
                class="fas fa-edit"
                @click="showJobHoursEditor = true"
                title="Edit"
            ></i>
            <i
                class="fa fa-trash"
                title="Delete"
                @click="$emit('removeJobHistory', jobHistory)"
            ></i>
        </div>

        <warning-multiple-job-shifts
            v-if="showWarning"
            @close="showWarning = false"
        ></warning-multiple-job-shifts>

        <hours-control
            v-if="showJobHoursEditor"
            @close="showJobHoursEditor = false"
            :jobHistory="jobHistory"
            @cancel="showJobHoursEditor = false"
            @addTime="(shiftJob) => $emit('addTime', shiftJob)"
            @removeTime="(shiftJob) => $emit('removeTime', shiftJob)"
            @updatedStationShiftJob="
                (shiftJob) => $emit('updateStationShiftJob', shiftJob)
            "
        ></hours-control>
    </div>
</template>

<script>
import machineShiftJobMixin from "Components/overview/building/machine/mixins/machine-shift-job.mixin";
import typeOfHours from "Components/overview/building/machine/machine-modal/type-of-hours";
import serialNumber from "Components/overview/building/machine/machine-modal/serial-number";
import warningMultipleJobShifts from "Components/overview/building/machine/machine-modal/warning-multiple-job-shifts";

export default {
    mixins: [machineShiftJobMixin],

    components: {
        typeOfHours,
        serialNumber,
        warningMultipleJobShifts,
    },

    props: {
        value: Array,
    },

    watch: {
        selected: function () {
            let selectedJobs = this.value;
            let jobHistory = this.jobHistory;

            if (this.selected) {
                selectedJobs.push(jobHistory);

                if (jobHistory.shifts.length > 1) {
                    this.showWarning = true;
                }
            } else {
                let selectedJobIndex = selectedJobs.findIndex(
                    (item) => item.id === jobHistory.id
                );
                if (selectedJobIndex !== -1) {
                    selectedJobs.splice(selectedJobIndex, 1);
                }
            }
            this.$emit("input", selectedJobs);
        },
    },

    data() {
        return {
            selected: false,
            showWarning: false,
        };
    },

    mounted() {
        const vm = this;
        vm.$eventHub.$on("showJobHoursEditor", (jobHistory) => {
            if (jobHistory.id === vm.jobHistory.id) {
                vm.showJobHoursEditor = true;
                Vue.nextTick(function () {
                    vm.$eventHub.$emit("editJobShiftTime", jobHistory);
                });
            }
        });
    },
};
</script>

<style scoped lang="scss">
.history-options-box {
    display: none;
    position: absolute;
    top: 2px;
    right: 2px;
}

.fas.fa-edit,
.fa.fa-trash {
    border-radius: 50%;
    padding: 4px;
    background-color: burlywood;
    margin-left: 3px;

    &:hover {
        cursor: pointer;
    }
}

.fas.fa-edit {
    background-color: rgb(169, 217, 176);
}

.job-item {
    font-weight: 500;
    transition: all 0.5s;

    a {
        color: #137b8d;
    }
}

.job-item:hover {
    background-color: rgba(0, 0, 0, 0.07);
    padding-left: 8px;

    .history-options-box {
        display: block;
    }
}
</style>
