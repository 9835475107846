export default {
    methods: {
        $scrollDown() {
            const cardTextElement = document.getElementById("steps-container");
            cardTextElement.scroll({
                top: parseInt(cardTextElement.scrollHeight),
                left: 0,
                behavior: "smooth",
            });
        },
    },
};
