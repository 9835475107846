<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>

            <v-toolbar flat color="primary darken-2" dark class="mb-3">
                <v-toolbar-title>Machine Time Report</v-toolbar-title>

                <v-divider class="ml-4" inset vertical></v-divider>

                <v-switch
                    label="Custom dates"
                    class="ml-4"
                    v-model="withCustomDates"
                    hide-details
                ></v-switch>

                <v-divider class="ml-4" inset vertical></v-divider>

                <v-dialog ref="dialog" v-model="showDatePicker" width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-col cols="2">
                            <v-text-field
                                v-if="withCustomDates"
                                label="Dates"
                                :value="customDatesString"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                class="date-input"
                            ></v-text-field>
                            <v-text-field
                                v-else
                                label="Month"
                                :value="monthFormatted"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                class="date-input"
                            ></v-text-field>
                        </v-col>
                    </template>
                    <v-calendar
                        v-show="withCustomDates"
                        :is-range="true"
                        :attributes="[
                            {
                                highlight: {
                                    backgroundColor: '#ddd',
                                },
                            },
                        ]"
                        mode="single"
                        v-model="customDates"
                        is-inline
                        is-expanded
                        @input="customDateChanged"
                    ></v-calendar>
                    <v-date-picker
                        v-show="!withCustomDates"
                        v-model="monthString"
                        type="month"
                        @change="monthChanged"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="showDatePicker = false"
                        >
                            Cancel
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-toolbar>
            <hr />
            <v-row v-for="building in buildings" :key="building.id">
                <v-col cols="12">
                    <building-report
                        :building="building"
                        :dates="datesForReport"
                    ></building-report>
                    <hr />
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import buildingReport from "Components/machine-time-report/building-report";
import VCalendar from "v-calendar/lib/components/date-picker.umd";
import toolbarDates from "Mixins/toolbarDates.mixin";

export default {
    mixins: [toolbarDates],

    components: { pageHeaderVuetify, buildingReport, VCalendar },

    data() {
        return {
            buildings: [],
            routeName: "machine-time-report",
        };
    },

    computed: {
        datesForReport() {
            return this.getDatesForReport();
        },
    },

    methods: {
        getRouterQuery() {
            const vm = this;
            let query = { ...vm.$route.query };
            return query;
        },

        getReport() {
            const vm = this;

            Vue.backendApi()
                .reports()
                .machineTime()
                .index(vm.datesForReport)
                .then(success, fail);

            function success(response) {
                Vue.set(vm, "buildings", response.data);
            }

            function fail(error) {
                console.log(
                    "Has ocurred an errror trying to get machine time report"
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input--switch label {
    color: white;
    margin-bottom: 0px;
}

::v-deep .theme--light {
    .v-text-field {
        .v-input__control > .v-input__slot:before {
            border-color: #e0e0e0;
        }

        .v-label {
            top: 4px;
        }
    }
}

::v-deep .theme--light .v-card__title > .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

::v-deep .date-input .v-input__control {
    margin-top: 8px;
}
</style>
