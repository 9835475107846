<template>
    <div class="label-filtering">
        <span>
            Filter by labels
            <span v-if="checkedLabels.length > 0">
                ({{ checkedLabels.length }})
            </span>
        </span>
        <i
            v-if="showLabelSelector"
            @click="showLabelSelector = false"
            class="fas fa-chevron-up"
        ></i>
        <i
            v-else
            @click="showLabelSelector = true"
            class="fas fa-chevron-down"
        ></i>

        <div
            class="label-selector"
            :class="{
                show: showLabelSelector,
            }"
        >
            <div
                v-for="label in labels"
                class="custom-control custom-checkbox"
                :key="label.id"
            >
                <input
                    type="checkbox"
                    :id="`${label.id}-checkbox`"
                    v-model="label.isChecked"
                    :value="label.id"
                    @change="addLabel(label)"
                    class="custom-control-input"
                />

                <label
                    :for="`${label.id}-checkbox`"
                    class="custom-control-label text-uppercase"
                >
                    {{ label.name }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: data,
    methods: getMethods(),
    mounted: mounted,
    props: {
        value: Array,
    },
};

function data() {
    return {
        showLabelSelector: false,
        checkedLabels: [],
        labels: [],
    };
}

function getMethods() {
    return {
        addLabel: addLabel,
    };

    function addLabel(label) {
        if (label.isChecked) {
            this.checkedLabels.push(label);
        } else {
            this.checkedLabels = this.checkedLabels.filter(
                (chekedLabel) => chekedLabel.id !== label.id
            );
        }
        this.$emit("input", this.checkedLabels);
    }
}

function mounted() {
    const vm = this;

    Vue.backendApi().labels().index().then(success, fail);

    function success(response) {
        Vue.set(vm, "labels", response.data);
    }

    function fail(error) {
        console.log("An error occurred trying to get labels");
    }
}
</script>

<style lang="scss">
.label-filtering {
    color: #95969a;
    border-bottom: 1px solid #bcbdbe;
    padding: 8px 15px 8px 15px;

    & i {
        cursor: pointer;
        font-size: 18px;
        float: right;
    }
}

.label-selector {
    background: #fff;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    line-height: 0;
    padding: 0;
    padding-left: 3px;
    padding-bottom: 3px;
    color: transparent;

    .custom-control {
        min-height: auto;
    }
}

.label-selector.show {
    line-height: 1.5;
    color: black;

    .custom-control-label {
        padding-top: 2px;
        cursor: pointer;
    }
}
</style>
