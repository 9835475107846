export default function (value) {
    const dueAt = Vue.moment(value, "YYYY-MM-DD HH:mm:ss");
    if (!dueAt.isValid()) {
        return "";
    }

    const now = Vue.moment();
    let dueIn = Vue.moment.duration(dueAt.diff(now));
    if (Math.abs(Math.round(dueIn.as("days"))) === 1) {
        return Math.round(dueIn.as("days")) + " day";
    } else {
        return Math.round(dueIn.as("days")) + " days";
    }
}
