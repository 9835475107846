<template>
    <div class="main-container" :class="$route.name">
        <kheera-errors
            v-if="errors.length > 0"
            :errors="errors"
        ></kheera-errors>
        <kheera-messages
            v-if="messages.length > 0"
            :messages="messages"
        ></kheera-messages>
        <router-view @processes-updated="getProcesses"></router-view>
        <overlay-waiting-spinner
            v-if="loading.length > 0"
        ></overlay-waiting-spinner>
    </div>
</template>

<script>
import kheeraErrors from "Components/overview/utils/kheera-errors";
import kheeraMessages from "Components/overview/utils/kheera-messages";

export default {
    created: created,
    data: data,
    components: { kheeraErrors, kheeraMessages },
    mounted: mounted,
    methods: getMethods(),
    watch: getWatchers(),
};

function data() {
    return {
        loading: ["user"],
        errors: [],
        messages: [],
    };
}

function mounted() {
    const vm = this;
    this.$store.watch(this.$store.getters.errors, (errors) => {
        Vue.set(vm, "errors", errors);
    });
    this.$store.watch(this.$store.getters.messages, (messages) => {
        Vue.set(vm, "messages", messages);
    });
}

function created() {
    const vm = this;
    vm.getProcesses.apply(vm);
    getMenuOptions();

    function getMenuOptions() {
        Vue.backendApi().menuOptions().index().then(success, fail);

        function success(response) {
            vm.$store.commit("menuOptions", response.data);
        }

        function fail(error) {
            console.log(error);
        }
    }
}

function getMethods() {
    return {
        getProcesses: getProcesses,
    };

    function getProcesses() {
        const vm = this;
        console.log("loading processes....");
        vm.loading.push("processes");
        Vue.backendApi().processes().index().then(success, fail);
        function success(response) {
            vm.loading = vm.loading.filter((element) => {
                return element !== "processes";
            });
            vm.$store.commit("processes", response.data);
        }

        function fail(error) {
            vm.loading = vm.loading.filter((element) => {
                return element !== "processes";
            });
        }
    }
}

function getWatchers() {
    return {
        "$store.state.user": function () {
            this.loading = this.loading.filter((element) => {
                return element !== "user";
            });
        },
    };
}
</script>

<style lang="scss" scoped>
#app {
    text-align: center;
}

.main-container {
    margin: 0 auto;
    margin-top: 20px;
    width: 1546px;
}

@media (max-width: 1546px) {
    .main-container {
        margin: 0 auto;
        margin-top: 20px;
        width: auto;
    }
}
</style>

<style lang="scss">
@import "~@/_variables.scss";

.filtered {
    display: none;
}

.nowrap-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nowrap-ellipsis:hover {
    white-space: normal;
    overflow: visible;
}

.show-on-hover {
    display: none;
    &:hover {
        display: block;
    }
}

input,
.button {
    height: 24px;
}
input:not[type="botton"] {
    width: 158px;
    border-style: none;
    padding: 10px;
    background-color: $lightGrey;
}

input[type="button"],
.button {
    float: right;
    width: 100px;
    line-height: initial;
    padding-top: 0;
    padding-bottom: 0;
}

input[type="button"] {
    color: $black;
    background-color: $primaryButtonColor;
}

.mx-input-wrapper .mx-input-append {
    height: inherit;
}

.tab {
    &:hover {
        cursor: pointer;
    }
}

.menu-container {
    display: inline-block;
    float: right;
}
.menu {
    position: relative;
    display: inline-block;
    float: right;
    font-size: 36px;
    z-index: 99999;
    & a {
        color: black;
    }

    & .menu-list {
        position: absolute;
        font-size: 20px;
        left: -206px;
        background-color: white;
        width: 220px;
        border-color: black;
        border-width: 2px;
        border-style: solid;
        text-align: center;

        & a.menu-list-item {
            display: block;
            border-color: black;
            border-bottom: 2px;
            border-style: none none solid none;
            &:hover {
                background-color: #e9ecef;
            }
        }

        & .submenu a:last-child {
            border-style: none;
        }
    }
}
</style>
