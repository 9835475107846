<template>
    <v-card elevation="1">
        <v-card-title>{{ group.name }} - Permissions</v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="6">
                    <dialog-modal
                        v-if="permissionMessage"
                        :title="title"
                        :content="permissionMessage"
                        :showOkButton="false"
                        @closeCancelDialog="cancelDialog"
                    />
                    <group-permissions-by-menu-options
                        v-if="permissions.groupedByMenuOptions"
                        :menuOptionsWithPermissions="
                            permissions.groupedByMenuOptions
                        "
                        :isAdmin="isAdmin"
                        :groupPermissions="groupPermissions"
                        :loading="isLoadingGroupPermissions"
                        @readPermissionChanged="savePermissionChange"
                        @writePermissionChanged="writePermissionChanged"
                    >
                    </group-permissions-by-menu-options>
                </v-col>
                <v-col cols="12" sm="6">
                    <ungrouped-group-permissions
                        v-if="permissions.ungrouped"
                        :permissions="permissions.ungrouped"
                        :isAdmin="isAdmin"
                        :groupPermissions="groupPermissions"
                        :loading="isLoadingGroupPermissions"
                        @permissionChanged="savePermissionChange"
                    >
                    </ungrouped-group-permissions>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import groupPermissionsByMenuOptions from "Components/group-management/group-permissions-by-menu-options";
import ungroupedGroupPermissions from "Components/group-management/ungrouped-group-permissions";
import dialogModal from "Components/utils/dialog-modal";
export default {
    components: {
        dialogModal,
        groupPermissionsByMenuOptions,
        ungroupedGroupPermissions,
    },
    props: {
        group: Object,
    },
    computed: {
        isAdmin: function () {
            return this.group.name === "Admin";
        },
    },
    data: data,
    watch: getWatchers(),
    methods: getMethods(),
    created: created,
};

function getWatchers() {
    return {
        group: {
            handler: getGroupPermissions,
            immediate: true,
        },

        permissions: {
            handler: addAdditionalPropsToPermissions,
            immediate: true,
        },
    };

    function getGroupPermissions() {
        this.getGroupPermissions();
    }

    function addAdditionalPropsToPermissions() {
        if (!this.permissions.groupedByMenuOptions) {
            return;
        }

        this.permissions.groupedByMenuOptions.forEach((menuOption) => {
            menuOption.permissions.forEach((permission) => {
                Vue.set(permission, "isCheck", false);
                Vue.set(permission, "isLoading", false);
                Vue.set(permission, "color", "undefined");
            });
        });
        this.permissions.ungrouped.forEach((permission) => {
            Vue.set(permission, "isCheck", false);
            Vue.set(permission, "isLoading", false);
            Vue.set(permission, "color", "undefined");
        });
    }
}

function data() {
    return {
        title: "Permission",
        permissionMessage: false,
        isLoadingGroupPermissions: false,
        permissions: [],
        groupPermissions: [],
    };
}

function getMethods() {
    return {
        savePermissionChange: savePermissionChange,
        writePermissionChanged: writePermissionChanged,
        storePermission: storePermission,
        destroyPermission: destroyPermission,
        showSuccessAnimation: showSuccessAnimation,
        getPermissions: getPermissions,
        cancelDialog: cancelDialog,
        getGroupPermissions: getGroupPermissions,
    };

    function cancelDialog() {
        const vm = this;
        vm.permissionMessage = false;
    }

    function storePermission(permission) {
        const vm = this;
        return new Promise((resolve, reject) => {
            Vue.backendApi()
                .groups(vm.$route.params.id)
                .permissions(permission.id)
                .store()
                .then(success, fail);

            function success(response) {
                vm.groupPermissions.push({ ...permission });
                resolve(permission);
            }

            function fail(error) {
                vm.permissionMessage =
                    error.response.data.errors.group_id.pop() || false;
                console.log("An error occurred trying to add permission");
                reject(permission);
            }
        });
    }

    function destroyPermission(permission) {
        const vm = this;
        return new Promise((resolve, reject) => {
            Vue.backendApi()
                .groups(vm.$route.params.id)
                .permissions(permission.id)
                .destroy()
                .then(success, fail);

            function success(response) {
                let groupPermissionIndex = vm.groupPermissions.findIndex(
                    (groupPermission) => {
                        return groupPermission.name === permission.name;
                    }
                );
                vm.groupPermissions.splice(groupPermissionIndex, 1);
                resolve(permission);
            }

            function fail(error) {
                vm.permissionMessage =
                    error.response.data.errors.group_id.pop() || false;
                console.log("An error occurred trying to remove permission");
                reject(permission);
            }
        });
    }

    function savePermissionChange(permission) {
        const vm = this;
        permission.isLoading = true;

        if (permission.isCheck) {
            this.storePermission(permission)
                .then(vm.showSuccessAnimation)
                .catch(undoCheckboxAction)
                .finally(always);
        } else {
            this.destroyPermission(permission)
                .catch(undoCheckboxAction)
                .finally(always);
        }

        function undoCheckboxAction(permission) {
            permission.isCheck = !permission.isCheck;
        }

        function always() {
            permission.isLoading = false;
        }
    }

    function writePermissionChanged(permission, readPermission) {
        this.savePermissionChange(permission);
        if (!readPermission.isCheck) {
            readPermission.isCheck = true;
            this.savePermissionChange(readPermission);
        }
    }

    function showSuccessAnimation(permission) {
        permission.color = "success";
        setTimeout(() => (permission.color = "undefined"), 1000);
    }

    function getPermissions() {
        const vm = this;

        Vue.backendApi().permissions().index().then(success, fail);

        function success(response) {
            Vue.set(vm, "permissions", response.data);
        }

        function fail(error) {
            console.log("An error occurred trying to get permissions");
        }
    }

    function getGroupPermissions() {
        const vm = this;
        vm.groupPermissions = [];
        vm.isLoadingGroupPermissions = true;

        Vue.backendApi()
            .groups(vm.group.id)
            .permissions()
            .index()
            .then(success, fail);

        function success(response) {
            vm.isLoadingGroupPermissions = false;
            Vue.set(vm, "groupPermissions", response.data);
        }

        function fail(error) {
            vm.isLoadingGroupPermissions = false;
            console.log("An error occurred trying to get group permissions");
        }
    }
}

function created() {
    const vm = this;
    vm.getPermissions();
}
</script>
