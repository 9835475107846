<template>
    <v-data-table
        :headers="headers"
        :items="typeOfHours"
        class="elevation-1"
        :loading="loading"
        disable-pagination
        hide-default-footer
        :item-class="rowClasses"
    >
        <template v-slot:top>
            <v-toolbar
                flat
                color="white"
                :prominent="$vuetify.breakpoint.width < 394"
            >
                <v-row class="justify-content-between">
                    <v-col cols="auto" class="vertical-centered pr-0 mr-auto">
                        <v-toolbar-title>Type of Hours</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col cols="auto">
                        <v-dialog
                            persistent
                            v-model="showTypeOfHourEditor"
                            max-width="500px"
                            @click:outside="confirmClose"
                            @keydown.esc="confirmClose"
                            @keydown.enter="save"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >Create new
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="primary white--text">
                                    <span class="headline">
                                        {{ formTitle }}
                                    </span>
                                </v-card-title>

                                <v-card-text>
                                    <v-form
                                        ref="editTypeOfHourForm"
                                        onSubmit="return false;"
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                    class="pb-0"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedTypeOfHour.name
                                                        "
                                                        label="Name"
                                                        @input="isDirty = true"
                                                        :rules="[
                                                            () =>
                                                                !!editedTypeOfHour.name ||
                                                                'Required.',
                                                        ]"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <h6 class="text-left">Type</h6>
                                                    <v-radio-group
                                                        v-model="editedTypeOfHour.type"
                                                        mandatory
                                                    >
                                                        <v-radio
                                                            label="Efficient 	"
                                                            value="Efficient 	"
                                                        ></v-radio>
                                                        <v-radio
                                                            label="Non-efficient"
                                                            value="Non-efficient"
                                                        ></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="confirmClose"
                                        >Cancel</v-btn
                                    >
                                    <v-btn color="primary" @click="save"
                                        >Save</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
        <template v-slot:item.is_default="{ item }">
            <v-icon v-if="item.is_default" class="is-default">
                mdi-check-bold
            </v-icon>
            <v-icon v-else class="make-default" @click="makeDefault(item)">
                mdi-check-bold
            </v-icon>
        </template>
        <template v-slot:item.actions="{ item, index }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="edit(item)"
                title="Edit"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="destroy(item, index)"
                title="Delete "
            >
                mdi-archive
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: data,
    mounted: mounted,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1
                ? "New Type of Hour"
                : "Edit Type of Hour";
        },
    },
};

function data() {
    return {
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Type",
                align: "start",
                sortable: false,
                value: "type",
                class: "grey--text text--darken-3",
            },
            {
                text: "Is Default",
                align: "center",
                sortable: false,
                value: "is_default",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        isWithTrashed: false,
        deletingTimeoutId: null,
        loading: false,
        typeOfHours: [],
        showTypeOfHourEditor: false,
        isDirty: false,
        editedIndex: -1,
        editedTypeOfHour: {
            id: "new-type-of-hour-id",
            name: "",
        },
        defaultTypeOfHour: {
            id: "new-type-of-hour-id",
            name: "",
        },
    };
}

function getWatchers() {
    return {
        showTypeOfHourEditor: {
            handler: dialog,
        },
        isWithTrashed: {
            handler: getLists,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function getLists() {
        this.getLists.apply(this);
    }
}

function getMethods() {
    return {
        edit: edit,
        destroy: destroy,
        getLists: getLists,
        close: close,
        confirmClose: confirmClose,
        rowClasses: rowClasses,
        save: save,
        makeDefault: makeDefault
    };

    function rowClasses(typeOfHour) {
        if (typeOfHour.deleted_at || typeOfHour.isTrashing) {
            return "user-archived";
        }
        return "";
    }

    function getLists() {
        const vm = this;
        vm.loading = true;

        Vue.backendApi().typeOfHours().index().then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "typeOfHours", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get typeOfHours");
        }
    }

    function edit(typeOfHour) {
        this.editedIndex = this.typeOfHours.indexOf(typeOfHour);
        this.editedTypeOfHour = Object.assign({}, typeOfHour);
        this.showTypeOfHourEditor = true;
    }

    function destroy(typeOfHour, index) {
        const vm = this;

        if (confirm("Delete permanently? \nAre you sure?")) {
            Vue.backendApi()
                .typeOfHours(typeOfHour.id)
                .destroy()
                .then(success, fail);
        }

        function success(response) {
            typeOfHour.deleted_at = response.data.deleted_at;
            vm.typeOfHours.splice(index, 1);
        }

        function fail(error) {
            console.log("An error occurred trying to archive an typeOfHour");
        }
    }

    function close() {
        this.showTypeOfHourEditor = false;
        this.$nextTick(() => {
            this.editedTypeOfHour = Object.assign({}, this.defaultTypeOfHour);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editTypeOfHourForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editTypeOfHourForm.validate()) {
            return false;
        }

        if (vm.editedTypeOfHour.id === "new-type-of-hour-id") {
            Vue.backendApi()
                .typeOfHours()
                .store(vm.editedTypeOfHour)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .typeOfHours(vm.editedTypeOfHour.id)
                .store(vm.editedTypeOfHour)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.typeOfHours[vm.editedIndex], response.data);
            } else {
                vm.typeOfHours.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save typeOfHour");
        }
    }

    function makeDefault(typeOfHour) {
        const vm = this;

        Vue.backendApi()
            .typeOfHours(typeOfHour.id)
            .makeDefault()
            .then(success, fail);

        function success(response) {
            vm.typeOfHours.map(type => type.is_default = 0);
            typeOfHour.is_default = 1;
        }

        function fail(error) {
            console.log("An error occurred trying to make default type of hour");
        }
    }
}

function mounted() {
    this.getLists();
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep .user-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep label {
    margin-bottom: 0px;
}

.is-default {
    color: #388E3C;
}

.make-default {
    color: #e0e0e0;
    cursor: pointer;

    &:hover {
        color: #388E3C;
    }
}
</style>
