<template>
    <v-col cols="6">
        <v-checkbox
            :class="{ inactive: !isActive }"
            dense
            hide-details
            :label="`${process.name} - ${$percentCommitted(process)}%`"
            v-model="isChecked"
            @change="change"
        ></v-checkbox>
    </v-col>
</template>

<script>
import overviewProcess from "Mixins/overview/overview-process.mixin";

export default {
    mixins: [overviewProcess],

    data() {
        return {
            isChecked: false,
        };
    },

    methods: {
        change() {
            const vm = this;
            let filterProcesses = this.value.types;

            if (vm.isChecked) {
                filterProcesses.push(vm.process);
            } else {
                let filterIndex = filterProcesses.findIndex(
                    (filterProcess) => filterProcess.id === vm.process.id
                );
                filterProcesses.splice(filterIndex, 1);
            }
        },
    },
};
</script>

<style scoped>
.inactive {
    text-decoration: line-through;
}
</style>
