<template>
    <v-simple-table dense class="mb-6 table-striped">
        <template v-slot:default>
            <v-dialog
                v-model="showSerialNumbersModal"
                width="550"
                transition="dialog-bottom-transition"
            >
                <v-card v-if="selectedMaterial">
                    <v-card-title class="primary white--text">
                        <span class="headline">
                            Serial numbers - {{ selectedMaterial.name }}</span
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            small
                            icon
                            @click="selectedMaterial = null"
                            class="ml-2"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pt-3 text-left">
                        <v-text-field
                            label="Search"
                            v-model="searchQuery"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                            class="mb-6"
                        >
                        </v-text-field>
                        <v-chip
                            v-for="serialNumber in selectedMaterial.serial_numbers"
                            :key="serialNumber.id"
                            :color="`blue lighten-3`"
                            class="mr-2 mb-2"
                            label
                            small
                            :class="{
                                filtered: isFiltered(serialNumber),
                            }"
                        >
                            <v-avatar left>
                                <v-icon>mdi-barcode</v-icon>
                            </v-avatar>
                            {{ serialNumber.serial }}
                        </v-chip>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <thead>
                <tr>
                    <th class="text-left grey--text text--darken-3">Name</th>
                    <th class="text-center grey--text text--darken-3">Qty</th>
                    <th class="text-center grey--text text--darken-3">Units</th>
                    <th
                        class="text-center grey--text text--darken-3 white-space-nowrap"
                    >
                        Cost
                    </th>
                    <th class="text-center grey--text text--darken-3">Total</th>
                    <th
                        class="text-right grey--text text--darken-3 pl-0 pr-1 actions-column"
                    ></th>
                </tr>
            </thead>

            <tbody class="text-left">
                <tr v-for="material in materials" :key="material.id">
                    <td v-if="material.serial_numbers.length > 0">
                        <a
                            href="javascript:;"
                            @click="selectedMaterial = material"
                            >{{ material.name }}</a
                        >
                    </td>
                    <td v-else>{{ material.name }}</td>
                    <td class="text-center">{{ material.quantity }}</td>
                    <td class="text-center">
                        {{ material.unit.name }}
                    </td>
                    <td class="text-center">
                        {{ material.cost | currencyFormat }}
                    </td>
                    <td class="text-right">
                        {{ material.total | currencyFormat }}
                    </td>
                    <td class="text-right pl-0 pr-1">
                        <v-icon small @click="$emit('edit', material)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="destroy(material)">
                            mdi-delete
                        </v-icon>
                    </td>
                </tr>
                <tr>
                    <td
                        colspan="2"
                        class="text-left grey--text font-weight-bold text--darken-3"
                    >
                        Total Amount:
                    </td>
                    <td
                        colspan="3"
                        class="text-right grey--text font-weight-bold text--darken-3"
                    >
                        {{ total | currencyFormat }}
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import currencyFormat from "Filters/currencyFormat";

export default {
    data() {
        return {
            showSerialNumbersModal: false,
            selectedMaterial: null,
            searchQuery: "",
        };
    },

    watch: {
        selectedMaterial() {
            this.showSerialNumbersModal = this.selectedMaterial ? true : false;
        },
    },

    props: {
        materials: Array,
    },

    computed: {
        total() {
            return this.materials.reduce((sum, material) => {
                return sum + parseFloat(material.total);
            }, 0);
        },
    },

    methods: {
        isFiltered(serialNumber) {
            if (this.searchQuery === "") {
                return false;
            }
            let query = this.searchQuery.toLowerCase();
            let serial = serialNumber.serial.toLowerCase();
            return serial.indexOf(query) === -1;
        },

        destroy(material) {
            const vm = this;

            Vue.backendApi()
                .jobs(material.job_id)
                .materials(material.id)
                .destroy()
                .then(success, fail);

            function success(response) {
                vm.$emit('destroy', material);
            }

            function fail(error) {
                console.log("An error occurred trying to remove a job material");
            }
        },
    },

    filters: { currencyFormat },
};
</script>

<style lang="scss" scoped>
.filtered {
    display: none;
}
</style>
