<template>
    <v-app>
        <v-dialog v-model="show" width="500" @click:outside="close">
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="headline"
                        >Make
                        {{
                            currentMachineDowntime ? "Available" : "Unavailable"
                        }}</span
                    >
                </v-card-title>

                <v-card-text class="pb-0 pt-4">
                    <v-form ref="editUnavailableForm" onSubmit="return false;">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-select
                                    v-model="
                                        machineDowntime.unavailable_reason_id
                                    "
                                    :items="$store.state.unavailableReasons"
                                    item-value="id"
                                    item-text="name"
                                    label="Unavailable Reason"
                                    height="25px"
                                    :rules="[
                                        () =>
                                            !!machineDowntime.unavailable_reason_id >
                                                0 || 'Required.',
                                    ]"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <time-picker
                                    v-model="machineDowntime.started_at"
                                    label="Started at"
                                ></time-picker>
                            </v-col>
                            <v-col
                                v-if="currentMachineDowntime"
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <time-picker
                                    v-model="machineDowntime.ended_at"
                                    label="Ended at"
                                    :errorMessages="endedAtError"
                                ></time-picker>
                            </v-col>
                            <v-col
                                v-if="allowedEndDateMax"
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <v-alert type="warning"
                                    >Since exists a future unavailable period,
                                    you should add the final end time before
                                    than:
                                    {{
                                        allowedEndDateMax | compactDatetime
                                    }}</v-alert
                                >
                                <v-row>
                                    <v-col cols="6" sm="6" md="6">
                                        <v-menu
                                            ref="menu1"
                                            v-model="menu1"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            max-width="290px"
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="dateFormatted"
                                                    label="End Date"
                                                    persistent-hint
                                                    prepend-icon="mdi-calendar"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="endDate"
                                                no-title
                                                @input="menu1 = false"
                                                :min="requiredEndRange.min"
                                                :max="requiredEndRange.max"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6" sm="6" md="6">
                                        <time-picker
                                            v-model="machineDowntime.ended_at"
                                            label="End time"
                                        ></time-picker>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="close">Cancel</v-btn>
                    <v-btn color="primary" @click="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import timePicker from "Components/utils/vuetify-time-picker-datetime";
import durationPicker from "Components/utils/vuetify-duration-picker-datetime";
import time from "Filters/timeFromDateTime";
import compactDatetime from "Filters/compactDateTime";

export default {
    components: { timePicker, durationPicker },

    data() {
        return {
            show: true,
            endDate: null,
            menu1: false,
            allowedEndDateMax: null,
            endedAtError: "",
            machineDowntime: {
                station_id: this.machine.id,
                unavailable_reason_id: this.currentMachineDowntime
                    ? this.currentMachineDowntime.unavailable_reason_id
                    : null,
                started_at: this.currentMachineDowntime
                    ? this.currentMachineDowntime.started_at
                    : Vue.moment(
                          `${this.$route.params.year}-${
                              this.$route.params.month
                          }-${this.$route.params.day} ${Vue.moment().format(
                              "HH:mm:ss"
                          )}`,
                          "YYYY-MM-DD HH:mm:ss"
                      ).format("YYYY-MM-DD HH:mm:ss"),
                ended_at: this.currentMachineDowntime
                    ? Vue.moment(
                          `${this.$route.params.year}-${
                              this.$route.params.month
                          }-${this.$route.params.day} ${Vue.moment().format(
                              "HH:mm:ss"
                          )}`,
                          "YYYY-MM-DD HH:mm:ss"
                      ).format("YYYY-MM-DD HH:mm:ss")
                    : null,
            },
        };
    },

    computed: {
        dateFormatted() {
            return Vue.moment(this.endDate).format("DD/MM/YY");
        },

        requiredEndRange() {
            if (!this.allowedEndDateMax) {
                return {
                    min: undefined,
                    max: undefined,
                };
            }

            return {
                min: Vue.moment(this.machineDowntime.started_at).format(
                    "YYYY-MM-DD"
                ),
                max: Vue.moment(this.allowedEndDateMax).format("YYYY-MM-DD"),
            };
        },
    },

    props: {
        machine: Object,
        currentMachineDowntime: Object,
    },

    methods: {
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },

        parseDate(date) {
            if (!date) return null;

            const [month, day, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },

        getUnavailableReasons() {
            const vm = this;

            Vue.backendApi().unavailableReasons().index().then(success, fail);

            function success(response) {
                vm.$store.commit("unavailableReasons", response.data);
            }

            function fail(error) {
                console.log("Error trying to get unavailable reasons");
            }
        },

        save() {
            const vm = this;
            vm.endedAtError = "";
            if (!vm.$refs.editUnavailableForm.validate()) {
                return false;
            }

            if (vm.currentMachineDowntime) {
                Vue.backendApi()
                    .stationDowntimes(vm.currentMachineDowntime.id)
                    .store(vm.machineDowntime)
                    .then(success, fail);
            } else {
                Vue.backendApi()
                    .stationDowntimes()
                    .store(vm.machineDowntime)
                    .then(success, fail);
            }

            function success(response) {
                vm.$eventHub.$emit("stored-machine-downtime");
                vm.$store.commit("storeMachineDowntime", response.data);
                vm.close();
            }

            function fail(error) {
                if (error.response.status !== 422) {
                    console.log("Error trying to make machine unavailable");
                    return;
                }
                if (!error.response.data.errors.ended_at) {
                    return;
                }

                const dateMaxMessage =
                    "You should add the final end time before then: ";
                let dateMaxErrorArray =
                    error.response.data.errors.ended_at[0].split(
                        dateMaxMessage
                    );
                if (dateMaxErrorArray.length < 2) {
                    vm.endedAtError = error.response.data.errors.ended_at[0];
                    return;
                }

                let allowedEndDateMax = dateMaxErrorArray[1];
                vm.endDate = allowedEndDateMax.split(" ")[0];
                vm.machineDowntime.ended_at = Vue.moment(
                    allowedEndDateMax,
                    "YYYY-MM-DD HH:mm:ss"
                ).format("YYYY-MM-DD HH:mm:ss");
                vm.allowedEndDateMax = allowedEndDateMax;
            }
        },

        close() {
            this.$nextTick(() => {
                let machineDowntime = {
                    station_id: this.machine.id,
                    unavailable_reason_id: this.currentMachineDowntime
                        ? this.currentMachineDowntime.unavailable_reason_id
                        : null,
                    started_at: this.currentMachineDowntime
                        ? this.currentMachineDowntime.started_at
                        : Vue.moment(
                              `${this.$route.params.year}-${
                                  this.$route.params.month
                              }-${this.$route.params.day} ${Vue.moment().format(
                                  "HH:mm:ss"
                              )}`,
                              "YYYY-MM-DD HH:mm:ss"
                          ).format("YYYY-MM-DD HH:mm:ss"),
                    ended_at: this.currentMachineDowntime
                        ? Vue.moment(
                              `${this.$route.params.year}-${
                                  this.$route.params.month
                              }-${this.$route.params.day} ${Vue.moment().format(
                                  "HH:mm:ss"
                              )}`,
                              "YYYY-MM-DD HH:mm:ss"
                          ).format("YYYY-MM-DD HH:mm:ss")
                        : null,
                };
                this.machineDowntime = machineDowntime;
                this.$refs.editUnavailableForm.resetValidation();
                this.$emit("close");
            });
        },
    },

    watch: {
        endDate: function () {
            let previousMachineDowntime =
                this.machineDowntime.ended_at.split(" ")[1];
            this.machineDowntime.ended_at = `${this.endDate} ${previousMachineDowntime}`;
        },
    },

    filters: { time, compactDatetime },

    created() {
        if (this.$store.state.unavailableReasons.length === 0) {
            this.getUnavailableReasons();
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    min-height: auto;
}
</style>
