import Vue from "vue";
import VueRouter from "vue-router";
import shopOverview from "Components/overview/shop-overview";
import overviewJobsList from "Components/overview/jobs/shop-overview-jobs-list";
import jobReports from "Components/jobs/job-reports/job-reports";
import manageJobs from "Components/jobs/manage-jobs";
import jobBuilder from "Components/jobs/job-builder";
import jobCollectionBuilder from "Components/jobs/job-collection/job-collection-builder";
import jobCollectionEstimatesEditor from "Components/jobs/job-collection/job-collection-estimates-editor";
import jobEstimatesEditor from "Components/jobs/job-estimates-editor";
import schedulesLayout from "Components/schedules/schedules-layout";
import buildingsLayout from "Components/buildings/buildings-layout";
import processLayout from "Components/processes/processes-layout";
import salesLayout from "Components/sales-orders/sales-layout";
import clientSalesOrderLayout from "Components/sales-orders/client-sales-order-layout";
import jobReportLayout from "Components/job-reports/job-reports-layout";
import salesOrderReportsLayout from "Components/sales-reports/sales-reports-layout";
import profitabilityReportLayout from "Components/sales-reports/profitability-report/profitability-report-layout";
import usersLayout from "Components/users/users-layout";
import clientLayout from "Components/clients/clients-layout";
import manufacturingReport from "Components/manufacturing-report-layout";
import groupManagementLayout from "Components/group-management/group-management-layout";
import inventoryLayout from "Components/inventory/inventory-layout";
import jobCostCalculationsLayout from "Components/job-cost-calculations-layout";
import inventoryCategoriesLayout from "Components/inventory/inventory-categories-layout";
import unitLayout from "Components/units/unit-layout";
import organizationsLayout from "Components/organizations/organizations-layout";
import matrixLayout from "Components/matrix/matrix-layout.vue";
import machineTimeReportLayout from "Components/machine-time-report/machine-time-report-layout";
import typeOfHoursLayout from "Components/type-of-hours/type-of-hours-layout";

Vue.use(VueRouter);

import store from "Services/store.js";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (err.name !== "NavigationDuplicated") throw err;
    });
};

const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/app",
            name: "home",
            component: shopOverview,
            meta: {
                permissions: ["read-overview"],
            },
        },
        {
            path: "/app/overview",
            redirect: { name: "home" },
            meta: {
                permissions: ["read-overview"],
            },
        },
        {
            path: "/app/overview/on/:year/:month/:day",
            name: "overview-on",
            component: shopOverview,
            meta: {
                permissions: ["read-overview"],
            },
        },
        {
            path: "/app/overview-jobs-list",
            name: "overview-jobs-list",
            component: overviewJobsList,
            meta: {
                permissions: ["read-overview"],
            },
        },
        {
            path: "/app/job-reports/on/:start?/:end?",
            name: "jobReports",
            component: jobReportLayout,
            meta: {
                permissions: ["read-job-report"],
            },
        },
        {
            path: "/app/jobs/job-collections/:jobCollection",
            name: "manage-job-collections",
            component: manageJobs,
            meta: {
                permissions: ["read-job"],
            },
            children: [
                {
                    path: "build/:activeTab",
                    name: "job-collection-builder",
                    components: {
                        jobCollectionFeature: jobCollectionBuilder,
                    },
                    meta: {
                        permissions: ["read-job"],
                    },
                },
                {
                    path: "edit-estimates",
                    name: "job-collection-estimates",
                    components: {
                        jobCollectionFeature: jobCollectionEstimatesEditor,
                    },
                    meta: {
                        permissions: ["read-job"],
                    },
                },
            ],
        },
        {
            path: "/app/jobs",
            name: "manage-jobs",
            component: manageJobs,
            meta: {
                permissions: ["read-job"],
            },
        },
        {
            path: "/app/job-priorities",
            name: "job-priorities",
            component: matrixLayout,
            meta: {
                permissions: ["read-job-priority"],
            },
        },
        {
            path: "/app/jobs/:id",
            component: manageJobs,
            meta: {
                permissions: ["read-job"],
            },
            children: [
                {
                    path: "build/:activeTab",
                    name: "job-builder",
                    components: {
                        jobFeature: jobBuilder,
                    },
                    meta: {
                        permissions: ["read-job"],
                    },
                },
                {
                    path: "reports/:activeTab?",
                    name: "job-reports",
                    components: {
                        jobFeature: jobReports,
                    },
                    meta: {
                        permissions: ["read-job"],
                    },
                },
                {
                    path: "edit-estimates",
                    name: "job-estimates",
                    components: {
                        jobFeature: jobEstimatesEditor,
                    },
                    meta: {
                        permissions: ["read-job"],
                    },
                },
            ],
        },
        {
            path: "/app/buildings/:id?",
            name: "buildings",
            component: buildingsLayout,
            meta: {
                permissions: ["read-building"],
            },
        },
        {
            path: "/app/schedules/:id?",
            name: "schedules",
            component: schedulesLayout,
            meta: {
                permissions: ["read-schedule"],
            },
        },
        {
            path: "/app/processes/:id?",
            name: "processes",
            component: processLayout,
            meta: {
                permissions: ["read-process"],
            },
        },
        {
            path: "/app/units/:id?",
            name: "units",
            component: unitLayout,
            meta: {
                permissions: ["read-unit"],
            },
        },
        {
            path: "/app/type-of-hours/:id?",
            name: "type-of-hours",
            component: typeOfHoursLayout,
            meta: {
                permissions: ["read-type-of-hour"],
            },
        },
        {
            path: "/app/organizations/:id?",
            name: "organizations",
            component: organizationsLayout,
            meta: {
                superUserRequired: true,
            },
        },
        {
            path: "/app/sales-orders/client/:clientId?",
            name: "client-sales-orders",
            component: salesLayout,
            meta: {
                permissions: ["read-sales-order"],
            },
        },
        {
            path: "/app/sales-orders/view-client/:clientId?",
            name: "view-client-sales-orders",
            component: clientSalesOrderLayout,
            meta: {
                permissions: ["read-sales-order"],
            },
        },
        {
            path: "/app/sales-orders/:id?",
            name: "sales-orders",
            component: salesLayout,
            meta: {
                permissions: ["read-sales-order"],
            },
        },
        {
            path: "/app/sales-order-reports/on/:start?/:end?",
            name: "sales-order-reports",
            component: salesOrderReportsLayout,
            meta: {
                permissions: ["read-sales-report"],
            },
        },
        {
            path: "/app/sales-order-reports/profitability-report/:from?/:to?",
            name: "profitability-report",
            component: profitabilityReportLayout,
            meta: {
                permissions: ["read-sales-report"],
            },
        },
        {
            path: "/app/sales-order-reports/job-cost-calculations/:id",
            name: "job-cost-calculations",
            component: jobCostCalculationsLayout,
            meta: {
                permissions: ["read-sales-report"],
            },
        },
        {
            path: "/app/users",
            name: "users",
            component: usersLayout,
            meta: {
                permissions: ["read-user"],
            },
        },
        {
            path: "/app/clients",
            name: "clients",
            component: clientLayout,
            meta: {
                permissions: ["read-client"],
            },
        },
        {
            path: "/app/manufacturing-report",
            name: "manufacturing",
            component: manufacturingReport,
            meta: {
                permissions: ["read-manufacturing-report"],
            },
        },
        {
            path: "/app/machine-time-report",
            name: "machine-time-report",
            component: machineTimeReportLayout,
            meta: {
                permissions: ["read-machine-time-report"],
            },
        },
        {
            path: "/app/group-management/:id?",
            name: "groups",
            component: groupManagementLayout,
            meta: {
                permissions: ["read-group-management"],
            },
        },
        {
            path: "/app/inventory",
            name: "inventory",
            component: inventoryLayout,
            meta: {
                permissions: ["read-inventory"],
            },
            children: [
                {
                    path: "item/:id",
                    name: "inventory-item",
                    meta: {
                        permissions: ["read-inventory"],
                    },
                },
            ],
        },
        {
            path: "/app/inventory-categories/:id?",
            name: "inventory-categories",
            component: inventoryCategoriesLayout,
            meta: {
                permissions: ["read-inventory-category"],
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.meta.superUserRequired) {
        getUserPermissions()
            .then(() => {
                if (store.state.user.type === "superuser") {
                    return next();
                }
            })
            .catch(fail);

        return;
    }

    if (!to.meta.permissions || to.meta.permissions.length === 0) {
        return next();
    }

    getUserPermissions()
        .then(() => {
            if (store.state.user.type === "superuser") {
                return next();
            }

            let isAllowed = store.state.user.permissions.some((permission) =>
                to.meta.permissions.includes(permission)
            );
            if (isAllowed) {
                return next();
            }
        })
        .catch(fail);

    function getUserPermissions() {
        return new Promise((resolve, reject) => {
            if (store.state.user.permissions) {
                resolve();
                return;
            }
            store.dispatch("getUser").then(
                () => resolve(),
                () => reject()
            );
        });
    }

    function fail() {
        console.log("Error trying to get user");
    }
});

export default router;
