<template>
    <input
        v-if="!disabled"
        type="button"
        @click="$emit('click')"
        :value="value"
    />
    <div v-else>
        <div class="button">
            {{ disabledValue }} <waiting-spinner></waiting-spinner>
        </div>
    </div>
</template>

<script>
export default {
    name: "kheera-button",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: "Save",
        },
        disabledValue: {
            tpe: String,
            default: "Saving...",
        },
    },
};
</script>

<style scoped>
input {
    border-style: revert;
    font-weight: normal;
}
</style>
