var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"375px"},on:{"click:outside":function($event){return _vm.$emit('close')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('close')}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"mx-auto text-left",attrs:{"max-width":"375"}},[_c('v-card-title',{staticClass:"justify-content-center"},[_vm._v(_vm._s(_vm.inventoryItem.name))]),_vm._v(" "),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.inventoryItem.description))]),_vm._v(" "),_c('v-card-text',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v("Thickness")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm._f("decimalFormat")(_vm.inventoryItem.thickness))+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Width")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm._f("decimalFormat")(_vm.inventoryItem.width))+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Length")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm._f("decimalFormat")(_vm.inventoryItem.length))+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Weight")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm._f("decimalFormat")(_vm.inventoryItem.weight))+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Category")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm.inventoryItem.category
                                        ? _vm.inventoryItem.category.name
                                        : "")+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Units")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm.inventoryItem.unit
                                        ? _vm.inventoryItem.unit.name
                                        : "")+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Cost")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm._f("currencyFormat")(_vm.inventoryItem.cost))+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Sale price")]),_vm._v(" "),_c('td',[_vm._v("\n                                "+_vm._s(_vm._f("currencyFormat")(_vm.inventoryItem.sale_price))+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Availability")]),_vm._v(" "),_c('td',{class:[
                                    _vm.inventoryItem.quantity <
                                    _vm.inventoryItem.low_inventory
                                        ? 'yellow--text text--darken-4'
                                        : '',
                                ]},[_vm._v("\n                                "+_vm._s(_vm.inventoryItem.quantity)+"\n                            ")])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Location")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.inventoryItem.location))])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Attachment")]),_vm._v(" "),_c('td',[(_vm.inventoryItem.attachment)?_c('a',{attrs:{"href":'/storage/' +
                                        _vm.inventoryItem.attachment,"target":"__blank"}},[(
                                            _vm.$isPDF(_vm.inventoryItem.attachment)
                                        )?_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-file-pdf-outline")]),_vm._v("\n                                        PDF\n                                    ")],1):_c('span',[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-file-image-outline")]),_vm._v("\n                                        Image\n                                    ")],1)]):_vm._e()])]),_vm._v(" "),_c('tr',[_c('th',[_vm._v("Id")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.inventoryItem.id))])])])]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }