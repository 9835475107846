<template>
    <div>
        <div class="text-left">
            <label class="h6"
                >Serial Numbers ({{
                    isNewItem ? value.length : existingSerialNumbers.length
                }})</label
            >
        </div>

        <div v-if="!isNewItem" class="serial-numbers-container">
            <v-chip
                v-for="serialNumber in existingSerialNumbers"
                :key="serialNumber.id"
                :color="`blue lighten-3`"
                class="mr-2"
                label
                small
            >
                <span class="pr-2">
                    {{ serialNumber.serial }}
                </span>
                <v-icon small @click="destroy(serialNumber)"
                    >mdi-close-circle</v-icon
                >
            </v-chip>
        </div>

        <v-textarea
            label="Add Serial numbers"
            v-model="input"
            rows="3"
            class="textarea"
            hint="Add 1 serial number per line"
        ></v-textarea>
    </div>
</template>

<script>
export default {
    props: {
        value: Array,
        isNewItem: Boolean,
    },

    data() {
        return {
            input: "",
            existingSerialNumbers: [],
            removing: [],
        };
    },

    watch: {
        input() {
            let newSerialNumbers = this.input
                .split("\n")
                .filter((serial) => serial !== "")
                .map((serial) => {
                    return {
                        serial: serial,
                    };
                });
            this.$emit("input", newSerialNumbers);
        },
    },

    methods: {
        destroy(inventoryItemSerialNumber) {
            const vm = this;
            if (vm.removing[inventoryItemSerialNumber.inventory_item_id]) {
                return;
            }

            vm.removing[inventoryItemSerialNumber.inventory_item_id] = true;
            Vue.backendApi()
                .inventory(inventoryItemSerialNumber.inventory_item_id)
                .serialNumber(inventoryItemSerialNumber.id)
                .destroy()
                .then(success, fail)
                .finally(() => {
                    vm.removing[
                        inventoryItemSerialNumber.inventory_item_id
                    ] = false;
                });

            function success(response) {
                let serialNumberIndex = vm.existingSerialNumbers.findIndex(
                    function (serialNumber) {
                        return serialNumber.id === inventoryItemSerialNumber.id;
                    }
                );
                vm.existingSerialNumbers.splice(serialNumberIndex, 1);
            }

            function fail(error) {
                console.log(
                    "An error occurred trying to destroy inventory item serial number"
                );
            }
        },
    },

    created() {
        this.existingSerialNumbers = this.value;
    },
};
</script>

<style lang="scss" scoped>
.serial-numbers-container {
    display: flex;
    max-height: 70px;
    overflow-y: auto;
    flex-wrap: wrap;
}

.serial-numbers-container > span {
    margin-bottom: 5px;
}
</style>
