<template>
    <v-card class="mb-3">
        <v-card-title class="text-h6">
            {{ jobCollection.name }}
        </v-card-title>

        <v-card-subtitle>{{ jobCollection.serial_number }}</v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    props: {
        jobCollection: Object,
    },
};
</script>
