<template>
    <div>
        <v-card-actions>
            <v-btn
                class="mb-3"
                color="grey"
                :disabled="!isDirty"
                @click="undoChanges"
            >
                Undo
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                v-if="from === 'job'"
                class="mb-3"
                color="primary"
                @click="deleteOwnSteps"
            >
                <v-icon class="mr-1">mdi-undo</v-icon>
                Delete job steps
            </v-btn>
        </v-card-actions>
        <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            transition="fade-transition"
            class="mb-6"
            v-if="loading"
        ></v-progress-circular>
        <v-timeline v-else align-top dense class="timeline">
            <draggable
                class="list-group pl-0"
                tag="ul"
                v-model="draggableJobSteps"
                v-bind="dragOptions"
                @end="move"
            >
                <transition-group type="transition" name="flip-list">
                    <v-timeline-item
                        class="pb-2"
                        v-for="step in steps"
                        :key="step.key"
                        color="primary"
                        small
                    >
                        <v-card class="mb-4 py-0">
                            <v-form :ref="`form-${step.key}`">
                                <v-card-text class="pb-0">
                                    <v-row>
                                        <v-col
                                            :cols="
                                                $vuetify.breakpoint.xsOnly
                                                    ? 'auto'
                                                    : false
                                            "
                                        >
                                            <v-select
                                                label="Process"
                                                :items="processes"
                                                item-text="name"
                                                item-value="id"
                                                dense
                                                height="24px"
                                                v-model="step.process_id"
                                                @input="isDirty = true"
                                                :rules="[checkProcess]"
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="7" sm="4">
                                            <v-text-field
                                                label="Estimated hours"
                                                dense
                                                v-model="step.estimated_hours"
                                                @input="isDirty = true"
                                                :rules="[
                                                    () =>
                                                        !!step.estimated_hours ||
                                                        'Required',
                                                    $checkStepHours,
                                                ]"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="5"
                                            sm="auto"
                                            class="d-flex flex-column py-0 align-items-end"
                                        >
                                            <v-menu left bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-btn
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        x-small
                                                        class="mb-1"
                                                    >
                                                        <v-icon
                                                            >mdi-dots-vertical</v-icon
                                                        >
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item
                                                        @click="showNotes(step)"
                                                    >
                                                        <v-list-item-title>
                                                            Add notes
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item
                                                        @click="
                                                            deleteStep(step)
                                                        "
                                                    >
                                                        <v-list-item-title>
                                                            Delete
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <v-btn
                                                icon
                                                x-small
                                                v-if="step.showSuccess"
                                            >
                                                <v-icon color="success">
                                                    mdi-check
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                x-small
                                                :loading="step.loading"
                                                title="Save"
                                                @click="save(step)"
                                                v-if="!step.showSuccess"
                                            >
                                                <v-icon
                                                    >mdi-content-save</v-icon
                                                >
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-expand-transition>
                                    <div v-show="step.notes || step.showNotes">
                                        <v-card-text class="pt-0">
                                            <v-textarea
                                                label="Notes"
                                                :rows="1"
                                                dense
                                                hide-details
                                                v-model="step.notes"
                                                @input="isDirty = true"
                                            ></v-textarea>
                                        </v-card-text>
                                    </div>
                                </v-expand-transition>
                            </v-form>
                        </v-card>
                    </v-timeline-item>
                </transition-group>
            </draggable>

            <v-timeline-item hide-dot class="add-new-step pb-2 mb-7">
                <v-btn
                    class="float-left"
                    color="primary"
                    text
                    outlined
                    @click="add()"
                >
                    Add another step
                </v-btn>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import getTemporaryId from "Mixins/getTemporaryId.mixin";
import reorder from "Mixins/reorder.mixin";
import checkStepHours from "Mixins/checkStepHours.mixin";

export default {
    components: { draggable },
    mixins: [getTemporaryId, reorder, checkStepHours],
    props: {
        from: {
            type: String,
            default: "collection",
        },
        steps: Array,
        loading: Boolean,
    },
    data() {
        return {
            isDirty: false,
            draggableJobSteps: [],
        };
    },
    computed: {
        dragOptions() {
            return {
                ghostClass: "ghost",
                disabled: this.$vuetify.breakpoint.xsOnly,
            };
        },
        processes() {
            return this.$store.state.processes;
        },
    },
    watch: {
        steps: function () {
            const vm = this;
            if (vm.steps.length === 0) {
                vm.add();
            } else {
                vm.draggableJobSteps = vm.steps.map((step) => {
                    if (!step.key) {
                        step.key = vm.$getTemporaryId();
                    }
                    Vue.set(step, "loading", false);
                    return step;
                });
            }
        },
    },
    methods: {
        undoChanges() {
            this.isDirty = false;
            this.$emit("undoChanges");
        },

        checkProcess(processId) {
            const isValid = this.processes.some((process) => {
                return process.id === processId;
            });
            return isValid ? true : "Required";
        },

        createStep() {
            return {
                key: this.$getTemporaryId(),
                process_id: null,
                estimated_hours: "0.00",
                notes: "",
                showNotes: false,
                loading: false,
                showSuccess: false,
                position: null,
            };
        },

        add() {
            this.steps.push(this.createStep());
            setTimeout(() => this.$emit("newStep"), 300);
        },

        showNotes(step) {
            Vue.set(step, "showNotes", true);
        },

        save(step) {
            const vm = this;
            vm.isDirty = false;
            if (this.$refs[`form-${step.key}`][0].validate()) {
                vm.$emit("save", step);
            }
        },

        move(evt) {
            const vm = this;
            let reorderedSteps = vm.$reorder(evt, vm.steps, "descending");
            if (reorderedSteps) {
                vm.$emit("reorder", reorderedSteps);
            }
        },

        deleteStep(step) {
            const vm = this;
            vm.$emit("deleteStep", step);
        },

        deleteOwnSteps() {
            const vm = this;
            vm.isDirty = false;
            vm.$emit("deleteOwnSteps");
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

.flip-list-move {
    transition: transform 0.5s;
}

.flip-list-enter-active,
.flip-list-leave-active {
    transition: opacity 0.5s;
}

.flip-list-enter,
.flip-list-leave-to {
    opacity: 0;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

::v-deep .v-text-field .v-label {
    top: 4px;
}

button:focus {
    outline: none;
}

.v-timeline-item:not(.add-new-step) {
    cursor: move;
}

.v-timeline::before {
    height: 95%;
}

.timeline {
    margin-left: -10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

::v-deep textarea {
    scrollbar-width: thin;
}
</style>
