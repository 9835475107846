var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.admins,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("List of admins")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.showAdminEditor = true}}},[_vm._v("New Admin")]),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},on:{"click:outside":_vm.confirmClose,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.confirmClose.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save.apply(null, arguments)}]},model:{value:(_vm.showAdminEditor),callback:function ($$v) {_vm.showAdminEditor=$$v},expression:"showAdminEditor"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"headline"},[_vm._v("\n                            "+_vm._s(_vm.formTitle)+"\n                        ")])]),_vm._v(" "),_c('v-card-text',[_c('v-form',{ref:"editOrganizationAdminForm",attrs:{"onSubmit":"return false;"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Name","rules":[
                                                () =>
                                                    !!_vm.editedAdmin.name ||
                                                    'Required.',
                                            ]},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.editedAdmin.name),callback:function ($$v) {_vm.$set(_vm.editedAdmin, "name", $$v)},expression:"editedAdmin.name"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Email","rules":[
                                                () =>
                                                    !!_vm.editedAdmin.email ||
                                                    'Required.',
                                            ]},on:{"input":function($event){_vm.isDirty = true}},model:{value:(_vm.editedAdmin.email),callback:function ($$v) {_vm.$set(_vm.editedAdmin, "email", $$v)},expression:"editedAdmin.email"}})],1)],1),_vm._v(" "),_c('password-input',{attrs:{"user":_vm.editedAdmin},on:{"change":function($event){_vm.isDirty = true}}})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.changePassword}},[_vm._v("\n                            Change password\n                        ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.confirmClose}},[_vm._v("Cancel")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editAdmin(item)}}},[_vm._v("\n            mdi-pencil\n        ")])]}}])})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }