<template>
    <input @change="function(event) { $emit('change', event.target.value); }">
</template>

<script>
export default {
    name: "editable-item-input",
};
</script>

<style scoped></style>
