<template>
    <v-row class="process-list">
        <process
            v-for="process in processes"
            :process="process"
            :key="process.id"
            v-model="value"
        ></process>
    </v-row>
</template>

<script>
import process from "Components/overview/mobile/process";

export default {
    components: { process },

    props: {
        processes: Array,
        value: Object,
    },
};
</script>

<style lang="scss" scoped>
.process-list {
    margin: 30px 0px;

    .col {
        padding: 0px;
    }

    ::v-deep label {
        margin-bottom: 0px;
        color: inherit;
    }
}
</style>
