<template>
    <v-data-table
        :headers="headers"
        class="elevation-1 row-pointer"
        :loading="loading"
        :item-class="rowClasses"
        :items="paginate.results"
        :page="paginate.page"
        :pageCount="paginate.pagesLength"
        :options.sync="options"
        :server-items-length="paginate.total"
    >
        <template v-slot:top>
            <v-toolbar
                flat
                color="white"
                :prominent="$vuetify.breakpoint.width < 670"
            >
                <v-row class="justify-content-between">
                    <v-col cols="auto" class="pr-0 vertical-centered">
                        <v-toolbar-title class="pb-0">Clients</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col
                        cols="7"
                        sm="auto"
                        md="5"
                        lg="4"
                        class="vertical-centered mx-lg-auto"
                    >
                        <v-text-field
                            v-model="searchClient"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="auto" class="vertical-centered">
                        <v-switch
                            label="Show all"
                            v-model="isWithTrashed"
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="6" sm="auto" class="text-right text-sm-center">
                        <v-btn color="primary" dark @click="onCreateClientClick"
                            >Create new
                        </v-btn>
                    </v-col>
                </v-row>

                <clients-dialog
                    v-if="showClientEditor"
                    :editedClient="editedClient"
                    @saveDialog="save"
                    @closeDialog="showClientEditor = false"
                />
            </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
            {{ item.name }}
        </template>
        <template v-slot:item.email="{ item }">
            {{ item.email }}
        </template>

        <template v-slot:item.phone="{ item }">
            {{ item.phone }}
        </template>

        <template v-slot:item.fax="{ item }">
            {{ item.fax }}
        </template>

        <template v-slot:item.address="{ item }">
            {{ item.address }}
        </template>

        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | date }}
        </template>
        <template v-slot:item.actions="{ item, index }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editClient(item)"
                title="Edit"
                v-if="!item.deleted_at"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="hardDestroy(item, index)"
                v-if="item.deleted_at"
                title="Delete Archive "
            >
                mdi-archive-alert
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="restore(item, index)"
                v-if="item.deleted_at || item.isTrashing"
                title="Unarchive "
            >
                mdi-backup-restore
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="softDestroy(item, index)"
                v-else
                title="Archive"
            >
                mdi-archive
            </v-icon>
            <router-link :to="clientSalesOrderlinkUrl(item)">
                <v-icon
                    :small="$vuetify.breakpoint.smAndUp"
                    title="Client Sales Orders"
                    >mdi-newspaper-variant-multiple</v-icon
                >
            </router-link>

            <router-link :to="viewClientSalesOrderlinkUrl(item)">
                <v-icon
                    :small="$vuetify.breakpoint.smAndUp"
                    title="View Client Sales Orders"
                    >mdi-point-of-sale</v-icon
                >
            </router-link>
        </template>
    </v-data-table>
</template>

<script>
import date from "Filters/shortDate";
import arrayToString from "Filters/arrayToString";
import truncate from "Filters/truncate";
import clientsDialog from "Components/clients/clients-dialog";

export default {
    data: data,
    created: created,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Client" : "Edit Client";
        },
    },
    components: { clientsDialog },
    filters: { date, truncate, arrayToString },
};

function getWatchers() {
    return {
        isWithTrashed: {
            handler: getClients,
        },
        options: {
            handler() {
                const { page, itemsPerPage } = this.options;
                this.paginate.pagesLength = itemsPerPage;
                this.paginate.page = page;
                this.$router.push({
                    name: this.routeName,
                    query: { ...this.$route.query, page, size: itemsPerPage },
                    params: this.$route.params,
                });
                this.getClients();
            },
            deep: true,
        },
        searchClient: {
            handler() {
                this.paginate.page = 1;
                this.getClients();
            },
            deep: true,
        },
    };

    function getClients() {
        this.getClients.apply(this);
    }
}

function data() {
    return {
        options: {},
        searchTimer: null,
        searchClient: null,
        paginate: {
            query: "",
            results: [],
            page: parseInt(this.$route.query.page) || 1,
            pagesLength: parseInt(this.$route.query.size) || 10,
            total: 0,
        },
        showClientEditor: false,
        isWithTrashed: false,
        isDirty: false,
        deletingTimeoutId: null,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "name",
                class: "grey--text text--darken-3",
                width: 150,
            },
            {
                text: "Phone",
                align: "start",
                sortable: true,
                value: "phone",
                class: "grey--text text--darken-3",
                width: 80,
            },
            {
                text: "Fax",
                align: "start",
                sortable: false,
                value: "fax",
                width: 80,
                class: "grey--text text--darken-3",
            },
            {
                text: "E-mail",
                align: "start",
                sortable: false,
                value: "email",
                width: 80,
                class: "grey--text text--darken-3",
            },
            {
                text: "Address",
                align: "start",
                sortable: false,
                value: "address",
                class: "grey--text text--darken-3",
                width: 320,
            },
            {
                text: "Created",
                align: "start",
                sortable: true,
                value: "created_at",
                class: "grey--text text--darken-3",
                filterable: false,
                width: 122,
            },
            {
                text: "Actions",
                value: "actions",
                align: "start",
                sortable: false,
                class: "grey--text text--darken-3",
                width: 120,
            },
        ],
        editedIndex: -1,
        editedClient: {
            id: "new-client-id",
            name: "",
        },
        clients: [],
        emailError: [],
        search: "",
        loading: false,
    };
}

function getMethods() {
    return {
        editClient: editClient,
        save: save,
        softDestroy: softDestroy,
        hardDestroy: hardDestroy,
        restore: restore,
        getClients: getClients,
        onCreateClientClick: onCreateClientClick,
        clientSalesOrderlinkUrl: clientSalesOrderlinkUrl,
        viewClientSalesOrderlinkUrl: viewClientSalesOrderlinkUrl,
        getRequestParams: getRequestParams,
        rowClasses: rowClasses,
    };

    function getRequestParams() {
        let searchTitle = this.searchClient || "";
        let page = this.paginate.page;
        let pagesLength = this.paginate.pagesLength;
        let isWithTrashed = this.isWithTrashed || false;
        let params = {};

        if (searchTitle) {
            params["title"] = searchTitle;
        }

        if (isWithTrashed) {
            params["type"] = isWithTrashed;
        }

        if (page) {
            params["page"] = page;
        }

        if (pagesLength) {
            params["size"] = pagesLength;
        }

        return params;
    }

    function clientSalesOrderlinkUrl(item) {
        return "/app/sales-orders/client/" + item.id;
    }

    function viewClientSalesOrderlinkUrl(item) {
        return "/app/sales-orders/view-client/" + item.id;
    }

    function onCreateClientClick() {
        this.showClientEditor = true;
        this.editedClient = {
            id: "new-client-id",
            name: "",
        };
    }

    function softDestroy(client, index) {
        const vm = this;

        client.isTrashing = false;
        Vue.set(vm.paginate.results, index, client);
        Vue.backendApi().clients(client.id).softDestroy().then(success, fail);

        function success(response) {
            if (vm.isWithTrashed) {
                client.deleted_at = response.data.deleted_at;
            } else {
                client.isTrashing = true;
                Vue.set(vm.paginate.results, index, client);
                vm.deletingTimeoutId = setTimeout(() => {
                    vm.paginate.results.splice(index, 1);
                }, 2000);
            }
        }

        function fail(error) {
            console.log("An error occurred trying to archive a job");
        }
    }

    function hardDestroy(client, index) {
        const vm = this;

        if (confirm("Delete permanently? \nAre you sure?")) {
            Vue.backendApi()
                .clients(client.id)
                .hardDestroy()
                .then(success, fail);
        }

        function success(response) {
            vm.paginate.results.splice(index, 1);
        }

        function fail(error) {
            console.log("An error occurred trying to archive a job");
        }
    }

    function restore(client, index) {
        const vm = this;
        clearTimeout(vm.deletingTimeoutId);

        Vue.backendApi().clients(client.id).restore().then(success, fail);

        function success(response) {
            client.isTrashing = false;
            Vue.set(vm.paginate.results, index, client);
            client.deleted_at = null;
        }

        function fail(error) {
            console.log("An error occurred trying to restore an user");
        }
    }

    function editClient(client) {
        this.editedIndex = this.paginate.results.indexOf(client);
        this.editedClient = Object.assign({}, client);
        this.showClientEditor = true;
    }

    function save(data) {
        data.deleted_at = null;
        if (this.editedIndex > -1) {
            Object.assign(this.paginate.results[this.editedIndex], data);
        } else {
            this.paginate.results.unshift(data);
        }
        this.showClientEditor = false;
    }

    function getClients() {
        const vm = this;
        this.loading = true;
        clearTimeout(this.searchTimer);

        const params = vm.getRequestParams();
        this.searchTimer = setTimeout(() => {
            Vue.backendApi().clients().index(params).then(success, fail);
        }, 250);

        function success(response) {
            vm.loading = false;
            const { total, data, to } = response.data;
            Vue.set(vm.paginate, "total", total);
            Vue.set(vm.paginate, "totalPages", to);
            Vue.set(vm.paginate, "results", data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get clients");
        }
    }

    function rowClasses(client) {
        if (client.deleted_at) {
            return "client-archived";
        }
        return "";
    }
}

function created() {
    this.getClients();
}
</script>

<style lang="scss" scoped>
.vertical-centered {
    display: flex;
    align-items: center;
}

.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep label {
    margin-bottom: 0px;
}

::v-deep .client-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
