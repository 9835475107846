<template>
    <v-data-table
        :headers="headers"
        :items="admins"
        class="elevation-1 row-pointer"
        :loading="loading"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>List of admins</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="showAdminEditor = true"
                    >New Admin</v-btn
                >
                <v-dialog
                    persistent
                    v-model="showAdminEditor"
                    @click:outside="confirmClose"
                    @keydown.esc="confirmClose"
                    @keydown.enter="save"
                    max-width="500px"
                >
                    <v-card>
                        <v-card-title class="primary white--text">
                            <span class="headline">
                                {{ formTitle }}
                            </span>
                        </v-card-title>

                        <v-card-text>
                            <v-form
                                ref="editOrganizationAdminForm"
                                onSubmit="return false;"
                            >
                                <v-container>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="editedAdmin.name"
                                                label="Name"
                                                @input="isDirty = true"
                                                :rules="[
                                                    () =>
                                                        !!editedAdmin.name ||
                                                        'Required.',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="editedAdmin.email"
                                                label="Email"
                                                @input="isDirty = true"
                                                :rules="[
                                                    () =>
                                                        !!editedAdmin.email ||
                                                        'Required.',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <password-input
                                        :user="editedAdmin"
                                        @change="isDirty = true"
                                    >
                                    </password-input>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn @click="changePassword">
                                Change password
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editAdmin(item)">
                mdi-pencil
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import passwordInput from "Components/utils/password-input";

export default {
    components: { passwordInput },
    data: data,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Admin" : "Edit Admin";
        },
    },
};

function data() {
    return {
        showAdminEditor: false,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Email",
                align: "start",
                sortable: false,
                value: "email",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedAdmin: {
            id: "new-admin-id",
            name: "",
            email: "",
        },
        defaultAdmin: {
            id: "new-admin-id",
            name: "",
            email: "",
        },
        admins: [],
        loading: false,
    };
}

function getWatchers() {
    return {
        showAdminEditor: {
            handler: dialog,
        },
        "$route.params.id": {
            handler: getOrganizationAdmins,
            immediate: true,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function getOrganizationAdmins() {
        const vm = this;
        vm.loading = true;

        Vue.backendApi()
            .organizations(this.$route.params.id)
            .admins()
            .index()
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "admins", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get organization admins");
        }
    }
}

function getMethods() {
    return {
        editAdmin: editAdmin,
        close: close,
        confirmClose: confirmClose,
        save: save,
        changePassword: changePassword,
    };

    function editAdmin(admin) {
        this.editedIndex = this.admins.indexOf(admin);
        this.editedAdmin = Object.assign({}, admin);
        this.showAdminEditor = true;
    }

    function close() {
        this.showAdminEditor = false;
        this.$nextTick(() => {
            this.editedAdmin = Object.assign({}, this.defaultAdmin);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editOrganizationAdminForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editOrganizationAdminForm.validate()) {
            return false;
        }

        if (vm.editedAdmin.id === "new-admin-id") {
            Vue.backendApi()
                .organizations(vm.$route.params.id)
                .admins()
                .store(vm.editedAdmin)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .organizations(vm.$route.params.id)
                .admins(vm.editedAdmin.id)
                .store(vm.editedAdmin)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.admins[vm.editedIndex], response.data);
            } else {
                vm.admins.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save admin");
        }
    }

    function changePassword() {
        Vue.set(this.editedAdmin, "password", "");
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
