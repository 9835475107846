<template>
    <v-card flat>
        <v-card-title
            >Machine Time Estimated Hours<v-divider
                v-if="$vuetify.breakpoint.smAndUp"
                class="mx-4"
                inset
                vertical
            ></v-divider
            ><v-spacer></v-spacer>
            <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text style="height: 390px">
            <v-tabs-items v-model="currentTab">
                <v-tab-item class="pt-2">
                    <v-progress-circular
                        v-if="loading"
                        transition="fade-transition"
                        :size="70"
                        :width="7"
                        color="primary"
                        indeterminate
                        class="mb-6"
                    ></v-progress-circular>
                    <div v-else v-for="jobStep in steps" :key="jobStep.id">
                        <v-col cols="12">
                            <v-text-field
                                :label="$processNames[jobStep.process_id]"
                                :placeholder="$processNames[jobStep.process_id]"
                                dense
                                hide-details="auto"
                                v-model="jobStep.estimated_hours"
                                :rules="[
                                    () =>
                                        !!jobStep.estimated_hours ||
                                        'Required.',
                                    $checkStepHours,
                                ]"
                                @input="isDirty = true"
                            >
                            </v-text-field>
                        </v-col>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th
                                        class="grey--text text--darken-3 text-left"
                                    >
                                        Process
                                    </th>
                                    <th
                                        class="grey--text text--darken-3 text-center"
                                    >
                                        Total estimated hours
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="step in estimatedHoursByProcess"
                                    :key="step.id"
                                >
                                    <td class="text-left">
                                        {{ $processNames[step.process_id] }}
                                    </td>
                                    <td class="text-center">
                                        {{ step.estimated_hours | round }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="text-left">Total</th>
                                    <th class="text-center">
                                        {{ totalHours | round }}
                                    </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

        <v-card-actions class="actions">
            <v-btn
                text
                color="primary"
                small
                @click="currentTab = currentTab === 0 ? 1 : 0"
            >
                <span v-if="currentTab === 0">Totals by process</span>
                <v-icon v-else>mdi-reply</v-icon>
            </v-btn>
            <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-btn
                small
                color="primary"
                @click="deleteOwnSteps"
                v-if="job.steps_from === 'job'"
                >Delete job estimates</v-btn
            >
            <v-btn
                v-if="steps.length > 0 && currentTab === 0"
                small
                color="primary"
                @click="saveEstimatedHours"
                >Save</v-btn
            >
        </v-card-actions>
    </v-card>
</template>

<script>
import processNames from "Mixins/processNames.mixin";
import checkStepHours from "Mixins/checkStepHours.mixin";
import emitEstimatedHoursUpdated from "Mixins/emitEstimatedHoursUpdated.mixin";
import round from "Filters/round";

export default {
    mixins: [processNames, checkStepHours, emitEstimatedHoursUpdated],
    data: data,
    methods: getMethods(),
    watch: getWatched(),
    computed: getComputed(),
    props: {
        job: Object,
    },
    filters: { round },
};

function data() {
    return {
        showEstimatesEditor: true,
        steps: [],
        isDirty: false,
        loading: false,
        currentTab: 0,
    };
}

function getWatched() {
    return {
        "$route.params.id": {
            immediate: true,
            handler: function () {
                this.getSteps();
            },
        },
    };
}

function getComputed() {
    return {
        estimatedHoursByProcess: estimatedHoursByProcess,
        totalHours: totalHours,
    };

    function estimatedHoursByProcess() {
        let totals = [];
        let addedProcesses = {};

        this.steps.forEach((step) => {
            if (!addedProcesses[step.process_id]) {
                let length = totals.push({ ...step });
                addedProcesses[step.process_id] = length;
            } else {
                let index = addedProcesses[step.process_id] - 1;
                let firstFoundstep = totals[index];
                firstFoundstep.estimated_hours =
                    parseInt(firstFoundstep.estimated_hours) +
                    parseInt(step.estimated_hours);
            }
        });
        return totals;
    }

    function totalHours() {
        return this.estimatedHoursByProcess.reduce((accumulator, step) => {
            return accumulator + parseInt(step.estimated_hours);
        }, 0);
    }
}

function getMethods() {
    return {
        saveEstimatedHours: saveEstimatedHours,
        getSteps: getSteps,
        deleteOwnSteps: deleteOwnSteps,
    };

    function getSteps() {
        const vm = this;
        vm.loading = true;

        Vue.backendApi().jobs(vm.job.id).steps().index().then(success, fail);

        function success(response) {
            vm.steps = response.data;
            vm.loading = false;
        }

        function fail(error) {
            console.log("An error occurred trying to get steps");
            vm.loading = false;
        }
    }

    function saveEstimatedHours() {
        const vm = this;

        if (!vm.$checkEstimatedHours(this.steps)) {
            return false;
        }

        Vue.backendApi()
            .jobs(vm.job.id)
            .steps()
            .updateEstimates({
                steps: this.steps,
            })
            .then(success, fail);

        function success(response) {
            vm.steps = response.data;
            vm.isDirty = false;
            vm.$emitEstimatedHoursUpdated(vm.steps);
        }

        function fail(error) {
            console.log("An error occurred trying to save estimated hours");
        }
    }

    function deleteOwnSteps() {
        if (
            !confirm(
                "Delete all job steps and use job collection steps instead?"
            )
        ) {
            return;
        }

        const vm = this;

        Vue.backendApi().jobs(vm.job.id).deleteOwnSteps().then(success, fail);

        function success(response) {
            const steps = response.data;
            vm.isDirty = false;
            Vue.set(vm, "steps", steps);
            vm.$emitEstimatedHoursUpdated(steps);
            vm.job.steps_from = "collection";
        }

        function fail(error) {
            console.log("An error occurred setting collection steps");
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

@media (max-width: 600px) {
    .actions {
        flex-direction: column;
        height: 130px;
        justify-content: space-between;
    }
}
</style>
