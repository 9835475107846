<template>
    <v-row class="mb-3">
        <v-col class="text-left">
            <router-link to="/app">
                <logo></logo>
            </router-link>
        </v-col>
        <v-col class="text-right">
            <vuetify-menu></vuetify-menu>
        </v-col>
    </v-row>
</template>

<script>
import vuetifyMenu from "Components/vuetify-menu";
import logo from "Components/logo";
export default {
    components: { vuetifyMenu, logo },
};
</script>
