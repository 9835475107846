<template>
    <v-data-table
        :headers="headers"
        class="elevation-1"
        :loading="loading"
        :items="filteredEmployees"
        @click:row="(employee) => (selectedEmployee = employee)"
    >
        <template v-slot:top>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Search..."
                        append-icon="mdi-magnify"
                        hide-details
                        class="p-3"
                        v-model="search"
                    ></v-text-field>

                    <employee-menu-actions
                        v-if="selectedEmployee"
                        :employee="selectedEmployee"
                        @close="selectedEmployee = null"
                        @destroy="destroy"
                        @edit="(employee) => (editingEmployee = employee)"
                        @details="
                            (employee) => (moreDetailsEmployee = employee)
                        "
                    ></employee-menu-actions>

                    <editor-modal
                        v-if="editingEmployee"
                        :employee="editingEmployee"
                        @close="editingEmployee = null"
                        @employee-updated="updateEmployee"
                    ></editor-modal>

                    <more-details-modal
                        v-if="moreDetailsEmployee"
                        :employee="moreDetailsEmployee"
                        @close="moreDetailsEmployee = null"
                        :employeeHistory="
                            getEmployeeHistory(moreDetailsEmployee)
                        "
                    ></more-details-modal>
                </v-col>
            </v-row>
        </template>

        <template v-slot:item.processes="{ item }">
            <v-chip small v-for="process in item.processes" :key="process.id">
                {{ process.name }}
            </v-chip>
        </template>

        <template v-slot:item.last_worked_at="{ item }">
            {{ item.last_worked_at | shortDate }}
        </template>
    </v-data-table>
</template>

<script>
import shortDate from "Filters/shortDate";
import employeeMenuActions from "Components/overview/mobile/employees/employee-menu-actions";
import overviewEmployees from "Mixins/overview/overview-employees.mixin.js";
import editorModal from "Components/overview/mobile/employees/editor-modal";
import moreDetailsModal from "Components/overview/mobile/employees/more-details-modal";

export default {
    mixins: [overviewEmployees],

    components: { employeeMenuActions, editorModal, moreDetailsModal },

    data() {
        return {
            loading: false,
            headers: [
                {
                    text: "Name",
                    sortable: false,
                    align: "start",
                    value: "name",
                    class: "grey--text text--darken-3",
                },
                {
                    text: "Processes",
                    align: "start",
                    sortable: false,
                    value: "processes",
                    class: "grey--text text--darken-3",
                },
                {
                    text: "Last worked day",
                    align: "start",
                    sortable: false,
                    value: "last_worked_at",
                    class: "grey--text text--darken-3",
                },
            ],
            employees: [],
            filteredEmployees: [],
            selectedEmployee: null,
            search: "",
            editingEmployee: null,
            moreDetailsEmployee: null,
        };
    },

    filters: { shortDate },

    props: {
        filters: Object,
        allShifts: Array,
    },

    methods: {
        getEmployees() {
            const vm = this;
            vm.loading = true;

            Vue.backendApi()
                .employees()
                .index()
                .then(success, fail)
                .finally(() => (vm.loading = false));

            function success(response) {
                Vue.set(vm, "employees", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to get employees");
            }
        },

        filterEmployees() {
            let filteredEmployees = [];
            for (const employee of this.employees) {
                if (
                    this.$isFiltered(employee) ||
                    this.$isFilteredBySearch(employee)
                ) {
                    continue;
                }
                filteredEmployees.push(employee);
            }
            this.filteredEmployees = filteredEmployees;
        },

        destroy(employee) {
            const vm = this;

            Vue.backendApi()
                .employees(employee.id)
                .destroy()
                .then(success, fail);

            function success(response) {
                let destroyedIndex = vm.employees.findIndex((item) => {
                    return employee.id === item.id;
                });
                vm.employees.splice(destroyedIndex, 1);
                vm.selectedEmployee = null;
            }

            function fail(error) {
                console.log("An error occurred trying to archive employee");
            }
        },

        updateEmployee(updatedEmployee) {
            const vm = this;
            let employeeIndex = vm.employees.findIndex((employee) => {
                return employee.id === updatedEmployee.id;
            });
            vm.employees.splice(employeeIndex, 1, updatedEmployee);
        },

        getEmployeeHistory(employee) {
            let defaultHistory = {
                workHours: 0,
                shiftCount: 0,
                stations: [],
            };
            if (!this.$employeeHistoryMap) {
                return defaultHistory;
            }
            let employeeHistory = this.$employeeHistoryMap[employee.id];
            if (!employeeHistory) {
                return defaultHistory;
            }
            return employeeHistory;
        },
    },

    watch: {
        employees: {
            immediate: true,
            handler: "filterEmployees",
        },

        search: {
            handler: "filterEmployees",
        },

        filters: {
            handler: "filterEmployees",
            deep: true,
        },
    },

    created() {
        this.getEmployees();
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
    .v-application .v-data-table.elevation-1 {
        box-shadow: none !important;
    }
}

::v-deep .v-data-table__mobile-table-row {
    border: thin solid rgba(0, 0, 0, 0.12);
    margin: 10px 0px;
}
</style>
