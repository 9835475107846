<template>
    <v-dialog
        persistent
        v-model="showClientEditor"
        @click:outside="confirmClose"
        @keydown.esc="confirmClose"
        @keydown.enter="save"
        max-width="500px"
    >
        <v-card>
            <v-card-title class="primary white--text">
                <span class="headline">
                    {{ formTitle }}
                </span>
            </v-card-title>

            <v-card-text>
                <v-form ref="editClientForm">
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    v-model="unsavedClient.name"
                                    label="Name"
                                    @input="isDirty = true"
                                    :rules="[
                                        () =>
                                            !!unsavedClient.name || 'Required.',
                                    ]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    v-model="unsavedClient.phone"
                                    label="Phone"
                                    @input="isDirty = true"
                                    :rules="[checkPhone]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    v-model="unsavedClient.fax"
                                    label="Fax"
                                    @input="isDirty = true"
                                    :rules="[checkPhone]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="unsavedClient.email"
                                    label="Email"
                                    @input="isDirty = true"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="unsavedClient.address"
                                    label="Address"
                                    @input="isDirty = true"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn color="primary" @click="confirmClose">Cancel </v-btn>
                <v-btn color="primary" @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import date from "Filters/shortDate";
import arrayToString from "Filters/arrayToString";
import truncate from "Filters/truncate";

export default {
    data: data,
    created: created,
    methods: getMethods(),
    props: {
        editedClient: Object,
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Client" : "Edit Client";
        },
    },
    filters: { date, truncate, arrayToString },
};

function created() {
    this.unsavedClient = this.editedClient;
}

function data() {
    return {
        showClientEditor: true,
        unsavedClient: {},
        isDirty: false,
        editedIndex: -1,
        defaultClient: {
            id: "new-client-id",
            name: "",
            phone: "",
            fax: "",
            email: "",
            address: "",
        },
        emailError: [],
        search: "",
        loading: false,
    };
}

function getMethods() {
    return {
        save: save,
        close: close,
        confirmClose: confirmClose,
        checkPhone: checkPhone,
    };

    function checkPhone(phone) {
        const pattern = /^(\+?)([0-9]){6,16}$/;

        if (!phone) {
            return true;
        }
        if (pattern.test(phone)) {
            return true;
        }
        return "Invalid format";
    }

    function save() {
        const vm = this;

        if (!this.$refs.editClientForm.validate()) {
            return false;
        }

        this.unsavedClient = Object.assign(
            this.defaultClient,
            vm.unsavedClient
        );
        if (vm.unsavedClient.id === "new-client-id") {
            Vue.backendApi()
                .clients()
                .store(vm.unsavedClient)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .clients(vm.unsavedClient.id)
                .store(vm.unsavedClient)
                .then(success, fail);
        }

        function success(response) {
            vm.$emit("saveDialog", response.data);
            vm.close();
        }

        function fail(error) {
            vm.emailError = error.response.data.errors.email || null;
            console.log("An error occurred trying to save client");
        }
    }

    function close() {
        this.$nextTick(() => {
            this.unsavedClient = Object.assign({}, this.defaultClient);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.emailError = null;
        this.$refs.editClientForm.resetValidation();
        this.$emit("closeDialog", null);
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep label {
    margin-bottom: 0px;
}

::v-deep .client-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
