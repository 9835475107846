<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>

            <v-row>
                <v-col cols="12" md="7">
                    <v-row>
                        <v-col cols="7">
                            <h1 class="text-left mb-3">
                                Job Cost Calculations
                            </h1>
                            <v-row class="sales-order-info text-left mb-7">
                                <v-col cols="auto">
                                    <div class="order-subtitle">Job Number</div>
                                    <h5
                                        class="font-weight-normal text--secondary"
                                    >
                                        {{ job.serial_number }}
                                    </h5>
                                </v-col>
                                <v-col cols="auto">
                                    <div class="order-subtitle">Job Name</div>
                                    <h5
                                        class="font-weight-normal text--secondary"
                                    >
                                        {{ job.name }}
                                    </h5>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            v-if="job.sales_order_item"
                            cols="5"
                            class="text-right"
                        >
                            <div class="order-subtitle">Order Date</div>
                            <h5
                                class="font-weight-normal text--secondary order-date"
                            >
                                {{
                                    job.sales_order_item.sales_order.created_at
                                        | date
                                }}
                            </h5>

                            <v-row class="justify-content-end">
                                <v-col cols="auto">
                                    <div class="order-subtitle">
                                        Customer Name
                                    </div>
                                    <h5
                                        class="font-weight-normal text--secondary"
                                    >
                                        {{
                                            job.sales_order_item.sales_order
                                                .client.name
                                        }}
                                    </h5>
                                </v-col>
                                <v-col cols="auto">
                                    <div class="order-subtitle">PO Number</div>
                                    <h5
                                        class="font-weight-normal text--secondary"
                                    >
                                        {{
                                            job.sales_order_item.sales_order
                                                .purchase_order_number
                                        }}
                                    </h5>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <h5 class="text-left">List of Materials</h5>
                    <v-row>
                        <v-col cols="12" md="8">
                            <v-simple-table class="elevation-1 mb-7">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th
                                                class="text-left grey--text text--darken-3"
                                            >
                                                Description
                                            </th>
                                            <th
                                                class="text-center grey--text text--darken-3"
                                            >
                                                Quantity
                                            </th>
                                            <th
                                                class="text-center grey--text text--darken-3"
                                            >
                                                Cost
                                            </th>
                                            <th
                                                class="text-center grey--text text--darken-3"
                                            >
                                                Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="jobMaterial in job.materials"
                                            :key="jobMaterial.id"
                                        >
                                            <td class="text-left">
                                                {{ jobMaterial.name }}
                                            </td>
                                            <td>{{ jobMaterial.quantity }}</td>
                                            <td class="text-right">
                                                {{
                                                    jobMaterial.cost
                                                        | currencyFormat
                                                }}
                                            </td>
                                            <td class="text-right">
                                                {{
                                                    jobMaterial.total
                                                        | currencyFormat
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                    <h5 class="text-left">List of Work</h5>
                    <v-simple-table class="elevation-1 mb-7">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th
                                        class="text-left grey--text text--darken-3"
                                    >
                                        Date
                                    </th>
                                    <th
                                        class="text-center grey--text text--darken-3"
                                    >
                                        Person
                                    </th>
                                    <th
                                        class="text-center grey--text text--darken-3"
                                    >
                                        Process
                                    </th>
                                    <th
                                        class="text-center grey--text text--darken-3"
                                    >
                                        Machine
                                    </th>
                                    <th
                                        class="text-center grey--text text--darken-3"
                                    >
                                        Hours
                                    </th>
                                    <th
                                        class="text-center grey--text text--darken-3"
                                    >
                                        Cost
                                    </th>
                                    <th
                                        class="text-center grey--text text--darken-3"
                                    >
                                        Total
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="work in job.workHistory"
                                    :key="work.id"
                                >
                                    <td>{{ work.started_at | date }}</td>
                                    <td>
                                        {{ work.user ? work.user.name : "" }}
                                    </td>
                                    <td>{{ work.station.process.name }}</td>
                                    <td>{{ work.station.name }}</td>
                                    <td>{{ work.all_hours }}</td>
                                    <td class="text-right">
                                        {{ work.station.cost | currencyFormat }}
                                    </td>
                                    <td>
                                        {{
                                            (work.station.cost *
                                                work.all_hours)
                                                | currencyFormat
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-simple-table class="elevation-1">
                        <template v-slot:default>
                            <tbody class="totals">
                                <tr>
                                    <th>Cost of Materials</th>
                                    <th class="text-right">
                                        {{
                                            job.cost_of_materials
                                                | currencyFormat
                                        }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>Cost of Machines</th>
                                    <th class="text-right">
                                        {{
                                            job.cost_of_machines
                                                | currencyFormat
                                        }}
                                    </th>
                                </tr>
                                <tr>
                                    <th>Total Cost of Job</th>
                                    <th class="text-right">
                                        {{ job.total_cost | currencyFormat }}
                                    </th>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import currencyFormat from "Filters/currencyFormat";
import date from "Filters/shortDate";

export default {
    components: { pageHeaderVuetify },
    data: data,
    created: created,
    filters: { currencyFormat, date },
};

function data() {
    return {
        loading: false,
        job: {},
        headers: [
            {
                text: "Sales order",
                align: "center",
                sortable: true,
                value: "id",
                class: "grey--text text--darken-3",
                width: 112,
            },
        ],
    };
}

function created() {
    const vm = this;
    vm.loading = true;

    Vue.backendApi()
        .reports()
        .jobs(this.$route.params.id)
        .costCalculations()
        .then(success, fail);

    function success(response) {
        vm.loading = false;
        Vue.set(vm, "job", response.data);
    }

    function fail(error) {
        vm.loading = false;
        console.log("An error occurred trying to get job cost calculations");
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr.v-data-table__expanded__content td {
    text-align: left;
    padding: 1rem;
}

.v-application .order-subtitle {
    font-size: 0.9rem;
    letter-spacing: 0.13em;
    font-weight: bold;
}

.sales-order-info {
    width: 70%;
}

.order-date {
    font-size: 1.1rem;
}

.v-data-table > .v-data-table__wrapper > table > tbody.totals > tr > th {
    font-size: 1rem;
}
</style>
