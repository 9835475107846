<template>
    <v-dialog
        v-if="inventoryItem"
        v-model="showDialog"
        persistent
        @click:outside="confirmClose"
        @keydown.esc="confirmClose"
        max-width="550px"
        scrollable
    >
        <v-card>
            <v-card-title class="primary white--text">
                <span class="headline"
                    >Inventory Item - {{ inventoryItem.name }}</span
                >
                <v-spacer></v-spacer>
                <v-btn icon dark @click="confirmClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <inventory-item-history
                    :jobHistory="jobHistory"
                    :loading="loadingJobHistory"
                    @destroy="destroy"
                ></inventory-item-history>

                <v-btn
                    v-if="!showForm"
                    @click="showForm = true"
                    class="mt-5 float-left"
                    small
                    color="primary"
                >
                    Add to job
                </v-btn>
            </v-card-text>

            <v-expand-transition>
                <div v-show="showForm && !isAddingJobs">
                    <v-divider></v-divider>

                    <inventory-item-add-jobs-form
                        :inventoryItem="inventoryItem"
                        @close="showForm = false"
                        @isDirty="isDirty = true"
                        @adding="addingJobs"
                        @added="handleAddedJobMaterials"
                        @fail="rollbackAddingJobs"
                    >
                    </inventory-item-add-jobs-form>
                </div>
            </v-expand-transition>
        </v-card>
    </v-dialog>
</template>

<script>
import inventoryItemHistory from "./inventory-item-history";
import inventoryItemAddJobsForm from "./inventory-item-add-jobs-form";

export default {
    components: { inventoryItemHistory, inventoryItemAddJobsForm },

    data: () => ({
        loadingJobHistory: false,
        isDirty: false,
        showForm: false,
        isAddingJobs: false,
        headers: [
            {
                text: "Job/Collection Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Date",
                align: "center",
                sortable: false,
                value: "created_at",
                class: "grey--text text--darken-3",
                width: 122,
            },
            {
                text: "",
                align: "center",
                sortable: false,
                value: "actions",
                class: "grey--text text--darken-3",
                width: 40,
            },
        ],
        jobHistory: [],
    }),

    props: {
        inventoryItem: Object,
    },

    watch: {
        inventoryItem: {
            immediate: true,
            handler: function () {
                if (this.inventoryItem) {
                    this.getJobHistory();
                }
            },
        },
    },

    computed: {
        showDialog: function () {
            return this.inventoryItem ? true : false;
        },
    },

    methods: {
        getJobHistory: function () {
            const vm = this;
            vm.loadingJobHistory = true;

            Vue.backendApi()
                .inventory(vm.inventoryItem.id)
                .jobHistory()
                .then(success, fail);

            function success(response) {
                vm.loadingJobHistory = false;
                Vue.set(vm, "jobHistory", response.data);
            }

            function fail(error) {
                vm.loadingJobHistory = false;
                console.log(
                    "An error occurred trying to get inventory item job history"
                );
            }
        },

        confirmClose: function () {
            if (!this.isDirty) {
                this.close();
                return;
            }

            if (confirm("Unsaved changes!\nDo you really want to close?")) {
                this.close();
            }
        },

        close: function () {
            const vm = this;
            vm.job = null;
            vm.jobs = [];
            vm.jobHistory = [];
            vm.showForm = false;
            vm.isDirty = false;
            vm.$emit("close");
        },

        addingJobs: function (jobs) {
            const vm = this;
            vm.isAddingJobs = true;
            jobs.forEach((job, index) => {
                let existingJobMaterial = vm.jobHistory.find(
                    (jobMaterial) => jobMaterial.job_id === job.id
                );

                if (existingJobMaterial) {
                    Vue.set(existingJobMaterial, "saving", true);
                } else {
                    vm.jobHistory.push({
                        id: `temporal-job-material-${index}`,
                        job: job,
                        job_id: job.id,
                        created_at: Vue.moment(),
                        saving: true,
                    });
                }
            });
        },

        handleAddedJobMaterials: function ({
            jobMaterials,
            inventoryItemQuantityUsed,
        }) {
            const vm = this;
            vm.$emit("useInventoryItem", inventoryItemQuantityUsed);

            for (const jobMaterial of jobMaterials) {
                const temporalJobMaterial = vm.jobHistory.find(
                    (temporalJobMaterial) =>
                        temporalJobMaterial.job_id === jobMaterial.job_id
                );
                const updatedJobMaterial = Object.assign(
                    temporalJobMaterial,
                    jobMaterial
                );
                Vue.set(updatedJobMaterial, "showSuccess", true);
                updatedJobMaterial.saving = false;
                setTimeout(
                    () => (updatedJobMaterial.showSuccess = false),
                    1200
                );
            }
            vm.isDirty = false;
            vm.showForm = false;
            vm.isAddingJobs = false;
        },

        rollbackAddingJobs: function () {
            this.jobHistory = this.jobHistory.filter((jobMaterial) => {
                if (jobMaterial.saving) {
                    jobMaterial.saving = false;
                }
                return jobMaterial.id >= 0;
            });
            this.showForm = true;
            this.isAddingJobs = false;
        },

        destroy: function (jobMaterial) {
            const vm = this;

            Vue.backendApi()
                .jobs(jobMaterial.job_id)
                .materials(jobMaterial.id)
                .destroy()
                .then(success, fail);

            function success(response) {
                if (response.data.restockedInventoryItem) {
                    vm.$emit("restockInventoryItem", jobMaterial.quantity);
                }
                let jobHistoryItemIndex = vm.jobHistory.findIndex((item) => {
                    return item.id === jobMaterial.id;
                });
                vm.jobHistory.splice(jobHistoryItemIndex, 1);
            }

            function fail(error) {
                console.log("An error occurred trying to delete job material");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep.v-data-table > .v-data-table__wrapper {
    margin-top: 16px;
}
</style>
