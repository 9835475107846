var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v("\n        Build Job Collection - "+_vm._s(_vm.jobCollection.name)+"\n        "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c('v-card-subtitle',{staticClass:"text-left"},[_c('v-icon',{staticClass:"pb-1",attrs:{"color":"primary"}},[_vm._v("mdi-web")]),_vm._v("\n        Building all jobs matching "+_vm._s(_vm.jobCollection.name)+"\n    ")],1),_vm._v(" "),_c('v-tabs',{attrs:{"dark":"","background-color":"primary"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.id,on:{"click":function($event){return _vm.$router.push({
                    name: 'job-collection-builder',
                    params: {
                        jobCollection: _vm.jobCollection.id,
                        activeTab: tab.route,
                    },
                })}}},[_vm._v("\n            "+_vm._s(tab.name)+"\n        ")])}),1),_vm._v(" "),_c('v-tabs-items',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"card-with-scroll",attrs:{"id":"steps-container"}},[_c(_vm.activeTab,{tag:"component",attrs:{"jobCollection":_vm.jobCollection},on:{"newStep":function($event){return _vm.$scrollDown()},"estimated-hours-updated":(estimated_hours) =>
                            _vm.$emit(
                                'estimated-hours-updated',
                                estimated_hours
                            ),"close":function($event){return _vm.$emit('close')}}})],1)],1)],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }