export default {
    data() {
        return {
            dragOptions: {
                ghostClass: "ghost",
                filter: ".ignored",
                disabled: this.$vuetify.breakpoint.smAndDown,
            },
            jobCollections: [],
            temporalJobCollections: [],
            countJobs: 0,
        };
    },

    watch: {
        jobCollections: {
            handler: function () {
                this.temporalJobCollections = [...this.jobCollections];
                this.countJobs = this.jobCollections.length;
            },
        },
    },

    methods: {
        $isFilteredBySearch(jobCollection) {
            if (this.search === "") {
                return false;
            }
            let query = this.search.toLowerCase();

            if (this.$matchJobItem(jobCollection, query)) {
                return false;
            }
            return true;
        },

        $blockMovementsWhileAnimationFinishes(evt) {
            let columnItems =
                evt.item.parentElement.querySelectorAll(".matrix-item");

            for (const columnItem of columnItems) {
                columnItem.classList.add("ignored");
            }

            setTimeout(() => {
                for (const columnItem of columnItems) {
                    columnItem.classList.remove("ignored");
                }
            }, 750);
        },

        $removeJobCollection(jobCollection) {
            let jobCollectionIndex = this.jobCollections.findIndex(
                (jobCollectionItem) => {
                    return jobCollectionItem.id === jobCollection.id;
                }
            );
            this.jobCollections.splice(jobCollectionIndex, 1);
        },
    },
};
