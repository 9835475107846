<template v-if="loading || success">
    <v-progress-circular
        v-if="loading"
        color="primary"
        :size="size"
        :width="width"
        indeterminate
        class="ml-2"
        transition="fade-transition"
    ></v-progress-circular>
    <v-icon
        v-else-if="success"
        color="success"
        transition="fade-transition"
        class="icon-success"
    >
        mdi-check
    </v-icon>
</template>

<script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        success: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 18,
        },
        width: {
            type: Number,
            default: 2,
        },
    },
};
</script>
