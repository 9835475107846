export default {
    methods: {
        $spliceById(
            arr,
            id,
            compareFunction = (i) => {
                return i === id;
            }
        ) {
            const removeIndex = arr.findIndex(compareFunction);
            if (removeIndex > -1) {
                arr.splice(removeIndex, 1);
            }
        },
    },
};
