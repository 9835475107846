<template>
    <div
        class="editable-item-text-list"
        v-custom-click-outside="clickOutside"
        @click="popoutIsShown = true"
    >
        {{ value.length }} items
        <i class="far fa-caret-square-down"></i>
        <div
            class="editable-item-text-list--popout"
            :class="{ 'editable-item-text-list--popout--show': popoutIsShown }"
        >
            <div
                class="editable-item-text-list-item"
                v-for="(option, index) in value"
                :key="option.id"
            >
                <div>{{ option.blocker }}</div>
                <i @click="trash(index)" class="far fa-trash-alt"></i>
            </div>
            <div class="editable-item-text-list-item">
                <div class="editable-item-text-list-input">
                    <input
                        v-model="newItem.blocker"
                        type="text"
                        @keyup.enter="add"
                    />
                </div>
                <i @click="add" class="fas fa-plus"></i>
            </div>
        </div>
    </div>
</template>

<script>
import "Components/overview/utils/editable-item-default-viewer";
import getTemporaryId from "Mixins/getTemporaryId.mixin";

export default {
    name: "editable-item-text-list",
    data: data,
    mixins: [getTemporaryId],
    methods: getMethods(),
    mounted: mounted,
    props: {
        value: Array,
        meta: Object,
    },
};

function mounted() {}
function data() {
    return {
        popoutIsShown: false,
        newItem: this.getNewItem(),
    };
}

function getMethods() {
    return {
        clickOutside: clickOutside,
        trash: trash,
        add: add,
        getNewItem: getNewItem,
    };

    function clickOutside() {
        this.popoutIsShown = false;
    }

    function trash(index) {
        this.value.splice(index, 1);
    }

    function add() {
        this.value.push(JSON.parse(JSON.stringify(this.newItem)));
        this.newItem = this.getNewItem();
    }

    function getNewItem() {
        return {
            blocker: "",
            id: this.$getTemporaryId(),
        };
    }
}
</script>

<style scoped>
input,
select,
button {
    border-style: revert;
}

input[type="text"] {
    background-color: revert;
    border-radius: revert;
    padding: 1px;
}
</style>

<style lang="scss">
div.editable-item-text-list--popout {
    position: absolute;
    display: none;
    z-index: 1;

    &.editable-item-text-list--popout--show {
        display: block;
        background-color: white;
        text-align: left;
        border-width: 1px;
        border-style: solid;

        & div.editable-item-text-list-item {
            border-width: 1px;
            border-style: solid;
            padding: 2px;
            display: block;
            width: 200px;

            & div {
                display: inline-block;
                width: 180px;

                &.editable-item-text-list-input {
                    width: 173px;
                    & input {
                        width: 100%;
                    }
                }
            }
        }
    }
}

div.editable-item-text-list {
    position: relative;
    border: 1px;
    border-style: solid;
}

i.far.fa-caret-square-down,
i.far.fa-trash-alt,
i.fas.fa-plus {
    float: right;
    font-size: 16px;
    padding-top: 3px;
}

i.fas.fa-plus {
    font-size: 18px;
    padding-top: 0;
}
</style>
