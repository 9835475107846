<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="12" md="6" lg="5">
                    <schedule-list></schedule-list>
                </v-col>
                <v-col cols="12" md="6" lg="7">
                    <schedule-shifts v-if="$route.params.id"></schedule-shifts>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import scheduleList from "Components/schedules/schedule-list";
import scheduleShifts from "Components/schedules/schedule-shifts";

export default {
    name: "schedules-layout",
    components: { pageHeaderVuetify, scheduleList, scheduleShifts },
};
</script>
