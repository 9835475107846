<template>
    <v-slider
        label="Duration"
        v-model="hours"
        step="0.5"
        min="0.5"
        max="24"
        track-color="#ced4da"
    >
        <template v-slot:append>
            <div style="width: 60px" class="text-right">
                {{ hours | hoursToReadableDuration }}
            </div>
        </template>
    </v-slider>
</template>

<script>
import hoursToReadableDuration from "Filters/hoursToReadableDuration";

export default {
    data: data,
    watch: getWatchers(),
    props: {
        startedAt: String,
        value: String,
    },
    filters: { hoursToReadableDuration },
};

function data() {
    return {
        hours: 0,
    };
}

function getWatchers() {
    return {
        value: {
            immediate: true,
            handler: value,
        },
        hours: hours,
    };

    function value() {
        let endTime = Vue.moment(this.value, "YYYY-MM-DD HH:mm:ss");
        let startTime = Vue.moment(this.startedAt, "YYYY-MM-DD HH:mm:ss");
        this.hours = endTime.diff(startTime, "minutes") / 60;
    }

    function hours() {
        if (this.startedAt) {
            let startTime = Vue.moment(this.startedAt, "YYYY-MM-DD HH:mm:ss");
            let endTime = startTime.add(this.hours, "hours");
            endTime = Vue.moment(endTime).format("YYYY-MM-DD HH:mm:ss");
            this.$emit("input", endTime);
        }
    }
}
</script>
