import processPercentCommitted from "Mixins/processPercentCommitted.mixin";

export default {
    mixins: [processPercentCommitted],

    props: {
        process: Object,
        value: Object,
    },

    computed: {
        isActive() {
            const vm = this;

            if (this.value.types.length < 1) {
                return true;
            }
            return this.value.types.some(function (selectedFilter) {
                return selectedFilter.id === vm.process.id;
            });
        },
    },
};
