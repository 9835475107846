<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="500px"
    >
        <v-card class="mx-auto text-left" tile>
            <v-list>
                <v-subheader>Actions</v-subheader>
                <v-list-item @click="$emit('more-details', inventoryItem)">
                    <v-list-item-icon>
                        <v-icon>mdi-table-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>More Details</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    :disabled="$denies('write-inventory')"
                    @click="$emit('create-offcut', inventoryItem)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-shape-polygon-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Create offcut</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('edit-jobs', inventoryItem)">
                    <v-list-item-icon>
                        <v-icon>mdi-application-export</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Jobs</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    :disabled="$denies('write-inventory')"
                    @click="$emit('edit-attachment', inventoryItem)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-attachment</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Add Attachment</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    :disabled="$denies('write-inventory')"
                    @click="$emit('edit', inventoryItem)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    :disabled="$denies('write-inventory')"
                    @click="$emit('archive', inventoryItem, inventoryItemIndex)"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-archive</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Archive</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
import permissions from "Mixins/permissions.mixin";

export default {
    mixins: [permissions],

    data() {
        return {
            show: true,
        };
    },

    props: {
        inventoryItem: Object,
        inventoryItemIndex: Number,
    },
};
</script>
