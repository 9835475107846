<template>
    <steps
        :steps="steps"
        :loading="loading"
        @save="save"
        @reorder="reorder"
        @deleteStep="deleteStep"
        @newStep="newStep"
        @undoChanges="undoChanges"
    >
    </steps>
</template>

<script>
import steps from "Components/jobs/steps";
import emitEstimatedHoursUpdated from "Mixins/emitEstimatedHoursUpdated.mixin";

export default {
    mixins: [emitEstimatedHoursUpdated],
    components: { steps },
    data() {
        return {
            steps: [],
            loading: false,
        };
    },
    props: {
        jobCollection: Object,
    },
    watch: {
        "$route.params.jobCollection": {
            handler: function () {
                this.getSteps();
            },
        },
    },
    methods: {
        undoChanges() {
            this.getSteps();
        },

        getSteps() {
            const vm = this;
            vm.loading = true;

            Vue.backendApi()
                .jobCollections(vm.jobCollection.id)
                .steps()
                .index()
                .then(success, fail);

            function success(response) {
                vm.loading = false;
                Vue.set(vm, "steps", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to get steps");
            }
        },

        save(step) {
            const vm = this;
            step.loading = true;
            const isUnsavedStep = !step.id;

            if (isUnsavedStep) {
                Vue.backendApi()
                    .jobCollections(vm.jobCollection.id)
                    .steps()
                    .store(step)
                    .then(success, fail);
            } else {
                Vue.backendApi()
                    .jobCollections(vm.jobCollection.id)
                    .steps(step.id)
                    .store(step)
                    .then(success, fail);
            }

            function success(response) {
                const savedStep = response.data;
                savedStep.key = step.key;

                let stepIndex = vm.steps.findIndex((jobStep) => {
                    return jobStep.id === step.id;
                });

                vm.steps.splice(stepIndex, 1, savedStep);
                vm.$emitEstimatedHoursUpdated(vm.steps);
                Vue.set(savedStep, "showSuccess", true);
                setTimeout(() => (savedStep.showSuccess = false), 2000);
            }

            function fail(error) {
                step.loading = false;
                console.log("An error occurred trying to save a job step");
            }
        },

        reorder(reorderedSteps) {
            const vm = this;

            Vue.backendApi()
                .jobCollections(vm.jobCollection.id)
                .steps()
                .updatePositions({
                    steps: reorderedSteps,
                })
                .then(success, fail);

            function success(response) {
                vm.steps = reorderedSteps;
            }

            function fail(error) {
                console.log("An error occurred trying to move step");
            }
        },

        deleteStep(step) {
            const vm = this;

            if (step.job_collection_id) {
                Vue.backendApi()
                    .jobCollections(vm.jobCollection.id)
                    .steps(step.id)
                    .destroy()
                    .then(success, fail);
            } else {
                removeStepOfList();
            }

            function success(response) {
                removeStepOfList();
                vm.$emitEstimatedHoursUpdated(vm.steps);
            }

            function fail(error) {
                console.log("An error occurred trying to delete a job step");
            }

            function removeStepOfList() {
                let stepIndex = vm.steps.findIndex(
                    (jobStep) => jobStep.key === step.key
                );
                vm.steps.splice(stepIndex, 1);
            }
        },

        newStep() {
            this.$emit("newStep");
        },
    },
    mounted() {
        this.getSteps();
    },
};
</script>
