<template>
    <v-card>
        <v-card-title>Costs</v-card-title>
        <hr class="mt-0" />
        <v-card-text>
            <pie-chart :items="costs"></pie-chart>
        </v-card-text>
    </v-card>
</template>

<script>
import pieChart from "Components/utils/pie-chart";

export default {
    components: { pieChart },

    props: {
        report: Object,
    },

    data() {
        return {
            costs: [],
        };
    },

    watch: {
        report() {
            const vm = this;
            let stationPercentage = 0;
            let materialPercentage = 0;

            if (vm.report.total_cost !== 0) {
                stationPercentage = Math.round(
                    (vm.report.cost_of_stations / vm.report.total_cost) * 100
                );
                materialPercentage = Math.round(
                    (vm.report.cost_of_materials / vm.report.total_cost) * 100
                );
            }

            let costs = [
                {
                    label: "Stations",
                    value: vm.report.cost_of_stations,
                    percentage: stationPercentage,
                },
                {
                    label: "Materials",
                    value: vm.report.cost_of_materials,
                    percentage: materialPercentage,
                },
            ];
            this.costs = costs;
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .v-progress-circular__underlay {
    stroke: rgba(0, 0, 0, 0.4);
}
</style>
