<template>
    <v-dialog
        v-model="dialog"
        max-width="450"
        @keydown.esc="cancelDialog"
        @click:outside="cancelDialog"
    >
        <v-card>
            <v-card-title class="text-h5">
                {{ title }}
            </v-card-title>

            <v-card-text class="align">
                {{ content }}
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="green darken-1"
                    text
                    @click.prevent="cancelDialog"
                >
                    Cancel
                </v-btn>

                <v-btn
                    color="green darken-1"
                    text
                    v-if="showOkButton"
                    @click.prevent="okDialog"
                >
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "modal-dialog",
    props: {
        title: String,
        showOkButton: false,
        content: String,
    },
    data: data,
    created: created,
    methods: getMethods(),
};

function created() {
    const vm = this;
    vm.dialog = true;
}

function data() {
    return {
        dialog: true,
    };
}

function getMethods() {
    return {
        cancelDialog: cancelDialog,
        okDialog: okDialog,
    };

    function cancelDialog() {
        const vm = this;
        vm.dialog = false;
        vm.$emit("closeCancelDialog", null);
    }

    function okDialog() {
        const vm = this;
        vm.dialog = false;
        vm.$emit("closeOkDialog", null);
    }
}
</script>
<style lang="scss" scoped>
::v-deep .align {
    text-align: left;
}
</style>
