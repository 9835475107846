<template>
    <div
        v-custom-click-outside="clickOutside"
        class="employee"
        :key="`view-employee-${unsavedEmployee.id}`"
    >
        <employee-extra-info-modal
            v-if="!unsavedEmployee.newEmployeeModel"
            :employeeHistory="employeeHistory"
            :employee="employee"
        >
        </employee-extra-info-modal>

        <span
            v-if="unsavedEmployee.newEmployeeModel"
            @click.prevent="showEmployeeModal = !showEmployeeModal"
        >
            Create new employee <i class="fas fa-plus"></i>
        </span>
        <span
            v-else
            @click="selectEmployee(employee)"
            :class="{ 'absence-employee': isAbsence }"
            :title="isAbsence ? 'Absence due to: ' + absence.reason : ''"
        >
            <i
                v-if="employeeHistory.shiftCount >= 1"
                class="fas user-icon"
                :class="{
                    'fa-user-cog': employeeHistory.shiftCount === 1,
                    'fa-users-cog': employeeHistory.shiftCount >= 2,
                }"
            >
                <span class="work-hours"
                    >Work: {{ employeeHistory.workHours }}h</span
                >
            </i>
            {{ unsavedEmployee.name }}
        </span>

        <div class="employee-modal" v-if="showEmployeeModal">
            <div class="column list-name">
                <div
                    class="edit-employee"
                    :key="`edit-employee-${unsavedEmployee.id}`"
                >
                    <div class="employee-name-container">
                        <input
                            type="text"
                            ref="employeeName"
                            v-model="unsavedEmployee.name"
                        />
                        <i
                            class="fas fa-times"
                            @click="showEmployeeModal = false"
                        ></i>
                    </div>
                    <div
                        class="custom-control custom-checkbox"
                        v-for="process in processes"
                        :key="`${unsavedEmployee.id}-div-process-${process.id}`"
                    >
                        <input
                            type="checkbox"
                            :id="`${unsavedEmployee.id}-checkbox-${process.id}`"
                            v-model="unsavedEmployee.processes"
                            :value="process"
                            class="custom-control-input"
                        />
                        <label
                            :for="`${unsavedEmployee.id}-checkbox-${process.id}`"
                            class="custom-control-label text-uppercase"
                        >
                            {{ process.name }}
                        </label>
                    </div>
                </div>
                <div class="button-container">
                    <kheera-button
                        @click="submit"
                        value="Save"
                        :disabled="isSubmitting"
                        disabled-value="Saving..."
                    ></kheera-button>
                    <kheera-button
                        @click="destroy"
                        value="Archive"
                        :disabled="isDestroying"
                        disabled-value="Archiving..."
                    ></kheera-button>
                    <div class="clearfix"></div>
                </div>
                <hr />
                <div v-if="isAbsence" class="absence-container">
                    <label>Absence due to</label>
                    <input
                        type="text"
                        v-model="absence.reason"
                        placeholder="Reason"
                        class="reason"
                    />
                    <kheera-button
                        class="absence-btn"
                        @click="updateAbsence"
                        value="Update reason"
                        :disabled="isProcessingAbsence"
                        disabled-value="processing..."
                    ></kheera-button>
                    <kheera-button
                        class="absence-btn"
                        @click="removeAbsence"
                        value="Remove absence"
                        :disabled="isProcessingAbsence"
                        disabled-value="processing..."
                    ></kheera-button>
                </div>
                <div v-else class="absence-container">
                    <label>Absent for today?</label>
                    <input
                        type="text"
                        v-model="absenceReason"
                        placeholder="Reason"
                        class="reason"
                    />
                    <kheera-button
                        class="absence-btn"
                        @click="saveAbsence"
                        value="Add absence"
                        :disabled="isSavingAbsence"
                        disabled-value="Adding..."
                    ></kheera-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import employeeExtraInfoModal from "Components/overview/employees/employee-extra-info-modal";

export default {
    name: "employee",
    components: { employeeExtraInfoModal },
    props: {
        absence: Object,
        employee: Object,
        employeeHistoryMap: Object,
    },
    data: data,
    methods: getMethods(),
    watch: getWatchers(),
    computed: getComputed(),
};

function getWatchers() {
    return {
        employee: {
            handler: employee,
            immediate: true,
        },
        employeeHistoryMap: {
            handler: setEmployeeHistory,
            immediate: true,
            deep: true,
        },
    };

    function employee() {
        this.unsavedEmployee = JSON.parse(JSON.stringify(this.employee));
    }

    function setEmployeeHistory() {
        const vm = this;
        vm.employeeHistory = {
            workHours: 0,
            shiftCount: 0,
            stations: [],
        };

        if (!vm.employeeHistoryMap) {
            return;
        }
        let employeeHistory = vm.employeeHistoryMap[vm.employee.id];
        if (!employeeHistory) {
            return;
        }
        vm.employeeHistory = employeeHistory;
    }
}

function data() {
    return {
        employeeHistory: {
            workHours: 0,
            shiftCount: 0,
            stations: [],
        },
        createOrEdit: false,
        unsavedEmployee: {},
        isSubmitting: false,
        isDestroying: false,
        showEmployeeModal: false,
        isSavingAbsence: false,
        absenceReason: "",
        isProcessingAbsence: false,
    };
}

function getMethods() {
    return {
        clickOutside: clickOutside,
        selectEmployee: selectEmployee,
        submit: submit,
        destroy: destroy,
        saveAbsence: saveAbsence,
        removeAbsence: removeAbsence,
        updateAbsence: updateAbsence,
    };

    function selectEmployee(item) {
        const vm = this;
        vm.showEmployeeModal = !vm.showEmployeeModal;
        vm.$eventHub.$emit("onSelectEmployee", vm.employee);
    }

    function clickOutside() {
        this.showEmployeeModal = false;
    }

    function destroy() {
        const vm = this;
        vm.isDestroying = true;
        Vue.backendApi()
            .employees(vm.unsavedEmployee.id)
            .destroy()
            .then(success, fail);

        function success(response) {
            vm.isDestroying = false;
            vm.$emit("employee-destroyed", vm.unsavedEmployee.id);
            vm.createOrEdit = false;
        }

        function fail(error) {
            vm.isDestroying = false;
        }
    }

    function submit() {
        const vm = this;
        vm.isSubmitting = true;
        if (this.unsavedEmployee.id) {
            Vue.backendApi()
                .employees(vm.unsavedEmployee.id)
                .update(vm.unsavedEmployee)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .employees()
                .store(vm.unsavedEmployee)
                .then(success, fail);
        }

        function success(response) {
            vm.isSubmitting = false;
            vm.showEmployeeModal = false;
            vm.$emit("employee-updated", response.data);
        }

        function fail(error) {
            vm.isSubmitting = false;
        }
    }

    function saveAbsence() {
        const vm = this;
        vm.isSavingAbsence = true;
        const date = `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day}`;

        Vue.backendApi()
            .employees(vm.unsavedEmployee.id)
            .absences()
            .store({
                date,
                reason: vm.absenceReason,
            })
            .then(success, fail);

        function success(response) {
            vm.isSavingAbsence = false;
            vm.$emit("absence-added", response.data);
        }

        function fail(error) {
            vm.isSavingAbsence = false;
            console.log("Error trying to save employee absence");
        }
    }

    function removeAbsence() {
        const vm = this;
        vm.isProcessingAbsence = true;

        Vue.backendApi()
            .employees(vm.unsavedEmployee.id)
            .absences(vm.absence.id)
            .destroy()
            .then(success, fail);

        function success(response) {
            vm.isProcessingAbsence = false;
            vm.absenceReason = "";
            vm.$emit("absence-destroyed", vm.absence);
        }

        function fail(error) {
            vm.isProcessingAbsence = false;
            console.log("Error trying to remove employee absence");
        }
    }

    function updateAbsence() {
        const vm = this;
        vm.isProcessingAbsence = true;

        Vue.backendApi()
            .employees(vm.unsavedEmployee.id)
            .absences(vm.absence.id)
            .store({ reason: vm.absence.reason })
            .then(success, fail);

        function success(response) {
            vm.isProcessingAbsence = false;
            vm.$emit("absence-updated", response.data);
        }

        function fail(error) {
            vm.isProcessingAbsence = false;
            console.log("Error trying to update employee absence");
        }
    }
}

function getComputed() {
    return {
        processes: processes,
        isAbsence: isAbsence,
    };

    function processes() {
        return this.$store.state.processes;
    }

    function isAbsence() {
        return this.absence ? true : false;
    }
}
</script>

<style lang="scss" scoped>
@import "~@/_variables.scss";

.user-icon {
    position: relative;
    font-size: 16px;

    .work-hours {
        display: none;
    }

    &:hover {
        .work-hours {
            display: block;
            position: absolute;
            background-color: #d49498;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 4px;
            top: 17px;
            white-space: nowrap;
        }
    }
}

input[type="text"] {
    background-color: revert;
    border-radius: revert;
    border-style: revert;
    font-weight: normal;
    padding: 1px;
}

.employee {
    overflow: initial;
}

.employee a {
    color: black;
}
.employee-modal {
    box-shadow: 1px 3px 1px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    cursor: default;
    position: absolute;
    margin-top: -32px;
    margin-left: -10px;
    width: 235px;
    padding: 5px;
    padding-top: 20px;
    padding-bottom: 13px;
    background-color: white;
    z-index: 110;
    line-height: initial;
    font-size: 12px;
    input[type="checkbox"] {
        vertical-align: middle;
    }
    & .custom-control.custom-checkbox {
        line-height: 24px;
    }
    & .fas.fa-times {
        border-radius: 50%;
        right: 2px;
        padding: 4px;
        position: absolute;
        background-color: burlywood;
        cursor: pointer;
    }
}
.edit-employee {
    color: black;
    text-align: start;
}

.absence-employee {
    font-weight: normal;
    color: #686767;

    &:hover {
        cursor: pointer;
    }
}

.employee-name-container {
    margin-bottom: 10px;
}

.absence-container {
    padding-bottom: 15px;

    label {
        display: block;
    }

    .reason {
        width: 100%;
        margin-bottom: 5px;
    }
}
</style>
