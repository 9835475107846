<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <h1 class="">Sales Orders</h1>

            <v-row>
                <v-col cols="12" lg="3">
                    <sales-order-search
                        v-model="showArchived"
                        :search="search"
                        @query="searchSalesOrders"
                        @pageChange="pageChange"
                        @getSalesOrder="getSalesOrderItem"
                    />
                </v-col>

                <v-col cols="12" lg="9">
                    <hr v-if="$vuetify.breakpoint.smAndDown" />
                    <div class="text-center text-md-right mb-8">
                        <v-btn class="primary" @click="clickedNewSalesOrder"
                            >Create New</v-btn
                        >
                    </div>

                    <v-row class="mb-1 text-left">
                        <v-col cols="12" md="8" lg="8">
                            <v-btn
                                class="mb-9 mb-md-7"
                                small
                                @click="showClientEditor = true"
                            >
                                <v-icon small>mdi-account-plus</v-icon>
                                <span class="ml-1">New Client</span>
                            </v-btn>
                            <clients-dialog
                                v-if="showClientEditor"
                                :editedClient="editedClient"
                                @saveDialog="saveClient"
                                @closeDialog="showClientEditor = false"
                            />

                            <v-row class="client-data">
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    class="py-0 py-md-3"
                                >
                                    <v-autocomplete
                                        v-model="selectedClient"
                                        :items="clientList"
                                        @change="
                                            getClientDetails(selectedClient.id)
                                        "
                                        item-text="name"
                                        autocomplete="off"
                                        item-value="id"
                                        dense
                                        required
                                        return-object
                                        :search-input.sync="searchClient"
                                        label="Client"
                                    >
                                        <template v-slot:append-item>
                                            <div v-intersect="endIntersect" />
                                        </template>
                                    </v-autocomplete>

                                    <v-text-field
                                        disabled
                                        label="Phone"
                                        v-model="client.phone"
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="3"
                                    class="py-0 py-md-3"
                                >
                                    <v-text-field
                                        label="Email"
                                        disabled
                                        v-model="client.email"
                                        dense
                                    ></v-text-field>

                                    <v-text-field
                                        label="Fax"
                                        dense
                                        disabled
                                        v-model="client.fax"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="5" class="py-0 py-md-3">
                                    <v-textarea
                                        label="Address"
                                        :auto-grow="true"
                                        :row-height="37"
                                        :rows="2"
                                        dense
                                        disabled
                                        v-model="client.address"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="4" lg="4">
                            <div class="text-lg-right">
                                <div class="order-subtitle">PURCHASE ORDER</div>
                                <v-text-field
                                    class="po-number font-weight-light"
                                    v-model="salesOrder.purchase_order_number"
                                    :rules="[checkPurchaseOrderNumber]"
                                    ref="purchase_order_number"
                                    @input="isDirty = true"
                                >
                                </v-text-field>

                                <v-row v-if="salesOrder.id">
                                    <v-col cols="6" class="pt-lg-1">
                                        <div class="order-subtitle">
                                            SALES ORDER
                                        </div>
                                        <h5
                                            class="font-weight-normal text--secondary"
                                        >
                                            {{ salesOrder.id }}
                                        </h5>
                                    </v-col>
                                    <v-col cols="6" class="pt-lg-1">
                                        <div class="order-subtitle">
                                            ORDER DATE
                                        </div>
                                        <h5
                                            class="font-weight-normal text--secondary"
                                        >
                                            {{
                                                salesOrder.created_at
                                                    | shortDate
                                            }}
                                        </h5>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="mb-5 mt-lg-0">
                        <v-col cols="12" class="py-0">
                            <div class="order-subtitle text-left">
                                ADDITIONAL NOTES
                            </div>
                            <v-textarea
                                auto-grow
                                dense
                                rows="1"
                                value
                                v-model="salesOrder.additional_information"
                                @input="isDirty = true"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-btn
                                class="primary float-right px-10"
                                @click="saveSalesOrder"
                                :disabled="!isDirty"
                                >Save</v-btn
                            >
                            <v-btn
                                v-if="salesOrder.id && !salesOrder.deleted_at"
                                text
                                color="primary"
                                class="float-right px-10"
                                @click="archiveSalesOrder"
                            >
                                Archive
                            </v-btn>
                        </v-col>
                    </v-row>

                    <data-table
                        :salesOrderId="salesOrder.id"
                        :items="salesOrder.items"
                        @updatedItem="updatedItem"
                        @newItem="newItem"
                        @removedItem="removedItem"
                    ></data-table>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog
            v-model="showNewSalesOrderConfirmationDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="headline primary white--text"
                    >Confirm</v-card-title
                >
                <v-card-text class="pt-5"
                    >Are you sure you want to start a completely new sales
                    order?</v-card-text
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="showNewSalesOrderConfirmationDialog = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="addSalesOrder()"
                        >Yes</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import shortDate from "Filters/shortDate";
import dataTable from "./data-table";
import salesOrderSearch from "./sales-order-search";
import clientsDialog from "Components/clients/clients-dialog";

export default {
    components: {
        pageHeaderVuetify,
        dataTable,
        salesOrderSearch,
        clientsDialog,
    },

    data: () => ({
        showClientEditor: false,
        isDirty: false,
        showNewSalesOrderConfirmationDialog: false,
        searchClient: null,
        searchTimer: null,
        selectedClient: null,
        showArchived: false,
        search: {
            query: "",
            results: [],
            page: 1,
            pagesLength: 1,
        },
        salesOrder: {
            id: "",
            purchase_order_number: "",
            client_id: "",
            additional_information: "",
            items: [],
        },
        client: {
            id: "new-client-id",
            name: "",
            email: "",
            phone: "",
            fax: "",
            address: "",
        },
        editedClient: {
            id: "new-client-id",
            name: "",
        },
        page: 0,
        clientId: null,
        pageSize: 20,
        clientList: [],
    }),

    watch: getWatchers(),
    methods: {
        endIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.page++;
                this.loadClientList();
            }
        },

        saveClient(data) {
            this.client = data;
            this.selectedClient = {
                id: this.client.id,
                name: this.client.name,
            };
            this.page = 1;
            this.clientId = this.client.id;
            this.clientList.unshift(this.selectedClient);
            this.showClientEditor = false;
        },

        getClientDetails(id) {
            const vm = this;
            vm.isDirty = true;

            Vue.backendApi().clients(id).index().then(success, fail);

            function success(response) {
                vm.client = response.data;
                vm.selectedClient = {
                    id: vm.client.id,
                    name: vm.client.name,
                };
                vm.clientList.unshift(vm.selectedClient);
            }

            function fail(error) {
                console.log("An error occurred trying to archive get client");
            }
        },

        getClientDetailsFromParams() {
            let clientId = this.$route.params.clientId;

            if (clientId) {
                this.page = 1;
                this.clientId = clientId;
                this.getClientDetails(clientId);
            }
        },

        getRequestParams() {
            let searchTitle = this.searchClient || "";
            let page = this.page;
            let pageSize = this.pageSize;
            let clientId = this.clientId || null;

            let params = {};

            if (searchTitle) {
                params["name"] = searchTitle;
            }

            if (clientId) {
                params["id"] = clientId;
            }

            if (page) {
                params["page"] = page;
            }

            if (pageSize) {
                params["size"] = pageSize;
            }

            return params;
        },

        loadClientList() {
            const vm = this;
            clearTimeout(this.searchTimer);

            const params = vm.getRequestParams();
            this.searchTimer = setTimeout(() => {
                Vue.backendApi().clients().search(params).then(success, fail);
            }, 250);

            function success(response) {
                const { data } = response.data;
                vm.clientList = [...vm.clientList, ...Object.values(data)];
            }

            function fail(error) {
                console.log("An error occurred trying to get client");
            }
        },

        checkPurchaseOrderNumber(purchaseOrderNumber) {
            const pattern = /^[0-9a-zA-Z]+$/;

            if (!purchaseOrderNumber) {
                return true;
            }
            if (pattern.test(purchaseOrderNumber)) {
                return true;
            }
            return "Alphanumeric characters only";
        },

        getSalesOrderList() {
            const vm = this;

            Vue.backendApi().salesOrders().index().then(success, fail);

            function success(response) {
                vm.search.results = response.data.data;
                vm.search.pagesLength = response.data.last_page;
                vm.search.page = response.data.current_page;
            }

            function fail(error) {
                vm.$store.commit("addError", error);
            }
        },

        getSalesOrderItem() {
            const vm = this;
            const salesOrderId = this.$route.params.id || null;

            if (!salesOrderId) {
                return;
            }

            Vue.backendApi()
                .salesOrders(salesOrderId)
                .show()
                .then(success, fail);

            function success(response) {
                vm.salesOrder = response.data;
                vm.client = response.data.client;
                vm.selectedClient = {
                    id: vm.client.id,
                    name: vm.client.name,
                };
                vm.page = 1;
                vm.clientId = vm.client.id;
                vm.clientList.unshift(vm.selectedClient);
            }

            function fail(error) {
                vm.$store.commit("addError", error);
            }
        },

        checkSalesOrder() {
            let salesOrderHasErrors = false;

            if (
                this.checkPurchaseOrderNumber(
                    this.salesOrder.purchase_order_number
                ) !== true
            ) {
                this.$refs.purchase_order_number.validate(true);
                salesOrderHasErrors = true;
            }
            if (!this.client.name) {
                salesOrderHasErrors = true;
            }
            if (salesOrderHasErrors) {
                return false;
            }
            return true;
        },

        saveSalesOrder() {
            const vm = this;

            if (!this.checkSalesOrder()) {
                return false;
            }
            vm.salesOrder.client_id = this.selectedClient.id;

            if (vm.salesOrder.id === "") {
                Vue.backendApi()
                    .salesOrders()
                    .store(vm.salesOrder)
                    .then(success, fail);
            } else {
                Vue.backendApi()
                    .salesOrders(vm.salesOrder.id)
                    .store(vm.salesOrder)
                    .then(success, fail);
            }

            function success(response) {
                Vue.set(vm, "salesOrder", response.data);

                let searchResult = {
                    ...vm.salesOrder,
                    client: {
                        ...vm.client,
                    },
                };

                let entryIndex = vm.search.results.findIndex((searchResult) => {
                    return searchResult.id === vm.salesOrder.id;
                });

                if (entryIndex === -1) {
                    vm.search.results.unshift(searchResult);
                } else {
                    vm.search.results.splice(entryIndex, 1, searchResult);
                }

                vm.isDirty = false;
                vm.$router.push({
                    name: "sales-orders",
                    params: { id: response.data.id },
                });
            }

            function fail(error) {
                vm.$store.commit("addError", error);
            }
        },

        archiveSalesOrder() {
            const vm = this;

            if (
                confirm(
                    "Are you sure you want to archive this sales order and it's associated jobs?"
                )
            ) {
                Vue.backendApi()
                    .salesOrders(vm.salesOrder.id)
                    .destroy()
                    .then(success, fail);
            }

            function success(response) {
                let salesOrderIndex = vm.search.results.findIndex(
                    (salesOrder) => {
                        return salesOrder.id === vm.salesOrder.id;
                    }
                );

                if (vm.showArchived) {
                    let salesOrder = vm.search.results[salesOrderIndex];
                    salesOrder.deleted_at = Vue.moment().format();
                } else {
                    vm.search.results.splice(salesOrderIndex, 1);
                }
                vm.addSalesOrder();
            }

            function fail(error) {
                vm.$store.commit("addError", error);
            }
        },

        searchSalesOrders(payload) {
            const vm = this;
            vm.$set(vm.search, "query", payload.query);

            Vue.backendApi().salesOrders().search(payload).then(success, fail);

            function success(response) {
                vm.search.results = response.data.data;
                vm.search.pagesLength = response.data.last_page;
                vm.search.page = response.data.current_page;
            }

            function fail(error) {
                vm.$store.commit("addError", error);
            }
        },

        pageChange(page) {
            const vm = this;

            Vue.backendApi()
                .salesOrders()
                .search(
                    {
                        query: this.search.query,
                    },
                    page
                )
                .then(success, fail);

            function success(response) {
                vm.search.results = response.data.data;
                vm.search.pagesLength = response.data.last_page;
                vm.search.page = response.data.current_page;
            }

            function fail(error) {
                vm.$store.commit("addError", error);
            }
        },

        addSalesOrder() {
            const vm = this;
            this.isDirty = false;
            this.$router.push({ name: "sales-orders" });

            this.$refs.purchase_order_number.resetValidation();
            this.$refs.client_name?.resetValidation();

            vm.salesOrder = {
                id: "",
                purchase_order_number: "",
                client_id: "",
                additional_information: "",
                items: [],
            };
            vm.client = {
                id: "new-client-id",
                name: "",
                email: "",
                phone: "",
                fax: "",
                address: "",
            };
            vm.selectedClient = {};

            this.showNewSalesOrderConfirmationDialog = false;
        },

        clickedNewSalesOrder() {
            let clientHasChanges = Object.values(this.client).find((value) => {
                return value !== "" && value !== "new-client-id";
            });

            let salesOrderHasChanges = Object.values(this.salesOrder).find(
                (value) => {
                    return typeof value === "string" && value !== "";
                }
            );

            if (
                this.salesOrder.id === "" &&
                (clientHasChanges || salesOrderHasChanges)
            ) {
                this.showNewSalesOrderConfirmationDialog = true;
            } else {
                this.addSalesOrder();
            }
        },

        updatedItem(item) {
            let resultsLength = this.search.results.length;

            for (let i = 0; i < resultsLength; i++) {
                if (this.search.results[i].id === item.sales_order_id) {
                    let itemIndex = this.search.results[i].items.findIndex(
                        (searchResult) => {
                            return searchResult.id === item.id;
                        }
                    );

                    if (itemIndex !== -1) {
                        this.search.results[i].items.splice(itemIndex, 1, item);
                        break;
                    }
                }
            }
        },

        newItem(item) {
            let resultsLength = this.search.results.length;

            for (let i = 0; i < resultsLength; i++) {
                if (this.search.results[i].id === item.sales_order_id) {
                    if (this.search.results[i].items) {
                        let itemIndex = this.search.results[i].items.findIndex(
                            (existingItem) => existingItem.id === item.id
                        );
                        if (itemIndex === -1) {
                            this.search.results[i].items.push(item);
                        }
                    } else {
                        Vue.set(this.search.results[i], "items", [item]);
                    }
                    break;
                }
            }
        },

        removedItem(item) {
            let resultsLength = this.search.results.length;

            for (let i = 0; i < resultsLength; i++) {
                if (this.search.results[i].id === item.sales_order_id) {
                    let itemIndex = this.search.results[i].items.findIndex(
                        (searchResult) => {
                            return searchResult.id === item.id;
                        }
                    );

                    if (itemIndex !== -1) {
                        this.search.results[i].items.splice(itemIndex, 1);
                        break;
                    }
                }
            }
        },
    },

    mounted() {
        this.getSalesOrderItem();
        this.getSalesOrderList();
        this.getClientDetailsFromParams();
    },

    filters: { shortDate },
};

function getWatchers() {
    return {
        searchClient: {
            handler: searchClient,
        },
    };

    function searchClient() {
        this.page = 1;
        this.loadClientList.apply(this);
    }
}
</script>

<style scoped lang="scss">
@import "~vuetify/dist/vuetify.css";

::v-deep input,
.button {
    height: auto;
}

.logo {
    text-align: left;
    margin: initial;
    display: inline-block;
}

.v-application .order-subtitle {
    font-size: 0.9rem;
    letter-spacing: 0.13em;
    font-weight: bold;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

.po-number {
    flex: 0 0 auto;
    padding-top: 0px;

    ::v-deep input {
        max-height: none;
        font-size: 2.5rem;
        color: rgba(0, 0, 0, 0.6);
        padding: 0px 0px 2px;
    }
}

.form-container {
    margin-left: 420px;
    margin-right: 10px;
}

@media (min-width: 960px) {
    .po-number {
        ::v-deep input {
            text-align: right;
        }
    }
}
</style>
