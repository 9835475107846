<template>
    <div class="general-info-container">
        <i class="fas fa-info-circle ml-2"></i>

        <div class="employee-info-modal">
            <div class="employee-info-modal-header">
                <h6>General Info</h6>
            </div>
            <table class="table table-striped table-bordered table-sm">
                <tbody>
                    <tr>
                        <td class="text-left">Employees working</td>
                        <td>{{ employeesWorking }}</td>
                    </tr>
                    <tr>
                        <td class="text-left">Assigned hours</td>
                        <td>{{ assignedHours }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    data: data,
    watch: getWatchers(),
    props: {
        employeeHistoryMap: Object,
    },
};

function data() {
    return {
        employeesWorking: 0,
        assignedHours: 0,
    };
}

function getWatchers() {
    return {
        employeeHistoryMap: {
            handler: setStats,
            immediate: true,
            deep: true,
        },
    };

    function setStats() {
        let employeesWorking = 0;
        let assignedHours = 0;

        for (const employeeId in this.employeeHistoryMap) {
            const employeeHistory = this.employeeHistoryMap[employeeId];
            assignedHours += parseInt(employeeHistory.workHours);
            employeesWorking++;
        }

        this.employeesWorking = employeesWorking;
        this.assignedHours = assignedHours;
    }
}
</script>

<style lang="scss" scoped>
.general-info-container {
    position: relative;

    .employee-info-modal {
        display: none;
        width: 200px;
        box-shadow: 0px 6px 12px 4px #555;
        position: absolute;
        background: white;
        z-index: 10;
        border-radius: 4px;
        left: -150px;
        top: 25px;
        padding: 10px;
        padding-bottom: 0px;

        h6 {
            padding: 0;
            padding-bottom: 0px;
            text-align: left;
            font-size: 14px;
            padding-bottom: 5px;
            border: none;
        }

        table {
            font-weight: normal;
        }
    }

    &:hover {
        .employee-info-modal {
            display: block;
        }
    }
}

.employee-info-modal-header {
    margin-bottom: 7px;
    border-bottom: 2px solid #202020;
}
</style>
