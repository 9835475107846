import { render, staticRenderFns } from "./machine.vue?vue&type=template&id=71cc0b3f&scoped=true&"
import script from "./machine.vue?vue&type=script&lang=js&"
export * from "./machine.vue?vue&type=script&lang=js&"
import style0 from "./machine.vue?vue&type=style&index=0&id=71cc0b3f&scoped=true&lang=scss&"
import style1 from "./machine.vue?vue&type=style&index=1&id=71cc0b3f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71cc0b3f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www2/lean.nuevoalmaden.com/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71cc0b3f')) {
      api.createRecord('71cc0b3f', component.options)
    } else {
      api.reload('71cc0b3f', component.options)
    }
    module.hot.accept("./machine.vue?vue&type=template&id=71cc0b3f&scoped=true&", function () {
      api.rerender('71cc0b3f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/overview/building/machine/machine.vue"
export default component.exports