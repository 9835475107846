<template>
    <v-autocomplete
        v-model="selectedItems"
        :loading="loading"
        :items="items"
        :search-input.sync="search"
        chips
        no-filter
        item-text="name"
        item-value="key"
        label="Select Jobs"
        return-object
        multiple
        hide-no-data
        validate-on-blur
        :rules="rules"
        :menu-props="{
            closeOnClick: true,
        }"
        @keydown="handleKeyPressed"
    >
        <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 5 && !item.isJobCollection">
                <span>{{
                    item.sales_order_item_id ? item.serial_number : item.name
                }}</span>
            </v-chip>
            <span v-if="index === 5" class="grey--text text-caption">
                (+{{ selectedJobs.length - 4 }} others)
            </span>
        </template>

        <template v-slot:item="{ item }">
            <v-list-item-avatar
                :color="item.isJobCollection ? 'teal lighten-2' : 'primary'"
                class="headline font-weight-light white--text"
            >
                {{ item.isJobCollection ? "JC" : "J" }}
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
                <v-list-item-title>
                    {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ item.serial_number }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        value: Array,
        rules: {
            type: Array,
            default: [],
        },
    },

    data: () => ({
        search: "",
        debounce: null,
        loading: false,
        jobCollections: [],
        items: [],
        selectedItems: [],
        selectedJobs: [],
    }),

    watch: {
        value() {
            this.selectedJobs = this.value;
            if (this.value.length === 0) {
                this.items = [];
                this.selectedItems = [];
                this.search = "";
            }
        },

        selectedItems(items, oldItems) {
            const vm = this;
            const touchedItem = vm.getTouchedItem(items, oldItems);
            if (!touchedItem) {
                return;
            }

            const wasSelectedItem = oldItems.indexOf(touchedItem) === -1;
            if (touchedItem.isJobCollection) {
                if (wasSelectedItem) {
                    vm.selectAllJobsOfJobCollection(touchedItem);
                } else {
                    vm.unselectAllJobsOfJobCollection(touchedItem);
                }
            }

            const wasUnselectedJob =
                !wasSelectedItem && !touchedItem.isJobCollection;

            if (wasUnselectedJob) {
                let selectedJobCollection = vm.selectedItems.find(
                    (item) => item.isJobCollection
                );
                if (selectedJobCollection) {
                    vm.unselectItem(selectedJobCollection);
                }
            }

            vm.selectedJobs = vm.selectedItems.filter(
                (item) => !item.isJobCollection
            );
            vm.makeOnlyOneJobCollectionSelectable();
        },

        selectedJobs() {
            const vm = this;
            vm.$emit("input", vm.selectedJobs);
        },

        search() {
            this.items = [];
            this.selectedItems = [];

            if (this.search === "" || this.search === null) {
                return;
            }

            const vm = this;
            clearTimeout(vm.debounce);
            this.debounce = setTimeout(function () {
                if (vm.search && vm.search.length > 0) {
                    vm.getJobCollections();
                }
            }, 250);
        },

        jobCollections() {
            const vm = this;
            let items = [];

            for (const jobCollection of vm.jobCollections) {
                if (jobCollection.job_count > 1) {
                    jobCollection.key = `jc-${jobCollection.id}`;
                    jobCollection.isJobCollection = true;
                    items.push(jobCollection);
                }
                for (const job of jobCollection.jobs) {
                    job.key = `j-${job.id}`;
                    items.push(job);
                }
                items.push({ divider: true });
            }
            items.pop();
            Vue.set(vm, "items", items);
        },
    },

    methods: {
        handleKeyPressed: function (e) {
            const closeSuggestionsKeys = ["Delete", "Backspace", "Enter"];
            const wasPressedCloseKey = closeSuggestionsKeys.includes(e.key);

            // Close menu suggestions
            if (wasPressedCloseKey && this.selectedItems.length > 0) {
                e.preventDefault();
                this.$children[0].$refs.menu.isActive = false;
                return;
            }
            // Avoid send form without selected items
            if (e.key === "Enter") {
                e.preventDefault();
            }
        },

        getJobCollections: function () {
            const vm = this;
            vm.loading = true;

            if (vm.cancelTokenSource) {
                vm.cancelTokenSource.cancel();
            }
            vm.cancelTokenSource = axios.CancelToken.source();

            Vue.backendApi()
                .jobCollections()
                .search(
                    { searchQuery: this.search },
                    vm.cancelTokenSource.token
                )
                .then(success, fail);

            function success(response) {
                Vue.set(vm, "jobCollections", response.data);
                vm.loading = false;
            }

            function fail(error) {
                vm.loading = false;
                if (!axios.isCancel(error)) {
                    console.log("An error occurred trying to get jobs");
                }
            }
        },

        makeOnlyOneJobCollectionSelectable() {
            const vm = this;
            const firstSelectedItem = vm.selectedItems[0];

            if (!firstSelectedItem) {
                for (const item of vm.items) {
                    if (item.disabled) {
                        item.disabled = false;
                    }
                }
                return;
            }

            const selectedJobCollectionId = firstSelectedItem.isJobCollection
                ? firstSelectedItem.id
                : firstSelectedItem.job_collection_id;

            for (const item of vm.items) {
                const itemJobCollectionId = item.isJobCollection
                    ? item.id
                    : item.job_collection_id;

                const itemBelongsToOtherJobCollection =
                    itemJobCollectionId !== selectedJobCollectionId;

                if (itemBelongsToOtherJobCollection) {
                    Vue.set(item, "disabled", true);
                }
            }
        },

        getTouchedItem(items, oldItems) {
            const arrayDiff = [
                ...items.filter((x) => !oldItems.includes(x)),
                ...oldItems.filter((x) => !items.includes(x)),
            ];
            return arrayDiff[0];
        },

        unselectItem: function (item) {
            let itemIndex = this.selectedItems.indexOf(item);
            if (itemIndex > -1) {
                this.selectedItems.splice(itemIndex, 1);
            }
        },

        selectAllJobsOfJobCollection: function (jobCollection) {
            const vm = this;
            jobCollection.jobs.forEach((job) => {
                let jobIsNotSelected = vm.selectedItems.indexOf(job) === -1;
                if (jobIsNotSelected) {
                    vm.selectedItems.push(job);
                }
            });
        },

        unselectAllJobsOfJobCollection: function (jobCollection) {
            const vm = this;
            jobCollection.jobs.forEach((job) => vm.unselectItem(job));
        },
    },
};
</script>
