export default {
    computed: {
        $processNames() {
            let processNames = {};
            this.$store.state.processes.forEach((process) => {
                processNames[process.id] = process.name;
            });
            return processNames;
        },
    },
};
