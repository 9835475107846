<template>
    <v-data-table
        :headers="headers"
        :items="loading ? [] : menuOptionsWithPermissions"
        class="elevation-1 row-pointer"
        :loading="loading"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Grouped by menu options</v-toolbar-title>
            </v-toolbar>
        </template>

        <template v-slot:item.read="{ item: menuOption }">
            <permission-checkbox
                :isAdmin="isAdmin"
                :permission="getPermission(menuOption, 'read')"
                @change="readPermissionChanged"
                :isDisabled="isReadCheckboxDisabled(menuOption)"
            >
            </permission-checkbox>
        </template>

        <template v-slot:item.write="{ item: menuOption }">
            <permission-checkbox
                :isAdmin="isAdmin"
                :permission="getPermission(menuOption, 'write')"
                :menuOption="menuOption"
                @change="writePermissionChanged"
            >
            </permission-checkbox>
        </template>
    </v-data-table>
</template>

<script>
import permissionCheckbox from "./permission-checkbox.vue";

export default {
    components: { permissionCheckbox },
    data: data,
    props: {
        isAdmin: Boolean,
        loading: Boolean,
        groupPermissions: Array,
        menuOptionsWithPermissions: Array,
    },
    methods: getMethods(),
};

function data() {
    return {
        headers: [
            {
                text: "Menu option",
                align: "start",
                sortable: false,
                value: "title",
                class: "grey--text text--darken-3",
            },
            {
                text: "Read",
                value: "read",
                align: "center",
                sortable: false,
                class: "grey--text text--darken-3",
            },
            {
                text: "Write",
                value: "write",
                align: "center",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
    };
}

function getMethods() {
    return {
        readPermissionChanged: readPermissionChanged,
        writePermissionChanged: writePermissionChanged,
        getPermission: getPermission,
        isReadCheckboxDisabled: isReadCheckboxDisabled,
    };

    function readPermissionChanged(permission) {
        const vm = this;
        vm.$emit("readPermissionChanged", permission);
    }

    function writePermissionChanged(writePermission, menuOption) {
        const vm = this;
        let readPermission = vm.getPermission(menuOption, "read");
        vm.$emit("writePermissionChanged", writePermission, readPermission);
    }

    function getPermission(menuOption, type) {
        let permission = menuOption.permissions.find((permission) => {
            return permission.pivot.type === type;
        });

        let isCheck =
            this.groupPermissions.findIndex((groupPermission) => {
                return groupPermission.name === permission.name;
            }) > -1;

        permission.isCheck = isCheck;
        return permission;
    }

    function isReadCheckboxDisabled(menuOption) {
        let writePermission = menuOption.permissions.find((permission) => {
            return permission.pivot.type === "write";
        });
        return writePermission.isCheck;
    }
}
</script>

<style lang="scss" scoped>
::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
