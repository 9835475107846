<template>
    <div>
        <drop-list
            :items="jobHistories"
            class="job-container"
            @insert="onDropJob"
            @reorder="$event.apply(jobHistories)"
            :accepts-data="shouldAcceptDrop"
        >
            <template v-slot:item="{ item: jobHistory }">
                <drag
                    class="job-item"
                    :data="jobHistory.job"
                    :class="{
                        removing: isRemoving(jobHistory),
                    }"
                    :key="jobHistory.job.id"
                    @dragstart="draggingJob = true"
                    @dragend="draggingJob = false"
                >
                    <machine-shift-job
                        :jobHistory="jobHistory"
                        @showModal="
                            (val, activator) =>
                                $emit('showModal', val, activator)
                        "
                        @removeJobHistory="removeJobHistory"
                        @addTime="addTime"
                        @removeTime="removeShift"
                        @updateStationShiftJob="updateStationShiftJob"
                    >
                    </machine-shift-job>
                </drag>
            </template>
            <template v-slot:feedback="{ data: job }">
                <div class="job-item ghost" :key="job.id">{{ job.name }}</div>
            </template>
        </drop-list>
        <overlapping-modal
            v-if="overlapping"
            :machineDowntime="overlapping.machineDowntime"
            :jobHistory="overlapping.jobHistory"
            @close="overlapping = null"
            @removeJobHistory="removeShift"
        >
        </overlapping-modal>
    </div>
</template>

<script>
import machineShiftJobListMixin from "Components/overview/building/machine/mixins/machine-shift-job-list.mixin";
import machineShiftJob from "Components/overview/building/machine/shift/jobs/machine-shift-job";

export default {
    mixins: [machineShiftJobListMixin],

    components: {
        machineShiftJob,
    },

    mounted: mounted,

    watch: {
        shift: {
            immediate: true,
            deep: true,
            handler: "showJobHistories",
        },
        showOnlyOneJob: {
            handler: "showJobHistories",
        },
    },
    methods: {
        showJobHistories() {
            let jobHistoryByJob = this.groupedJobHistoryByJob();
            let jobHistories = this.showOnlyOneJob
                ? jobHistoryByJob.slice(0, 1)
                : jobHistoryByJob;

            Vue.set(this, "jobHistories", jobHistories);
        },

        saveJobHistory(payload) {
            const vm = this;
            const jobHistoryId = payload.id;

            Vue.backendApi()
                .stationShift(vm.shift.id)
                .addJobToShift(payload.job_id, payload)
                .then(success, failure);

            function success(response) {
                replaceJobHistory();

                if (vm.hasDowntime) {
                    showDowntimeWarningIfExistsOverlapping();
                }

                if (vm.isCtrlKeyPressed) {
                    Vue.nextTick(function () {
                        vm.$eventHub.$emit("editJobHoursShift", response.data);
                    });
                }

                function replaceJobHistory() {
                    let replaceIndex = payload.pending_id
                        ? vm.shift.job_history.findIndex(
                              (jobHistory) =>
                                  jobHistory.pending_id === jobHistoryId
                          )
                        : vm.shift.job_history.findIndex(
                              (jobHistory) => jobHistory.id === jobHistoryId
                          );

                    if (replaceIndex > -1) {
                        vm.shift.job_history.splice(
                            replaceIndex,
                            1,
                            response.data
                        );
                    }
                    vm.$eventHub.$emit("job-history-added", response.data);
                }

                function showDowntimeWarningIfExistsOverlapping() {
                    let lastDowntimeIndex = vm.machineDowntimes.length - 1;
                    let downtime = vm.machineDowntimes[lastDowntimeIndex];
                    let downtimeEnd = Vue.moment(downtime.ended_at);
                    let shiftTimeStart = Vue.moment(payload.started_at);
                    let existsOverlapping =
                        shiftTimeStart.isBefore(downtimeEnd);

                    if (existsOverlapping) {
                        vm.overlapping = {
                            machineDowntime: downtime,
                            jobHistory: response.data,
                        };
                    }
                }
            }

            function failure(error) {
                let index = vm.shift.job_history.findIndex(
                    (jobHistory) => jobHistory.id === jobHistoryId
                );
                vm.shift.job_history.splice(index, 1);
            }
        },
    },
};

function mounted() {
    const vm = this;

    vm.$eventHub.$on("showAllInShift", (shift) => {
        if (vm.shift.id === shift.id) {
            vm.showOnlyOneJob = false;
        }
    });

    vm.$eventHub.$on("showOneInShift", (shift) => {
        if (
            vm.shift.id === shift.id &&
            !this.selectedJobHistory &&
            !this.draggingJob
        ) {
            vm.showOnlyOneJob = true;
        }
    });

    window.addEventListener("keydown", function (e) {
        if (e.ctrlKey) {
            vm.isCtrlKeyPressed = true;
        }
    });

    window.addEventListener("keyup", function (e) {
        if (e.key === "Control") {
            vm.isCtrlKeyPressed = false;
        }
    });
}
</script>

<style scoped lang="scss">
.job-container {
    min-height: 22px;
}

.editing {
    border: 1px dashed red;
}

.removing {
    opacity: 0.5;
    text-decoration: line-through;
}

.job-item {
    position: relative;
    padding: 2px;

    &.ghost {
        background-color: rgb(255, 220, 220);
        border: 2px dashed black;
    }
}
</style>
