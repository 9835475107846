<template>
    <form id="logout-form" action="/logout" method="POST" style="display: none">
        <input type="hidden" name="_token" :value="csrf" />
    </form>
</template>

<script>
export default {
    data() {
        return {
            csrf: document
                .querySelector('meta[name="csrf-token"]')
                .getAttribute("content"),
        };
    },
};
</script>
