<template>
    <v-data-table
        :headers="headers"
        :items="schedule.shifts"
        class="elevation-1 row-pointer"
        :loading="loading"
        :item-class="rowClasses"
    >
        <template v-slot:top>
            <v-toolbar
                flat
                color="white"
                :prominent="$vuetify.breakpoint.width < 409"
            >
                <v-row class="justify-content-between">
                    <v-col cols="auto" class="vertical-centered pr-0 mr-auto">
                        <v-toolbar-title>Shifts</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col cols="auto" class="vertical-centered">
                        <v-switch
                            label="Show all"
                            v-model="isWithTrashed"
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="auto" class="vertical-centered">
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="showShiftEditor = true"
                            v-if="schedule.shifts.length < 3"
                            >New Shift</v-btn
                        >
                        <v-dialog
                            persistent
                            v-model="showShiftEditor"
                            @click:outside="confirmClose"
                            @keydown.esc="confirmClose"
                            max-width="500px"
                        >
                            <v-card>
                                <v-card-title class="primary white--text">
                                    <span class="headline">
                                        {{ formTitle }}
                                    </span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-text-field
                                                    v-model="editedShift.name"
                                                    label="Name"
                                                    @input="isDirty = true"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <time-picker
                                                    v-model="
                                                        editedShift.start_at
                                                    "
                                                    @input="isDirty = true"
                                                >
                                                </time-picker>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <duration-picker
                                                    v-model="
                                                        editedShift.duration
                                                    "
                                                    @input="isDirty = true"
                                                >
                                                </duration-picker>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="confirmClose"
                                        >Cancel</v-btn
                                    >
                                    <v-btn color="primary" @click="save"
                                        >Save</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
        <template v-slot:item.start_at="{ item }">
            {{ item.start_at | secondsToTime }}
        </template>
        <template v-slot:item.duration="{ item }">
            {{ item.duration | secondsToReadableDuration }}
        </template>
        <template v-slot:item.actions="{ item, index }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editShift(item)"
                title="Edit"
                v-if="!item.deleted_at"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="hardDestroy(item, index)"
                v-if="item.deleted_at"
                title="Delete Archive "
            >
                mdi-archive-alert
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="restore(item, index)"
                v-if="item.deleted_at || item.isTrashing"
                title="Unarchive "
            >
                mdi-backup-restore
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="softDestroy(item, index)"
                v-else
                title="Archive"
            >
                mdi-archive
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import secondsToTime from "Filters/secondsToTime";
import secondsToReadableDuration from "Filters/secondsToReadableDuration";
import timePicker from "Components/utils/vuetify-time-picker";
import durationPicker from "Components/utils/vuetify-duration-picker";

export default {
    components: { timePicker, durationPicker },
    data: data,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Shift" : "Edit Shift";
        },
    },
    filters: { secondsToTime, secondsToReadableDuration },
};

function data() {
    return {
        showShiftEditor: false,
        isWithTrashed: false,
        deletingTimeoutId: null,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Start at",
                align: "start",
                sortable: false,
                value: "start_at",
                class: "grey--text text--darken-3",
            },
            {
                text: "Duration",
                align: "start",
                sortable: false,
                value: "duration",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedShift: {
            id: "new-shift-id",
            name: "",
            start_at: 28800,
            duration: 28800,
        },
        defaultShift: {
            id: "new-shift-id",
            name: "",
            start_at: 28800,
            duration: 28800,
        },
        schedule: { shifts: [] },
        loading: false,
        showNewShiftButton: false,
    };
}

function getWatchers() {
    return {
        showShiftEditor: {
            handler: dialog,
        },
        isWithTrashed: {
            handler: loadSchedule,
        },
        "$route.params.id": {
            handler: loadSchedule,
            immediate: true,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function loadSchedule() {
        this.getLists.apply(this);
    }
}

function getMethods() {
    return {
        editShift: editShift,
        close: close,
        rowClasses: rowClasses,
        restore: restore,
        getLists: getLists,
        confirmClose: confirmClose,
        save: save,
        softDestroy: softDestroy,
        hardDestroy: hardDestroy,
    };

    function restore(shift, index) {
        const vm = this;
        clearTimeout(vm.deletingTimeoutId);

        Vue.backendApi()
            .schedules(vm.schedule.id)
            .shifts(shift.id)
            .restore()
            .then(success, fail);

        function success(response) {
            shift.isTrashing = false;
            Vue.set(vm.schedule.shifts, index, shift);
            shift.deleted_at = null;
        }

        function fail(error) {
            console.log("An error occurred trying to restore a shifts");
        }
    }

    function getLists() {
        const vm = this;
        const isWithTrashed = vm.isWithTrashed || false;
        vm.loading = true;

        Vue.backendApi()
            .schedules(this.$route.params.id)
            .shifts()
            .index(isWithTrashed)
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "schedule", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get shifts");
        }
    }

    function rowClasses(shifts) {
        if (shifts.deleted_at || shifts.isTrashing) {
            return "user-archived";
        }
        return "";
    }

    function editShift(shift) {
        this.editedIndex = this.schedule.shifts.indexOf(shift);
        this.editedShift = Object.assign({}, shift);
        this.showShiftEditor = true;
    }

    function close() {
        this.showShiftEditor = false;
        this.$nextTick(() => {
            this.editedShift = Object.assign({}, this.defaultShift);
            this.editedIndex = -1;
        });
        this.isDirty = false;
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (vm.editedShift.id === "new-shift-id") {
            Vue.backendApi()
                .schedules(vm.schedule.id)
                .shifts()
                .store(vm.editedShift)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .schedules(vm.schedule.id)
                .shifts(vm.editedShift.id)
                .store(vm.editedShift)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(
                    vm.schedule.shifts[vm.editedIndex],
                    response.data
                );
            } else {
                vm.schedule.shifts.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save shift");
        }
    }

    function softDestroy(shift, index) {
        const vm = this;

        shift.isTrashing = false;
        Vue.set(vm.schedule.shifts, index, shift);
        Vue.backendApi()
            .schedules(vm.schedule.id)
            .shifts(shift.id)
            .softDestroy()
            .then(success, fail);

        function success(response) {
            if (vm.isWithTrashed) {
                shift.deleted_at = response.data.deleted_at;
            } else {
                shift.isTrashing = true;
                Vue.set(vm.schedule.shifts, index, shift);

                vm.deletingTimeoutId = setTimeout(() => {
                    vm.schedule.shifts.splice(index, 1);
                }, 2000);
            }
        }

        function fail(error) {
            console.log("An error occurred trying to archive a shifts");
        }
    }

    function hardDestroy(shift, index) {
        const vm = this;

        if (confirm("Delete permanently? \nAre you sure?")) {
            Vue.backendApi()
                .schedules(vm.schedule.id)
                .shifts(shift.id)
                .hardDestroy()
                .then(success, fail);
        }

        function success(response) {
            vm.schedule.shifts.splice(index, 1);
        }

        function fail(error) {
            console.log("An error occurred trying to archive a shifts");
        }
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep .user-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep label {
    margin-bottom: 0px;
}
</style>
