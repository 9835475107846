<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="500px"
    >
        <v-card class="mx-auto text-left" tile>
            <v-card-title
                v-if="item.isJobCollection"
                :class="{ 'pb-0': !item.serial_number }"
            >
                <span class="job-collection">Job collection </span> |
                {{ item.name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-card-title>
            <v-card-title v-else :class="{ 'pb-0': !item.serial_number }">
                Job | {{ item.name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-card-title>
            <v-list>
                <v-subheader>Actions</v-subheader>
                <v-list-item @click="$emit('details', item)">
                    <v-list-item-icon>
                        <v-icon>mdi-table-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>More Details</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <div v-if="item.isJobCollection">
                    <v-list-item
                        v-if="
                            expandedJobCollection &&
                            expandedJobCollection.id === item.id
                        "
                        @click="$emit('collapse', item)"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-up</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Hide jobs</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-else @click="$emit('expand', item)">
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Show jobs</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            show: true,
        };
    },
    props: {
        item: Object,
        expandedJobCollection: Object,
    },
};
</script>

<style lang="scss" scoped>
.job-collection {
    border-radius: 10px;
    padding: 0px 8px;
    background-color: #7ca6ad;
    color: white;
    margin-right: 8px;
    margin-left: -8px;
}
</style>
