<template>
    <v-row v-if="user.password != undefined">
        <v-col cols="12" class="py-0" v-if="user.id === $store.state.user.id">
            <v-text-field
                v-model="user.current_password"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Current password"
                @input="change"
                :rules="[rules.required]"
                :type="showCurrentPassword ? 'text' : 'password'"
                @click:append="showCurrentPassword = !showCurrentPassword"
                autocomplete="off"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
            <v-text-field
                v-model="user.password"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="New password"
                @input="change"
                :rules="[rules.required, rules.min]"
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
                hint="At least 8 characters"
                counter
                autocomplete="off"
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
            <v-text-field
                v-model="passwordConfirmation"
                :append-icon="
                    showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
                "
                label="New password confirmation"
                @input="change"
                :rules="[rules.required, rules.min, confirmPasswordMatch]"
                :type="showPasswordConfirmation ? 'text' : 'password'"
                @click:append="
                    showPasswordConfirmation = !showPasswordConfirmation
                "
                hint="At least 8 characters"
                counter
                autocomplete="off"
            ></v-text-field>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data: data,
    watch: getWatchers(),
    props: {
        user: Object,
    },
    methods: getMethods(),
};

function data() {
    return {
        passwordConfirmation: "",
        showCurrentPassword: false,
        showNewPassword: false,
        showPasswordConfirmation: false,
        rules: {
            required: (value) => !!value || "Required.",
            min: (v) => v.length >= 8 || "Min 8 characters",
        },
    };
}

function getWatchers() {
    return {
        user: function () {
            this.passwordConfirmation = "";
        },
    };
}

function getMethods() {
    return {
        change: change,
        confirmPasswordMatch: confirmPasswordMatch,
    };

    function change() {
        const vm = this;
        vm.$emit("change");
    }

    function confirmPasswordMatch() {
        if (this.passwordConfirmation === this.user.password) {
            return true;
        }
        return "Passwords do not match";
    }
}
</script>
