<template>
    <div>
        <div class="error-overlay"></div>
        <div class="errors">
            <div class="errors-header">An error occurred</div>
            <ul class="errors--error-items">
                <li
                    v-for="error in errors"
                    class="errors--error-items--error-item"
                >
                    {{ error }}
                </li>
            </ul>
            <div class="errors-footer">
                <kheera-button
                    value="Dismiss"
                    @click="clearErrors"
                ></kheera-button>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import kheeraButton from "Components/overview/utils/kheera-button";
export default {
    name: "kheera-errors",
    components: { kheeraButton },
    methods: getMethods(),
    props: {
        errors: Array,
    },
};

function getMethods() {
    return {
        clearErrors: clearErrors,
    };

    function clearErrors() {
        this.$store.commit("clearErrors");
    }
}
</script>

<style scoped lang="scss">
@import "~@/_variables.scss";

div.error-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $lightGrey;
    opacity: 0.7;
    z-index: 980;
}
div.errors {
    background-color: transparent;
    position: absolute;
    z-index: 9999;
    width: 25vw;
    left: 37vw;
    top: 30vh;

    & div.errors-header {
        border-radius: 10px 10px 0 0;
        padding: 10px;
        background-color: #c9949a;
        border-style: solid;
        border-width: 1px 1px 0 1px;
        border-color: black;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
    }

    & ul.errors--error-items {
        padding: 20px 20px 10px 40px;
        background-color: white;
        border-style: solid;
        border-width: 0 1px 0 1px;
        border-color: black;
        margin: 0;

        & li.errors--error-items--error-item {
            word-wrap: break-word;
        }
    }

    & .errors-footer {
        padding: 10px;
        border-radius: 0 0 10px 10px;
        background-color: white;
        border-style: solid;
        border-width: 0 1px 1px 1px;
        border-color: black;
    }
}
</style>
