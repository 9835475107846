export default {
    data: () => ({
        nonEfficientReport: {
            show: false,
            isLoading: false,
            subtitle: "",
            report: [],
        }
    }),

    methods: {
        $closeNonEfficientReport() {
            this.nonEfficientReport = {
                show: false,
                isLoading: false,
                subtitle: "",
                report: [],
            };
        }
    },
};
