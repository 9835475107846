<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>

            <v-card class="mb-6">
                <toolbar-report
                    title="Sales order reports"
                    routeName="sales-order-reports"
                    @search="(query) => (search = query)"
                    useFilters
                    @no-filters="salesOrders = []"
                    @get-reports-on="(payload) => getSalesOrders(payload)"
                >
                    <template>
                        <v-btn
                            class="ma-2"
                            outlined
                            :href="`/app/sales-order-reports/profitability-report/${profitabilityReportPeriod}`"
                        >
                            Profitability Report
                        </v-btn>
                    </template>
                </toolbar-report>
            </v-card>

            <v-data-table
                :headers="headers"
                :items="salesOrders"
                class="elevation-2"
                :loading="loading"
                :items-per-page="100"
                show-expand
                single-expand
                item-key="id"
                :expanded.sync="expanded"
                @item-expanded="loadDetails"
                :search="search"
            >
                <template v-slot:item.id="{ item }">
                    <router-link
                        class="grey--text text--darken-3 font-weight-medium"
                        :to="`/app/sales-orders/${item.id}`"
                    >
                        {{ item.id }}
                    </router-link>
                </template>
                <template v-slot:item.client_name="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                class="text-truncate"
                                style="max-width: 150px"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ item.client_name }}
                            </div>
                        </template>
                        <span>{{ item.client_name }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.purchase_order_number="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                class="text-truncate"
                                style="max-width: 127px"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ item.purchase_order_number }}
                            </div>
                        </template>
                        <span>{{ item.purchase_order_number }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.item_names="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                class="text-truncate"
                                style="max-width: 92px"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ item.item_names }}
                            </div>
                        </template>
                        <span>{{ item.item_names }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:item.cost_of_materials="{ item }">
                    {{ item.cost_of_materials | currencyFormat }}
                </template>
                <template v-slot:item.cost_of_stations="{ item }">
                    {{ item.cost_of_stations | currencyFormat }}
                </template>
                <template v-slot:item.total_cost="{ item }">
                    {{ item.total_cost | currencyFormat }}
                </template>
                <template v-slot:item.amount="{ item }">
                    {{ item.amount | currencyFormat }}
                </template>
                <template v-slot:item.profit="{ item }">
                    {{ item.profit | currencyFormat }}
                </template>
                <template v-slot:item.profit_margin="{ item }">
                    <span
                        class="font-weight-medium text--darken-2"
                        :class="{
                            'green--text': item.profit_margin > 0,
                            'red--text': item.profit_margin < 0,
                        }"
                    >
                        {{ item.profit_margin | round | percent }}
                    </span>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ item.created_at | date }}
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="py-6">
                        <div v-if="item.detailedSalesOrderItems">
                            <b>Additional information:</b>
                            {{ item.additional_information }}
                            <br />
                            <b>Items:</b>
                            {{ item.detailedSalesOrderItems.length }}
                            <sales-report-items
                                :salesOrder="item"
                                class="mt-4 mb-3"
                            >
                            </sales-report-items>
                        </div>
                        <div class="text-center my-6" v-else>
                            <v-progress-circular
                                :size="70"
                                :width="7"
                                color="primary"
                                indeterminate
                                transition="fade-transition"
                            ></v-progress-circular>
                        </div>
                    </td>
                </template>
            </v-data-table>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import currencyFormat from "Filters/currencyFormat";
import round from "Filters/round";
import percent from "Filters/percent";
import date from "Filters/shortDate";
import toolbarReport from "Components/utils/toolbarReport";
import salesReportItems from "Components/sales-reports/sales-report-items";

export default {
    components: { pageHeaderVuetify, toolbarReport, salesReportItems },
    data: data,
    computed: getComputed(),
    methods: getMethods(),
    watch: getWatchers(),
    filters: { currencyFormat, round, percent, date },
};

function getWatchers() {
    return {
        "$route.query.search": function () {
            this.search = this.$route.query.search;
        },
        expanded: function () {
            this.salesOrderId = this.expanded.map((item) => item.id).join(",");
            this.$router.push({
                name: this.routeName,
                query: {
                    ...this.$route.query,
                    salesOrderId: this.salesOrderId,
                },
                params: this.$route.params,
            });
        },
    };
}

function data() {
    return {
        loading: false,
        search: this.$route.query.search,
        salesOrderId: this.$route.query.salesOrderId,
        salesOrders: [],
        expanded: [],
        headers: [
            {
                text: "S. Order",
                align: "center",
                sortable: true,
                value: "id",
                class: "grey--text text--darken-3",
                width: 95,
            },
            {
                text: "Client",
                align: "center",
                sortable: true,
                value: "client_name",
                class: "grey--text text--darken-3",
                width: 150,
            },
            {
                text: "Purchase order",
                align: "center",
                sortable: false,
                value: "purchase_order_number",
                class: "grey--text text--darken-3",
                width: 127,
            },
            {
                text: "Items",
                align: "center",
                sortable: false,
                value: "item_names",
                class: "grey--text text--darken-3",
                width: 92,
            },
            {
                text: "Cost of materials",
                align: "center",
                sortable: true,
                value: "cost_of_materials",
                class: "grey--text text--darken-3",
                filterable: false,
                width: 143,
            },
            {
                text: "Cost of stations",
                align: "center",
                sortable: true,
                value: "cost_of_stations",
                class: "grey--text text--darken-3",
                filterable: false,
                width: 136,
            },
            {
                text: "Total cost",
                align: "center",
                sortable: true,
                value: "total_cost",
                class: "grey--text text--darken-3",
                filterable: false,
                width: 145,
            },
            {
                text: "Sales order amount",
                align: "center",
                sortable: true,
                value: "amount",
                class: "grey--text text--darken-3",
                filterable: false,
                width: 156,
            },
            {
                text: "Profit",
                value: "profit",
                align: "center",
                sortable: true,
                class: "grey--text text--darken-3",
                filterable: false,
                width: 140,
            },
            {
                text: "Profit margin",
                value: "profit_margin",
                align: "center",
                sortable: true,
                class: "grey--text text--darken-3",
                filterable: false,
                width: 121,
            },
            {
                text: "Created",
                value: "created_at",
                align: "center",
                sortable: true,
                class: "grey--text text--darken-3",
                filterable: false,
                width: 121,
            },
            {
                value: "data-table-expand",
                width: 10,
            },
        ],
    };
}

function getComputed() {
    return {
        profitabilityReportPeriod: profitabilityReportPeriod,
    };

    function profitabilityReportPeriod() {
        let start = this.$route.params.start;
        let end = this.$route.params.end;
        const monthPattern = /^[0-9]{4}\-[0-9]{2}$/;
        const isStartMonth = monthPattern.test(start);

        if (isStartMonth) {
            start = `${start}-01`;
            end = Vue.moment(start).endOf("month").format("YYYY-MM-DD");
        }
        return `${start}/${end}`;
    }
}

function getMethods() {
    return {
        getSalesOrders: getSalesOrders,
        loadDetails: loadDetails,
        expandItem: expandItem,
    };

    function expandItem() {
        if (this.salesOrderId) {
            let expandList = this.salesOrderId
                .split(",")
                .map((numStr) => parseInt(numStr));
            let expandedSalesOrder =
                this.salesOrders.filter((x) => expandList.includes(x.id)) || [];

            if (expandedSalesOrder.length > 0) {
                this.expanded = expandedSalesOrder;
                expandedSalesOrder.map((eachExpand) => {
                    this.loadDetails({ item: eachExpand, value: true });
                });
            }
        }
    }

    function getSalesOrders(payload) {
        const vm = this;
        vm.loading = true;

        Vue.backendApi()
            .reports()
            .salesOrders()
            .on(payload)
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "salesOrders", response.data);
            vm.expandItem();
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get sales reports");
        }
    }

    function loadDetails({ item: salesOrder, value: expand }) {
        if (!expand) {
            return;
        }

        Vue.set(salesOrder, "loadingDetails", true);
        Vue.backendApi()
            .reports()
            .salesOrders(salesOrder.id)
            .show()
            .then(success, fail);

        function success(response) {
            salesOrder.loadingDetails = false;
            Vue.set(salesOrder, "detailedSalesOrderItems", response.data);
        }

        function fail(error) {
            salesOrder.loadingDetails = false;
            console.log(
                "An error occurred trying to get details of sales order"
            );
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr.v-data-table__expanded__content td {
    text-align: left;
    padding: 1rem;
}
::v-deep.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 0 12px;
}
::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 12px;
}
</style>
