<template>
    <v-card class="mr-5 card-matrix mb-4 mb-sm-0">
        <v-app-bar
            dark
            color="#ebecf0"
            elevation="0"
            :scroll-target="`#scrolling-container-${column.id}`"
        >
            <v-toolbar-title class="black--text toolbar-title">
                {{ column.label.name }}
                <span class="jobs-counter text-muted">
                    {{ this.countJobs }}
                    {{ this.countJobs === 1 ? "job" : "jobs" }}
                </span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu v-if="$vuetify.breakpoint.smAndUp" left bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" color="grey darken-1">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item @click="edit">
                        <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showDeleteConfirmation = true">
                        <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-sheet
            :id="`scrolling-container-${column.id}`"
            class="overflow-y-auto scroll-container"
            max-height="600"
            color="#ebecf0"
        >
            <v-container>
                <v-row dense>
                    <v-col cols="12">
                        <draggable
                            v-model="temporalJobCollections"
                            v-bind="dragOptions"
                            @end="moveJobCollection"
                            group="jobCollections"
                            :move="checkMove"
                        >
                            <transition-group
                                type="transition"
                                name="flip-matrix"
                                :data-column-id="column.id"
                                class="matrix-container"
                            >
                                <job-collection
                                    v-for="jobCollection in jobCollections"
                                    :key="jobCollection.id"
                                    :jobCollection="jobCollection"
                                    class="matrix-item"
                                    :class="{
                                        filtered:
                                            $isFilteredBySearch(jobCollection),
                                    }"
                                ></job-collection>
                            </transition-group>
                        </draggable>
                    </v-col>

                    <confirmation
                        :show="showDeleteConfirmation"
                        @cancelled="showDeleteConfirmation = false"
                        @confirmed="destroy()"
                    ></confirmation>

                    <dialog-modal
                        v-if="deletingErrorMessage"
                        title="Action not allowed"
                        :content="deletingErrorMessage"
                        :showOkButton="false"
                        @closeCancelDialog="cancelDialog"
                    />
                </v-row>
            </v-container>
        </v-sheet>
    </v-card>
</template>

<script>
import draggable from "vuedraggable";
import jobCollection from "Components/matrix/job-collection";
import confirmation from "Components/utils/confirmation";
import dialogModal from "Components/utils/dialog-modal";
import filterJobItemBySearchMixin from "Mixins/filterJobItemBySearch.mixin";
import matrixColumnMovementMixin from "Mixins/matrix-column-movement.mixin";

export default {
    mixins: [filterJobItemBySearchMixin, matrixColumnMovementMixin],

    components: { draggable, jobCollection, confirmation, dialogModal },

    props: {
        column: Object,
        search: String,
    },

    data: () => ({
        showDeleteConfirmation: false,
        deletingErrorMessage: "",
    }),

    methods: {
        checkMove: function (evt) {
            const columnDestinationId = parseInt(evt.to.dataset.columnId);
            if (isNaN(columnDestinationId)) {
                return false;
            }
        },

        moveJobCollection: function (evt) {
            const vm = this;
            const columnDestinationId = parseInt(evt.to.dataset.columnId);
            const isMovementInSameColumn = columnDestinationId === vm.column.id;
            const noMovement =
                isMovementInSameColumn && evt.oldIndex === evt.newIndex;

            if (noMovement || isNaN(columnDestinationId)) {
                return;
            }

            const jobCollection = evt.item._underlying_vm_;
            Vue.backendApi()
                .matrix()
                .columns(columnDestinationId)
                .jobCollection(jobCollection.id)
                .movement({
                    newPosition: evt.newIndex,
                    oldColumnId: vm.column.id,
                })
                .then(success, fail);

            function success(response) {
                if (isMovementInSameColumn) {
                    let reorderedColumns = [...vm.jobCollections];
                    reorderedColumns.splice(evt.oldIndex, 1);
                    reorderedColumns.splice(evt.newIndex, 0, jobCollection);
                    vm.jobCollections = reorderedColumns;
                } else {
                    vm.$blockMovementsWhileAnimationFinishes(evt);
                    vm.$removeJobCollection(jobCollection);
                    vm.$eventHub.$emit("moveJobCollection", {
                        jobCollection,
                        columnDestinationId,
                        index: evt.newIndex,
                    });
                }
            }

            function fail(error) {
                console.log("An error occurred trying to move job");
            }
        },

        cancelDialog: function () {
            const vm = this;
            vm.deletingErrorMessage = "";
        },

        edit: function () {
            const vm = this;
            vm.$emit("edit", vm.column);
        },

        destroy: function () {
            const vm = this;

            Vue.backendApi()
                .matrix()
                .columns(vm.column.id)
                .destroy()
                .then(success, fail);

            function success(response) {
                vm.showDeleteConfirmation = false;
                vm.$emit("destroyed", vm.column);
            }

            function fail(error) {
                vm.showDeleteConfirmation = false;
                console.log("An error occurred trying to delete a column");
                vm.deletingErrorMessage =
                    error.response.data.errors.column.pop() || "";
            }
        },
    },

    mounted() {
        const vm = this;

        vm.$eventHub.$on("moveJobCollection", (payload) => {
            if (payload.columnDestinationId === vm.column.id) {
                vm.jobCollections.splice(
                    payload.index,
                    0,
                    payload.jobCollection
                );
            }
        });

        getJobCollections();

        function getJobCollections() {
            Vue.backendApi()
                .jobCollections()
                .withLabels({
                    labelIds: [vm.column.label.id],
                    columnId: vm.column.id,
                })
                .then(success, fail);

            function success(response) {
                Vue.set(vm, "jobCollections", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to get job collections");
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.flip-matrix-move {
    transition: transform 0.5s;
}

.flip-matrix-enter-active,
.flip-matrix-leave-active {
    transition: opacity 0.5s;
}

.flip-matrix-enter,
.flip-matrix-leave-to {
    opacity: 0;
}

.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #dbdbdb;
    border: 2px solid #7ca6ad;
}

.matrix-container {
    display: block;
}

.matrix-item {
    cursor: pointer;
}

.scroll-container {
    scrollbar-width: thin;
}

.card-matrix {
    width: 100%;
    display: block;
    white-space: nowrap;
    vertical-align: top;
}
.toolbar-title {
    position: relative;
    line-height: 2.6;
}
.jobs-counter {
    position: absolute;
    left: 0;
    top: 30px;
    font-size: 12px;
}
.filtered {
    display: none;
}

@media (min-width: 600px) and (max-width: 700px) {
    .card-matrix {
        width: 280px;
        min-width: 280px;
        max-width: 280px;
        display: inline-block;
    }
}

@media (min-width: 701px) {
    .card-matrix {
        width: 300px;
        min-width: 300px;
        max-width: 300px;
        display: inline-block;
    }
}
</style>
