<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="12" sm="8" md="4">
                    <type-of-hours-list></type-of-hours-list>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import typeOfHoursList from "Components/type-of-hours/type-of-hours-list";

export default {
    components: { pageHeaderVuetify, typeOfHoursList },
};
</script>
