<template>
    <v-card-title>
        <span
            class="headline h4 d-flex justify-space-between"
            :class="preferences.background"
        >
            <slot></slot>
            <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn></span
        >
    </v-card-title>
</template>

<script>
export default {
    props: {
        preferences: Object
    }
}
</script>