<template>
    <div class="menu-container">
        <div
            class="menu"
            v-custom-click-outside="clickOutside"
            @click="showMenu = !showMenu"
        >
            <i class="fas fa-ellipsis-v"></i>
            <cinasa-menu :showMenu="showMenu"></cinasa-menu>
        </div>
        <div class="title">{{ title }}</div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import cinasaMenu from "Components/overview/utils/cinasa-menu";
export default {
    name: "cinasa-menu0",
    data: data,
    components: { cinasaMenu },
    props: { title: String },
    methods: getMethods(),
};

function data() {
    return {
        showMenu: false,
    };
}

function getMethods() {
    return {
        clickOutside: clickOutside,
    };

    function clickOutside() {
        this.showMenu = false;
    }
}
</script>

<style scoped>
.menu {
    cursor: pointer;
}
.page-header {
    margin-bottom: 10px;
}

.title {
    display: inline-block;
    font-size: 35px;
    font-weight: 700;
    float: right;
    margin-right: 29px;
}
</style>
