<template>
    <v-data-table
        :headers="headers"
        :items="users"
        class="elevation-1 row-pointer"
        :loading="loading"
        :search="search"
        :item-class="rowClasses"
    >
        <template v-slot:top>
            <v-toolbar
                flat
                color="white"
                :prominent="$vuetify.breakpoint.width < 641"
            >
                <v-row class="justify-content-between">
                    <v-col cols="auto" class="vertical-centered pr-0 mr-auto">
                        <v-toolbar-title>Users</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col
                        cols="7"
                        sm="auto"
                        md="5"
                        lg="4"
                        class="vertical-centered mx-lg-auto"
                    >
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="auto" class="vertical-centered">
                        <v-switch
                            label="Show all"
                            v-model="isWithTrashed"
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="6" sm="auto" class="text-right text-sm-center">
                        <v-dialog
                            persistent
                            v-model="showUserEditor"
                            @click:outside="confirmClose"
                            @keydown.esc="confirmClose"
                            @keydown.enter="save"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >New User</v-btn
                                >
                            </template>
                            <v-card>
                                <v-card-title class="primary white--text">
                                    <span class="headline">
                                        {{ formTitle }}
                                    </span>
                                </v-card-title>

                                <v-card-text>
                                    <v-form ref="editUserForm">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" class="pb-0">
                                                    <v-text-field
                                                        v-model="
                                                            editedUser.name
                                                        "
                                                        label="Name"
                                                        @input="isDirty = true"
                                                        :rules="[
                                                            () =>
                                                                !!editedUser.name ||
                                                                'Required.',
                                                        ]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                        v-model="
                                                            editedUser.email
                                                        "
                                                        label="Email"
                                                        :error-messages="
                                                            emailError
                                                        "
                                                        @input="isDirty = true"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" class="pt-0">
                                                    <v-select
                                                        v-model="
                                                            editedUser.processes
                                                        "
                                                        :items="processes"
                                                        item-text="name"
                                                        item-value="id"
                                                        :menu-props="{
                                                            maxHeight: '400',
                                                        }"
                                                        label="Processes"
                                                        return-object
                                                        multiple
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" class="pt-0">
                                                    <v-select
                                                        v-model="
                                                            editedUser.groups
                                                        "
                                                        :items="groups"
                                                        item-text="name"
                                                        item-value="id"
                                                        :menu-props="{
                                                            maxHeight: '400',
                                                        }"
                                                        label="User Group"
                                                        return-object
                                                        multiple
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                            <password-input
                                                :user="editedUser"
                                                @change="isDirty = true"
                                            >
                                            </password-input>
                                        </v-container>
                                    </v-form>
                                </v-card-text>

                                <v-card-actions>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="auto"
                                            class="mr-sm-auto"
                                        >
                                            <v-btn @click="changePassword">
                                                Change password
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="auto">
                                            <v-btn
                                                color="primary"
                                                @click="confirmClose"
                                                >Cancel</v-btn
                                            >
                                            <v-btn color="primary" @click="save"
                                                >Save</v-btn
                                            >
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
            <div class="text-truncate" style="max-width: 200px">
                {{ item.name }}
            </div>
        </template>
        <template v-slot:item.email="{ item }">
            <div class="text-truncate" style="max-width: 200px">
                {{ item.email }}
            </div>
        </template>
        <template v-slot:item.groups="{ item }">
            <vuetify-some-chips
                :items="item.groups"
                :number-items-to-show="3"
                chip-color="green lighten-3"
                icon="mdi-shield-check"
            >
            </vuetify-some-chips>
        </template>
        <template v-slot:item.processes="{ item }">
            <vuetify-some-chips
                :items="item.processes"
                :number-items-to-show="3"
                chip-color="grey lighten-2"
                icon="mdi-wrench"
            >
            </vuetify-some-chips>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | date }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editUser(item)"
                title="Edit"
                v-if="!item.deleted_at"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="restore(item)"
                v-if="item.deleted_at"
                title="Unarchive "
            >
                mdi-backup-restore
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="destroy(item)"
                v-else
                title="Archive"
            >
                mdi-archive
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import date from "Filters/shortDate";
import arrayToString from "Filters/arrayToString";
import truncate from "Filters/truncate";
import passwordInput from "Components/utils/password-input";
import vuetifySomeChips from "Components/utils/vuetify-some-chips";

export default {
    components: { passwordInput, vuetifySomeChips },
    data: data,
    created: created,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New User" : "Edit User";
        },
        processes() {
            return this.$store.state.processes;
        },
    },
    filters: { date, truncate, arrayToString },
};

function data() {
    return {
        showUserEditor: false,
        isDirty: false,
        emailError: [],
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "name",
                class: "grey--text text--darken-3",
                width: 200,
            },
            {
                text: "Email",
                align: "start",
                sortable: true,
                value: "email",
                class: "grey--text text--darken-3",
                width: 200,
            },
            {
                text: "User Group",
                align: "center",
                sortable: false,
                value: "groups",
                class: "grey--text text--darken-3",
            },
            {
                text: "Processes",
                align: "center",
                sortable: false,
                value: "processes",
                class: "grey--text text--darken-3",
            },
            {
                text: "Created",
                align: "center",
                sortable: true,
                value: "created_at",
                class: "grey--text text--darken-3",
                filterable: false,
                width: 122,
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
                width: 77,
            },
        ],
        editedIndex: -1,
        editedUser: {
            id: "new-user-id",
            name: "",
        },
        defaultUser: {
            id: "new-user-id",
            name: "",
        },
        users: [],
        groups: [],
        search: "",
        isWithTrashed: false,
        loading: false,
    };
}

function getWatchers() {
    return {
        showUserEditor: {
            handler: dialog,
        },
        isWithTrashed: {
            handler: getUsers,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function getUsers() {
        this.getUsers.apply(this);
    }
}

function getMethods() {
    return {
        editUser: editUser,
        close: close,
        confirmClose: confirmClose,
        save: save,
        destroy: destroy,
        getUsers: getUsers,
        restore: restore,
        rowClasses: rowClasses,
        getGroups: getGroups,
        changePassword: changePassword,
    };

    function getUsers() {
        const vm = this;
        this.loading = true;

        Vue.backendApi().users().index(vm.isWithTrashed).then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "users", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get users");
        }
    }

    function editUser(user) {
        this.editedIndex = this.users.indexOf(user);
        this.editedUser = Object.assign({}, user);
        this.showUserEditor = true;
    }

    function close() {
        this.showUserEditor = false;
        this.$nextTick(() => {
            this.editedUser = Object.assign({}, this.defaultUser);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.emailError = null;
        this.$refs.editUserForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editUserForm.validate()) {
            return false;
        }

        if (vm.editedUser.id === "new-user-id") {
            Vue.backendApi().users().store(vm.editedUser).then(success, fail);
        } else {
            Vue.backendApi()
                .users(vm.editedUser.id)
                .update(vm.editedUser)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.users[vm.editedIndex], response.data);
            } else {
                vm.users.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            vm.emailError = error.response.data.errors.email || null;
            console.log("An error occurred trying to save user");
        }
    }

    function destroy(user) {
        const vm = this;

        Vue.backendApi().users(user.id).destroy().then(success, fail);

        function success(response) {
            if (vm.isWithTrashed) {
                user.deleted_at = response.data.deleted_at;
            } else {
                let deletedItemIndex = vm.users.findIndex(function (item) {
                    return item.id === user.id;
                });
                vm.users.splice(deletedItemIndex, 1);
            }
        }

        function fail(error) {
            console.log("An error occurred trying to archive an user");
        }
    }

    function restore(user) {
        const vm = this;

        Vue.backendApi().users(user.id).restore().then(success, fail);

        function success(response) {
            user.deleted_at = null;
        }

        function fail(error) {
            console.log("An error occurred trying to restore an user");
        }
    }

    function rowClasses(user) {
        if (user.deleted_at) {
            return "user-archived";
        }
        return "";
    }

    function getGroups() {
        const vm = this;
        this.loading = true;

        Vue.backendApi().groups().index().then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "groups", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get user groups");
        }
    }

    function changePassword() {
        Vue.set(this.editedUser, "password", "");
    }
}

function created() {
    this.getGroups();
    this.getUsers();
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep label {
    margin-bottom: 0px;
}

::v-deep .user-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
