var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"jobs-list",staticClass:"jobs-list-container"},[_c('div',{staticClass:"employee-list-inner border border-radius"},[_c('div',{staticClass:"hrsjobs heightbgscrolltophrsjob"},[_c('span',{staticClass:"hrs hrs-header"},[_vm._v("\n                    HRS\n                    "),_c('i',{staticClass:"fas fa-long-arrow-alt-up",class:{
                            show: _vm.orderBy === 'hours' ? true : false,
                            active: _vm.orderBy === 'hours' ? true : false,
                            down: _vm.hoursOrder === 'desc' ? true : false,
                        },on:{"click":function($event){return _vm.orderByHours(true)}}})]),_vm._v(" "),_c('span',{staticClass:"hrs hrs-header jobsbg"},[_vm._v("\n                    JOBS\n                    "),_c('i',{staticClass:"fas fa-long-arrow-alt-up",class:{
                            show: _vm.orderBy === 'jobName' ? true : false,
                            active: _vm.orderBy === 'jobName' ? true : false,
                            down: _vm.jobNameOrder === 'desc' ? true : false,
                        },on:{"click":function($event){return _vm.orderByJobName(true)}}})])]),_vm._v(" "),_c('div',{staticClass:"jobs-list"},[_c('div',{staticClass:"jobs-list-inner-container",on:{"mouseover":function($event){_vm.showCtrlMessage = true},"mouseleave":_vm.hideCtrlMessage}},[_c('shop-searcher',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('labels-filter',{model:{value:(_vm.checkedLabels),callback:function ($$v) {_vm.checkedLabels=$$v},expression:"checkedLabels"}}),_vm._v(" "),_vm._l((_vm.collapsedJobs),function(item,index){return _c('drag',{key:index,staticClass:"item",class:{ jobCollection: item.isJobCollection },attrs:{"disabled":item.isJobCollection,"data":item},on:{"dragstart":_vm.handleDragStart,"dragend":_vm.handleDragEnd},scopedSlots:_vm._u([{key:"drag-image",fn:function(){return [_c('div',{staticClass:"item-dragging"},[_vm._v("\n                                "+_vm._s(_vm._f("truncate")(item.name,20))+"\n                            ")])]},proxy:true}],null,true)},[_c('shop-job',{class:{
                                filtered:
                                    _vm.$isFiltered(item) ||
                                    _vm.$isFilteredBySearch(item) ||
                                    _vm.isFilteredByLabel(item),
                            },attrs:{"item":item},on:{"clickOnJobCollection":_vm.clickOnJobCollection}})],1)})],2)])]),_vm._v(" "),(_vm.loading)?_c('overlay-waiting-spinner'):_vm._e(),_vm._v(" "),(_vm.alertModal)?_c('alert-modal',{attrs:{"alert":_vm.alertModal},on:{"close":function($event){_vm.alertModal = null}}}):_vm._e()],1),_vm._v(" "),_c('Transition',[(_vm.showCtrlMessage)?_c('div',{staticClass:"centered-message"},[_c('i',{staticClass:"far fa-lightbulb"}),_vm._v("\n            Press CTRL key while dragging to automatically edit the time\n        ")]):_vm._e()])],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }