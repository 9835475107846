<template>
    <div class="employee-extra-info-container">
        <i class="fas fa-chevron-down"></i>
        <div class="employee-extra-info-modal">
            <div class="employee-info-modal-header">
                <h6>Last Worked Day</h6>
            </div>
            <table class="table table-striped table-bordered">
                <tbody>
                    <tr>
                        <td>{{ employee.last_worked_at | shortDate }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="employee-info-modal-header">
                <h6>Machines</h6>
            </div>
            <table class="table table-striped table-bordered">
                <tbody v-if="employeeHistory.stations.length > 0">
                    <tr
                        v-for="station in employeeHistory.stations"
                        :key="station.id"
                    >
                        <td colspan="2">{{ station.name }}</td>
                    </tr>
                    <tr>
                        <th>Work hours</th>
                        <th class="text-center">
                            {{ employeeHistory.workHours }}h
                        </th>
                    </tr>
                </tbody>

                <tbody v-else>
                    <tr>
                        <td>No work assigned</td>
                    </tr>
                </tbody>
            </table>

            <div class="employee-info-modal-header">
                <h6>Processes</h6>
            </div>
            <table class="table table-striped table-bordered">
                <tbody>
                    <tr v-for="process in employee.processes" :key="process.id">
                        <td>{{ process.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import shortDate from "Filters/shortDate";

export default {
    props: {
        employeeHistory: Object,
        employee: Object,
    },

    filters: { shortDate },
};
</script>

<style lang="scss" scoped>
.employee-extra-info-container {
    position: absolute;
    height: 35px;
    right: 0px;
    color: rgba(0, 0, 0, 0.5);

    .employee-extra-info-modal {
        width: 228px;
        display: none;
        position: absolute;
        right: 0;
        top: 30px;
        box-shadow: 0px 6px 12px 4px #555;
        background: white;
        z-index: 10;
        padding: 10px;
        border-radius: 4px;
        color: rgba(0, 0, 0, 1);
        font-weight: normal;

        h6 {
            padding: 0;
            padding-bottom: 0px;
            text-align: left;
            font-size: 12px;
            padding-bottom: 5px;
        }

        table > tbody > tr > td,
        table > tbody > tr > th {
            padding-top: 0px;
            padding-bottom: 0px;
        }

        p {
            padding-bottom: 0px;
        }
    }

    &:hover {
        .employee-extra-info-modal {
            display: block;
        }
    }
}
</style>
