<template>
    <v-app>
        <v-dialog v-model="show" width="500" @click:outside="$emit('close')">
            <v-card>
                <v-card-title class="white--text" :class="alert.type">
                    <span class="headline">{{ alert.title }}</span>
                </v-card-title>

                <v-card-text class="pb-0 pt-4">
                    {{ alert.message }}
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="default" @click="$emit('close')">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            show: true,
        };
    },

    props: {
        alert: Object,
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    min-height: auto;
}
</style>
