import { render, staticRenderFns } from "./menu-options.vue?vue&type=template&id=5fd07974&scoped=true&"
import script from "./menu-options.vue?vue&type=script&lang=js&"
export * from "./menu-options.vue?vue&type=script&lang=js&"
import style0 from "./menu-options.vue?vue&type=style&index=0&id=5fd07974&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd07974",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www2/lean.nuevoalmaden.com/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fd07974')) {
      api.createRecord('5fd07974', component.options)
    } else {
      api.reload('5fd07974', component.options)
    }
    module.hot.accept("./menu-options.vue?vue&type=template&id=5fd07974&scoped=true&", function () {
      api.rerender('5fd07974', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/overview/building/machine/shift/jobs/menu-options.vue"
export default component.exports