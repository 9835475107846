<template>
    <v-card>
        <v-card-title>
            {{ title }}
            <v-spacer></v-spacer>
        </v-card-title>

        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="collapsedJobs"
            item-key="key"
            :search="search"
            @click:row="$clickOnItem"
            class="elevation-1 row-pointer"
            :item-class="$rowClasses"
        >
            <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
                <slot :name="name" v-bind="item"></slot>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn
                    text
                    :to="
                        item.isJobCollection
                            ? `/app/jobs/job-collections/${item.id}/edit-estimates`
                            : `/app/jobs/${item.id}/reports/job-processes`
                    "
                    title="Job details"
                    x-small
                >
                    <v-icon left>mdi-file-eye-outline</v-icon> Details
                </v-btn>

                <v-btn
                    title="Sales Order"
                    text
                    x-small
                    :disabled="!item.sales_order_id"
                    :to="
                        item.sales_order_id
                            ? `/app/sales-orders/${item.sales_order_id}`
                            : ''
                    "
                >
                    <v-icon left>mdi-newspaper-variant-multiple</v-icon> SO
                </v-btn>

                <v-icon
                    v-if="item.isJobCollection"
                    @click.stop="$expand(item)"
                    class="btn-expand"
                    :class="{
                        'v-data-table__expand-icon--active': item.isExpanded,
                    }"
                >
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import collapse from "Mixins/collapse.mixin";

export default {
    mixins: [collapse],
    data: data,
    watch: getWatchers(),
    props: {
        title: String,
        loading: Boolean,
        headers: Array,
        jobCollections: Array,
        querySearch: String,
    },
};

function data() {
    return {
        search: "",
    };
}

function getWatchers() {
    return {
        jobCollections: {
            handler: collapseJobs,
            deep: true,
        },
        "$route.query.search": {
            handler: filtersSearch,
            immediate: true,
        },
        expandedJobCollection: {
            handler: collapseJobs,
        },
        querySearch(querySearch) {
            this.search = querySearch;
        },
    };

    function filtersSearch() {
        this.search = this.$route.query.search;
    }

    function collapseJobs() {
        this.$collapseJobs();
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep .v-data-table__wrapper {
    tr td:last-child {
        padding: 0;

        div {
            display: inline-flex;
        }
    }
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem
    > td {
    height: 30px;
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem:not(.latestSubItem)
    > td {
    border: none;
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.firstSubItem {
    box-shadow: inset 0 4px 4px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-top: 25px;
        padding-bottom: 5px;
    }
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem.latestSubItem {
    box-shadow: inset 0 -4px 4px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-bottom: 25px;
        padding-top: 5px;
    }
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.latestSubItem.firstSubItem {
    box-shadow: inset 0 4px 8px -5px rgba(50, 50, 50, 0.75),
        inset 0 -4px 8px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

::v-deep .btn-expand {
    font-size: 18px;
    margin-top: -3px;
}
</style>
