<template>
    <v-data-table
        :headers="headers"
        :items="stationJobHistory"
        disable-pagination
        hide-default-footer
        :loading="loading"
        dense
    >
        <template v-slot:top>
            <v-dialog v-model="showEditDialog" max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="headline"> Edit item </span>
                    </v-card-title>

                    <v-card-text class="pb-0 pt-4">
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-select
                                    v-model="editedItem.type_of_hour_id"
                                    :items="typeOfHours"
                                    item-text="name"
                                    item-value="id"
                                    label="Type"
                                    height="25px"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <time-picker
                                    v-model="editedItem.started_at"
                                    @previousTime="updatePreviousDuration"
                                    @input="keepDuration"
                                ></time-picker>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field
                                    :value="editedItem.ended_at | time"
                                    label="End at"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <duration-picker
                                    :startedAt="editedItem.started_at"
                                    v-model="editedItem.ended_at"
                                ></duration-picker>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="close">Cancel</v-btn>
                        <v-btn color="primary" @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        <template v-slot:item.station="{ item }">
            {{ item.station | arrayToString("name") }}
        </template>
        <template v-slot:item.started_at="{ item }">
            {{ item.started_at | compactDateTime }}
        </template>
        <template v-slot:item.ended_at="{ item }">
            {{ item.ended_at | compactDateTime }}
        </template>
        <template v-slot:item.all_hours="{ item }">
            {{ item.all_hours | round(1) }}
        </template>
        <template v-slot:item.non_efficient="{ item }">
            {{ item.type === "Non-efficient" ? item.all_hours : 0 | round(1) }}
        </template>
        <template v-if="$isAdmin()" v-slot:item.actions="{ item }" class="px-1">
            <div class="d-flex">
                <v-icon
                    :small="$vuetify.breakpoint.smAndUp"
                    class="mr-1"
                    title="Edit"
                    @click="edit(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    :small="$vuetify.breakpoint.smAndUp"
                    title="Delete"
                    @click="destroy(item)"
                >
                    mdi-delete
                </v-icon>
            </div>
        </template>
    </v-data-table>
</template>

<script>
import arrayToString from "Filters/arrayToString";
import compactDateTime from "Filters/compactDateTime";
import round from "Filters/round";
import time from "Filters/timeFromDateTime";
import timePicker from "Components/utils/vuetify-time-picker-datetime";
import durationPicker from "Components/utils/vuetify-duration-picker-datetime";
import permissions from "Mixins/permissions.mixin";

export default {
    mixins: [permissions],
    components: { timePicker, durationPicker },
    name: "job-history",
    data: data,
    methods: getMethods(),
    props: {
        job: Object,
    },
    watch: getWatchers(),
    filters: { arrayToString, compactDateTime, round, time },
    mounted: mounted
};

function getWatchers() {
    return {
        showEditDialog: {
            handler: showEditDialog,
        },
        job: {
            handler: job,
            immediate: true,
        },
    };

    function showEditDialog(val) {
        val || this.close();
    }

    function job() {
        const vm = this;
        getStationJobHistory();

        function getStationJobHistory() {
            vm.loading = true;
            Vue.backendApi()
                .jobs(vm.job.id)
                .stationJobHistory()
                .then(success, fail);

            function success(response) {
                vm.loading = false;
                Vue.set(vm, "stationJobHistory", response.data);
            }

            function fail(error) {
                vm.loading = false;
                console.log("An error occurred trying to get job history");
            }
        }
    }
}

function data() {
    return {
        showEditDialog: false,
        previousDuration: 0.5,
        headers: [
            {
                text: "Station",
                align: "start",
                sortable: false,
                value: "station",
                class: "grey--text text--darken-3",
            },
            {
                text: "Start at",
                value: "started_at",
                sortable: false,
                class: "grey--text text--darken-3",
            },
            {
                text: "Ended at",
                value: "ended_at",
                sortable: false,
                class: "grey--text text--darken-3",
            },
            {
                text: "Hrs",
                value: "all_hours",
                sortable: false,
                align: "center",
                class: "grey--text text--darken-3",
            },
            {
                text: "Non efficient",
                value: "non_efficient",
                align: "center",
                sortable: false,
                class: "grey--text text--darken-3",
            },
            {
                text: "",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3 px-1",
            },
        ],
        editedItem: {
            station: "",
            started_at: "",
            ended_at: "",
            all_hours: "",
        },
        loading: false,
        stationJobHistory: [],
        typeOfHours: []
    };
}

function getMethods() {
    return {
        edit: edit,
        close: close,
        save: save,
        destroy: destroy,
        updatePreviousDuration: updatePreviousDuration,
        keepDuration: keepDuration,
    };

    function edit(item) {
        this.editedItem = Object.assign({}, item);
        this.showEditDialog = true;
    }

    function close() {
        this.showEditDialog = false;
    }

    function save() {
        const vm = this;

        Vue.backendApi()
            .stationShift(vm.editedItem.station_shift_id)
            .updateJobHistory(vm.editedItem)
            .then(success, fail);

        function success(response) {
            let shiftJobIndex = vm.stationJobHistory.findIndex(function (
                jobHistory
            ) {
                return jobHistory.id === response.data.id;
            });
            vm.stationJobHistory.splice(shiftJobIndex, 1, response.data);
            vm.close();
            vm.$emit("job-work-hours-updated");
        }

        function fail(error) {
            console.log(
                "An error occurred trying to save station job history changes"
            );
        }
    }

    function destroy(shiftJob) {
        const vm = this;

        if (
            confirm("Are you sure you want to delete this job history record?")
        ) {
            Vue.backendApi()
                .stationShift(shiftJob.station_shift_id)
                .destroyJobHistory(shiftJob.id)
                .then(success, fail);
        }

        function success(response) {
            vm.$spliceById(
                vm.stationJobHistory,
                shiftJob.id,
                (shift) => shift.id === shiftJob.id
            );
            vm.$emit("job-work-hours-updated");
        }

        function fail(error) {
            console.log("An error occurred trying to delete job history");
        }
    }

    function updatePreviousDuration(previousTime) {
        let { ended_at } = this.editedItem;
        let startTime = Vue.moment(previousTime, "YYYY-MM-DD HH:mm:ss");
        let endTime = Vue.moment(ended_at, "YYYY-MM-DD HH:mm:ss");
        let duration = Math.abs(startTime.diff(endTime, "minutes")) / 60;
        this.previousDuration = duration;
    }

    function keepDuration() {
        let startTime = Vue.moment(
            this.editedItem.started_at,
            "YYYY-MM-DD HH:mm:ss"
        );
        let endTime = startTime.add(this.previousDuration, "hours");
        endTime = Vue.moment(endTime).format("YYYY-MM-DD HH:mm:ss");
        this.editedItem.ended_at = endTime;
    }
}

function mounted() {
    const vm = this;

    Vue.backendApi()
        .typeOfHours()
        .index()
        .then(success, fail);

    function success(response) {
        Vue.set(vm, "typeOfHours", response.data);
    }

    function fail(error) {
        console.log(
            "An error has occurred trying to get type of hours"
        );
    }
}

</script>

<style lang="scss" scoped>
::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep .v-data-table__wrapper > table > tbody > tr > td:last-child {
    padding: 0px 2px;

    i {
        cursor: pointer;
    }
}
</style>
