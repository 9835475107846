<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>

            <v-row>
                <v-col>
                    <h1>{{ client.name }}'s Sales Orders</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="auto">
                    <ul>
                        <ol class="align">
                            <h5>
                                Phone:
                                {{ client.phone }}
                            </h5>
                        </ol>
                        <ol class="align">
                            <h5>
                                Fax:
                                {{ client.fax }}
                            </h5>
                        </ol>

                        <ol class="align">
                            <h5>
                                Email:
                                {{ client.email }}
                            </h5>
                        </ol>

                        <ol class="align">
                            <h5>
                                Address:
                                {{ client.address }}
                            </h5>
                        </ol>
                    </ul>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-data-table
                        :headers="headers"
                        :items="paginate.results"
                        class="elevation-2"
                        :loading="loading"
                        item-key="id"
                        :page="paginate.page"
                        :pageCount="paginate.pagesLength"
                        :options.sync="options"
                        :server-items-length="paginate.total"
                    >
                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at | date }}
                        </template>
                        <template v-slot:item.sales-order-items="{ item }">
                            <div>
                                {{ getItemName(item.items) }}
                                <span v-if="item.items.length > 1">
                                    (
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="showItems(item.items)"
                                        title="Show More"
                                    >
                                        {{ formatItemNeeded(item.items) }}
                                    </v-icon>
                                    )
                                </span>
                            </div>
                        </template>
                    </v-data-table>

                    <v-dialog
                        v-model="showAllOfItem"
                        max-width="300px"
                        @click:outside="closeSaleOrderItemDialog"
                        @keydown.esc="closeSaleOrderItemDialog"
                        v-if="isItemDialogActive"
                    >
                        <v-card>
                            <v-card-title>Items</v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <ul>
                                    <li v-for="eachItem in currentItemList">
                                        <h6 class="align">
                                            {{ eachItem.name }}
                                        </h6>
                                    </li>
                                </ul>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeSaleOrderItemDialog"
                                >
                                    Close
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import currencyFormat from "Filters/currencyFormat";
import date from "Filters/shortDate";

export default {
    components: { pageHeaderVuetify },
    data: data,
    created: created,
    methods: getMethods(),
    watch: getWatchers(),
    computed: getComputed(),
    filters: { currencyFormat, date },
};

function getComputed() {
    return {
        showAllOfItem: showAllOfItem,
    };

    function showAllOfItem() {
        return this.isItemDialogActive ? true : false;
    }
}

function getWatchers() {
    return {
        options: {
            handler() {
                const { page, itemsPerPage } = this.options;
                this.paginate.pagesLength = itemsPerPage;
                this.paginate.page = page;
                this.$router.push({
                    name: this.routeName,
                    query: { ...this.$route.query, page, size: itemsPerPage },
                    params: this.$route.params,
                });
                this.getSalesOrders();
            },
            deep: true,
        },
    };
}

function data() {
    return {
        isItemDialogActive: false,
        currentItemList: [],
        options: {},
        paginate: {
            query: "",
            results: [],
            page: parseInt(this.$route.query.page) || 1,
            pagesLength: parseInt(this.$route.query.size) || 10,
            total: 0,
        },
        loading: false,
        client: {},
        expanded: [],
        headers: [
            {
                text: "ID",
                align: "start",
                sortable: false,
                value: "id",
                class: "grey--text text--darken-3",
                width: 80,
            },
            {
                text: "PO Number",
                align: "start",
                sortable: true,
                value: "purchase_order_number",
                class: "grey--text text--darken-3",
                width: 80,
            },
            {
                text: "Created",
                value: "created_at",
                align: "start",
                sortable: true,
                class: "grey--text text--darken-3",
                filterable: false,
                width: 80,
            },
            {
                text: "Order Items",
                value: "sales-order-items",
                class: "grey--text text--darken-3",
                width: 150,
            },
            {
                sortable: false,
                text: "Status",
                align: "start",
                value: "status",
                class: "grey--text text--darken-3",
                width: 80,
            },
        ],
    };
}

function getMethods() {
    return {
        getClientDetails: getClientDetails,
        getSalesOrders: getSalesOrders,
        getItemName: getItemName,
        closeSaleOrderItemDialog: closeSaleOrderItemDialog,
        showItems: showItems,
        formatItemNeeded: formatItemNeeded,
        getRequestParams: getRequestParams,
    };

    function formatItemNeeded(item) {
        let length = item.length - 1;
        let formattedWord = length + " more item";
        let isPlural = length === 1 ? "" : "s";
        return formattedWord + isPlural;
    }

    function showItems(item) {
        this.currentItemList = item;
        this.isItemDialogActive = true;
    }

    function closeSaleOrderItemDialog() {
        this.orderItemId = null;
        this.isItemDialogActive = false;
    }

    function getItemName(item) {
        if (item.length === 0) {
            return;
        }
        let salesOrderItem = item.length > 1 ? item.slice(0, 1) : item;
        let firstItem = salesOrderItem.find((e) => true);
        return firstItem.name;
    }

    function getRequestParams() {
        let page = this.paginate.page;
        let pagesLength = this.paginate.pagesLength;
        let params = {};

        if (page) {
            params["page"] = page;
        }

        if (pagesLength) {
            params["size"] = pagesLength;
        }

        params["type"] = "trashed";
        return params;
    }

    function getSalesOrders() {
        const vm = this;
        let clientId = this.$route.params.clientId;

        const params = { ...vm.getRequestParams(), clientId };
        Vue.backendApi().salesOrders().index(params).then(success, fail);

        function success(response) {
            const { total, data, to } = response.data;
            Vue.set(vm.paginate, "total", total);
            Vue.set(vm.paginate, "totalPages", to);
            Vue.set(vm.paginate, "results", data);
        }

        function fail(error) {
            vm.$store.commit("addError", error);
        }
    }

    function getClientDetails() {
        let clientId = this.$route.params.clientId;

        const vm = this;
        Vue.backendApi().clients(clientId).index().then(success, fail);

        function success(response) {
            vm.client = response.data;
        }

        function fail(error) {
            console.log("An error occurred trying to archive get client");
        }
    }
}

function created() {
    this.getClientDetails();
    this.getSalesOrders();
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr.v-data-table__expanded__content td {
    text-align: left;
    padding: 1rem;
}

.v-application .order-subtitle {
    font-size: 0.9rem;
    letter-spacing: 0.13em;
    font-weight: bold;
}

.sales-order-info {
    width: 70%;
}

.order-date {
    font-size: 1.1rem;
}

.v-data-table > .v-data-table__wrapper > table > tbody.totals > tr > th {
    font-size: 1rem;
}

::v-deep .align {
    text-align: left;
}
</style>
