var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.title)+"\n        "),_c('v-spacer')],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.collapsedJobs,"item-key":"key","search":_vm.search,"item-class":_vm.$rowClasses},on:{"click:row":_vm.$clickOnItem},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(slot,name){return {key:name,fn:function(item){return [_vm._t(name,null,null,item)]}}}),{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","to":item.isJobCollection
                        ? `/app/jobs/job-collections/${item.id}/edit-estimates`
                        : `/app/jobs/${item.id}/reports/job-processes`,"title":"Job details","x-small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-eye-outline")]),_vm._v(" Details\n            ")],1),_vm._v(" "),_c('v-btn',{attrs:{"title":"Sales Order","text":"","x-small":"","disabled":!item.sales_order_id,"to":item.sales_order_id
                        ? `/app/sales-orders/${item.sales_order_id}`
                        : ''}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-newspaper-variant-multiple")]),_vm._v(" SO\n            ")],1),_vm._v(" "),(item.isJobCollection)?_c('v-icon',{staticClass:"btn-expand",class:{
                    'v-data-table__expand-icon--active': item.isExpanded,
                },on:{"click":function($event){$event.stopPropagation();return _vm.$expand(item)}}},[_vm._v("\n                mdi-chevron-down\n            ")]):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }