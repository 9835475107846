<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>

            <jobs-list
                class="text-left"
                @clickedOnJob="setActiveJob"
                @editJobCollection="setActiveJobCollection"
                @unselectJobCollection="unselectJobCollection"
                @jobArchived="getUpdatedJobCollection"
                :updatedJobCollection="updatedJobCollection"
            ></jobs-list>
        </v-container>

        <v-dialog
            v-if="
                activeJobCollection && $route.name !== 'manage-job-collections'
            "
            v-model="showJobCollectionModal"
            @click:outside="unselectJobCollection"
            @keydown.esc="unselectJobCollection"
            max-width="550px"
            scrollable
        >
            <router-view
                name="jobCollectionFeature"
                :jobCollection="activeJobCollection"
                @estimated-hours-updated="getUpdatedJobCollection"
                @close="unselectJobCollection"
            ></router-view>
        </v-dialog>

        <v-dialog
            v-if="activeJob"
            v-model="showJobModal"
            @click:outside="unselectJob"
            @keydown.esc="unselectJob"
            max-width="600px"
            scrollable
        >
            <router-view
                name="jobFeature"
                :job="activeJob"
                @processes-updated="$emit('processes-updated')"
                @estimated-hours-updated="getUpdatedJobCollection"
                @job-work-hours-updated="getUpdatedJobCollection"
                @close="unselectJob"
            ></router-view>
        </v-dialog>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import jobsList from "Components/jobs/manage-jobs-list";
import getEfficiency from "Mixins/getEfficiency.mixin";

export default {
    mixins: [getEfficiency],
    name: "manage-jobs",
    components: {
        pageHeaderVuetify,
        jobsList,
    },
    methods: getMethods(),
    data: data,
};

function data() {
    return {
        activeJob: null,
        updatedJob: {},
        activeJobCollection: null,
        updatedJobCollection: null,
        isWithTrashed: false,
        showJobCollectionModal: true,
        showJobModal: true,
    };
}
function getMethods() {
    return {
        setActiveJob: setActiveJob,
        setActiveJobCollection: setActiveJobCollection,
        unselectJobCollection: unselectJobCollection,
        unselectJob: unselectJob,
        getUpdatedJobCollection: getUpdatedJobCollection,
    };

    function setActiveJob({ job, jobCollection }) {
        this.activeJobCollection = jobCollection;
        if (this.activeJob === null || this.activeJob.id !== job.id) {
            this.activeJob = job;
        }
    }

    function setActiveJobCollection(jobCollection) {
        this.activeJob = null;
        this.activeJobCollection = jobCollection;
    }

    function unselectJobCollection() {
        if (this.activeJobCollection) {
            this.$router.push({
                name: "manage-job-collections",
                params: {
                    jobCollection: this.activeJobCollection.id,
                },
            });
        }
        this.activeJob = null;
        this.activeJobCollection = null;
    }

    function unselectJob() {
        if (this.activeJobCollection.isJobCollection) {
            this.$router.push({
                name: "manage-job-collections",
                params: {
                    jobCollection: this.activeJobCollection.id,
                },
            });
        } else {
            this.$router.push({ name: "manage-jobs" });
        }
        this.activeJob = null;
        this.activeJobCollection = null;
    }

    function getUpdatedJobCollection() {
        const vm = this;
        if (!vm.activeJobCollection) {
            return;
        }

        let isWithTrashedJobCollection = vm.activeJobCollection.jobs.some(
            (job) => job.deleted_at !== null
        );

        Vue.backendApi()
            .jobCollections(vm.activeJobCollection.id)
            .find({ isWithTrashed: isWithTrashedJobCollection })
            .then(success, fail);

        function success(response) {
            let jobCollection = response.data;
            if (jobCollection.job_count > 0) {
                Vue.set(vm, "updatedJobCollection", jobCollection);
            }
        }

        function fail(error) {
            console.log(
                "An error occurred trying to get updated job collection"
            );
        }
    }
}
</script>
