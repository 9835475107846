<template>
    <steps
        :steps="steps"
        :loading="loading"
        :from="job.steps_from"
        @save="save"
        @reorder="reorder"
        @deleteStep="deleteStep"
        @newStep="newStep"
        @deleteOwnSteps="deleteOwnSteps"
        @undoChanges="undoChanges"
    >
    </steps>
</template>

<script>
import steps from "./steps";
import emitEstimatedHoursUpdated from "Mixins/emitEstimatedHoursUpdated.mixin";

export default {
    mixins: [emitEstimatedHoursUpdated],
    components: { steps },
    data() {
        return {
            steps: [],
            loading: false,
        };
    },
    props: {
        job: Object,
    },
    watch: {
        job: {
            immediate: true,
            handler: function () {
                if (this.job.id) {
                    this.getSteps();
                }
            },
        },
    },
    methods: {
        undoChanges() {
            this.getSteps();
        },

        getSteps() {
            const vm = this;
            vm.loading = true;

            Vue.backendApi()
                .jobs(vm.job.id)
                .steps()
                .index()
                .then(success, fail);

            function success(response) {
                vm.loading = false;
                Vue.set(vm, "steps", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to get steps");
            }
        },

        save(step) {
            const vm = this;

            if (vm.job.steps_from === "collection") {
                vm.copyCollectionSteps(vm.steps);
                return;
            }

            step.loading = true;
            const isUnsavedStep = !step.id;

            if (isUnsavedStep) {
                Vue.backendApi()
                    .jobs(vm.job.id)
                    .steps()
                    .store(step)
                    .then(success, fail);
            } else {
                Vue.backendApi()
                    .jobs(vm.job.id)
                    .steps(step.id)
                    .store(step)
                    .then(success, fail);
            }

            function success(response) {
                const savedStep = response.data;
                savedStep.key = step.key;

                let stepIndex = vm.steps.findIndex((jobStep) => {
                    return jobStep.id === step.id;
                });

                vm.steps.splice(stepIndex, 1, savedStep);
                vm.$emitEstimatedHoursUpdated(vm.steps);
                Vue.set(savedStep, "showSuccess", true);
                setTimeout(() => (savedStep.showSuccess = false), 2000);
            }

            function fail(error) {
                step.loading = false;
                console.log("An error occurred trying to save a job step");
            }
        },

        deleteStep(step) {
            const vm = this;
            const unsavedStep = !step.job_collection_id && !step.job_id;

            if (unsavedStep) {
                removeStepOfList();
                return;
            }
            if (vm.job.steps_from === "collection") {
                const filteredCollectionSteps = vm.steps.filter(
                    (collectionStep) => collectionStep.id !== step.id
                );
                vm.copyCollectionSteps(filteredCollectionSteps);
                return;
            }

            Vue.backendApi()
                .jobs(vm.job.id)
                .steps(step.id)
                .destroy()
                .then(success, fail);

            function success(response) {
                removeStepOfList();
                vm.$emitEstimatedHoursUpdated(vm.steps);
            }

            function fail(error) {
                console.log("An error occurred trying to delete a job step");
            }

            function removeStepOfList() {
                let stepIndex = vm.steps.findIndex((jobStep) => {
                    return jobStep.key === step.key;
                });
                vm.steps.splice(stepIndex, 1);
            }
        },

        copyCollectionSteps(steps) {
            const vm = this;

            Vue.backendApi()
                .jobs(vm.job.id)
                .steps()
                .copyCollectionSteps({
                    steps: steps,
                })
                .then(success, fail);

            function success(response) {
                const jobSteps = response.data;
                vm.$emitEstimatedHoursUpdated(jobSteps);
                vm.job.steps_from = "job";
                vm.loading = true;
                Vue.set(vm, "steps", jobSteps);
                setTimeout(() => (vm.loading = false), 500);
            }

            function fail(error) {
                console.log("An error occurred copying collection steps");
            }
        },

        deleteOwnSteps() {
            if (
                !confirm(
                    "Delete all job steps and use job collection steps instead?"
                )
            ) {
                return;
            }

            const vm = this;
            vm.loading = true;

            Vue.backendApi()
                .jobs(vm.job.id)
                .deleteOwnSteps()
                .then(success, fail);

            function success(response) {
                vm.loading = false;
                const steps = response.data;
                Vue.set(vm, "steps", steps);
                vm.$emitEstimatedHoursUpdated(steps);
                vm.job.steps_from = "collection";
            }

            function fail(error) {
                console.log("An error occurred setting collection steps");
            }
        },

        reorder(reorderedSteps) {
            const vm = this;

            if (vm.job.steps_from === "collection") {
                vm.copyCollectionSteps(reorderedSteps);
                return;
            }

            Vue.backendApi()
                .jobs(vm.job.id)
                .steps()
                .updatePositions({
                    steps: reorderedSteps,
                })
                .then(success, fail);

            function success(response) {
                vm.steps = reorderedSteps;
            }

            function fail(error) {
                console.log("An error occurred trying to move step");
            }
        },

        newStep() {
            this.$emit("newStep");
        },
    },
};
</script>
