var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('page-header-vuetify'),_vm._v(" "),_c('v-card',{staticClass:"mb-6"},[_c('toolbar-report',{attrs:{"title":"Manufacturing report","routeName":"manufacturing","useFilters":""},on:{"search":(query) => (_vm.search = query),"get-reports-on":(payload) => _vm.getManufacturingReport(payload),"no-filters":function($event){_vm.jobCollections = []}}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.collapsedJobs,"item-key":"key","loading":_vm.loading,"disable-pagination":"","hide-default-footer":"","search":_vm.search,"item-class":_vm.$rowClasses},on:{"click:row":_vm.$clickOnItem},scopedSlots:_vm._u([{key:"header.efficiency",fn:function({ header }){return [_vm._v("\n                "+_vm._s(header.text)+"\n                "),_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-stairs-up")])]}},{key:"item.progress",fn:function({ item }){return [_vm._v("\n                "+_vm._s(_vm._f("percent")(_vm._f("round")(item.progress)))+"\n            ")]}},{key:"item.efficiency",fn:function({ item }){return [_vm._v("\n                "+_vm._s(_vm._f("percent")(_vm._f("round")(item.efficiency)))+"\n            ")]}},{key:"item.delivered",fn:function({ item }){return [(item.deliveryDiff !== null)?_c('span',{staticClass:"font-weight-medium text--darken-2",class:{
                        'red--text': item.deliveryDiff > 0,
                        'green--text': item.deliveryDiff <= 0,
                    }},[_vm._v("\n                    "+_vm._s(item.deliveryDiff > 0 ? "+" : "")+"\n                    "+_vm._s(item.deliveryDiff)+"\n                    "+_vm._s(Math.abs(item.deliveryDiff) === 1 ? "day" : "days")+"\n                ")]):_vm._e()]}},{key:"item.feedback",fn:function({ item }){return [(item.isJobCollection)?_c('span',[_vm._v("\n                    "+_vm._s(item.job_feedbacks)+" / "+_vm._s(item.job_count)+"\n                ")]):_c('span',[_vm._v("\n                    "+_vm._s(_vm._f("date")(item.feedback_meeting_completed_at))+"\n                ")])]}},{key:"item.actions",fn:function({ item }){return [(item.isJobCollection)?_c('v-icon',{class:{
                        'v-data-table__expand-icon--active':
                            item.isExpanded,
                    }},[_vm._v("\n                    mdi-chevron-down\n                ")]):_vm._e()]}}])})],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }