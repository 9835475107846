import { render, staticRenderFns } from "./profitability-report-costs.vue?vue&type=template&id=41c944bc&scoped=true&"
import script from "./profitability-report-costs.vue?vue&type=script&lang=js&"
export * from "./profitability-report-costs.vue?vue&type=script&lang=js&"
import style0 from "./profitability-report-costs.vue?vue&type=style&index=0&id=41c944bc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41c944bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www2/lean.nuevoalmaden.com/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('41c944bc')) {
      api.createRecord('41c944bc', component.options)
    } else {
      api.reload('41c944bc', component.options)
    }
    module.hot.accept("./profitability-report-costs.vue?vue&type=template&id=41c944bc&scoped=true&", function () {
      api.rerender('41c944bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/sales-reports/profitability-report/profitability-report-costs.vue"
export default component.exports