<template>
    <v-card class="mr-5 card-matrix mb-4 mb-sm-0">
        <v-app-bar color="primary" elevation="0">
            <v-toolbar-title class="white--text toolbar-title">
                Jobs with no label
                <span class="jobs-counter">
                    {{ this.countJobs }}
                    {{ this.countJobs === 1 ? "job" : "jobs" }}
                </span>
            </v-toolbar-title>
        </v-app-bar>

        <v-sheet
            class="overflow-y-auto scroll-container"
            max-height="600"
            color="#f7f7f7"
        >
            <v-container>
                <v-row dense>
                    <v-col cols="12">
                        <draggable
                            v-model="temporalJobCollections"
                            v-bind="dragOptions"
                            @end="moveJobCollection"
                            group="jobCollections"
                        >
                            <transition-group
                                type="transition"
                                name="flip-matrix"
                                class="matrix-container"
                            >
                                <job-collection
                                    v-for="jobCollection in jobCollections"
                                    :key="jobCollection.id"
                                    :jobCollection="jobCollection"
                                    class="matrix-item"
                                    :class="{
                                        filtered:
                                            $isFilteredBySearch(jobCollection),
                                    }"
                                ></job-collection>
                            </transition-group>
                        </draggable>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </v-card>
</template>

<script>
import draggable from "vuedraggable";
import jobCollection from "Components/matrix/job-collection";
import filterJobItemBySearchMixin from "Mixins/filterJobItemBySearch.mixin";
import matrixColumnMovementMixin from "Mixins/matrix-column-movement.mixin";

export default {
    mixins: [filterJobItemBySearchMixin, matrixColumnMovementMixin],
    components: { draggable, jobCollection },

    props: {
        search: String,
    },

    methods: {
        moveJobCollection: function (evt) {
            const vm = this;
            const columnDestinationId = parseInt(evt.to.dataset.columnId);
            if (isNaN(columnDestinationId)) {
                return;
            }

            const jobCollection = evt.item._underlying_vm_;
            Vue.backendApi()
                .matrix()
                .columns(columnDestinationId)
                .jobCollection(jobCollection.id)
                .movement({
                    newPosition: evt.newIndex,
                })
                .then(success, fail);

            function success(response) {
                vm.$blockMovementsWhileAnimationFinishes(evt);
                vm.$removeJobCollection(jobCollection);
                vm.$eventHub.$emit("moveJobCollection", {
                    jobCollection,
                    columnDestinationId,
                    index: evt.newIndex,
                });
            }

            function fail(error) {
                console.log("An error occurred trying to move job");
            }
        },
    },

    mounted() {
        const vm = this;

        getJobCollections();

        function getJobCollections() {
            Vue.backendApi().jobCollections().withNoLabel().then(success, fail);

            function success(response) {
                Vue.set(vm, "jobCollections", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to get job collections");
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.flip-matrix-move {
    transition: transform 0.5s;
}

.flip-matrix-enter-active,
.flip-matrix-leave-active {
    transition: opacity 0.5s;
}

.flip-matrix-enter,
.flip-matrix-leave-to {
    opacity: 0;
}

.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #dbdbdb;
    border: 2px solid #7ca6ad;
}

.matrix-container {
    display: block;
}

.matrix-item {
    cursor: pointer;
}

.scroll-container {
    scrollbar-width: thin;
}

.card-matrix {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: top;
}
.filtered {
    display: none;
}

.toolbar-title {
    position: relative;
    line-height: 2.6;
}
.jobs-counter {
    position: absolute;
    left: 0;
    top: 30px;
    font-size: 12px;
    color: #e3e3e3;
}

@media (min-width: 600px) and (max-width: 700px) {
    .card-matrix {
        width: 280px;
        min-width: 280px;
        max-width: 280px;
    }
}

@media (min-width: 701px) {
    .card-matrix {
        width: 300px;
        min-width: 300px;
        max-width: 300px;
    }
}
</style>
