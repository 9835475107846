<template>
    <v-card class="mx-auto text-center sales-card" color="#8A95BA" dark>
        <v-card-text>
            <div class="text-h4 font-weight-thin">Sales Order Report</div>
            <hr class="mt-2" />
            <div
                class="text-h5 font-weight-thin mt-0 mt-lg-6"
                v-html="message"
            ></div>
        </v-card-text>

        <v-divider class="my-0"></v-divider>

        <v-card-actions class="justify-center">
            <v-btn
                block
                text
                :href="`/app/sales-order-reports/on/${start}/${end}?filters=closed`"
                >Go to Report
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        start: String,
        end: String,
        numberSalesOrders: Number,
    },

    computed: {
        message() {
            if (this.numberSalesOrders > 1) {
                return `There were <b>${this.numberSalesOrders}</b> sales orders completed during this period.`;
            }

            if (this.numberSalesOrders === 1) {
                return "There was <b>one</b> sales order completed during this period.";
            }

            return "<b>Zero</b> sales orders completed during this period.";
        },
    },
};
</script>

<style scoped lang="scss">
@media (min-width: 1264px) {
    .sales-card {
        min-height: 263px;
        display: flex;
        flex-direction: column;

        .v-card__text {
            flex: 1 auto;
        }
    }
}
</style>
