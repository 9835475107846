<template>
    <v-data-table
        no-data-text="This item hasn't been used on any jobs"
        :headers="headers"
        :items="jobHistory"
        :loading="loading"
        disable-pagination
        hide-default-footer
        class="mt-5"
        dense
    >
        <template v-slot:top>
            <v-toolbar-title class="text-left">
                Used on
                <small class="text-caption">(Showing latest jobs)</small>
            </v-toolbar-title>
        </template>

        <template v-slot:item.serial_number="{ item }">
            {{ item.job.serial_number }}
            <loading-success
                :loading="item.saving"
                :success="item.showSuccess"
            ></loading-success>
        </template>

        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | date }}
        </template>

        <template v-slot:item.actions="{ item }">
            <v-btn
                v-if="item.id >= 0 && !item.saving"
                small
                @click="$emit('destroy', item)"
                icon
                title="Delete"
            >
                <v-icon small> mdi-delete </v-icon>
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
import date from "Filters/shortDate";
import loadingSuccess from "Components/utils/loading-success";

export default {
    components: { loadingSuccess },

    filters: { date },

    props: {
        jobHistory: Array,
        loading: Boolean,
    },

    data: () => ({
        headers: [
            {
                text: "PO Item number",
                align: "start",
                sortable: false,
                value: "serial_number",
                class: "grey--text text--darken-3",
                width: 140,
            },
            {
                text: "Job Name",
                align: "start",
                sortable: false,
                value: "job.name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Date",
                align: "center",
                sortable: false,
                value: "created_at",
                class: "grey--text text--darken-3",
                width: 122,
            },
            {
                text: "",
                align: "center",
                sortable: false,
                value: "actions",
                class: "grey--text text--darken-3",
                width: 40,
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep .icon-success {
    font-size: 18px;
    margin-left: 4px;
}
</style>
