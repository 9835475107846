<template>
    <img :src="logo" class="logo" />
</template>

<script>
export default {
    data: data,
    computed: {
        logo() {
            const path = "/images";
            let fileName =
                this.user.current_organization_id === 1
                    ? "logo.png"
                    : "logo-placeholder.png";
            return `${path}/${fileName}`;
        },
    },
};

function data() {
    return {
        user: this.$store.state.user,
    };
}
</script>
