<template>
    <div class="job-item">
        {{ jobHistory.job.name }}

        <div v-if="jobHistory.created_at" class="history-options-box">
            <i v-if="jobHistory.job.serial_number" class="fas fa-barcode">
                <span class="job-serial-on-machine">
                    {{ jobHistory.job.serial_number }}
                </span>
            </i>
            <i
                class="fas fa-ellipsis-v menu-activator"
                @click="showMenuOptions = true"
            ></i>
        </div>

        <menu-options
            v-if="showMenuOptions"
            @close="showMenuOptions = false"
            :jobHistory="jobHistory"
            @showJobHoursEditor="showJobHoursEditor = true"
            @removeJobHistory="$emit('removeJobHistory', jobHistory)"
        ></menu-options>

        <hours-control
            v-if="showJobHoursEditor"
            @close="showJobHoursEditor = false"
            :jobHistory="jobHistory"
            @cancel="showJobHoursEditor = false"
            @addTime="(shiftJob) => $emit('addTime', shiftJob)"
            @removeTime="(shiftJob) => $emit('removeTime', shiftJob)"
            @updatedStationShiftJob="
                (shiftJob) => $emit('updateStationShiftJob', shiftJob)
            "
        ></hours-control>
    </div>
</template>

<script>
import menuOptions from "Components/overview/building/machine/shift/jobs/menu-options";
import machineShiftJobMixin from "Components/overview/building/machine/mixins/machine-shift-job.mixin";

export default {
    mixins: [machineShiftJobMixin],

    components: { menuOptions },

    mounted() {
        const vm = this;
        vm.$eventHub.$on("editJobHoursShift", (jobHistory) => {
            if (jobHistory.id === vm.jobHistory.id) {
                vm.showJobHoursEditor = true;
                Vue.nextTick(function () {
                    vm.$eventHub.$emit("editJobShiftTime", jobHistory);
                });
            }
        });
    },
};
</script>

<style scoped lang="scss">
.history-options-box {
    display: none;
    position: absolute;
    top: 2px;
    right: 2px;
}

.fas.fa-barcode,
.fas.fa-ellipsis-v {
    border-radius: 50%;
    padding: 4px;
    background-color: burlywood;
    margin-left: 3px;
}

.fas.fa-ellipsis-v {
    padding: 4px 8px;
}

.job-item:hover {
    & .fa-ellipsis-v,
    & .fas.fa-barcode {
        cursor: pointer;
    }

    .history-options-box {
        display: block;
    }
}

.fas.fa-barcode {
    position: relative;
    background-color: #fff;

    & .job-serial-on-machine {
        display: none;
        position: absolute;
    }

    &:hover {
        & .job-serial-on-machine {
            display: block;
            background-color: #fff;
            right: 0px;
            padding: 3px 5px;
            z-index: 1;
            border-radius: 5px;
            top: 23px;
            font-weight: normal;
            white-space: nowrap;
        }
    }
}
</style>
