<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="375px"
    >
        <v-card max-width="375" class="mx-auto text-left">
            <v-card-title
                v-if="item.isJobCollection"
                :class="{ 'pb-0': !item.serial_number }"
            >
                <span class="job-collection">Job collection </span> |
                {{ item.name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-card-title>
            <v-card-title v-else :class="{ 'pb-0': !item.serial_number }">
                Job | {{ item.name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-card-title>
            <v-subheader>Details</v-subheader>

            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>PO Item number</td>
                                <td>
                                    {{ item.serial_number }}
                                </td>
                            </tr>
                            <tr v-if="item.isJobCollection">
                                <td>Jobs</td>
                                <td>{{ item.job_count }}</td>
                            </tr>
                            <tr>
                                <td>Due at</td>
                                <td>
                                    {{ item.due_at | shortDate }}
                                </td>
                            </tr>
                            <tr>
                                <td>Hours</td>
                                <td>
                                    {{
                                        item.isJobCollection
                                            ? item.job_all_hours
                                            : item.all_hours | round
                                    }}
                                    /
                                    {{ item.estimated_hours | round }}
                                </td>
                            </tr>
                            <tr v-if="item.isJobCollection">
                                <td>Description</td>
                                <td>
                                    {{ item.description }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>

            <div v-if="!item.isJobCollection">
                <v-subheader>Blockers</v-subheader>
                <v-card-text class="pt-0">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody v-if="item.blockers.length > 0">
                                <tr
                                    v-for="blocker in item.blockers"
                                    :key="blocker.id"
                                >
                                    <td>{{ blocker.name }}</td>
                                </tr>
                            </tbody>

                            <tbody v-else>
                                <tr>
                                    <td>No blockers</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </div>

            <v-subheader>Progress</v-subheader>
            <v-card-text>
                <v-progress-circular
                    :rotate="-90"
                    :size="40"
                    :width="5"
                    :value="item.progress"
                    color="green"
                >
                    {{ item.progress | round }}%
                </v-progress-circular>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import shortDate from "Filters/shortDate";
import round from "Filters/round";

export default {
    data() {
        return {
            show: true,
        };
    },

    props: {
        item: Object,
    },

    filters: { shortDate, round },
};
</script>

<style lang="scss" scoped>
.job-collection {
    border-radius: 10px;
    padding: 0px 8px;
    background-color: #7ca6ad;
    color: white;
    margin-right: 8px;
    margin-left: -8px;
}
</style>
