<template>
    <div class="search">
        <v-text-field
            label="Search..."
            dense
            :append-icon="search === '' ? 'mdi-magnify' : 'mdi-close'"
            single-line
            v-model="search"
            @click:append="cleanSearch"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    data: data,
    watch: getWatchers(),
    methods: getMethods(),
    props: {
        value: String,
    },
};

function data() {
    return {
        search: "",
    };
}

function getWatchers() {
    return {
        search: search,
        value: {
            immediate: true,
            handler: value,
        },
    };

    function search() {
        this.$emit("input", this.search);
    }

    function value() {
        this.search = this.value;
    }
}

function getMethods() {
    return {
        cleanSearch: cleanSearch,
    };

    function cleanSearch() {
        this.search = "";
    }
}
</script>

<style lang="scss">
.search {
    border-bottom: 1px solid #bcbdbe;
    padding: 5px 15px 0px 15px;
}
</style>
