<template>
    <v-card class="mb-4 py-0" :elevation="isEditing ? 5 : 1">
        <v-form :ref="`form-${journal.id}`">
            <v-card-text class="pb-0">
                <v-row class="mt-0" @click="isEditing = true">
                    <v-col cols="12" class="py-0">
                        <v-textarea
                            class="card-textarea"
                            label="Notes"
                            :rows="1"
                            dense
                            hide-details
                            v-model="journal.notes"
                            @change="$emit('dirty', journal)"
                            :filled="isEditing"
                            :disabled="!isEditing"
                            :no-resize="!isEditing"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" class="text-left my-0">
                        <face-rating
                            v-model="journal.rating"
                            :is-editing="isEditing"
                            @edit="isEditing = true"
                            @change="$emit('dirty', journal)"
                        ></face-rating>
                    </v-col>
                    <v-col cols="6">
                        <span
                            v-if="journal.updated_at"
                            class="font-italic grey--text"
                        >
                            {{ journal.updated_at | compactDateTime }}
                        </span>
                    </v-col>
                    <v-col cols="3" class="text-right my-0">
                        <v-btn icon x-small v-if="showSuccess">
                            <v-icon color="success"> mdi-check </v-icon>
                        </v-btn>
                        <v-btn
                            icon
                            x-small
                            :loading="journal.loading"
                            title="Save"
                            @click="save()"
                            v-if="isEditing && !showSuccess"
                        >
                            <v-icon class="save-btn">mdi-content-save</v-icon>
                        </v-btn>

                        <v-btn
                            v-if="!isEditing && !showSuccess"
                            icon
                            x-small
                            title="Edit"
                            @click="isEditing = true"
                            :class="{
                                noEditing: !isEditing,
                            }"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn
                            v-if="isEditing"
                            icon
                            x-small
                            title="Delete"
                            @click="showDeleteConfirmation()"
                            class="ml-2"
                            :class="{
                                noEditing: !isEditing,
                            }"
                        >
                            <v-icon class="trash-btn">mdi-trash-can</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>

        <confirmation
            :show="isDeleting"
            @cancelled="isDeleting = false"
            @confirmed="destroy()"
        ></confirmation>
    </v-card>
</template>

<script>
import confirmation from "Components/utils/confirmation";
import compactDateTime from "Filters/compactDateTime";
import faceRating from "Components/jobs/face-rating";
import getTemporaryId from "Mixins/getTemporaryId.mixin";

export default {
    mixins: [getTemporaryId],
    filters: { compactDateTime },
    components: { confirmation, faceRating },
    data: data,
    props: {
        job: Object,
        journal: Object,
    },
    methods: getMethods(),
    watch: getWatchers(),
};

function data() {
    return {
        isDeleting: false,
        isEditing: this.journal.isEditing ? true : false,
        showSuccess: false,
    };
}

function getMethods() {
    return {
        save: save,
        showDeleteConfirmation: showDeleteConfirmation,
        destroy: destroy,
    };

    function save() {
        const vm = this;
        Vue.set(vm.journal, "loading", true);
        const isUnsavedJournal = !vm.journal.updated_at;

        if (isUnsavedJournal) {
            Vue.backendApi()
                .jobs(vm.job.id)
                .journals()
                .store(vm.journal)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .jobs(vm.job.id)
                .journals(vm.journal.id)
                .store(vm.journal)
                .then(success, fail);
        }

        function success(response) {
            const savedJournal = response.data;
            if (isUnsavedJournal) {
                savedJournal.key = vm.journal.id;
            } else if (vm.journal.key) {
                savedJournal.key = vm.journal.key;
            }

            vm.$emit("saved", vm.journal, savedJournal);
            vm.showSuccess = true;
            setTimeout(() => {
                vm.showSuccess = false;
                vm.isEditing = false;
            }, 2000);
            vm.journal.loading = false;
        }

        function fail(error) {
            vm.journal.loading = false;
            console.log("An error occurred trying to save a journal");
        }
    }

    function showDeleteConfirmation() {
        const vm = this;
        const isUnsavedJournal = !vm.journal.updated_at;

        if (isUnsavedJournal) {
            vm.destroy();
        } else {
            vm.isDeleting = true;
        }
    }

    function destroy() {
        const vm = this;
        vm.isDeleting = false;
        const isUnsavedJournal = !vm.journal.updated_at;

        if (isUnsavedJournal) {
            vm.$emit("destroyed", vm.journal);
            return;
        }

        Vue.backendApi()
            .jobs(vm.job.id)
            .journals(vm.journal.id)
            .destroy()
            .then(success, fail);

        function success(response) {
            vm.$emit("destroyed", vm.journal, 250);
        }

        function fail(error) {
            console.log("An error occurred trying to delete a job journal");
        }
    }
}

function getWatchers() {
    return {
        journal: {
            handler: checkJournal,
            immediate: true,
        },
    };

    function checkJournal() {
        if (Object.keys(this.journal).length === 1) {
            let defaultJournalProps = {
                rating: 0,
                notes: "",
                loading: false,
                showSuccess: false,
                entry_type: "daily journal",
            };
            Object.assign(this.journal, defaultJournalProps);
        }
    }
}
</script>
