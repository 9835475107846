<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>

            <v-card class="mb-6">
                <toolbar-report
                    title="Manufacturing report"
                    routeName="manufacturing"
                    @search="(query) => (search = query)"
                    @get-reports-on="
                        (payload) => getManufacturingReport(payload)
                    "
                    @no-filters="jobCollections = []"
                    useFilters
                >
                </toolbar-report>
            </v-card>

            <v-data-table
                :headers="headers"
                :items="collapsedJobs"
                item-key="key"
                class="elevation-2 row-pointer"
                :loading="loading"
                disable-pagination
                hide-default-footer
                :search="search"
                @click:row="$clickOnItem"
                :item-class="$rowClasses"
            >
                <template v-slot:header.efficiency="{ header }">
                    {{ header.text }}
                    <v-icon small color="green">mdi-stairs-up</v-icon>
                </template>
                <template v-slot:item.progress="{ item }">
                    {{ item.progress | round | percent }}
                </template>
                <template v-slot:item.efficiency="{ item }">
                    {{ item.efficiency | round | percent }}
                </template>
                <template v-slot:item.delivered="{ item }">
                    <span
                        v-if="item.deliveryDiff !== null"
                        class="font-weight-medium text--darken-2"
                        :class="{
                            'red--text': item.deliveryDiff > 0,
                            'green--text': item.deliveryDiff <= 0,
                        }"
                    >
                        {{ item.deliveryDiff > 0 ? "+" : "" }}
                        {{ item.deliveryDiff }}
                        {{ Math.abs(item.deliveryDiff) === 1 ? "day" : "days" }}
                    </span>
                </template>
                <template v-slot:item.feedback="{ item }">
                    <span v-if="item.isJobCollection">
                        {{ item.job_feedbacks }} / {{ item.job_count }}
                    </span>
                    <span v-else>
                        {{ item.feedback_meeting_completed_at | date }}
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        v-if="item.isJobCollection"
                        :class="{
                            'v-data-table__expand-icon--active':
                                item.isExpanded,
                        }"
                    >
                        mdi-chevron-down
                    </v-icon>
                </template>
            </v-data-table>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import toolbarReport from "Components/utils/toolbarReport";
import round from "Filters/round";
import percent from "Filters/percent";
import date from "Filters/shortDate";
import collapse from "Mixins/collapse.mixin";

export default {
    mixins: [collapse],
    components: { pageHeaderVuetify, toolbarReport },
    data: data,
    methods: getMethods(),
    watch: getWatchers(),
    filters: { date, round, percent },
};

function data() {
    return {
        loading: false,
        reportDates: {},
        search: "",
        jobCollections: [],
        headers: [
            {
                text: "Sales order",
                align: "center",
                sortable: true,
                value: "sales_order_id",
                class: "grey--text text--darken-3",
            },
            {
                text: "Client",
                align: "center",
                sortable: true,
                value: "client_name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Purchase order",
                align: "center",
                sortable: true,
                value: "purchase_order_number",
                class: "grey--text text--darken-3",
            },
            {
                text: "PO Item number",
                align: "left",
                sortable: false,
                value: "serial_number",
                class: "grey--text text--darken-3",
            },
            {
                text: "Job name",
                align: "left",
                sortable: true,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Efficiency",
                align: "center",
                sortable: false,
                value: "efficiency",
                class: "grey--text text--darken-3",
            },
            {
                text: "Progress",
                align: "center",
                sortable: false,
                value: "progress",
                class: "grey--text text--darken-3",
            },
            {
                text: "Delivered",
                align: "center",
                sortable: false,
                value: "delivered",
                class: "grey--text text--darken-3",
            },
            {
                text: "Feedback",
                align: "center",
                sortable: true,
                value: "feedback",
                class: "grey--text text--darken-3",
            },
            {
                text: "",
                value: "actions",
                align: "center",
                sortable: false,
            },
        ],
    };
}

function getWatchers() {
    return {
        jobCollections: {
            handler: collapseJobs,
            deep: true,
        },
        expandedJobCollection: {
            handler: collapseJobs,
        },
    };

    function collapseJobs() {
        this.$collapseJobs();
    }
}

function getMethods() {
    return {
        getManufacturingReport: getManufacturingReport,
    };

    function getManufacturingReport(payload) {
        const vm = this;
        vm.loading = true;

        Vue.backendApi()
            .reports()
            .manufacturing()
            .on(payload)
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "jobCollections", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log(
                "An error occurred trying to get manufacturing reports"
            );
        }
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep .v-data-table__wrapper {
    tr td:last-child {
        padding: 0;

        div {
            display: inline-flex;
        }
    }
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem
    > td {
    height: 30px;
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem:not(.latestSubItem)
    > td {
    border: none;
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.firstSubItem {
    box-shadow: inset 0 4px 4px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-top: 25px;
        padding-bottom: 5px;
    }
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem.latestSubItem {
    box-shadow: inset 0 -4px 4px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-bottom: 25px;
        padding-top: 5px;
    }
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.latestSubItem.firstSubItem {
    box-shadow: inset 0 4px 8px -5px rgba(50, 50, 50, 0.75),
        inset 0 -4px 8px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
</style>
