<template>
    <v-data-table
        :headers="headers"
        :items="lineItems"
        sort-by="item"
        class="elevation-2 mb-5"
    >
        <template v-slot:top>
            <v-toolbar
                flat
                color="white"
                :prominent="$vuetify.breakpoint.width < 421"
            >
                <v-row class="justify-content-between">
                    <v-col cols="auto" class="pr-0 vertical-centered">
                        <v-toolbar-title>ORDER ITEMS</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col cols="auto" class="vertical-centered">
                        <v-btn
                            v-if="salesOrderId"
                            class="mr-2"
                            title="Job Details"
                            small
                            :to="`/app/jobs?searchQuery=salesOrderId:${salesOrderId}&isWithTrashed=true`"
                        >
                            <v-icon small class="mr-1"
                                >mdi-newspaper-variant-multiple</v-icon
                            >
                            Jobs
                        </v-btn>

                        <v-dialog
                            persistent
                            v-model="showDialog"
                            max-width="500px"
                            @click:outside="confirmClose"
                            @keydown.esc="confirmClose"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    :disabled="!Boolean(salesOrderId)"
                                    ><v-icon small class="mr-1"
                                        >mdi-basket-plus</v-icon
                                    >
                                    Add New
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="primary white--text">
                                    <span class="headline">
                                        {{ formTitle }}
                                    </span>
                                </v-card-title>

                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="6">
                                                <v-text-field
                                                    v-model="editedItem.name"
                                                    label="Name"
                                                    :rules="[rules.required]"
                                                    ref="name"
                                                    @input="isDirty = true"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12">
                                                <v-textarea
                                                    v-model="
                                                        editedItem.description
                                                    "
                                                    label="Description"
                                                    :rows="2"
                                                    :rules="[rules.required]"
                                                    ref="description"
                                                    @input="isDirty = true"
                                                ></v-textarea>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field
                                                    v-model="editedItem.units"
                                                    label="Quantity"
                                                    :rules="[
                                                        rules.required,
                                                        rules.min1,
                                                    ]"
                                                    type="number"
                                                    min="1"
                                                    ref="units"
                                                    @input="isDirty = true"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field
                                                    v-model="
                                                        editedItem.unit_price
                                                    "
                                                    label="Unit price"
                                                    :rules="[
                                                        rules.required,
                                                        rules.price,
                                                    ]"
                                                    ref="unit_price"
                                                    @input="isDirty = true"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-text-field
                                                    v-model="itemTotal"
                                                    label="Total"
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-menu
                                                    v-model="menu0"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                    @input="isDirty = true"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="
                                                                itemStartAt
                                                            "
                                                            label="Start Date"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :rules="[
                                                                rules.required,
                                                            ]"
                                                            ref="start_at"
                                                            @input="
                                                                isDirty = true
                                                            "
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="
                                                            editedItem.start_at
                                                        "
                                                        @input="
                                                            menu0 = false;
                                                            isDirty = true;
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <v-menu
                                                    v-model="menu1"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs,
                                                        }"
                                                    >
                                                        <v-text-field
                                                            v-model="itemDueAt"
                                                            label="Date Due"
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            :rules="[
                                                                rules.required,
                                                            ]"
                                                            ref="due_at"
                                                            @input="
                                                                isDirty = true
                                                            "
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="
                                                            editedItem.due_at
                                                        "
                                                        @input="
                                                            menu1 = false;
                                                            isDirty = true;
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="confirmClose"
                                        >Cancel</v-btn
                                    >
                                    <v-btn color="primary" @click="save"
                                        >Save</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
        <template v-slot:item.unit_price="{ item }">
            {{ item.unit_price | currencyFormat }}
        </template>
        <template v-slot:item.total="{ item }">
            {{ getItemTotal(item) | currencyFormat }}
        </template>
        <template v-slot:item.start_at="{ item }">
            {{ item.start_at | shortDate }}
        </template>
        <template v-slot:item.due_at="{ item }">
            {{ item.due_at | shortDate }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editItem(item)"
                >mdi-pencil
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="deleteItem(item)"
                >mdi-delete</v-icon
            >
        </template>
    </v-data-table>
</template>

<script>
import round from "Filters/round";
import shortDate from "Filters/shortDate";
import currencyFormat from "Filters/currencyFormat";

export default {
    data: data,
    created: created,
    methods: getMethods(),
    props: {
        salesOrderId: [String, Number],
        items: Array,
    },
    watch: getWatchers(),
    computed: getComputed(),
    filters: { round, shortDate, currencyFormat },
};

function data() {
    return {
        showDialog: false,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Description",
                value: "description",
                class: "grey--text text--darken-3",
                sortable: false,
            },
            {
                text: "Quantity",
                value: "units",
                class: "grey--text text--darken-3",
                align: "center",
                width: 96,
            },
            {
                text: "Price",
                value: "unit_price",
                class: "grey--text text--darken-3",
            },
            {
                text: "Total",
                value: "total",
                class: "grey--text text--darken-3",
            },
            {
                text: "Start Date",
                value: "start_at",
                class: "grey--text text--darken-3",
                width: 121,
            },
            {
                text: "End Date",
                value: "due_at",
                class: "grey--text text--darken-3",
                width: 121,
            },
            {
                text: "Actions",
                value: "actions",
                sortable: false,
                class: "grey--text text--darken-3",
                width: 76,
            },
        ],
        lineItems: [],
        editedIndex: -1,
        editedItem: {
            id: "new-item-id",
            name: "",
            description: "",
            units: "",
            unit_price: "",
            total: this.itemTotal,
            start_at: "",
            due_at: "",
            jobs: [],
        },
        defaultItem: {
            id: "new-item-id",
            name: "",
            description: "",
            units: "",
            unit_price: "",
            total: this.itemTotal,
            start_at: "",
            due_at: "",
            jobs: [],
        },
        menu0: false,
        menu1: false,
        rules: {
            required: (value) => {
                if (value === undefined || value === null || value === "") {
                    return "Required.";
                }
                return true;
            },
            min1: (value) => value >= 1 || "Min 1",
            price: (value) => {
                const pattern = /^[0-9]{1,9}(\.[0-9]{1,2})?$/;
                return pattern.test(value) || "Invalid format.";
            },
        },
    };
}

function getMethods() {
    return {
        initialize: initialize,
        editItem: editItem,
        deleteItem: deleteItem,
        close: close,
        confirmClose: confirmClose,
        save: save,
        formatDate: formatDate,
        getItemTotal: getItemTotal,
        checkItem: checkItem,
    };

    function initialize() {
        this.lineItems = this.items;
    }

    function editItem(item) {
        this.editedIndex = this.lineItems.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showDialog = true;
    }

    function deleteItem(item) {
        const vm = this;

        if (confirm("Are you sure you want to delete this item?")) {
            Vue.backendApi()
                .salesOrders(vm.salesOrderId)
                .items(item.id)
                .destroy()
                .then(success, fail);
        }

        function success(response) {
            const index = vm.lineItems.indexOf(item);
            vm.lineItems.splice(index, 1);
            vm.$emit("removedItem", item);
        }

        function fail(error) {
            vm.$store.commit("addError", error);
        }
    }

    function close() {
        this.showDialog = false;
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        });
        Object.keys(this.editedItem).forEach((field) => {
            if (this.$refs[field]) {
                this.$refs[field].resetValidation();
            }
        });
        this.isDirty = false;
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function checkItem() {
        let itemHasErrors = false;
        const pricePattern = /^[0-9]{1,9}(\.[0-9]{1,2})?$/;

        Object.keys(this.editedItem).forEach((field) => {
            if (this.$refs[field]) {
                if (!this.editedItem[field]) {
                    itemHasErrors = true;
                }
                this.$refs[field].validate(true);
            }
        });

        if (itemHasErrors) {
            return false;
        }
        if (this.editedItem.units < 1) {
            this.$refs.units.focus();
            return false;
        }
        if (!pricePattern.test(this.editedItem.unit_price)) {
            this.$refs.unit_price.focus();
            return false;
        }
        return true;
    }

    function save() {
        const vm = this;

        if (!this.checkItem()) {
            return false;
        }

        if (vm.salesOrderId && vm.editedItem.units > 0) {
            if (vm.editedItem.id === "new-item-id") {
                Vue.backendApi()
                    .salesOrders(vm.salesOrderId)
                    .items()
                    .add(vm.editedItem)
                    .then(success, fail);
            } else {
                Vue.backendApi()
                    .salesOrders(vm.salesOrderId)
                    .items(vm.editedItem.id)
                    .put(vm.editedItem)
                    .then(success, fail);
            }

            function success(response) {
                vm.editedItem = response.data;

                if (vm.editedIndex > -1) {
                    Object.assign(vm.lineItems[vm.editedIndex], vm.editedItem);
                    vm.$emit("updatedItem", vm.editedItem);
                } else {
                    vm.lineItems.push(vm.editedItem);
                    vm.$emit("newItem", vm.editedItem);
                }
                vm.close();
            }

            function fail(error) {
                vm.$store.commit("addError", error);
            }
        }
    }

    function formatDate(date) {
        const dateFormatted = Vue.moment(date);
        if (dateFormatted.isValid()) {
            return dateFormatted.format("DD / MM / YYYY");
        } else {
            return "";
        }
    }

    function getItemTotal(item) {
        return round(item.units * item.unit_price, 2);
    }
}

function getComputed() {
    return {
        formTitle: formTitle,
        itemTotal: itemTotal,
        itemStartAt: itemStartAt,
        itemDueAt: itemDueAt,
    };

    function formTitle() {
        return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }

    function itemTotal() {
        if (this.editedItem.units > 0 && this.editedItem.unit_price >= 0) {
            return round(this.editedItem.units * this.editedItem.unit_price, 2);
        }
        return "";
    }

    function itemStartAt() {
        return this.formatDate(this.editedItem.start_at);
    }

    function itemDueAt() {
        return this.formatDate(this.editedItem.due_at);
    }
}

function getWatchers() {
    return {
        showDialog: showDialog,
        items: {
            immediate: true,
            handler: getItems,
        },
    };

    function showDialog(val) {
        val || this.close();
    }

    function getItems() {
        if (this.items) {
            this.lineItems = this.items;
        }
    }
}

function created() {
    this.initialize();
}
</script>
