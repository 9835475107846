<template>
    <div
        v-custom-click-outside="clickOutside"
        class="set-hours-modal dnd-no-drag no-drag"
    >
        <div class="mini-modal-header">
            <i class="fas fa-times" @click="cancel"></i>
            <h6 class="hours-title">
                Hours:
                <span>{{ totalHours | round(1) }}</span>
            </h6>
        </div>

        <v-app>
            <v-dialog v-model="showTimeEditor" width="500" class="dnd-no-drag">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="headline"> Edit time </span>
                    </v-card-title>

                    <v-card-text class="pb-0 pt-4">
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-select
                                    v-model="editedShiftJob.type_of_hour_id"
                                    :items="typeOfHours"
                                    item-text="name"
                                    item-value="id"
                                    label="Type"
                                    height="25px"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <time-picker
                                    v-model="editedShiftJob.started_at"
                                    @previousTime="updatePreviousDuration"
                                    @input="keepDuration"
                                ></time-picker>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field
                                    :value="editedShiftJob.ended_at | time"
                                    label="End at"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <duration-picker
                                    :startedAt="editedShiftJob.started_at"
                                    v-model="editedShiftJob.ended_at"
                                ></duration-picker>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="showTimeEditor = false"
                            >Cancel</v-btn
                        >
                        <v-btn color="primary" @click="save(editedShiftJob)"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app>

        <div class="mini-modal-body">
            <div
                class="work-time"
                v-for="shiftJob in jobHistory.shifts"
                :key="shiftJob.id"
            >
                <div class="d-flex justify-content-between">
                    <div>
                        <select
                            v-model="shiftJob.type_of_hour_id"
                            class="mini-select"
                            @change="save(shiftJob)"
                        >
                            <option
                                v-for="typeOfHour in typeOfHours"
                                :key="typeOfHour.id"
                                :value="typeOfHour.id"
                            >
                                {{ typeOfHour.name }}
                            </option>
                        </select>
                    </div>
                    <div>
                        <b>{{ getWorkedHours(shiftJob) | round(1) }}h</b>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                    <div>
                        {{ shiftJob.started_at | time }} -
                        {{ shiftJob.ended_at | time }}
                    </div>
                    <div>
                        <i
                            class="fas fa-edit"
                            @click="editTime(shiftJob)"
                            title="Edit"
                        ></i>
                        <i
                            class="fas fa-trash"
                            title="Delete"
                            v-if="hoursMix"
                            @click="removeTime(shiftJob)"
                        ></i>
                    </div>
                </div>
            </div>

            <input
                type="button"
                class="mt-3 btn-block"
                value="Add time"
                :disabled="hoursMix"
                @click="addTime"
            />
        </div>
    </div>
</template>

<script>
import timePicker from "Components/utils/vuetify-time-picker-datetime";
import durationPicker from "Components/utils/vuetify-duration-picker-datetime";
import time from "Filters/timeFromDateTime";
import round from "Filters/round";

export default {
    components: { timePicker, durationPicker },
    data() {
        return {
            showTimeEditor: false,
            previousDuration: 0.5,
            editedShiftJob: {
                started_at: "",
                ended_at: "",
            },
            defaultShiftJob: {
                started_at: "",
                ended_at: "",
            },
            isClickOutsideChecked: false,
            typeOfHours: []
        };
    },
    props: {
        jobHistory: Object,
    },
    watch: {
        showTimeEditor(val) {
            val || this.close();
        },
    },
    computed: {
        hoursMix() {
            return this.jobHistory.shifts.length > 1 ? true : false;
        },
        totalHours() {
            const vm = this;
            if (this.hoursMix) {
                return this.jobHistory.shifts.reduce(
                    (acc, shiftJob) => acc + vm.getWorkedHours(shiftJob),
                    0
                );
            }
            return vm.getWorkedHours(this.jobHistory.shifts[0]);
        },
    },
    methods: {
        save(shiftJob) {
            const vm = this;

            Vue.backendApi()
                .stationShift(shiftJob.station_shift_id)
                .updateJobHistory(shiftJob)
                .then(success, fail);

            function success(response) {
                vm.$emit("updatedStationShiftJob", response.data);
                vm.$eventHub.$emit(
                    "job-history-updated",
                    shiftJob,
                    response.data
                );
                vm.close();
            }

            function fail(error) {
                console.log("An error occurred trying to add time");
            }
        },
        addTime() {
            const vm = this;
            vm.$emit("addTime", vm.jobHistory);
        },
        removeTime(shiftJob) {
            const vm = this;
            vm.$emit("removeTime", shiftJob);
        },
        editTime(shiftJob) {
            this.editedShiftJob = shiftJob;
            this.showTimeEditor = true;
        },
        cancel() {
            const vm = this;
            vm.$emit("cancel");
        },
        getWorkedHours(shiftJob) {
            let { started_at, ended_at } = shiftJob;
            let endTime = Vue.moment(started_at, "YYYY-MM-DD HH:mm:ss");
            let startTime = Vue.moment(ended_at, "YYYY-MM-DD HH:mm:ss");
            let minutes = startTime.diff(endTime, "minutes");
            return minutes / 60;
        },
        close() {
            this.showTimeEditor = false;
            this.$nextTick(() => {
                this.editedShiftJob = Object.assign({}, this.defaultShiftJob);
            });
        },
        updatePreviousDuration(previousTime) {
            let { ended_at } = this.editedShiftJob;
            let startTime = Vue.moment(previousTime, "YYYY-MM-DD HH:mm:ss");
            let endTime = Vue.moment(ended_at, "YYYY-MM-DD HH:mm:ss");
            let duration = Math.abs(startTime.diff(endTime, "minutes")) / 60;
            this.previousDuration = duration;
        },
        keepDuration() {
            let startTime = Vue.moment(
                this.editedShiftJob.started_at,
                "YYYY-MM-DD HH:mm:ss"
            );
            let endTime = startTime.add(this.previousDuration, "hours");
            endTime = Vue.moment(endTime).format("YYYY-MM-DD HH:mm:ss");
            this.editedShiftJob.ended_at = endTime;
        },
        clickOutside(evt) {
            if (this.showTimeEditor) {
                return;
            }
            if (!this.isClickOutsideChecked) {
                this.isClickOutsideChecked = true;
                return;
            }
            this.$emit("close");
            this.isClickOutsideChecked = false;
        },
    },
    filters: { time, round },

    mounted() {
        const vm = this;
        vm.$eventHub.$on("editJobShiftTime", (jobHistory) => {
            let jobShift = vm.jobHistory.shifts.find(
                (jobShift) => jobShift.id === jobHistory.id
            );
            if (jobShift) {
                vm.editTime(jobShift);
            }
        });

        Vue.backendApi()
            .typeOfHours()
            .index()
            .then(success, fail);

        function success(response) {
            Vue.set(vm, "typeOfHours", response.data);
        }

        function fail(error) {
            console.log(
                "An error has occurred trying to get type of hours"
            );
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    min-height: auto;
}

input[type="button"],
.button {
    float: initial;
}

input[type="button"]:disabled {
    opacity: 0.5;
}

.fas {
    cursor: pointer;
}

.work-time {
    & .fas {
        color: rgba(0, 0, 0, 0.54);
    }

    & .fa-trash {
        margin-left: 3px;
    }
}

.work-time:not(:first-child) {
    margin-top: 10px;
    border-top: 1px dotted #ced4da;
    padding-top: 11px;
}

.mini-select {
    border-style: revert;
    background-color: revert;
    border-radius: revert;
    -moz-appearance: revert;
    -webkit-appearance: revert;
    border: 1px solid #ced4da;
    background-color: #fff;
    color: #495057;
    border-radius: 3px;
    min-width: 80px;
    max-width: 83px;
}

.set-hours-modal {
    width: 128px;
    box-shadow: 0px 6px 12px 4px #555;
    position: absolute;
    background: white;
    z-index: 8;
    border-radius: 4px;
    font-size: 12px;

    &:hover {
        cursor: initial;
    }

    .mini-modal-header {
        padding: 10px 10px 0px 10px;
        border-bottom: 1px solid #ced4da;
    }

    .mini-modal-body {
        padding: 10px;
    }

    & .fas.fa-times {
        border-radius: 50%;
        right: 4px;
        padding: 4px;
        position: absolute;
        background-color: burlywood;
        top: 9px;
    }
}
</style>
