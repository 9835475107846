<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="500px"
    >
        <v-card class="mx-auto text-left" tile>
            <v-card-title class="primary white--text">
                <span class="headline"> {{ employee.name }} - Edit </span>
            </v-card-title>

            <v-card-text>
                <v-form ref="editUserForm">
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-text-field
                                    v-model="employee.name"
                                    label="Name"
                                    :rules="[
                                        () => !!employee.name || 'Required.',
                                    ]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pt-0">
                                <v-select
                                    v-model="employee.processes"
                                    :items="processes"
                                    item-text="name"
                                    item-value="id"
                                    :menu-props="{
                                        maxHeight: '400',
                                    }"
                                    label="Processes"
                                    return-object
                                    multiple
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn color="default" @click="$emit('close')">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            show: true,
        };
    },

    props: {
        employee: Object,
    },

    computed: {
        processes() {
            return this.$store.state.processes;
        },
    },

    methods: {
        save() {
            const vm = this;

            Vue.backendApi()
                .employees(vm.employee.id)
                .update(vm.employee)
                .then(success, fail);

            function success(response) {
                vm.$emit("employee-updated", response.data);
                vm.$emit("close");
            }

            function fail(error) {
                console.log("Error trying to update employee");
            }
        },
    },
};
</script>
