<template>
    <li>
        <div class="custom-control custom-checkbox">
            <input
                type="checkbox"
                class="custom-control-input"
                :id="`process-checkbox-${process.id}`"
                v-model="value.types"
                :value="process"
            />
            <label
                class="transition-size custom-control-label"
                :class="{ inactive: !isActive }"
                :for="`process-checkbox-${process.id}`"
                >{{ process.name }} -
                <span class="show-percent"
                    >{{ $percentCommitted(process) }}%</span
                >
                <span class="show-hours"
                    >{{ process.booked_machine_hours }} /
                    {{ process.possible_machine_shift_hours }}</span
                >
            </label>
        </div>
    </li>
</template>

<script>
import overviewProcess from "Mixins/overview/overview-process.mixin";

export default {
    mixins: [overviewProcess],
};
</script>

<style scoped lang="scss">
.custom-control-label:after,
.custom-control-label:before {
    width: 0.8rem;
    height: 0.8rem;
}
.headerpart-managment {
    & .custom-control {
        line-height: 18px;
        min-height: 1rem;
    }

    & .custom-control-label::before {
        top: 0.24rem;
    }

    & .checkmenu label.custom-control-label {
        font-weight: 700;
        text-transform: uppercase;
    }
}

.inactive {
    text-decoration: line-through;
}

.custom-control.custom-checkbox:hover {
    .show-percent {
        display: none;
    }

    .show-hours {
        display: inline;
    }
}

.show-hours {
    display: none;
}
</style>
