<template>
    <div>
        <div class="disable-entire-page"></div>
        <div class="waiting-spinner">
            <i class="fas fa-spinner"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "overlay-waiting-spinner",
};
</script>

<style scoped lang="scss">
@import "~@/_variables.scss";
.waiting-spinner {
    display: block;
    font-size: 50px;
    position: fixed;
    top: 50vh;
    left: 50vw;
    z-index: 990;
}

.disable-entire-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $lightGrey;
    opacity: 0.7;
    z-index: 980;
}

.waiting-spinner {
    color: $red;
    display: inline-block;
    -webkit-animation: spin 2s linear infinite;
    animation: spinner 2s linear infinite;
    border-radius: 50%;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
