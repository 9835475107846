import filterJobItemBySearchMixin from "Mixins/filterJobItemBySearch.mixin";

export default {
    mixins: [filterJobItemBySearchMixin],

    methods: {
        $isFiltered(item) {
            if (this.filters.types.length === 0) {
                return false;
            }

            let hideJob = true;
            this.filters.types.forEach(function (process) {
                if (item.isJobCollection) {
                    let someJobUseProcess = item.jobs.some(function (job) {
                        return jobUseProcess(job, process);
                    });

                    if (someJobUseProcess) {
                        hideJob = false;
                    }
                } else if (jobUseProcess(item, process)) {
                    hideJob = false;
                }
            });

            function jobUseProcess(job, process) {
                return job.processes.some(
                    (jobProcess) => jobProcess.id === process.id
                );
            }
            return hideJob;
        },

        $isFilteredBySearch(item) {
            const vm = this;
            if (vm.search === "") {
                return false;
            }
            let query = vm.search.toLowerCase();

            if (vm.$matchJobItem(item, query)) {
                return false;
            }

            if (item.isJobCollection) {
                let someJobMatch = item.jobs.some((job) =>
                    vm.$matchJobItem(job, query)
                );
                if (someJobMatch) {
                    return false;
                }
            }
            return true;
        },
    },
};
