var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row-job",class:{
            'job-collection': _vm.item.isJobCollection,
            'is-expanded-job-collection': _vm.item.isExpandedJobCollection,
            'belongs-to-expanded-collection':
                _vm.item.belongsToExpandedCollection,
            'is-latest-job-in-collection': _vm.item.isLatestJobInCollection,
        },on:{"click":function($event){return _vm.clickOnItem(_vm.item)}}},[_c('div',{staticClass:"hrs"},[_c('div',{staticClass:"actual-hours-text"},[_vm._v("\n                "+_vm._s(_vm._f("round")(_vm.item.progress))+"%\n            ")])]),_vm._v(" "),_c('div',{key:'view-employee-${job.id}',staticClass:"job-text jobsbg"},[_vm._v("\n            "+_vm._s(_vm.item.name)+"\n            "),(_vm.dueIn.as('days') < 1)?_c('span',[_c('i',{staticClass:"fas fa-hourglass-half"})]):_vm._e(),_vm._v(" "),_vm._l((_vm.item.blockers),function(blocker,i){return _c('div',{key:i,staticClass:"blocker-text"},[_vm._v("\n                "+_vm._s(blocker.blocker)+" <\n            ")])}),_vm._v(" "),_c('div',{staticClass:"clearfix"}),_vm._v(" "),(_vm.item.serial_number || _vm.item.due_at)?_c('div',{staticClass:"job-info"},[(_vm.item.serial_number)?_c('span',{staticClass:"job-serial"},[_c('i',{staticClass:"fas fa-barcode"}),_vm._v(" "+_vm._s(_vm.item.serial_number)+"\n                ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"job-delivery-date"},[_vm._v("\n                    "+_vm._s(_vm._f("shortDate")(_vm.item.due_at))+"\n                ")])]):_vm._e()],2)]),_vm._v(" "),_c('div',{staticClass:"clearfix"})])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }