export default {
    methods: {
        $getEfficiency(hours) {
            if (hours.total <= 0) {
                return 100;
            }

            const percentInEfficiencies =
                (hours.Inefficiencies / hours.total) * 100;
            return 100 - percentInEfficiencies;
        },
    },
};
