<template>
    <v-simple-checkbox
        v-if="isAdmin"
        disabled
        :value="true"
    ></v-simple-checkbox>

    <div v-else>
        <v-progress-circular
            v-if="permission.isLoading"
            indeterminate
            color="primary"
            :size="20"
            :width="3"
        ></v-progress-circular>
        <v-simple-checkbox
            v-else
            v-model="permission.isCheck"
            :disabled="isDisabled"
            :color="permission.color"
            @input="change"
        ></v-simple-checkbox>
    </div>
</template>

<script>
export default {
    data: data,

    props: {
        isAdmin: Boolean,
        isDisabled: {
            type: Boolean,
            default: false,
        },
        menuOption: {
            type: Object,
            default: null,
        },
        permission: Object,
    },

    methods: getMethods(),
};

function data() {
    return {
        check: false,
        isLoading: false,
    };
}

function getMethods() {
    return {
        change: change,
    };

    function change() {
        const vm = this;

        if (vm.menuOption) {
            vm.$emit("change", vm.permission, vm.menuOption);
        } else {
            vm.$emit("change", vm.permission);
        }
    }
}
</script>
