<template>
    <span class="type-of-hours-container">
        <span class="badge badge-info">
            <i class="fas fa-history"></i> {{ totalHours }}h
            {{ typeOfHours }}
        </span>
    </span>
</template>

<script>
export default {
    props: {
        jobShifts: Array,
    },

    computed: {
        totalHours() {
            let total = this.jobShifts.reduce((accumulator, current) => {
                return accumulator + current.all_hours;
            }, 0);

            return isNaN(total) ? "--" : total;
        },

        typeOfHours() {
            if (!this.jobShifts[0].type_of_hour) {
                return "Pending...";
            }

            let firstType = this.jobShifts[0].type_of_hour.name;
            let hasSameTypeOfHour = true;

            for (const shift of this.jobShifts) {
                if (firstType !== shift.type_of_hour.name) {
                    hasSameTypeOfHour = false;
                    break;
                }
            }

            return hasSameTypeOfHour ? firstType : "Mix";
        },
    },
};
</script>

<style scoped>
.type-of-hours-container {
    margin-right: 65px;
}
</style>
