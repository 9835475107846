<template>
    <v-card color="primary" dark class="summary-card">
        <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title class="text-h5">
                    Profitability Report
                </v-list-item-title>
                <v-list-item-subtitle>General Summary</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-text>
            <v-row align="center">
                <v-col cols="12" sm="6" md="3">
                    <div class="text-overline white--text">
                        Cost of materials
                    </div>
                    <div class="text-h3 text-md-h4 text-lg-h3 white--text">
                        {{ report.cost_of_materials | currencyFormat }}
                    </div>
                    <div class="summary-comparison-info">
                        <report-comparator
                            :value="report['cost_of_materials']"
                            :previous-value="
                                comparedReport['cost_of_materials']
                            "
                        ></report-comparator>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <div class="text-overline white--text">
                        Cost of stations
                    </div>
                    <div class="text-h3 text-md-h4 text-lg-h3 white--text">
                        {{ report.cost_of_stations | currencyFormat }}
                    </div>
                    <div class="summary-comparison-info">
                        <report-comparator
                            :value="report['cost_of_stations']"
                            :previous-value="comparedReport['cost_of_stations']"
                        ></report-comparator>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <div class="text-overline white--text">Total Cost</div>
                    <div class="text-h3 text-md-h4 text-lg-h3 white--text">
                        {{ report.total_cost | currencyFormat }}
                    </div>
                    <div class="summary-comparison-info">
                        <report-comparator
                            :value="report['total_cost']"
                            :previous-value="comparedReport['total_cost']"
                        ></report-comparator>
                    </div>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <div class="text-overline white--text">
                        Sales Order Amount
                    </div>
                    <div class="text-h3 text-md-h4 text-lg-h3 white--text">
                        {{ report.sales_order_amount | currencyFormat }}
                    </div>
                    <div class="summary-comparison-info">
                        <report-comparator
                            :value="report['sales_order_amount']"
                            :previous-value="
                                comparedReport['sales_order_amount']
                            "
                        ></report-comparator>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import currencyFormat from "Filters/currencyFormat";
import round from "Filters/round";
import reportComparator from "Components/sales-reports/profitability-report/report-comparator";

export default {
    components: { reportComparator },

    props: {
        report: Object,
        comparedReport: Object,
    },

    filters: { currencyFormat, round },
};
</script>

<style scoped lang="scss">
.text-h3 {
    margin-bottom: 5px;
}

.summary-card,
.profit-card {
    min-height: 215.6px;
}
</style>
