export default function (value) {
    if (value === 24) {
        return "24h0m";
    }
    const duration = Vue.moment.duration(parseFloat(value), "hours");
    if (Vue.moment.isDuration(duration)) {
        let hours = duration.hours();
        let minutes = duration.minutes();
        return `${hours}h${minutes}m`;
    } else {
        return "";
    }
}
