<template>
    <v-data-table
        :headers="headers"
        :items="schedules"
        class="elevation-1 row-pointer"
        :loading="loading"
        @click:row="focusOnSchedule"
        :item-class="rowClasses"
    >
        <template v-slot:top>
            <v-toolbar
                flat
                color="white"
                :prominent="$vuetify.breakpoint.width < 493"
            >
                <v-row class="justify-content-between">
                    <v-col cols="auto" class="vertical-centered pr-0 mr-auto">
                        <v-toolbar-title>Schedules</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col cols="auto" class="vertical-centered">
                        <v-switch
                            class="px-0"
                            label="Show all"
                            v-model="isWithTrashed"
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="auto">
                        <v-dialog
                            persistent
                            v-model="showScheduleEditor"
                            @click:outside="confirmClose"
                            @keydown.esc="confirmClose"
                            @keydown.enter="save"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >New Schedule
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="primary white--text">
                                    <span class="headline">
                                        {{ formTitle }}
                                    </span>
                                </v-card-title>

                                <v-card-text>
                                    <v-form
                                        ref="editScheduleForm"
                                        onSubmit="return false;"
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedSchedule.name
                                                        "
                                                        label="Name"
                                                        @input="isDirty = true"
                                                        :rules="[
                                                            () =>
                                                                !!editedSchedule.name ||
                                                                'Required.',
                                                        ]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="confirmClose"
                                        >Cancel</v-btn
                                    >
                                    <v-btn color="primary" @click="save"
                                        >Save</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item, index }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editSchedule(item)"
                title="Edit"
                v-if="!item.deleted_at"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="hardDestroy(item, index)"
                v-if="item.deleted_at"
                title="Delete Archive "
            >
                mdi-archive-alert
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="restore(item, index)"
                v-if="item.deleted_at || item.isTrashing"
                title="Unarchive "
            >
                mdi-backup-restore
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="softDestroy(item, index)"
                v-else
                title="Archive"
            >
                mdi-archive
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: data,
    created: created,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Schedule" : "Edit Schedule";
        },
    },
};

function data() {
    return {
        showScheduleEditor: false,
        isWithTrashed: false,
        isDirty: false,
        deletingTimeoutId: null,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedSchedule: {
            id: "new-schedule-id",
            name: "",
        },
        defaultSchedule: {
            id: "new-schedule-id",
            name: "",
        },
        schedules: [],
        loading: false,
    };
}

function getWatchers() {
    return {
        showScheduleEditor: {
            handler: dialog,
        },
        isWithTrashed: {
            handler: getLists,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function getLists() {
        this.getLists.apply(this);
    }
}

function getMethods() {
    return {
        focusOnSchedule: focusOnSchedule,
        editSchedule: editSchedule,
        close: close,
        getLists: getLists,
        confirmClose: confirmClose,
        save: save,
        softDestroy: softDestroy,
        hardDestroy: hardDestroy,
        restore: restore,
        rowClasses: rowClasses,
    };

    function getLists() {
        const vm = this;
        const isWithTrashed = vm.isWithTrashed || false;
        vm.loading = true;

        Vue.backendApi().schedules().index(isWithTrashed).then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "schedules", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get schedules");
        }
    }

    function restore(schedule, index) {
        const vm = this;
        clearTimeout(vm.deletingTimeoutId);

        Vue.backendApi().schedules(schedule.id).restore().then(success, fail);

        function success(response) {
            schedule.isTrashing = false;
            Vue.set(vm.schedules, index, schedule);
            schedule.deleted_at = null;
        }

        function fail(error) {
            console.log("An error occurred trying to restore a schedule list");
        }
    }

    function focusOnSchedule(schedule) {
        if (schedule.id === "new-schedule-id") {
            this.$router.push({ name: "schedules" });
        } else {
            this.$router.push({
                name: "schedules",
                params: { id: schedule.id },
            });
        }
    }

    function editSchedule(schedule) {
        this.editedIndex = this.schedules.indexOf(schedule);
        this.editedSchedule = Object.assign({}, schedule);
        this.showScheduleEditor = true;
    }

    function close() {
        this.showScheduleEditor = false;
        this.$nextTick(() => {
            this.editedSchedule = Object.assign({}, this.defaultSchedule);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editScheduleForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editScheduleForm.validate()) {
            return false;
        }

        if (vm.editedSchedule.id === "new-schedule-id") {
            Vue.backendApi()
                .schedules()
                .store(vm.editedSchedule)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .schedules(vm.editedSchedule.id)
                .store(vm.editedSchedule)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.schedules[vm.editedIndex], response.data);
            } else {
                vm.schedules.unshift(response.data);
                vm.$router.push({
                    name: "schedules",
                    params: { id: response.data.id },
                });
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save schedule");
        }
    }

    function softDestroy(schedule, index) {
        const vm = this;

        schedule.isTrashing = false;
        Vue.set(vm.schedules, index, schedule);
        Vue.backendApi()
            .schedules(schedule.id)
            .softDestroy()
            .then(success, fail);

        function success(response) {
            if (vm.isWithTrashed) {
                schedule.deleted_at = response.data.deleted_at;
            } else {
                schedule.isTrashing = true;
                Vue.set(vm.schedules, index, schedule);
                vm.deletingTimeoutId = setTimeout(() => {
                    vm.schedules.splice(index, 1);

                    if (vm.$route.params.id === schedule.id) {
                        vm.$router.push({ name: "schedules" });
                    }
                }, 2000);
            }
        }

        function fail(error) {
            console.log("An error occurred trying to archive a job");
        }
    }

    function hardDestroy(schedule, index) {
        const vm = this;

        if (confirm("Delete permanently? \nAre you sure?")) {
            Vue.backendApi()
                .schedules(schedule.id)
                .hardDestroy()
                .then(success, fail);
        }

        function success(response) {
            vm.schedules.splice(index, 1);
            if (vm.$route.params.id === schedule.id) {
                vm.$router.push({ name: "schedules" });
            }
        }

        function fail(error) {
            console.log("An error occurred trying to archive a job");
        }
    }

    function rowClasses(schedule) {
        if (schedule.deleted_at || schedule.isTrashing) {
            return "user-archived";
        }
        if (schedule.id === parseInt(this.$route.params.id)) {
            return "v-data-table__selected";
        }
    }
}

function created() {
    this.getLists();
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep .user-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep label {
    margin-bottom: 0px;
}
</style>
