<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="375px"
        content-class="non-efficient-report"
    >
        <v-card max-width="375" class="mx-auto text-left">
            <v-card-title class="justify-content-center"
                >Non efficient hours
                <v-spacer></v-spacer>
                <v-btn small icon @click="$emit('close')" class="ml-2">
                    <v-icon>mdi-close</v-icon>
                </v-btn></v-card-title
            >
            <v-card-subtitle>{{ subtitle }}</v-card-subtitle>

            <v-card-text class="text-center">
                <v-progress-circular
                    v-if="isLoading"
                    color="primary"
                    :size="18"
                    :width="2"
                    indeterminate
                    class="ml-2"
                    transition="fade-transition"
                ></v-progress-circular>
                <v-simple-table v-else dense class="text-left">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Reason</th>
                                <th class="text-center">Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="type_of_hour of report"
                                :key="type_of_hour.id"
                            >
                                <td>{{ type_of_hour.name }}</td>
                                <td class="text-center">
                                    {{ type_of_hour.hours | round(1) }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import round from "Filters/round";

export default {
    data() {
        return {
            show: true,
        };
    },

    props: {
        subtitle: String,
        isLoading: Boolean,
        report: Array,
    },

    filters: { round },
};
</script>

<style lang="scss" scoped></style>
