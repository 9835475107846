<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="375px"
    >
        <v-card max-width="375" class="mx-auto text-left">
            <v-card-title class="justify-content-center">{{
                inventoryItem.name
            }}</v-card-title>
            <v-card-subtitle>{{ inventoryItem.description }}</v-card-subtitle>

            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <th>Thickness</th>
                                <td>
                                    {{
                                        inventoryItem.thickness | decimalFormat
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th>Width</th>
                                <td>
                                    {{ inventoryItem.width | decimalFormat }}
                                </td>
                            </tr>
                            <tr>
                                <th>Length</th>
                                <td>
                                    {{ inventoryItem.length | decimalFormat }}
                                </td>
                            </tr>
                            <tr>
                                <th>Weight</th>
                                <td>
                                    {{ inventoryItem.weight | decimalFormat }}
                                </td>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <td>
                                    {{
                                        inventoryItem.category
                                            ? inventoryItem.category.name
                                            : ""
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th>Units</th>
                                <td>
                                    {{
                                        inventoryItem.unit
                                            ? inventoryItem.unit.name
                                            : ""
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th>Cost</th>
                                <td>
                                    {{ inventoryItem.cost | currencyFormat }}
                                </td>
                            </tr>
                            <tr>
                                <th>Sale price</th>
                                <td>
                                    {{
                                        inventoryItem.sale_price
                                            | currencyFormat
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <th>Availability</th>
                                <td
                                    :class="[
                                        inventoryItem.quantity <
                                        inventoryItem.low_inventory
                                            ? 'yellow--text text--darken-4'
                                            : '',
                                    ]"
                                >
                                    {{ inventoryItem.quantity }}
                                </td>
                            </tr>
                            <tr>
                                <th>Location</th>
                                <td>{{ inventoryItem.location }}</td>
                            </tr>
                            <tr>
                                <th>Attachment</th>
                                <td>
                                    <a
                                        v-if="inventoryItem.attachment"
                                        :href="
                                            '/storage/' +
                                            inventoryItem.attachment
                                        "
                                        target="__blank"
                                    >
                                        <span
                                            v-if="
                                                $isPDF(inventoryItem.attachment)
                                            "
                                        >
                                            <v-icon color="red"
                                                >mdi-file-pdf-outline</v-icon
                                            >
                                            PDF
                                        </span>
                                        <span v-else>
                                            <v-icon color="blue"
                                                >mdi-file-image-outline</v-icon
                                            >
                                            Image
                                        </span>
                                    </a>
                                </td>
                            </tr>

                            <tr>
                                <th>Id</th>
                                <td>{{ inventoryItem.id }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import currencyFormat from "Filters/currencyFormat";
import decimalFormat from "Filters/decimalFormat";
import isPdf from "Mixins/isPdf.mixin";

export default {
    mixins: [isPdf],

    data() {
        return {
            show: true,
        };
    },

    props: {
        inventoryItem: Object,
    },

    filters: { currencyFormat, decimalFormat },
};
</script>
