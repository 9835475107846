export default {
    methods: {
        $isPDF(filename) {
            if (filename.length <= 4) {
                return false;
            }
            if (filename.slice(-4).toLowerCase() === ".pdf") {
                return true;
            }
            return false;
        },
    },
};
