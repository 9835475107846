export default {
    data() {
        return {
            withCustomDates: false,
            showDatePicker: false,
            month: Vue.moment(this.$route.params.start),
            monthString: "",
            customDates: {
                start: Vue.moment(this.$route.params.start).toDate(),
                end: Vue.moment(this.$route.params.end).toDate(),
            },
        };
    },

    computed: {
        customDatesString() {
            const start = this.customDates.start;
            const end = this.customDates.end;
            return `${Vue.moment(start).format("YYYY-MM-DD")} - ${Vue.moment(
                end
            ).format("YYYY-MM-DD")}`;
        },

        monthFormatted() {
            return this.formatMonth(this.month);
        },
    },

    watch: {
        withCustomDates() {
            if (this.withCustomDates) {
                this.customDateChanged();
            } else {
                this.monthChanged(this.month);
            }
        },
    },

    methods: {
        formatMonth(month) {
            if (!month) return null;
            return Vue.moment(month, "YYYY-MM").format("MMMM, YYYY");
        },

        getMonthString() {
            const vm = this;
            return `${vm.month.format("YYYY")}-${vm.month.format("MM")}`;
        },

        monthChanged(month) {
            const vm = this;
            vm.month = Vue.moment(month, "YYYY-MM");
            vm.$router.push({
                name: vm.routeName,
                query: vm.getRouterQuery(),
                params: {
                    ...vm.$route.params,
                    start: vm.month.format("YYYY-MM"),
                },
            });
            vm.showDatePicker = false;
            vm.getReport();
        },

        getDatesForReport() {
            let startDate = "";
            let endDate = "";

            if (this.withCustomDates) {
                startDate = Vue.moment(this.customDates.start).format(
                    "YYYY-MM-DD"
                );
                endDate = Vue.moment(this.customDates.end).format("YYYY-MM-DD");
            } else {
                startDate = this.month.startOf("month").format("YYYY-MM-DD");
                endDate = this.month.endOf("month").format("YYYY-MM-DD");
            }
            return { startDate, endDate };
        },

        customDateChanged() {
            const vm = this;
            vm.$router.push({
                name: vm.routeName,
                query: vm.$route.query,
                params: {
                    ...vm.$route.params,
                    ...{
                        start: Vue.moment(vm.customDates.start).format(
                            "YYYY-MM-DD"
                        ),
                        end: Vue.moment(vm.customDates.end).format(
                            "YYYY-MM-DD"
                        ),
                    },
                },
            });
            vm.showDatePicker = false;
            vm.getReport();
        },
    },

    created() {
        const vm = this;
        const isMonthlyFormatted = Vue.moment(
            vm.$route.params.start,
            "YYYY-MM",
            true
        ).isValid();
        const startDateValid =
            vm.$route.params.start && !isNaN(vm.customDates.start.valueOf());
        const endDateValid =
            vm.$route.params.end && !isNaN(vm.customDates.end.valueOf());

        if (!vm.month.isValid() || !isMonthlyFormatted) {
            vm.month = Vue.moment();
        }
        if (!startDateValid || isMonthlyFormatted) {
            vm.customDates.start = new Date().setDate(
                new Date().getDate() - 30
            );
        }
        if (!endDateValid) {
            vm.customDates.end = new Date();
        }

        vm.monthString = this.getMonthString();

        if (isMonthlyFormatted || !endDateValid) {
            vm.withCustomDates = false;
            vm.monthChanged(vm.month);
        } else {
            vm.withCustomDates = true;
        }
    },
};
