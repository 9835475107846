<template>
    <materials
        :materials="materials"
        :from="job.materials_from"
        :loading="loading"
        :closeEditor="closeEditor"
        @editor-closed="closeEditor = false"
        @destroy="destroy"
        @save="save"
        @saveFromInventory="addInventoryItemToJob"
    >
    </materials>
</template>

<script>
import materials from "Components/jobs/materials";

export default {
    components: { materials },
    data() {
        return {
            materials: [],
            loading: false,
            closeEditor: false,
        };
    },
    props: {
        job: Object,
    },
    watch: {
        job: {
            immediate: true,
            handler: function () {
                if (this.job.id) {
                    this.getMaterials();
                }
            },
        },
    },
    methods: {
        getMaterials() {
            const vm = this;
            vm.loading = true;

            Vue.backendApi()
                .jobs(vm.job.id)
                .materials()
                .index()
                .then(success, fail);

            function success(response) {
                vm.loading = false;
                Vue.set(vm, "materials", response.data);
            }

            function fail(error) {
                vm.loading = false;
                console.log("An error occurred trying to get materials");
            }
        },

        save(material, materialIndex) {
            const vm = this;
            const isUnsavedMaterial = material.id === "new-material-id";

            if (isUnsavedMaterial) {
                Vue.backendApi()
                    .jobs(vm.job.id)
                    .materials()
                    .store(material)
                    .then(success, fail);
            } else {
                Vue.backendApi()
                    .jobs(vm.job.id)
                    .materials(material.id)
                    .store(material)
                    .then(success, fail);
            }

            function success(response) {
                isUnsavedMaterial
                    ? vm.materials.push(response.data)
                    : Object.assign(vm.materials[materialIndex], response.data);

                vm.closeEditor = true;
            }

            function fail(error) {
                console.log("An error occurred trying to save material");
            }
        },

        addInventoryItemToJob(material, inventoryItem) {
            const vm = this;

            Vue.backendApi()
                .jobs(vm.job.id)
                .materials()
                .fromInventory(inventoryItem.id)
                .store(material)
                .then(success, fail);

            function success(response) {
                vm.materials.push(response.data);
                vm.closeEditor = true;
            }

            function fail(error) {
                console.log(
                    "An error occurred trying to save job material from inventory"
                );
            }
        },

        destroy(material) {
            let deletedMaterialIndex = this.materials.findIndex((item) => {
                return item.id === material.id;
            });
            this.materials.splice(deletedMaterialIndex, 1);
        },
    },
};
</script>
