<template>
    <v-data-table
        :headers="headers"
        :items="inventoryCategories"
        class="elevation-1 row-pointer"
        :loading="loading"
        :item-class="rowClasses"
    >
        <template v-slot:top>
            <v-toolbar
                flat
                color="white"
                :prominent="$vuetify.breakpoint.width < 566"
            >
                <v-row class="justify-content-between">
                    <v-col cols="auto" class="vertical-centered pr-0 mr-auto">
                        <v-toolbar-title>Inventory Categories</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col cols="auto" class="vertical-centered">
                        <v-switch
                            label="Show all"
                            v-model="isWithTrashed"
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="auto">
                        <v-dialog
                            persistent
                            v-model="showInventoryCategoryEditor"
                            @click:outside="confirmClose"
                            @keydown.esc="confirmClose"
                            @keydown.enter="save"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    :small="
                                        $vuetify.breakpoint.width >= 320 &&
                                        $vuetify.breakpoint.width <= 338
                                    "
                                    >New Category
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="primary white--text">
                                    <span class="headline">
                                        {{ formTitle }}
                                    </span>
                                </v-card-title>

                                <v-card-text>
                                    <v-form
                                        ref="editInventoryCategoryForm"
                                        onSubmit="return false;"
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedInventoryCategory.name
                                                        "
                                                        label="Name"
                                                        @input="isDirty = true"
                                                        :rules="[
                                                            () =>
                                                                !!editedInventoryCategory.name ||
                                                                'Required.',
                                                        ]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="confirmClose"
                                        >Cancel
                                    </v-btn>
                                    <v-btn color="primary" @click="save"
                                        >Save</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item, index }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editInventoryCategory(item)"
                title="Edit"
                v-if="!item.deleted_at"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="hardDestroy(item, index)"
                v-if="item.deleted_at"
                title="Delete Archive "
            >
                mdi-archive-alert
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="restore(item, index)"
                v-if="item.deleted_at || item.isTrashing"
                title="Unarchive "
            >
                mdi-backup-restore
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="softDestroy(item, index)"
                v-else
                title="Archive"
            >
                mdi-archive
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: data,
    created: created,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1
                ? "New Inventory Category"
                : "Edit Inventory Category";
        },
    },
};

function data() {
    return {
        showInventoryCategoryEditor: false,
        isDirty: false,
        deletingTimeoutId: null,
        isWithTrashed: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedInventoryCategory: {
            id: "new-inventoryCategory-id",
            name: "",
        },
        defaultInventoryCategory: {
            id: "new-inventoryCategory-id",
            name: "",
        },
        inventoryCategories: [],
        loading: false,
    };
}

function getWatchers() {
    return {
        showInventoryCategoryEditor: {
            handler: dialog,
        },
        isWithTrashed: {
            handler: getInventoryCategory,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function getInventoryCategory() {
        this.getInventoryCategory.apply(this);
    }
}

function getMethods() {
    return {
        editInventoryCategory: editInventoryCategory,
        close: close,
        getInventoryCategory: getInventoryCategory,
        restore: restore,
        confirmClose: confirmClose,
        save: save,
        rowClasses: rowClasses,
        hardDestroy: hardDestroy,
        softDestroy: softDestroy,
    };

    function rowClasses(inventoryCategory) {
        if (inventoryCategory.deleted_at || inventoryCategory.isTrashing) {
            return "user-archived";
        }
        return "";
    }

    function restore(inventoryCategory, index) {
        const vm = this;
        clearTimeout(vm.deletingTimeoutId);
        Vue.backendApi()
            .inventoryCategories(inventoryCategory.id)
            .restore()
            .then(success, fail);

        function success(response) {
            inventoryCategory.isTrashing = false;
            Vue.set(vm.inventoryCategories, index, inventoryCategory);
            inventoryCategory.deleted_at = null;
        }

        function fail(error) {
            console.log(
                "An error occurred trying to restore an inventory category"
            );
        }
    }

    function getInventoryCategory() {
        const vm = this;
        this.loading = true;

        Vue.backendApi()
            .inventoryCategories()
            .index(vm.isWithTrashed)
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "inventoryCategories", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get inventory category");
        }
    }

    function editInventoryCategory(inventoryCategory) {
        this.editedIndex = this.inventoryCategories.indexOf(inventoryCategory);
        this.editedInventoryCategory = Object.assign({}, inventoryCategory);
        this.showInventoryCategoryEditor = true;
    }

    function close() {
        this.showInventoryCategoryEditor = false;
        this.$nextTick(() => {
            this.editedInventoryCategory = Object.assign(
                {},
                this.defaultInventoryCategory
            );
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editInventoryCategoryForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editInventoryCategoryForm.validate()) {
            return false;
        }

        if (vm.editedInventoryCategory.id === "new-inventoryCategory-id") {
            Vue.backendApi()
                .inventoryCategories()
                .store(vm.editedInventoryCategory)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .inventoryCategories(vm.editedInventoryCategory.id)
                .store(vm.editedInventoryCategory)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(
                    vm.inventoryCategories[vm.editedIndex],
                    response.data
                );
            } else {
                vm.inventoryCategories.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save inventoryCategory");
        }
    }

    function hardDestroy(inventoryCategory, index) {
        const vm = this;

        if (confirm("Delete permanently? \nAre you sure?")) {
            Vue.backendApi()
                .inventoryCategories(inventoryCategory.id)
                .hardDestroy()
                .then(success, fail);
        }

        function success(response) {
            inventoryCategory.deleted_at = response.data.deleted_at;
            vm.inventoryCategories.splice(index, 1);
        }

        function fail(error) {
            console.log(
                "An error occurred trying to archive an inventory category"
            );
        }
    }

    function softDestroy(inventoryCategory, index) {
        const vm = this;
        inventoryCategory.isTrashing = false;
        Vue.set(vm.inventoryCategories, index, inventoryCategory);
        Vue.backendApi()
            .inventoryCategories(inventoryCategory.id)
            .softDestroy()
            .then(success, fail);

        function success(response) {
            if (vm.isWithTrashed) {
                inventoryCategory.deleted_at = response.data.deleted_at;
            } else {
                inventoryCategory.isTrashing = true;
                Vue.set(vm.inventoryCategories, index, inventoryCategory);
                vm.deletingTimeoutId = setTimeout(() => {
                    vm.inventoryCategories.splice(index, 1);
                }, 2000);
            }
        }

        function fail(error) {
            console.log(
                "An error occurred trying to archive an inventory category"
            );
        }
    }
}

function created() {
    const vm = this;
    this.loading = true;

    Vue.backendApi().inventoryCategories().index().then(success, fail);

    function success(response) {
        vm.loading = false;
        Vue.set(vm, "inventoryCategories", response.data);
    }

    function fail(error) {
        vm.loading = false;
        console.log("An error occurred trying to get inventory categories");
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep .user-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep label {
    margin-bottom: 0px;
}
</style>
