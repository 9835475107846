export default {
    methods: {
        $reorder(evt, arr, order) {
            if (evt.oldIndex === evt.newIndex) {
                return null;
            }

            let item = evt.item._underlying_vm_;
            let reorderedArr = [...arr];
            reorderedArr.splice(evt.oldIndex, 1);
            reorderedArr.splice(evt.newIndex, 0, item);

            if (order === "descending") {
                let position = 1000;
                reorderedArr.forEach((step) => (step.position = position--));
            } else {
                reorderedArr.forEach(
                    (step, index) => (step.position = index + 1)
                );
            }
            return reorderedArr;
        },
    },
};
