<template>
    <div class="building-one-main">
        <div
            class="building-one border border-radius"
            :class="preferences.background"
        >
            <h4>{{ preferences.name }}</h4>
            <div class="building-one-inner border" @scroll="scrolling($event)">
                <div
                    v-for="(stationGroup, index) in groupedStations"
                    :class="`building-column-${index}`"
                >
                    <shop-machine
                        v-for="machine in stationGroup"
                        :key="machine.id"
                        :preferences="preferences"
                        :filters="filters"
                        :machine="machine"
                        :machineId="machine.id"
                        :station-shifts="getStationShiftsOfMachine(machine)"
                    ></shop-machine>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</template>

<script>
import shopMachine from "Components/overview/building/machine/machine";

export default {
    name: "shop-overview-building",
    components: { shopMachine },
    props: {
        preferences: Object,
        filters: Object,
        building: Object,
        allShifts: Array,
    },
    computed: getComputed(),
    methods: getMethods(),
};

function getComputed() {
    return {
        groupedStations: groupedStations,
    };

    function groupedStations() {
        const vm = this;
        var groups = [[], []];
        vm.building.stations.forEach((building, index) => {
            groups[index % 2].push(building);
        });
        return groups;
    }
}

function getMethods() {
    return {
        scrolling: scrolling,
        getStationShiftsOfMachine: getStationShiftsOfMachine,
    };

    function scrolling(e) {
        const stationModal = e.target.querySelector(".station-modal");

        if (stationModal) {
            const marginTopModal = 39;
            const modalHeight = 125;

            const machine = stationModal.parentNode;
            const machinePosition = machine.getBoundingClientRect().top + 3;
            const finalPosition = machinePosition + window.scrollY;
            stationModal.style.top = finalPosition + "px";

            const containerRect = e.target.getBoundingClientRect();
            const containerTop = containerRect.top;
            const containerHeight = containerRect.height;

            const isOutTop = machinePosition + marginTopModal <= containerTop;
            const isOutBottom = finalPosition - modalHeight >= containerHeight;

            if (isOutTop || isOutBottom) {
                stationModal.style.display = "none";
            } else if (stationModal.style.display == "none") {
                stationModal.style.display = "block";
            }
        }
    }

    function getStationShiftsOfMachine(machine) {
        return this.allShifts.filter(
            (shift) => shift.station_id === machine.id
        );
    }
}
</script>

<style>
.building-column-0 {
    float: left;
    margin-right: 10px;
}

.building-column-1 {
    float: left;
    margin-right: 0;
}

.building-yellow {
    background: #e2e5ae;
}
.building-green {
    background: #aacbac;
}
.building-blue {
    background: #98bbc1;
}

.machine-inactive {
    opacity: 0.3;
}
</style>
