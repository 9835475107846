<template>
    <div>
        <div class="headerpart-managment">
            <div class="space-common">
                <process-report
                    v-model="filters"
                    :processes="processReport"
                ></process-report>
            </div>

            <div class="space-common">
                <logo></logo>
                <div
                    v-if="buildings.length > 3"
                    class="buildings-exchanger-container"
                >
                    <span @click="buildingScrollLeft">
                        <i
                            class="fa fa-angle-left leftone"
                            aria-hidden="true"
                        ></i>
                    </span>
                    <span @click="buildingScrollRight">
                        <i
                            class="fa fa-angle-right rightone"
                            aria-hidden="true"
                        ></i>
                    </span>
                </div>
            </div>

            <div class="space-common project-overview">
                <div class="project-overview-container">
                    <h3>PROJECT OVERVIEW</h3>
                    <div class="dateset-calender">
                        <a href="#" @click.prevent="previousDate">
                            <i
                                class="fa fa-angle-left leftone"
                                aria-hidden="true"
                            ></i>
                        </a>
                        <span class="center-main">
                            {{ overviewDate | shortDate }}
                        </span>
                        <a href="#" @click.prevent="nextDate">
                            <i
                                class="fa fa-angle-right rightone"
                                aria-hidden="true"
                            ></i>
                        </a>
                    </div>
                    <div>
                        <a
                            v-if="allShifts.length > 0"
                            href="#"
                            class="button"
                            @click="displayCopyModal = true"
                            >Copy</a
                        >
                        <a
                            v-else-if="creatingShifts"
                            href="#"
                            class="button create-shifts disabled"
                            >Creating...</a
                        >
                        <a
                            v-else
                            href="#"
                            class="button create-shifts"
                            @click="createShifts"
                            >Create Shifts</a
                        >
                    </div>
                </div>
                <cinasa-menu0></cinasa-menu0>
            </div>

            <div class="clearfix"></div>
        </div>
        <div class="custom-container">
            <shop-overview-employees
                :filters="filters"
                :all-shifts="allShifts"
                @employee-updated="updateEmployeeInShifts"
                :overview-date="overviewDate"
            >
            </shop-overview-employees>

            <div id="buildings-container" class="buildings-container">
                <shop-building
                    v-for="building in buildings"
                    :key="building.id"
                    :preferences="{
                        name: building.name,
                        background: building.colour,
                    }"
                    :filters="filters"
                    :building="building"
                    :all-shifts="allShifts"
                ></shop-building>
            </div>

            <template v-if="buildings.length === 0">
                <div></div>
                <div></div>
                <div></div>
            </template>
            <jobs-list :filters="filters"></jobs-list>

            <overlay-waiting-spinner
                v-if="loading.length > 0"
            ></overlay-waiting-spinner>
        </div>
        <div
            class="modal"
            :class="{ 'show-modal': displayCopyModal }"
            tabindex="-1"
            role="dialog"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Copy Work History</h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="displayCopyModal = false"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>
                            Copy from:
                            <editable-item
                                v-model="copy.fromDay"
                                @change="copy.fromDay = $event"
                                :meta="{
                                    canEdit: true,
                                    editor: 'date-picker',
                                    filters: [],
                                }"
                                :isEditing="true"
                            ></editable-item>
                        </p>
                        <p>
                            Copy to:
                            <editable-item
                                v-model="copy.toDay"
                                @change="copy.toDay = $event"
                                :meta="{
                                    canEdit: true,
                                    editor: 'date-picker',
                                    filters: [],
                                }"
                                :isEditing="true"
                            ></editable-item>
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                            @click="displayCopyModal = false"
                        >
                            Close
                        </button>
                        <button
                            v-if="!creatingCopy"
                            type="button"
                            class="btn btn-primary"
                            @click="startCopy"
                        >
                            Start copy
                        </button>
                        <button v-else type="button" class="btn btn-primary">
                            Copying...
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import shopBuilding from "Components/overview/building/shop-overview-building";
import shopOverviewEmployees from "Components/overview/employees/shop-overview-employee-list";
import jobsList from "Components/overview/jobs/shop-overview-jobs-list";
import processReport from "Components/overview/processes/process-report";

import editableItem from "Components/overview/utils/editable-item";
import shortDate from "Filters/shortDate";
import cinasaMenu0 from "Components/overview/utils/cinasa-menu0";

import store from "Services/store";
import logo from "Components/logo";
import overview from "Mixins/overview/overview.mixin";

export default {
    mixins: [overview],
    components: {
        editableItem,
        cinasaMenu0,
        shopBuilding,
        shopOverviewEmployees,
        jobsList,
        logo,
        processReport,
    },
    data: data,
    methods: getMethods(),
    filters: { shortDate },
};

function data() {
    return {
        displayCopyModal: false,
        creatingCopy: false,
        creatingShifts: false,
    };
}

function getMethods() {
    return {
        startCopy: startCopy,
        createShifts: createShifts,
        addNewStationShifts: addNewStationShifts,
        removeMachineStationShifts: removeMachineStationShifts,
        updateEmployeeInShifts: updateEmployeeInShifts,
        buildingScrollLeft: buildingScrollLeft,
        buildingScrollRight: buildingScrollRight,
    };

    function createShifts() {
        const vm = this;
        vm.creatingShifts = true;
        let date = vm.getOverviewShortDate();

        Vue.backendApi().stationShift().create({ date }).then(success, error);

        function success(response) {
            vm.displayCopyModal = false;
            let results = response.data;
            store.commit(
                "addMessage",
                `Created shifts on ${results.countOfStationsModified} stations.`
            );
            vm.getAllShifts.apply(this);
            vm.getProcessUtilizationReport.apply(this);
            vm.$eventHub.$emit("shifts-created");
            vm.creatingShifts = false;
        }

        function error() {
            console.log("An error occurred trying to create shifts");
            vm.creatingShifts = false;
        }
    }

    function startCopy() {
        const vm = this;
        vm.creatingCopy = true;
        let payload = {
            fromDay: this.copy.fromDay,
            toDay: this.copy.toDay,
        };
        Vue.backendApi().workHistory().copyADay(payload).then(success, error);

        function success() {
            vm.creatingCopy = false;
            vm.displayCopyModal = false;
            store.commit("addMessage", "Day copied successfully");
        }

        function error() {
            vm.creatingCopy = false;
            console.log("An error occurred");
        }
    }

    function addNewStationShifts(stationShifts) {
        this.allShifts = this.allShifts.concat(stationShifts);
    }

    function removeMachineStationShifts(machine) {
        this.allShifts = this.allShifts.filter(
            (shift) => shift.station.id !== machine.id
        );
    }

    function updateEmployeeInShifts(employee) {
        this.allShifts.forEach((shift) => {
            shift.user_history.forEach((userHistory) => {
                if (userHistory.user.id === employee.id) {
                    Object.assign(userHistory.user, employee);
                }
            });
        });
    }

    function buildingScrollLeft() {
        const buildingsContainer = document.getElementById(
            "buildings-container"
        );
        buildingsContainer.scroll({
            top: 0,
            left: parseInt(buildingsContainer.scrollLeft) - 325,
            behavior: "smooth",
        });
    }

    function buildingScrollRight() {
        const buildingsContainer = document.getElementById(
            "buildings-container"
        );
        buildingsContainer.scroll({
            top: 0,
            left: parseInt(buildingsContainer.scrollLeft) + 325,
            behavior: "smooth",
        });
    }
}
</script>

<style scoped lang="scss">
@import "~@/_variables.scss";

.buildings-exchanger-container {
    text-align: center;

    i {
        font-size: 20px;
        background-color: #dbdbdb;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        transition: all 0.5s;

        &:hover {
            cursor: pointer;
            background-color: #cbcbcb;
        }
    }
}

.buildings-container {
    width: 965px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 5px;
}

.show-modal {
    display: block;
}

.clearfix {
    clear: both;
}

.transition-size {
    transition: font-size 500ms;
}

.headerpart-managment {
    position: absolute;
    z-index: 9;
    text-align: center;

    min-width: 1000px;
    margin-left: 290px;
}

.larger-font {
    font-size: 14px;
}

.space-common.project-overview {
    padding-top: 0;
    text-align: center;
    & h3 {
        margin-bottom: 0;
    }

    & a.button {
        color: $buildingGreen;
        border-color: $buildingGreen;
        border-style: solid;
        border-radius: 10px;
        display: block;
        width: 70px;
        margin: 0 auto 0 auto;
        float: initial;
    }
    & a.button:hover {
        color: white;
        font-weight: 700;
        background-color: $buildingGreen;
    }

    & a.button.create-shifts {
        width: 140px;
        border-color: red;
        color: red;
        transition: 0.5s all;
    }

    & a.button.create-shifts.disabled {
        opacity: 0.5;
    }
}

.project-overview-container {
    display: inline-block;
}

.modal {
    border-style: solid;
    border-color: black;
    border-radius: 20px;
}

.modal-header {
    background-color: $buildingGreen;
    color: black;
    font-weight: 700;
}

.modal-footer button {
    background-color: white;
    border-color: $buildingGreen;
    color: $buildingGreen;
    font-weight: 700;
    border-style: solid;
    border-width: 1px;

    &.btn-secondary {
        border-color: grey;
        color: grey;
    }
}

.modal-footer button:hover {
    background-color: $buildingGreen;
    color: white;
    &.btn-secondary {
        background-color: grey;
        color: white;
    }
}
</style>

<style lang="scss">
.smooth-dnd-draggable-wrapper {
    cursor: pointer;
}

.transition-size {
    transition: font-size 500ms;
}

.transition-opacity {
    transition: opacity 500ms;
}
</style>
