<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="12" md="4">
                    <group-list @selected="setActiveGroup"></group-list>
                </v-col>
                <v-col cols="12" md="8">
                    <group-permissions
                        v-if="activeGroup"
                        :group="activeGroup"
                    ></group-permissions>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import groupList from "Components/group-management/group-list";
import groupPermissions from "Components/group-management/group-permissions";

export default {
    components: { pageHeaderVuetify, groupList, groupPermissions },
    data: data,
    methods: getMethods(),
};

function data() {
    return {
        activeGroup: null,
    };
}

function getMethods() {
    return {
        setActiveGroup: setActiveGroup,
    };

    function setActiveGroup(group) {
        this.activeGroup = group;
    }
}
</script>
