export default function (value, places) {
    let workingNumber = parseFloat(value);
    if (typeof workingNumber !== "number") {
        return workingNumber;
    }
    if (typeof places === "undefined") {
        places = 0;
    }
    let result = +(Math.round(workingNumber + "e+" + places) + "e-" + places);
    return result;
}
