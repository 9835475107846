<template>
    <v-dialog
        v-model="show"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title
                    >{{
                        item.isJobCollection ? "Job Collection" : "Job"
                    }}
                    Progress Report - {{ item.name }}</v-toolbar-title
                >
                <v-spacer></v-spacer>
            </v-toolbar>

            <h3 class="mt-5">Accumulative Progress vs Goals</h3>
            <apexchart
                type="line"
                height="300"
                :options="accumulativeChartOptions"
                :series="accumulativeSeries"
            ></apexchart>

            <h3 class="mt-5">Daily Progress vs Goals</h3>
            <apexchart
                type="line"
                height="300"
                :options="dailyChartOptions"
                :series="dailySeries"
            ></apexchart>
        </v-card>
    </v-dialog>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },

    props: {
        item: Object,
    },

    data: () => ({
        show: true,
        workedHours: [],
        estimatedHours: [],
        dates: [],
        goals: [],
        report: null,
        accumulativeSeries: [
            {
                name: "Accumulative Estimated Hours",
                type: "line",
                data: [],
            },
            {
                name: "Accumulative Worked Hours",
                type: "line",
                data: [],
            },
        ],
        dailySeries: [
            {
                name: "Estimated Hours",
                type: "line",
                data: [],
            },
            {
                name: "Worked Hours",
                type: "line",
                data: [],
            },
        ],
        accumulativeChartOptions: {
            chart: {
                height: 350,
                type: "line",
            },
            stroke: {
                curve: "smooth",
            },
            fill: {
                type: "solid",
                opacity: [0.35, 1],
            },
            labels: [],
            markers: {
                size: 0,
            },
            yaxis: [
                {
                    title: {
                        text: "Accumulative Hours",
                    },
                },
            ],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " hours";
                        }
                        return y;
                    },
                },
            },
        },
        dailyChartOptions: {
            chart: {
                height: 350,
                type: "line",
            },
            stroke: {
                curve: "smooth",
            },
            fill: {
                type: "solid",
                opacity: [0.35, 1],
            },
            labels: [],
            markers: {
                size: 0,
            },
            yaxis: [
                {
                    title: {
                        text: "Hours",
                    },
                },
            ],
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0) + " hours";
                        }
                        return y;
                    },
                },
            },
        },
    }),

    created() {
        const vm = this;

        if (vm.item.isJobCollection) {
            Vue.backendApi()
                .reports()
                .jobCollections(vm.item.id)
                .progress()
                .then(success, fail);
        } else {
            Vue.backendApi()
                .reports()
                .jobs(vm.item.id)
                .progress()
                .then(success, fail);
        }

        function success(response) {
            vm.accumulativeSeries[0].data =
                response.data.accumulated_estimated_hours;
            vm.accumulativeSeries[1].data =
                response.data.accumulated_worked_hours;
            vm.accumulativeChartOptions = {
                ...vm.chartOptions,
                labels: response.data.dates,
            };

            vm.dailySeries[0].data = response.data.estimated_hours;
            vm.dailySeries[1].data = response.data.worked_hours;
            vm.dailyChartOptions = {
                ...vm.chartOptions,
                labels: response.data.dates,
            };
        }

        function fail(error) {
            console.log("An error occurred trying to get progress report");
        }
    },
};
</script>
