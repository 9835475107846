export default {
    methods: {
        $isFiltered(employee) {
            const vm = this;
            if (this.filters.types.length === 0) {
                return false;
            }

            return !employee.processes.some(function (process) {
                return vm.filters.types.some(function (filter) {
                    return filter.id === process.id;
                });
            });
        },

        $isFilteredBySearch(employee) {
            if (this.search === "") {
                return false;
            }
            let query = this.search.toLowerCase();
            let employeeName = employee.name.toLowerCase();
            return employeeName.indexOf(query) === -1;
        },
    },

    computed: {
        $employeeHistoryMap() {
            let employeeHistoryMap = {};

            this.allShifts.forEach((shift) => {
                shift.user_history.forEach((userHistory) => {
                    let employeeHistory =
                        employeeHistoryMap[userHistory.user.id];

                    if (!employeeHistory) {
                        employeeHistory = {
                            workHours: 0,
                            shiftCount: 0,
                            stations: [],
                        };
                        employeeHistoryMap[userHistory.user.id] =
                            employeeHistory;
                    }

                    let shiftActualHours = shift.job_history.reduce(
                        (acc, jobHistory) =>
                            acc + parseFloat(jobHistory.all_hours),
                        0
                    );
                    employeeHistory.workHours += shiftActualHours;
                    employeeHistory.shiftCount++;

                    let stationIndex = employeeHistory.stations.findIndex(
                        (station) => {
                            return station.id === shift.station.id;
                        }
                    );
                    if (stationIndex === -1) {
                        employeeHistory.stations.push(shift.station);
                    }
                });
            });
            return employeeHistoryMap;
        },
    },
};
