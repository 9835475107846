<template>
    <v-menu offset-y absolute :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="white"
                v-bind="attrs"
                v-on="on"
                fab
                class="mx-2"
                :elevation="2"
            >
                <v-icon color="primary" large>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list>
            <div v-for="item in menuOptions" :key="item.title">
                <v-list-group v-if="item.is_parent" no-action class="text-left">
                    <v-list-item slot="activator" :to="item.link">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                        v-for="sub in item.sub_options"
                        :key="sub.title"
                        :to="sub.link"
                    >
                        <v-list-item-title>{{ sub.title }}</v-list-item-title>
                    </v-list-item>
                </v-list-group>

                <v-list-item v-else :to="item.link" class="text-left">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </div>

            <v-list-item
                href="/logout"
                class="text-left"
                @click.prevent="$logout"
            >
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
        </v-list>
        <logout-form></logout-form>
    </v-menu>
</template>

<script>
import menuItems from "Mixins/menuItems.mixin";
import logout from "Mixins/logout.mixin";
import logoutForm from "Components/logout-form";

export default {
    mixins: [menuItems, logout],
    components: { logoutForm },
};
</script>

<style lang="scss" scoped>
button:focus {
    outline: none;
}

.theme--light.v-list-item--active::before {
    opacity: 0;
}

.theme--light.v-list-item--active:hover::before {
    opacity: 0.04;
}

.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus::before {
    opacity: 0.12;
}

.v-list-group .v-list-item {
    padding-left: 0px;
}

.v-application--is-ltr
    .v-list-group--no-action
    > .v-list-group__items
    > .v-list-item {
    padding-left: 50px;
}
</style>
