<template>
    <v-rating v-model="rating" length="3" clearable @input="emitChange">
        <template v-slot:item="props">
            <v-icon
                v-if="showDefaultFace(props)"
                @click="$emit('edit')"
                color="grey lighten-1"
                dense
            >
                mdi-emoticon-neutral-outline
            </v-icon>
            <v-icon
                v-else-if="isEditing || isSelectedFace(props)"
                @click="selectFace(props)"
                :color="getFace(props)['color']"
                dense
            >
                {{ getFace(props)["icon"] }}
            </v-icon>
        </template>
    </v-rating>
</template>

<script>
export default {
    props: {
        value: Number,
        isEditing: Boolean,
    },

    watch: {
        value: {
            immediate: true,
            handler(newVal, oldVal) {
                this.rating = newVal;
            },
        },

        rating: {
            immediate: true,
            handler(newVal, oldVal) {
                this.$emit("input", newVal);
            },
        },
    },

    data: () => ({
        rating: 0,
        faces: [
            {
                icon: "mdi-emoticon-frown",
                color: "red",
            },
            {
                icon: "mdi-emoticon-neutral",
                color: "blue",
            },
            {
                icon: "mdi-emoticon-happy",
                color: "green",
            },
            {
                icon: "mdi-emoticon-frown-outline",
                color: "grey lighten-1",
            },
            {
                icon: "mdi-emoticon-neutral-outline",
                color: "grey lighten-1",
            },
            {
                icon: "mdi-emoticon-happy-outline",
                color: "grey lighten-1",
            },
        ],
    }),

    methods: {
        isSelectedFace(props) {
            return props.value === parseInt(props.index) + 1;
        },

        showDefaultFace(props) {
            const isDefaultFaceIndex = props.index === 1;
            const noFaceSelected = !this.isEditing && props.value === 0;
            return noFaceSelected && isDefaultFaceIndex;
        },

        getFace(props) {
            let faceIndex = this.isSelectedFace(props)
                ? props.index
                : parseInt(props.index) + 3;

            return this.faces[faceIndex];
        },

        selectFace(props) {
            if (this.isEditing) {
                props.click();
                return;
            }
        },

        emitChange() {
            this.$emit("change");
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep.v-rating .v-icon {
    padding: 0px 3px;

    &:first-child {
        padding-left: 0px;
    }
}
</style>
