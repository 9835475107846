export default {
    methods: {
        $getOffcutsOfInventoryItem(inventoryItemId) {
            let offcuts = [];
            this.inventory.forEach((item) => {
                if (item.parent_id === inventoryItemId) {
                    offcuts.push(item);
                }
            });
            return offcuts;
        },
    },
};
