require("./bootstrap");

window.Vue = require("vue");
window.axios = require("axios");

Vue.prototype.$eventHub = new Vue();

import Vuex from "vuex";
Vue.use(Vuex);

import spliceById from "Mixins/spliceById.mixin";
Vue.mixin(spliceById);

import Api from "Services/api";
Vue.use(Api);

import VueRouter from "vue-router";
Vue.use(VueRouter);

import vuetify from "Plugins/vuetify";

import VueDraggable from "vuedraggable";
Vue.use(VueDraggable);
Vue.component("vue-draggable", VueDraggable);

Vue.use(require("vue-moment"));

import store from "Services/store.js";

import truncate from "Filters/truncate";
Vue.filter("truncate", truncate);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

import mainComponent from "Components/main-component";
Vue.component("main-component", mainComponent);

import waitingSpinner from "Components/overview/utils/waiting-spinner";
Vue.component("waiting-spinner", waitingSpinner);

import overlayWaitingSpinner from "Components/overview/utils/overlay-waiting-spinner";
Vue.component("overlay-waiting-spinner", overlayWaitingSpinner);

import kheeraButton from "Components/overview/utils/kheera-button";
Vue.component("kheera-button", kheeraButton);

Vue.directive("custom-click-outside", {
    bind: function (el, binding, vnode) {
        el.event = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.event);
    },
    unbind: function (el) {
        document.body.removeEventListener("click", el.event);
    },
});

import router from "./router";

const app = new Vue({
    el: "#app",
    vuetify,
    components: { mainComponent },
    router,
    store,
});
