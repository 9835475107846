import { Drag, DropList } from "vue-easy-dnd";

export default {
    components: { Drag, DropList },

    props: {
        employees: Array,
        shift: Object,
        machine: Object,
        currentMachineDowntime: Object,
    },

    data: () => ({
        pending: [],
        removing: [],
        draggingEmployee: false,
    }),

    methods: {
        isRemoving(userHistory) {
            return this.removing.some((id) => userHistory.id === id);
        },

        shouldAcceptDrop(item) {
            if (this.currentMachineDowntime) {
                return false;
            }

            return true;
        },
    },
};
