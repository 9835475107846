<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                :label="label"
                readonly
                v-bind="attrs"
                v-on="on"
                :error-messages="errorMessages"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="menu"
            v-model="time"
            full-width
            @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
    </v-menu>
</template>

<script>
export default {
    data: data,
    watch: getWatchers(),
    props: {
        value: String,
        label: {
            type: String,
            default: "Start at",
        },
        errorMessages: {
            type: String,
            default: "",
        },
    },
};

function data() {
    return {
        menu: false,
        time: null,
    };
}

function getWatchers() {
    return {
        value: {
            immediate: true,
            handler: value,
        },
        time: {
            handler: timeChanged,
        },
    };

    function value() {
        const date = Vue.moment(this.value, "YYYY-MM-DD HH:mm:ss");
        this.time = date.format("HH:mm");
    }

    function timeChanged(time) {
        const vm = this;
        if (this.value) {
            vm.$emit("previousTime", this.value);
            let date = this.value.substring(0, 10);
            let updatedDateTime = `${date} ${time}:00`;
            vm.$emit("input", updatedDateTime);
        }
    }
}
</script>
