<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="12">
                    <users-list></users-list>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import usersList from "Components/users/users-list";

export default {
    name: "users-layout",
    components: { pageHeaderVuetify, usersList },
};
</script>
