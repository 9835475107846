<template>
    
        <v-col cols="12" class="text-left">
            <label class="serial-numbers-label"
                >Serial numbers ({{
                    editedMaterial.serial_numbers.length
                }})</label
            >
            <div class="serial-numbers-container">
                <v-checkbox
                    v-for="serial in serialNumbers"
                    :key="serial.id"
                    dense
                    hide-details
                    v-model="editedMaterial.serial_numbers"
                    :label="serial.serial"
                    :value="serial"
                    class="mt-0 pt-0"
                ></v-checkbox>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        serialNumbers: Array,
        editedMaterial: Object
    }
};
</script>

<style lang="scss" scoped>
.serial-numbers-container {
    max-height: 95px;
    overflow: auto;
    border: 1px solid #e0e0e0;
    padding: 5px 2px;

    ::v-deep label {
        margin-bottom: 0px;
    }
}

.serial-numbers-label {
    color: rgba(0, 0, 0, 0.6);
    height: 20px;
    line-height: 20px;
    letter-spacing: normal;
    font-size: 12px;
    margin-bottom: 2px;
}
</style>
