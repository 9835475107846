<template>
    <v-dialog
        persistent
        v-model="show"
        @click:outside="close"
        @keydown.esc="close"
        max-width="500px"
        scrollable
    >
        <v-card>
            <v-card-title class="primary white--text">
                <span class="headline">Attachment</span>
            </v-card-title>

            <v-card-text>
                <v-form ref="editAttachmentForm" onSubmit="return false;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <a
                                    v-if="inventoryItem.attachment"
                                    :href="
                                        '/storage/' + inventoryItem.attachment
                                    "
                                    target="__blank"
                                >
                                    <div
                                        v-if="$isPDF(inventoryItem.attachment)"
                                    >
                                        <v-icon color="red" class="bigIcon"
                                            >mdi-file-pdf-outline</v-icon
                                        >
                                        <br />
                                        Document
                                    </div>

                                    <img
                                        v-else
                                        :src="
                                            '/storage/' +
                                            inventoryItem.attachment
                                        "
                                        width="100%"
                                    />
                                </a>

                                <v-file-input
                                    v-else
                                    :loading="isLoading"
                                    v-model="attachment"
                                    placeholder="Select your file"
                                    accept="image/png, image/jpeg, image/bmp, application/pdf"
                                    @input="isDirty = true"
                                    :rules="[rules.required]"
                                    truncate-length="30"
                                    @change="uploadAttachment"
                                    :clearable="false"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            v-if="errorMessage !== ''"
                                            @click="uploadAttachment"
                                            title="Try again"
                                            >mdi-reload</v-icon
                                        >
                                    </template>
                                </v-file-input>
                            </v-col>
                        </v-row>
                        <v-row v-if="errorMessage !== ''">
                            <v-col class="py-0">
                                <v-alert
                                    border="left"
                                    color="red"
                                    dense
                                    text
                                    class="text-left mb-0"
                                >
                                    {{
                                        errorMessage
                                            ? errorMessage
                                            : "There was an error uploading the file. Please try again."
                                    }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn color="primary" @click="close">Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="inventoryItem.attachment"
                    color="error"
                    @click="destroyAttachment"
                    >Delete</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import isPdf from "Mixins/isPdf.mixin";

export default {
    mixins: [isPdf],

    data() {
        return {
            show: true,
            isLoading: false,
            errorMessage: "",
            attachment: null,
            rules: {
                required: (value) => {
                    if (value === undefined || value === null || value === "") {
                        return "Required.";
                    }
                    return true;
                },
            },
        };
    },

    props: {
        inventoryItem: Object,
    },

    methods: {
        uploadAttachment() {
            const vm = this;
            vm.isLoading = true;
            let formData = new FormData();
            formData.append("attachment", vm.attachment);

            Vue.backendApi()
                .inventory(vm.inventoryItem.id)
                .attachment()
                .store(formData)
                .then(success, fail);

            function success(response) {
                Vue.set(vm.inventoryItem, "attachment", response.data.path);
                vm.isLoading = false;
                vm.errorMessage = "";
                vm.attachment = null;
            }

            function fail(error) {
                let errorMessage = "";
                const maxExceededMessage = "The attachment failed to upload.";

                if (error.response.status === 500) {
                    errorMessage =
                        "File exceeds the allowed size limit (post_max_size)";
                } else if (error.response.data.message === maxExceededMessage) {
                    errorMessage =
                        "File exceeds the allowed size limit (upload_max_filesize)";
                } else {
                    errorMessage = error.response.data.message;
                }

                vm.isLoading = false;
                vm.errorMessage = errorMessage;
                console.log(
                    "An error occurred trying to add attachment to inventory item"
                );
            }
        },

        destroyAttachment() {
            const vm = this;

            Vue.backendApi()
                .inventory(vm.inventoryItem.id)
                .attachment()
                .destroy()
                .then(success, fail);

            function success(response) {
                vm.inventoryItem.attachment = null;
                vm.attachment = null;
            }

            function fail(error) {
                console.log(
                    "An error occurred trying to delete inventory item attachment"
                );
            }
        },

        close() {
            this.$emit("close");
        },
    },
};
</script>
