<template>
    <div>
        <div class="jobs-list-container" ref="jobs-list">
            <div class="employee-list-inner border border-radius">
                <div class="hrsjobs heightbgscrolltophrsjob">
                    <span class="hrs hrs-header">
                        HRS
                        <i
                            class="fas fa-long-arrow-alt-up"
                            @click="orderByHours(true)"
                            :class="{
                                show: orderBy === 'hours' ? true : false,
                                active: orderBy === 'hours' ? true : false,
                                down: hoursOrder === 'desc' ? true : false,
                            }"
                        ></i>
                    </span>
                    <span class="hrs hrs-header jobsbg">
                        JOBS
                        <i
                            class="fas fa-long-arrow-alt-up"
                            @click="orderByJobName(true)"
                            :class="{
                                show: orderBy === 'jobName' ? true : false,
                                active: orderBy === 'jobName' ? true : false,
                                down: jobNameOrder === 'desc' ? true : false,
                            }"
                        ></i>
                    </span>
                </div>
                <div class="jobs-list">
                    <div
                        class="jobs-list-inner-container"
                        @mouseover="showCtrlMessage = true"
                        @mouseleave="hideCtrlMessage"
                    >
                        <shop-searcher v-model="search"></shop-searcher>
                        <labels-filter v-model="checkedLabels"></labels-filter>
                        <drag
                            v-for="(item, index) in collapsedJobs"
                            :disabled="item.isJobCollection"
                            :data="item"
                            class="item"
                            :class="{ jobCollection: item.isJobCollection }"
                            :key="index"
                            @dragstart="handleDragStart"
                            @dragend="handleDragEnd"
                        >
                            <shop-job
                                @clickOnJobCollection="clickOnJobCollection"
                                :item="item"
                                :class="{
                                    filtered:
                                        $isFiltered(item) ||
                                        $isFilteredBySearch(item) ||
                                        isFilteredByLabel(item),
                                }"
                            ></shop-job>

                            <template v-slot:drag-image>
                                <div class="item-dragging">
                                    {{ item.name | truncate(20) }}
                                </div>
                            </template>
                        </drag>
                    </div>
                </div>
            </div>
            <overlay-waiting-spinner v-if="loading"></overlay-waiting-spinner>
            <alert-modal
                v-if="alertModal"
                :alert="alertModal"
                @close="alertModal = null"
            ></alert-modal>
        </div>
        <Transition>
            <div v-if="showCtrlMessage" class="centered-message">
                <i class="far fa-lightbulb"></i>
                Press CTRL key while dragging to automatically edit the time
            </div>
        </Transition>
    </div>
</template>

<script>
import shopJob from "Components/overview/jobs/job";
import shopSearcher from "Components/overview/shop-overview-searcher";
import labelsFilter from "Components/overview/jobs/labels-filter";
import trucate from "Filters/truncate";
import { Drag } from "vue-easy-dnd";
import overviewJobs from "Mixins/overview/overview-jobs.mixin";
import alertModal from "Components/overview/alert-modal";

export default {
    name: "jobs-list",
    mixins: [overviewJobs],
    components: { shopJob, shopSearcher, Drag, labelsFilter, alertModal },
    data: data,
    created: created,
    mounted: mounted,
    methods: getMethods(),
    props: getProps(),
    watch: getWatchers(),
    filters: { trucate },
};

function getWatchers() {
    return {
        expandedJobCollection: {
            handler: collapseJobs,
        },
        jobCollections: {
            handler: collapseJobs,
            immediate: true,
        },
    };

    function collapseJobs() {
        const vm = this;
        let collapsedJobs = [];

        for (const jobCollection of vm.jobCollections) {
            if (jobCollection.job_count === 1) {
                let job = jobCollection.jobs[0];
                job.labels = jobCollection.labels;
                collapsedJobs.push(job);
                continue;
            }

            jobCollection.isJobCollection = true;
            Vue.set(jobCollection, "isExpandedJobCollection", false);
            collapsedJobs.push(jobCollection);

            if (vm.expandedJobCollection === jobCollection.id) {
                jobCollection.isExpandedJobCollection = true;
                let latestIndex = jobCollection.job_count - 1;
                jobCollection.jobs[latestIndex].isLatestJobInCollection = true;

                jobCollection.jobs.forEach((job) => {
                    job.belongsToExpandedCollection = true;
                    collapsedJobs.push(job);
                });
            }
        }
        Vue.set(vm, "collapsedJobs", collapsedJobs);

        let customOrder = vm.orderBy !== "jobName" || vm.jobNameOrder !== "asc";
        if (customOrder) {
            this.orderJobList();
        }
    }
}

function data() {
    return {
        search: "",
        jobCollections: [],
        collapsedJobs: [],
        expandedJobCollection: null,
        loading: true,
        orderBy: "jobName",
        jobNameOrder: "asc",
        hoursOrder: "desc",
        checkedLabels: [],
        alertModal: null,
        showCtrlMessage: false,
        dragStart: false,
    };
}

function created() {
    const vm = this;
    vm.$eventHub.$on("job-history-added", incrementJobProgress);
    vm.$eventHub.$on("job-history-removed", decrementJobProgress);
    vm.$eventHub.$on("job-history-updated", updateJobProgress);
    vm.$eventHub.$on(
        "machine-station-shifts-removed",
        decrementJobProgressFromMachineShifts
    );

    function incrementJobProgress(workHistory) {
        let jobCollection = findJobCollection(workHistory);
        if (!jobCollection) {
            return;
        }

        let job = jobCollection.jobs.find(
            (job) => job.id === workHistory.job.id
        );

        job.all_hours =
            parseFloat(job.all_hours) + parseFloat(workHistory.all_hours);

        setJobProgress(job);
        setJobCollectionProgress(jobCollection);
    }

    function findJobCollection(workHistory) {
        return vm.jobCollections.find(
            (jobCollection) =>
                jobCollection.id === workHistory.job.job_collection_id
        );
    }

    function setJobProgress(job) {
        job.estimated_hours = parseFloat(job.estimated_hours);
        if (job.estimated_hours <= 0) {
            return;
        }
        job.progress = Math.round((job.all_hours / job.estimated_hours) * 100);
    }

    function setJobCollectionProgress(jobCollection) {
        if (!jobCollection.isJobCollection) {
            return;
        }

        let progressPercentagePerJob = 100 / jobCollection.job_count / 100;
        let jobCollectionProgress = jobCollection.jobs.reduce((acc, job) => {
            return acc + job.progress * progressPercentagePerJob;
        }, 0);
        jobCollection.progress = jobCollectionProgress;
    }

    function decrementJobProgress(workHistory) {
        let jobCollection = findJobCollection(workHistory);
        if (!jobCollection) {
            return;
        }
        let job = jobCollection.jobs.find(
            (job) => job.id === workHistory.job.id
        );

        job.all_hours =
            parseFloat(job.all_hours) - parseFloat(workHistory.all_hours);

        setJobProgress(job);
        setJobCollectionProgress(jobCollection);
    }

    function updateJobProgress(oldWorkHistory, newWorkHistory) {
        let hoursDiff =
            parseFloat(newWorkHistory.all_hours) -
            parseFloat(oldWorkHistory.all_hours);

        if (hoursDiff === 0) {
            return;
        }

        let jobCollection = findJobCollection(oldWorkHistory);
        if (!jobCollection) {
            return;
        }

        let job = jobCollection.jobs.find(
            (job) => job.id === oldWorkHistory.job.id
        );
        job.all_hours = parseFloat(job.all_hours) + hoursDiff;
        setJobProgress(job);
        setJobCollectionProgress(jobCollection);
    }

    function decrementJobProgressFromMachineShifts(machine, stationShifts) {
        for (const stationShift of stationShifts) {
            for (const workHistory of stationShift.job_history) {
                decrementJobProgress(workHistory);
            }
        }
    }
}

function mounted() {
    const vm = this;

    Vue.backendApi().jobCollections().index().then(success, fail);

    function success(response) {
        Vue.set(vm, "jobCollections", response.data);
        vm.loading = false;
    }

    function fail(error) {
        console.log("An error occurred trying to get job collections");
        vm.loading = false;
    }
}

function getMethods() {
    return {
        handleDragEnd: handleDragEnd,
        handleDragStart: handleDragStart,
        hideCtrlMessage: hideCtrlMessage,
        checkDragEndOnMachineInDowntime: checkDragEndOnMachineInDowntime,
        isFilteredByLabel: isFilteredByLabel,
        clickOnJobCollection: clickOnJobCollection,
        orderByHours: orderByHours,
        orderByJobName: orderByJobName,
        orderJobList: orderJobList,
        orderExpandedJobList: orderExpandedJobList,
    };

    function handleDragEnd(ev) {
        this.showCtrlMessage = false;
        this.dragStart = false;
        this.checkDragEndOnMachineInDowntime(ev);
    }

    function handleDragStart() {
        this.showCtrlMessage = true;
        this.dragStart = true;
    }

    function hideCtrlMessage() {
        if (!this.dragStart) {
            this.showCtrlMessage = false;
        }
    }

    function checkDragEndOnMachineInDowntime(ev) {
        let dropElement = ev.native.target;
        if (dropElement.classList.contains("machine-in-downtime")) {
            this.alertModal = {
                title: "Action not allowed",
                message: "You cannot add work on a machine in downtime",
                type: "error",
            };
        }
    }

    function isFilteredByLabel(item) {
        const vm = this;
        if (vm.checkedLabels.length === 0) {
            return false;
        }
        if (!item.labels) {
            return true;
        }

        let jobCollectionHasLabelMatch = vm.checkedLabels.some((label) => {
            return item.labels.some((jobCollectionLabel) => {
                return label.id === jobCollectionLabel.id;
            });
        });

        if (jobCollectionHasLabelMatch) {
            return false;
        }
        return true;
    }

    function clickOnJobCollection(jobCollection) {
        this.expandedJobCollection =
            this.expandedJobCollection === jobCollection.id
                ? null
                : jobCollection.id;
    }

    function orderExpandedJobList(orderCallback) {
        const vm = this;
        let expandedCollectionIndex = vm.collapsedJobs.findIndex(
            (jobCollection) => jobCollection.id === vm.expandedJobCollection
        );
        const expandedCollection = vm.collapsedJobs[expandedCollectionIndex];
        const firstJobIndex = expandedCollectionIndex + 1;
        const jobsOfCollection = vm.collapsedJobs.splice(
            firstJobIndex,
            expandedCollection.job_count
        );

        orderCallback(vm.collapsedJobs);
        expandedCollectionIndex = vm.collapsedJobs.findIndex(
            (jobCollection) => jobCollection.id === vm.expandedJobCollection
        );
        jobsOfCollection.forEach((job) => {
            vm.collapsedJobs.splice(++expandedCollectionIndex, 0, job);
        });
    }

    function orderByHours(toggle = false) {
        this.orderBy = "hours";
        if (toggle) {
            this.hoursOrder = this.hoursOrder === "asc" ? "desc" : "asc";
        }
        let order = this.hoursOrder === "asc" ? [1, -1] : [-1, 1];

        if (this.expandedJobCollection) {
            this.orderExpandedJobList(orderByProgress);
        } else {
            orderByProgress(this.collapsedJobs);
        }

        function orderByProgress(arr) {
            arr.sort(function (a, b) {
                if (a.progress === b.progress) {
                    return 0;
                }
                if (a.progress > b.progress) {
                    return order[0];
                } else {
                    return order[1];
                }
            });
        }
    }

    function orderByJobName(toggle = false) {
        this.orderBy = "jobName";
        if (toggle) {
            this.jobNameOrder = this.jobNameOrder === "asc" ? "desc" : "asc";
        }
        let order = this.jobNameOrder === "asc" ? [1, -1] : [-1, 1];

        if (this.expandedJobCollection) {
            this.orderExpandedJobList(orderByName);
        } else {
            orderByName(this.collapsedJobs);
        }

        function orderByName(arr) {
            arr.sort(function (a, b) {
                if (a.name.toLowerCase() === b.name.toLowerCase()) {
                    return 0;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return order[0];
                } else {
                    return order[1];
                }
            });
        }
    }

    function orderJobList() {
        if (this.orderBy === "jobName") {
            this.orderByJobName();
        } else {
            this.orderByHours();
        }
    }
}

function getProps() {
    return {
        filters: Object,
    };
}
</script>

<style lang="scss">
@import "~@/_variables.scss";

.centered-message {
    position: absolute;
    z-index: 15;
    left: 0;
    right: 0;
    width: 300px;
    margin: auto;
    bottom: 20px;
    background: rgba(97, 97, 97, 0.9);
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    display: inline-block;
    padding: 5px 16px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}

.jobs-list {
    scrollbar-width: thin;
}

.extra-padding {
    padding: 5px;
}

.item-dragging {
    width: 120px;
    background-color: lightsalmon;
    padding: 2px 4px;
    border-radius: 4px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
}
.job-dropping {
    background-color: grey;
}
.job-placeholder {
    background-color: grey;
}

.jobs-list-container .hrsjobs .sortable-chosen.sortable-chosen {
    display: none;

    &.hrs-header {
        display: block;
    }
}

.employee-list div[draggable].sortable-chosen.job-list-ghost {
    background-color: $red;
    border-color: $red;
    border-style: solid;
    opacity: 0.3;
}

.job,
.job-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        white-space: normal;
    }
}

.jobs-list-container {
    margin: 0;
    overflow-y: auto;

    min-height: $listHeight;
    max-height: $listHeight;
    float: left;
    width: 280px;
    background: darken($lightGrey, 7%);
    padding: 10px;
    position: relative;
    z-index: 8;

    & .hrs {
        float: left;
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        padding: 5px 2px 2px 5px;

        &:nth-child(1) {
            width: 60px;
        }
    }

    & .jobs-list {
        margin: 0;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: $listInnerHeight;
        max-height: $listInnerHeight;
        float: left;
        width: 100%;
        max-width: 96%;

        &-inner-container {
            min-height: 830px;
        }

        & div[draggable].by-shift__job.job-list-ghost {
            background-color: yellow;
            height: 40px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 700;
            width: 163px;
            margin-left: 60px;
            padding-top: 10px;
            text-align: center;
        }
    }

    & span.hrs {
        &.hrs-header {
            padding: 14px 5px;
            width: 60px;
        }

        &.jobsbg {
            width: calc(88% - 61px);
        }

        i {
            font-size: 10px;
            opacity: 0.3;
            transition: all 0.5s;
            display: none;
            padding: 0px 3px;

            &:hover {
                opacity: 1;
            }
            &.show {
                display: inline-block;
            }
            &.down {
                transform: rotate(180deg);
            }
            &.active {
                opacity: 1;
            }
        }

        &:hover {
            i {
                display: inline-block;
            }
        }
    }

    & .jobsbg {
        background: $lightGrey;
    }
}
</style>
