<template>
    <div>
        <v-data-table
            dense
            :headers="headers"
            :items="salesOrder.detailedSalesOrderItems"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:item.name="{ item }">
                <div class="text-truncate" style="max-width: 170">
                    {{ item.name }}
                </div>
            </template>
            <template v-slot:item.start_at="{ item }">
                {{ item.start_at | date }}
            </template>
            <template v-slot:item.units="{ item }">
                {{ item.units }}
            </template>
            <template v-slot:item.cost_of_materials="{ item }">
                {{ item.cost_of_materials | currencyFormat }}
            </template>
            <template v-slot:item.cost_of_stations="{ item }">
                {{ item.cost_of_stations | currencyFormat }}
            </template>
            <template v-slot:item.total_cost="{ item }">
                {{ item.total_cost | currencyFormat }}
            </template>
            <template v-slot:item.amount="{ item }">
                {{ item.amount | currencyFormat }}
            </template>
            <template v-slot:item.profit="{ item }">
                {{ item.profit | currencyFormat }}
            </template>
            <template v-slot:item.profit_margin="{ item }">
                <div
                    class="text-center font-weight-medium text--darken-2"
                    :class="{
                        'green--text': item.profit_margin > 0,
                        'red--text': item.profit_margin < 0,
                    }"
                >
                    {{ item.profit_margin | round | percent }}
                </div>
            </template>
            <template v-slot:item.due_at="{ item }">
                {{ item.due_at | date }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    text
                    color="primary"
                    @click="activeItem = item"
                    :height="24"
                >
                    <v-icon>mdi-file-search-outline</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog
            v-model="showJobsOfItem"
            scrollable
            max-width="900px"
            @click:outside="closeSaleOrderItemJobs"
            @keydown.esc="closeSaleOrderItemJobs"
            v-if="activeItem"
        >
            <v-card>
                <v-card-title>{{ activeItem.name }} - Jobs</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 350px">
                    <v-data-table
                        dense
                        :headers="jobHeaders"
                        :items="salesOrderItemJobs"
                        disable-pagination
                        hide-default-footer
                        :loading="loading"
                    >
                        <template v-slot:item.name="{ item }">
                            <router-link
                                class="grey--text text--darken-4"
                                :to="`/app/sales-order-reports/job-cost-calculations/${item.id}`"
                                >{{ item.name }}</router-link
                            >
                        </template>
                        <template v-slot:item.cost_of_materials="{ item }">
                            {{
                                item.cost_of_materials | round | currencyFormat
                            }}
                        </template>
                        <template v-slot:item.all_hours="{ item }">
                            {{ item.all_hours | round }}
                        </template>
                        <template v-slot:item.cost_of_stations="{ item }">
                            {{ item.cost_of_stations | round | currencyFormat }}
                        </template>
                        <template v-slot:item.total_cost="{ item }">
                            {{
                                (parseInt(item.cost_of_materials) +
                                    parseInt(item.cost_of_stations))
                                    | round
                                    | currencyFormat
                            }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                                :disabled="$denies('read-job')"
                                title="View"
                                text
                                small
                                :to="`/app/sales-order-reports/job-cost-calculations/${item.id}`"
                            >
                                <v-icon>mdi-file-search-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="closeSaleOrderItemJobs"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import currencyFormat from "Filters/currencyFormat";
import round from "Filters/round";
import percent from "Filters/percent";
import date from "Filters/shortDate";
import permissions from "Mixins/permissions.mixin";

export default {
    mixins: [permissions],
    data: data,
    mounted: mounted,
    computed: getComputed(),
    methods: getMethods(),
    watch: getWatchers(),
    props: {
        salesOrder: Object,
    },
    filters: { currencyFormat, round, percent, date },
};

function mounted() {
    const vm = this;

    if (this.orderItemId) {
        this.activeItem = this.salesOrder.detailedSalesOrderItems.find(
            (x) => x.id === parseInt(this.orderItemId)
        );
    }

    window.onpopstate = function (event) {
        vm.activeItem = false;
    };
}

function data() {
    return {
        activeItem: null,
        orderItemId: this.$route.query.orderItemId,
        headers: [
            {
                text: "Item",
                align: "start",
                sortable: true,
                value: "name",
                class: "grey--text text--darken-3",
                width: 170,
            },
            {
                text: "Start at",
                align: "center",
                sortable: true,
                value: "start_at",
                class: "grey--text text--darken-3",
                width: 130,
            },
            {
                text: "Units",
                align: "center",
                sortable: true,
                value: "units",
                class: "grey--text text--darken-3",
                width: 105,
            },
            {
                text: "Costs of materials",
                align: "center",
                sortable: true,
                value: "cost_of_materials",
                class: "grey--text text--darken-3",
                width: 120,
            },
            {
                text: "Costs of stations",
                align: "center",
                sortable: true,
                value: "cost_of_stations",
                class: "grey--text text--darken-3",
                width: 150,
            },
            {
                text: "Total cost",
                align: "center",
                sortable: true,
                value: "total_cost",
                class: "grey--text text--darken-3",
                width: 150,
            },
            {
                text: "Item amount",
                align: "center",
                sortable: true,
                value: "amount",
                class: "grey--text text--darken-3",
                width: 140,
            },
            {
                text: "Profit",
                align: "center",
                sortable: true,
                value: "profit",
                class: "grey--text text--darken-3",
                width: 150,
            },
            {
                text: "Profit margin",
                align: "center",
                sortable: true,
                value: "profit_margin",
                class: "grey--text text--darken-3",
                width: 122,
            },
            {
                text: "Due at",
                align: "center",
                sortable: true,
                value: "due_at",
                width: 122,
                class: "grey--text text--darken-3",
            },
            {
                text: "",
                align: "center",
                sortable: false,
                value: "actions",
                width: 122,
                class: "grey--text text--darken-3",
            },
        ],
        jobHeaders: [
            {
                text: "Name",
                align: "start",
                sortable: true,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "PO item number",
                align: "center",
                sortable: true,
                value: "serial_number",
                class: "grey--text text--darken-3",
            },
            {
                text: "Material cost",
                align: "center",
                sortable: true,
                value: "cost_of_materials",
                class: "grey--text text--darken-3",
            },
            {
                text: "Station hours",
                align: "center",
                sortable: true,
                value: "all_hours",
                class: "grey--text text--darken-3",
            },
            {
                text: "Station cost",
                align: "center",
                sortable: true,
                value: "cost_of_stations",
                class: "grey--text text--darken-3",
            },
            {
                text: "Total cost",
                align: "center",
                sortable: true,
                value: "total_cost",
                class: "grey--text text--darken-3",
            },
            {
                text: "",
                align: "center",
                sortable: false,
                value: "actions",
                class: "grey--text text--darken-3",
            },
        ],
        salesOrderItemJobs: [],
        loading: false,
    };
}

function getComputed() {
    return {
        showJobsOfItem: showJobsOfItem,
    };

    function showJobsOfItem() {
        return this.activeItem ? true : false;
    }
}

function getWatchers() {
    return {
        activeItem(item) {
            if (item) {
                this.$router.push({
                    name: this.routeName,
                    query: { ...this.$route.query, orderItemId: item.id },
                    params: this.$route.params,
                });
                this.getSalesOrderItemReport(item);
            }
        },
    };
}

function getMethods() {
    return {
        getSalesOrderItemReport: getSalesOrderItemReport,
        closeSaleOrderItemJobs: closeSaleOrderItemJobs,
    };

    function getSalesOrderItemReport(item) {
        const vm = this;
        vm.loading = true;

        Vue.backendApi()
            .reports()
            .salesOrderItems(item.id)
            .index()
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "salesOrderItemJobs", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get sales item report");
        }
    }

    function closeSaleOrderItemJobs() {
        this.orderItemId = null;
        this.activeItem = null;
        this.salesOrderItemJobs = [];
        const { orderItemId, ...queryItems } = this.$route.query;
        this.$router.push({
            name: this.routeName,
            query: queryItems,
            params: this.$route.params,
        });
    }
}
</script>
