<template>
    <div>
        <v-dialog
            persistent
            v-model="showMaterialEditor"
            @click:outside="confirmClose"
            @keydown.esc="confirmClose"
            max-width="500px"
        >
            <v-card>
                <v-toolbar color="primary" dark flat>
                    <span class="headline"> Material </span>

                    <template v-slot:extension>
                        <v-tabs v-model="currentTab" centered>
                            <v-tab v-for="tab in tabs" :key="tab">
                                {{ tab }}
                            </v-tab>
                        </v-tabs>
                    </template>
                </v-toolbar>

                <v-tabs-items v-model="currentTab">
                    <v-tab-item>
                        <v-card-text>
                            <v-form ref="editJobMaterialForm">
                                <v-container>
                                    <v-row>
                                        <v-col cols="8" sm="8" md="8">
                                            <v-text-field
                                                v-model="editedMaterial.name"
                                                label="Name"
                                                :rules="[rules.required]"
                                                @input="isDirty = true"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4" md="4">
                                            <v-select
                                                label="Unit name"
                                                @input="isDirty = true"
                                                :rules="[rules.required]"
                                                v-model="editedMaterial.unit_id"
                                                :items="units"
                                                item-text="name"
                                                item-value="id"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="4" sm="4" md="4">
                                            <v-text-field
                                                v-model="
                                                    editedMaterial.quantity
                                                "
                                                label="Quantity"
                                                type="number"
                                                min="1"
                                                :rules="[
                                                    rules.required,
                                                    rules.greaterThan(0),
                                                ]"
                                                @input="isDirty = true"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4" md="4">
                                            <v-text-field
                                                v-model="editedMaterial.cost"
                                                label="Cost"
                                                :rules="[
                                                    rules.required,
                                                    rules.price,
                                                ]"
                                                @input="isDirty = true"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" sm="4" md="4">
                                            <v-text-field
                                                v-model="editedMaterialTotal"
                                                label="Total"
                                                @input="isDirty = true"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <serial-numbers-editor
                                        :serialNumbers="
                                            serialNumbersOfEditedMaterial
                                        "
                                        :editedMaterial="editedMaterial"
                                    >
                                    </serial-numbers-editor>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn :disabled="!isDirty" @click="undoChanges"
                                >Undo</v-btn
                            >
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-tab-item>

                    <v-tab-item>
                        <v-card-text>
                            <v-form ref="jobMaterialFromInventoryForm">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-autocomplete
                                                v-model="
                                                    editedMaterialInventory
                                                "
                                                :items="inventoryItems"
                                                item-text="name"
                                                item-value="id"
                                                label="Inventory item"
                                                return-object
                                                clearable
                                                chips
                                                :search-input.sync="search"
                                                :rules="[rules.required]"
                                                :loading="loadingInventory"
                                                :no-data-text="
                                                    noInventoryDataMessage
                                                "
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="editedMaterialInventory">
                                        <v-col cols="12" class="text-left">
                                            <serial-numbers-editor
                                                :serialNumbers="
                                                    editedMaterialInventory.serial_numbers
                                                "
                                                :editedMaterial="newMaterialFromInventory"
                                            >
                                            </serial-numbers-editor>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="3">
                                            <v-text-field
                                                v-model="
                                                    newMaterialFromInventory.quantity
                                                "
                                                label="Quantity"
                                                type="number"
                                                min="1"
                                                :max="
                                                    editedMaterialInventory.quantity
                                                "
                                                persistent-hint
                                                :hint="availabilityMessage"
                                                :rules="[
                                                    rules.required,
                                                    rules.min1,
                                                    rules.max(
                                                        editedMaterialInventory.quantity
                                                    ),
                                                ]"
                                                @input="isDirty = true"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="3">
                                            <v-text-field
                                                v-model="
                                                    newMaterialFromInventory
                                                        .unit.name
                                                "
                                                label="Unit name"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="3">
                                            <v-text-field
                                                v-model="
                                                    newMaterialFromInventory.cost
                                                "
                                                label="Cost"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="3">
                                            <v-text-field
                                                v-model="
                                                    editedMaterialInventoryTotal
                                                "
                                                label="Total"
                                                readonly
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn
                                color="primary"
                                @click="saveMaterialFromInventory"
                                >Save</v-btn
                            >
                        </v-card-actions>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>

        <v-progress-circular
            v-if="loading"
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            transition="fade-transition"
            class="mb-6"
        ></v-progress-circular>

        <div v-else class="text-right">
            <div class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mb-3"
                    color="primary"
                    x-small
                    @click="() => (showMaterialEditor = true)"
                >
                    <v-icon x-small class="mr-1">mdi-plus</v-icon>
                    Add material
                </v-btn>
            </div>

            <material-list
                :materials="materials"
                @edit="editMaterial"
                @destroy="(material) => $emit('destroy', material)"
            ></material-list>
        </div>
    </div>
</template>

<script>
import round from "Filters/round";
import materialList from "Components/jobs/material-list";
import serialNumbersEditor from "Components/jobs/serial-numbers-editor";

export default {
    components: { materialList, serialNumbersEditor },
    data: data,
    created: created,
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods(),
    props: {
        materials: Array,
        from: {
            type: String,
            default: "collection",
        },
        loading: Boolean,
        closeEditor: Boolean,
    },
    filters: { round },
};

function data() {
    return {
        currentTab: 0,
        showMaterialEditor: false,
        isDirty: false,
        editedIndex: -1,
        units: [],
        editedMaterial: {
            id: "new-material-id",
            name: "",
            quantity: 1,
            unit_id: null,
            cost: "",
            serial_numbers: [],
        },
        editedMaterialSerialNumbers: [],
        defaultMaterial: {
            id: "new-material-id",
            name: "",
            quantity: 1,
            unit_id: null,
            cost: "",
            serial_numbers: [],
        },
        search: "",
        debounce: null,
        cancelTokenSource: null,
        loadingInventory: false,
        inventoryItems: [],
        editedMaterialInventory: null,
        newMaterialFromInventory: null,
        previousMaterial: { ...this.defaultMaterial },
        rules: {
            required: (value) => {
                if (value === undefined || value === null || value === "") {
                    return "Required.";
                }
                return true;
            },
            greaterThan(min) {
                return (value) => {
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }
                    return (
                        parseFloat(value) > parseFloat(min) ||
                        "It should be greater than " + min
                    );
                };
            },
            min1: (value) => value >= 1 || "Min 1",
            max(max) {
                return (value) => {
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }
                    return parseFloat(value) <= parseFloat(max) || "Max " + max;
                };
            },
            price: (value) => {
                const pattern = /^[0-9]{1,9}(\.[0-9]{1,2})?$/;
                return pattern.test(value) || "Invalid format.";
            },
        },
    };
}

function getWatchers() {
    return {
        showMaterialEditor: {
            handler: dialog,
        },
        closeEditor: {
            handler: closeEditorFromParent,
        },
        editedMaterialInventory: {
            handler: computeMaterialFromInventory,
        },
        search: {
            handler: searchSuggestions,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function closeEditorFromParent() {
        if (this.closeEditor) {
            this.close();
            this.$emit("editor-closed");
        }
    }

    function computeMaterialFromInventory() {
        if (!this.editedMaterialInventory) {
            this.newMaterialFromInventory = null;
            return;
        }

        this.newMaterialFromInventory = {
            id: "new-material-id",
            inventory_item_id: this.editedMaterialInventory.id,
            name: this.editedMaterialInventory.name,
            quantity: 1,
            unit: this.editedMaterialInventory.unit,
            unit_id: this.editedMaterialInventory.unit_id,
            cost: this.editedMaterialInventory.cost,
            serial_numbers: [],
        };
    }

    function searchSuggestions() {
        const vm = this;
        clearTimeout(vm.debounce);
        this.debounce = setTimeout(function () {
            if (vm.search && vm.search.length > 0) {
                vm.getInventoryItems();
            }
        }, 250);
    }
}

function getComputed() {
    return {
        tabs: tabs,
        editedMaterialTotal: editedMaterialTotal,
        editedMaterialInventoryTotal: editedMaterialInventoryTotal,
        noInventoryDataMessage: noInventoryDataMessage,
        availabilityMessage: availabilityMessage,
        serialNumbersOfEditedMaterial: serialNumbersOfEditedMaterial,
    };

    function tabs() {
        let tabs =
            this.editedIndex === -1 ? ["New", "From inventory"] : ["Edit"];
        return tabs;
    }

    function editedMaterialTotal() {
        if (this.editedMaterial.quantity > 0 && this.editedMaterial.cost >= 0) {
            return round(
                this.editedMaterial.quantity * this.editedMaterial.cost,
                2
            );
        }
        return 0;
    }

    function editedMaterialInventoryTotal() {
        if (!this.newMaterialFromInventory) {
            return "";
        }
        return round(
            this.newMaterialFromInventory.quantity *
                this.editedMaterialInventory.cost,
            2
        );
    }

    function noInventoryDataMessage() {
        if (this.search && this.search.length > 0) {
            return "No data available";
        } else {
            return "Start to write inventory item name...";
        }
    }

    function availabilityMessage() {
        let totalAvailability = this.editedMaterialInventory.quantity;
        let usedQuantity = this.newMaterialFromInventory.quantity;
        let availability = totalAvailability - usedQuantity;
        let word = availability === 1 ? "available" : "availables";
        return `${availability} ${word}`;
    }

    function serialNumbersOfEditedMaterial() {
        if (!this.editedMaterial.inventory_item) {
            return [];
        }

        return [
            ...this.editedMaterialSerialNumbers,
            ...this.editedMaterial.inventory_item.serial_numbers,
        ];
    }
}

function getMethods() {
    return {
        editMaterial: editMaterial,
        close: close,
        confirmClose: confirmClose,
        undoChanges: undoChanges,
        save: save,
        getInventoryItems: getInventoryItems,
        saveMaterialFromInventory: saveMaterialFromInventory,
        getUnits: getUnits,
    };

    function editMaterial(material) {
        this.editedIndex = this.materials.indexOf(material);
        this.editedMaterialSerialNumbers = [...material.serial_numbers];
        this.editedMaterial = Object.assign({}, material);
        this.previousMaterial = Object.assign({}, material);
        this.showMaterialEditor = true;
    }

    function close() {
        this.inventoryItems = [];
        this.showMaterialEditor = false;
        this.currentTab = 0;
        this.editedMaterialInventory = null;
        this.$nextTick(() => {
            this.editedMaterial = Object.assign({}, this.defaultMaterial);
            this.previousMaterial = Object.assign({}, this.defaultMaterial);
            this.editedIndex = -1;
        });
        this.isDirty = false;

        if (this.$refs.editJobMaterialForm) {
            this.$refs.editJobMaterialForm.resetValidation();
        }
        if (this.$refs.jobMaterialFromInventoryForm) {
            this.$refs.jobMaterialFromInventoryForm.resetValidation();
        }
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function undoChanges() {
        this.isDirty = false;
        this.editedMaterial = Object.assign({}, this.previousMaterial);
    }

    function save() {
        const vm = this;
        vm.isDirty = false;

        if (this.$refs.editJobMaterialForm.validate()) {
            vm.$emit("save", vm.editedMaterial, vm.editedIndex);
        }
    }

    function getInventoryItems() {
        const vm = this;
        vm.loadingInventory = true;

        if (vm.cancelTokenSource) {
            vm.cancelTokenSource.cancel();
        }
        vm.cancelTokenSource = axios.CancelToken.source();

        Vue.backendApi()
            .inventory()
            .search({ searchQuery: this.search }, vm.cancelTokenSource.token)
            .then(success, fail);

        function success(response) {
            Vue.set(vm, "inventoryItems", response.data);
            vm.loadingInventory = false;
        }

        function fail(error) {
            vm.loadingInventory = false;
            if (!axios.isCancel(error)) {
                console.log("An error occurred trying to get inventory items");
            }
        }
    }

    function saveMaterialFromInventory() {
        const vm = this;

        if (this.$refs.jobMaterialFromInventoryForm.validate()) {
            vm.$emit(
                "saveFromInventory",
                vm.newMaterialFromInventory,
                vm.editedMaterialInventory
            );

            let addedSerialNumberIds =
                vm.newMaterialFromInventory.serial_numbers.map(
                    (serialNumber) => serialNumber.id
                );

            vm.editedMaterialInventory.serial_numbers =
                vm.editedMaterialInventory.serial_numbers.filter(
                    (serialNumber) =>
                        !addedSerialNumberIds.includes(serialNumber.id)
                );
        }
    }

    function getUnits() {
        const vm = this;

        Vue.backendApi().units().index().then(success, fail);

        function success(response) {
            Vue.set(vm, "units", response.data);
        }

        function fail(error) {
            console.log("An error occurred trying to get units");
        }
    }
}

function created() {
    this.getUnits();
}
</script>

<style lang="scss" scoped>
::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep .white-space-nowrap {
    white-space: nowrap;
}

.actions-column {
    min-width: 40px;
}

.type-info {
    line-height: 19px;
}
</style>
