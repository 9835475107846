<template>
    <div
        v-custom-click-outside="clickOutside"
        class="machine-container machine-top border border-radius"
        :class="{ machineInactive: !$isActive }"
    >
        <machine-modal
            v-if="showMachineModal"
            @close="showMachineModal = false"
            :machine="machine"
            :preferences="preferences"
            :station-shifts="stationShifts"
            :machineDowntimes="machineDowntimes"
            :current-machine-downtime="currentMachineDowntime"
            :has-downtime="hasDowntime"
        ></machine-modal>

        <div v-if="showStationModal" class="station-modal">
            <div>
                <input
                    type="text"
                    v-model="myStation.name"
                    @change="isDirty = true"
                />
                <i class="fas fa-times" @click="cancelStationChanges"></i>
            </div>
            <div>
                <select v-model="myStation.schedule" @change="isDirty = true">
                    <option :value="null" disabled>Select Schedule</option>
                    <option
                        v-for="schedule in availableSchedules"
                        :key="schedule.id"
                        :value="schedule"
                    >
                        {{ schedule.name }}
                    </option>
                </select>
            </div>
            <div>
                <input type="button" value="Save" @click="saveStationChanges" />
            </div>
            <div v-if="stationShifts.length > 0">
                <h6>Today's Shifts</h6>
                <div
                    v-for="shift in stationShifts"
                    :key="shift.id"
                    :class="'shift-id-' + shift.id"
                >
                    {{ shift.started_at | moment("HH:mm") }} -
                    {{ shift.ended_at | moment("HH:mm") }}
                    <span
                        v-if="
                            checkIfDifferentDay(
                                shift.started_at,
                                shift.ended_at
                            )
                        "
                    >
                        +{{
                            checkIfDifferentDay(
                                shift.started_at,
                                shift.ended_at
                            )
                        }}
                        Day
                    </span>
                </div>
            </div>
            <div v-if="!isDirty" class="shift-buttons">
                <input
                    v-if="stationShifts.length === 0 && myStation.schedule"
                    type="button"
                    value="Create Shifts"
                    @click="createStationShifts"
                />
                <input
                    v-if="stationShifts.length > 0"
                    type="button"
                    value="Destroy Shifts"
                    @click="destroyStationShifts"
                    class="destroy-shifts"
                />
            </div>

            <hr class="mb-2" />
            <div v-if="hasDowntime" class="machine-unavailable">
                <input
                    type="button"
                    @click="showMachineDowntimesModal = true"
                    value="Show Machine Downtime"
                />
            </div>
            <div v-if="!currentMachineDowntime" class="text-center">
                <h6>Unavailable</h6>
                <input
                    type="button"
                    value="Make Unavailable"
                    @click="showMachineUnavailableModal = true"
                />
            </div>
            <unavailable-modal
                v-if="showMachineUnavailableModal"
                :machine="machine"
                :current-machine-downtime="currentMachineDowntime"
                @close="showMachineUnavailableModal = false"
            >
            </unavailable-modal>
        </div>
        <div class="machine">
            <div
                class="machine-expand-button"
                @click="showMachineModal = true"
            >
                <i class="fas fa-expand"></i>
            </div>
            <div
                class="machine__name transition-opacity nowrap-ellipsis"
                :class="preferences.background"
            >
                <a href="#" @click="handleClickedOnMachineName">
                    <i v-if="hasDowntime" class="fas fa-wrench"></i>
                    {{ myStation.name }}
                </a>
            </div>
            <machine-downtimes-modal
                v-if="showMachineDowntimesModal"
                :machine="machine"
                :machine-downtimes="machineDowntimes"
                :current-machine-downtime="currentMachineDowntime"
                @close="showMachineDowntimesModal = false"
                @makeAvailable="showMachineUnavailableModal = true"
            >
            </machine-downtimes-modal>
        </div>
        <div v-if="stationShifts.length > 0" class="position-relative">
            <div
                v-if="currentMachineDowntime"
                class="machine-in-downtime"
            ></div>

            <div
                v-for="shift in stationShifts"
                v-on:mouseover="showAllMouseOver(shift)"
                v-on:mouseleave="showAllMouseLeave(shift)"
                :key="shift.id"
                class="shift-container"
                :class="{
                    'evening-shift': shift.isEveningShift,
                    'morning-shift': shift.isMorningShift,
                    selectedShift: isShiftSelected(shift),
                }"
            >
                <div
                    class="shift-lists"
                    :class="[
                        showMachineJobModal ? 'show-machine-job-modal' : '',
                    ]"
                >
                    <machine-shift-job-list
                        :shift="shift"
                        :machine="myStation"
                        :machineDowntimes="machineDowntimes"
                        :current-machine-downtime="currentMachineDowntime"
                        :has-downtime="hasDowntime"
                        @showModal="showModal"
                    >
                    </machine-shift-job-list>
                    <machine-shift-employee-list
                        :shift="shift"
                        :machine="myStation"
                        :current-machine-downtime="currentMachineDowntime"
                    >
                    </machine-shift-employee-list>
                </div>
                <div class="show-more" v-if="hasManyJobsAndUsers(shift)">
                    <i class="fas fa-chevron-down"></i>
                </div>
            </div>
        </div>
        <div v-else class="station-shift-placeholder position-relative">
            <div
                v-if="currentMachineDowntime"
                class="machine-in-downtime"
            ></div>
        </div>
    </div>
</template>

<script>
import truncate from "Filters/truncate";
import machineShiftJobList from "Components/overview/building/machine/shift/jobs/machine-shift-job-list";
import machineShiftEmployeeList from "Components/overview/building/machine/shift/employees/machine-shift-employee-list";
import unavailableModal from "Components/overview/building/machine/unavailable-modal";
import machineDowntimesModal from "Components/overview/building/machine/machine-downtimes-modal";
import overviewMachine from "Mixins/overview/overview-machine.mixin";
import machineModal from "Components/overview/building/machine/machine-modal/machine-modal";

export default {
    name: "machine",
    mixins: [overviewMachine],
    data: data,
    components: {
        machineShiftJobList,
        machineShiftEmployeeList,
        unavailableModal,
        machineDowntimesModal,
        machineModal,
    },
    computed: getComputed(),
    mounted: mounted,
    created: onCreate,
    methods: getMethods(),
    props: {
        preferences: Object,
        machine: Object,
        filters: Object,
        stationShifts: Array,
    },
    filters: getFilters(),
};

function onCreate() {
    const vm = this;
    vm.$eventHub.$on("onSelectEmployee", (payload) => {
        vm.selectedEmployee = payload;
    });

    vm.$eventHub.$on("onSelectJob", (payload) => {
        vm.selectedJob = payload;
    });
}

function data() {
    return {
        unavailableReason: null,
        showStationModal: false,
        selectedEmployee: {},
        selectedJob: {},
        availableSchedules: [],
        isDirty: false,
        myStation: {},
        showMachineJobModal: false,
        modalActivator: "",
        showMachineUnavailableModal: false,
        showMachineDowntimesModal: false,
        showMachineModal: false,
    };
}

function getFilters() {
    return {
        firstItemName: firstItemName,
        truncate: truncate,
    };

    function firstItemName(item) {
        if (item.length > 0) {
            return item[0].name;
        } else {
            return "";
        }
    }
}

function getMethods() {
    return {
        getChildPayload: getChildPayload,
        onDrop: onDrop,
        isShiftSelected: isShiftSelected,
        handleClickedOnMachineName: handleClickedOnMachineName,
        clickOutside: clickOutside,
        saveStationChanges: saveStationChanges,
        cancelStationChanges: cancelStationChanges,
        createStationShifts: createStationShifts,
        destroyStationShifts: destroyStationShifts,
        checkIfDifferentDay: checkIfDifferentDay,
        hasManyJobsAndUsers: hasManyJobsAndUsers,
        showAllMouseOver: showAllMouseOver,
        showAllMouseLeave: showAllMouseLeave,
        showModal: showModal,
    };

    function checkIfDifferentDay(shiftStart, shiftEnd) {
        let shiftStartDay = Vue.moment(
            Vue.moment(shiftStart).format("YYYY-MM-DD")
        );
        let shiftEndDay = Vue.moment(Vue.moment(shiftEnd).format("YYYY-MM-DD"));
        let diffDays = shiftEndDay.diff(shiftStartDay, "days");

        if (!diffDays) {
            return 0;
        } else {
            return diffDays;
        }
    }

    function isShiftSelected(shift) {
        const vm = this;
        let isJobSelected = shift.job_history.some(
            (jobHistory) => jobHistory.job.name == vm.selectedJob.name
        );
        if (isJobSelected) {
            return true;
        }

        let isEmployeeSelected = shift.user_history.some(
            (userHistory) => userHistory.user.id == vm.selectedEmployee.id
        );
        if (isEmployeeSelected) {
            return true;
        }

        return false;
    }

    function showAllMouseOver(shift) {
        let isHasManyJobsAndUsers = this.hasManyJobsAndUsers(shift);

        if (isHasManyJobsAndUsers) {
            this.$eventHub.$emit("showAllInShift", shift);
        }
    }

    function showAllMouseLeave(shift) {
        this.$eventHub.$emit("showOneInShift", shift);
    }

    function clickOutside(e) {
        if (
            this.showMachineUnavailableModal ||
            this.showMachineDowntimesModal
        ) {
            return;
        }
        this.showStationModal = false;
    }

    function getChildPayload() {}

    function onDrop() {}

    function handleClickedOnMachineName() {
        const vm = this;

        vm.showStationModal = !vm.showStationModal;
        if (this.showStationModal) {
            Vue.backendApi().schedules().index().then(success, failure);

            function success(response) {
                let schedule = vm.machine.schedule;

                if (schedule) {
                    let scheduleIndex = response.data.findIndex((item) => {
                        return item.id === schedule.id;
                    });

                    if (scheduleIndex === -1) {
                        response.data.push(schedule);
                    }
                }

                Vue.set(vm, "availableSchedules", response.data);

                const stationModal = vm.$el.querySelector(".station-modal");
                const machinePosition = vm.$el.getBoundingClientRect().top + 3;
                const finalPosition = machinePosition + window.scrollY + "px";

                stationModal.style.top = finalPosition;
                stationModal.style.display = "block";
            }

            function failure(error) {
                console.log("an error occurred");
            }
        }
    }

    function saveStationChanges() {
        const vm = this;
        Vue.backendApi()
            .buildings(vm.myStation.building_id)
            .stations(vm.myStation.id)
            .store(vm.myStation)
            .then(success, fail);
        function success() {
            vm.showStationModal = false;
            vm.isDirty = false;
        }
        function fail(error) {
            console.log("an error occurred");
        }
    }

    function createStationShifts() {
        const vm = this;
        const payload = {
            date: `${vm.$route.params.year}-${vm.$route.params.month}-${vm.$route.params.day}`,
        };
        Vue.backendApi()
            .buildings(vm.myStation.building_id)
            .stations(vm.myStation.id)
            .createStationShifts(payload)
            .then(success, fail);

        function success(response) {
            Vue.set(vm, "stationShifts", response.data);
            vm.$eventHub.$emit(
                "machine-station-shifts-created",
                vm.stationShifts
            );
            vm.showStationModal = false;
        }

        function fail() {
            console.log("an error occurred");
        }
    }

    function destroyStationShifts() {
        const vm = this;
        const payload = {
            date: `${vm.$route.params.year}-${vm.$route.params.month}-${vm.$route.params.day}`,
        };

        Vue.backendApi()
            .buildings(vm.myStation.building_id)
            .stations(vm.myStation.id)
            .destroyStationShifts(payload)
            .then(success, fail);

        function success(response) {
            Vue.set(vm, "stationShifts", response.data);
            vm.$eventHub.$emit(
                "machine-station-shifts-removed",
                vm.machine,
                vm.stationShifts
            );
            vm.showStationModal = false;
        }

        function fail() {
            console.log("an error occurred");
        }
    }

    function cancelStationChanges() {
        const vm = this;
        vm.isDirty = false;
        vm.showStationModal = false;
    }

    function hasManyJobsAndUsers(shift) {
        let jobIds = {};
        let numberJobs = 0;
        for (const jobHistory of shift.job_history) {
            if (!jobIds[jobHistory.job_id]) {
                jobIds[jobHistory.job_id] = true;
                numberJobs++;
            }
        }

        let numberUsers = shift.user_history.length;
        return numberJobs > 1 || numberUsers > 1;
    }

    function showModal(val, activator) {
        const vm = this;
        if (val === false && vm.modalActivator !== activator) {
            return;
        }

        vm.showMachineJobModal = val;
        vm.modalActivator = activator;
    }
}

function getComputed() {
    var vm = this;
    return {
        machineDowntimes: machineDowntimes,
        hasDowntime: hasDowntime,
        currentMachineDowntime: currentMachineDowntime,
    };

    function machineDowntimes() {
        const vm = this;
        return vm.$store.state.machineDowntimes[vm.machine.id]
            ? vm.$store.state.machineDowntimes[vm.machine.id]
            : [];
    }

    function hasDowntime() {
        return this.machineDowntimes.length > 0;
    }

    function currentMachineDowntime() {
        if (!this.hasDowntime) {
            return null;
        }

        let currentMachineDowntime = this.machineDowntimes[0];
        if (!currentMachineDowntime.ended_at) {
            return currentMachineDowntime;
        }

        let currentDate = Vue.moment(
            `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day}`,
            "YYYY-MM-DD"
        );
        let machineDowntimeEnd = Vue.moment(
            currentMachineDowntime.ended_at.split(" ")[0]
        );
        if (currentDate.isSame(machineDowntimeEnd)) {
            return null;
        }
        return currentMachineDowntime;
    }
}

function mounted() {
    const vm = this;
    Vue.set(vm, "myStation", vm.machine);
}
</script>

<style scoped lang="scss">
@import "~@/_variables.scss";

$morningColor: floralwhite;
$eveningColor: #e9ecef;
$selectedColor: #90ee90;

input,
select,
button {
    border-style: revert;
}

input[type="text"] {
    background-color: revert;
    border-radius: revert;
    padding: 1px;
}

select {
    background-color: revert;
    border-radius: revert;
    -moz-appearance: revert;
    -webkit-appearance: revert;
}

.machine-in-downtime {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.station-modal {
    position: absolute;
    min-width: 200px;
    margin-top: 39px;
    margin-left: -2px;
    padding: 5px;
    background-color: lightgray;
    border-width: 0 2px 2px 2px;
    border-style: solid;
    border-color: black;
    border-radius: 0 5px 5px 5px;
    z-index: 8;
    display: none;

    & div {
        padding: 3px;
        margin: 3px;
    }

    div.machine-unavailable {
        text-align: center;
        padding: 5px;

        input[type="button"] {
            background-color: white;
            padding: 5px 5px;
            width: auto;
            height: auto;
            font-weight: bold;
            color: #474747;
        }
    }

    .v-application {
        margin: 0px;
        padding: 0px;
    }

    & input[type="button"],
    .button {
        float: initial;
    }

    & div.shift-buttons {
        margin-top: 5px;
    }

    & input.destroy-shifts {
        border-color: red;
        color: red;
    }

    & .fas.fa-times {
        border-radius: 50%;
        right: 4px;
        padding: 4px;
        position: absolute;
        background-color: burlywood;
    }
}

.station-shift-placeholder {
    min-height: 109px;
    background-color: #a8a9ad;
}

div.machine-container {
    border-radius: 5px;
    &.machineInactive {
        display: none;
    }

    & .smooth-dnd-container {
        min-height: 21px;
    }

    & .shift-container:nth-child(even) {
        background-color: $morningColor;
        min-height: 53px;
    }

    & .shift-container:nth-child(odd) {
        background-color: $eveningColor;
        min-height: 53px;
    }

    & .shift-container:not(:last-child) {
        margin-bottom: 3px;
    }

    & .shift-container.morning-shift {
        background-color: $morningColor;
    }

    & .shift-container.evening-shift {
        background-color: $eveningColor;
    }

    & .shift-container.selectedShift {
        background-color: $selectedColor;
    }
}

.machine {
    width: 100%;
    background: #a8a9ad;
    padding: 5px 5px 5px;
    border-bottom: 2px solid #000;
    position: relative;

    .machine-expand-button {
        display: none;
        background-color: #a8a9ad;
        position: absolute;
        right: -10px;
        top: -4px;
        border-radius: 50%;
        padding: 2px 4px;
        transition: all 0.5s;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
        cursor: pointer;
        border: 1px solid #231f20;
    }

    &:hover > .machine-expand-button {
        display: block;
    }

    & a {
        color: #212529;
    }

    &__name {
        width: 100%;
        text-align: center;
        padding: 4px 5px 6px 5px;
        font-weight: 700;
        border-radius: 0 20px;
        border: 1px solid #fff;
        box-shadow: 1px 1px 2px #000;
    }

    &__job-description {
        width: 100%;
        text-align: center;
        padding: 3px 10px;
        font-weight: 700;
        border-bottom: 2px solid #000;
    }

    &-top {
        margin: 0 1% 10px;
        max-width: 128px;
        min-width: 128px;
        width: 128px;
    }
}
</style>
<style scoped lang="scss">
@import "~@/_variables.scss";

div[draggable].employee-machine-ghost {
    & .list-number {
        display: none;
    }

    & .list-name {
        font-size: 10px;
        color: black;
        width: 100%;
        margin: 0;
        line-height: 15px;
        padding: 3px 10px 3px 3px;
        font-weight: 700;
        text-align: right;
    }

    & .fas.fa-pencil-alt {
        display: none;
    }

    .morning-shift & .list-name {
        background-color: $morningGhost;
    }

    .evening-shift & .list-name {
        background-color: $eveningGhost;
        color: white;
    }
}

div[draggable].job-machine-ghost {
    width: 100%;
    & .row-job {
        max-width: 100%;
    }

    & .hrs {
        display: none;
    }

    & .blocker-text {
        display: none;
    }

    & .job-text {
        width: 100%;
        margin-right: 0;
        font-weight: 700;
        line-height: 13px;
        font-size: 12px;
        min-height: 0;
        padding: 6px;
    }

    .morning-shift & .job-text {
        background-color: #fffe0b;
        color: black;
    }

    .evening-shift & .job-text {
        background-color: darkblue;
        color: white;
    }
}

.shift-lists {
    max-height: 43px;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.show-more {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.03);
    transition: background 1s ease-in-out;
    font-size: 8px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shift-container:hover {
    .shift-lists {
        max-height: 765px;
        transition: max-height 1s ease-in-out;
    }

    .show-more {
        color: transparent;
        background-color: rgba(0, 0, 0, 0);
    }
}

.shift-lists.show-machine-job-modal {
    max-height: 765px;
    transition: max-height 1s ease-in-out;
    overflow: visible;
}

.show-more.show-machine-job-modal {
    color: transparent;
    background-color: rgba(0, 0, 0, 0);
}
</style>
