<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="inventory"
            class="elevation-1"
            :loading="loading"
            :item-class="rowClasses"
            :page="page"
            :pageCount="totalPages"
            :options.sync="options"
            :server-items-length="totalInventory"
            @click:row="clickOnItem"
        >
            <template v-slot:top>
                <v-toolbar flat color="white" class="table-toolbar">
                    <v-row class="justify-content-between">
                        <v-col cols="auto" class="pr-0 vertical-centered">
                            <v-toolbar-title>Inventory</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-col>

                        <v-col
                            cols="auto"
                            class="vertical-centered mr-lg-auto searchbar-container"
                        >
                            <inventory-search-input
                                v-model="search"
                                :headers="headers"
                            >
                            </inventory-search-input>
                        </v-col>

                        <v-col
                            v-if="$vuetify.breakpoint.mdAndUp"
                            cols="auto"
                            class="vertical-centered"
                        >
                            <v-switch
                                label="Low inventory Items"
                                v-model="onlyLowInventoryItems"
                                hide-details
                                class="mr-4"
                            ></v-switch>

                            <v-switch
                                label="Show all"
                                v-model="isWithTrashed"
                                hide-details
                            ></v-switch>
                        </v-col>

                        <v-col cols="auto" class="vertical-centered">
                            <v-btn
                                @click="showInventoryEditor = true"
                                color="primary"
                                class="mb-2"
                                :disabled="$denies('write-inventory')"
                                >New Item</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
                <div
                    class="text-truncate"
                    :style="
                        $vuetify.breakpoint.mdAndUp ? 'max-width: 120px' : ''
                    "
                >
                    <v-icon
                        v-if="item.parent_id"
                        small
                        color="primary "
                        @click="editInventoryItem(item.parent_item)"
                        >mdi-content-duplicate</v-icon
                    >
                    {{ item.name }}
                </div>
            </template>
            <template v-slot:item.description="{ item }">
                <div
                    class="text-truncate"
                    :style="
                        $vuetify.breakpoint.mdAndUp ? 'max-width: 120px' : ''
                    "
                >
                    {{ item.description }}
                </div>
            </template>
            <template v-slot:item.category.name="{ item }">
                <div class="text-truncate" style="max-width: 80px">
                    {{ item.category ? item.category.name : "" }}
                </div>
            </template>
            <template v-slot:item.cost="{ item }">
                {{ item.cost | currencyFormat }}
            </template>
            <template v-slot:item.sale_price="{ item }">
                {{ item.sale_price | currencyFormat }}
            </template>
            <template v-slot:item.quantity="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            small
                            v-bind="attrs"
                            v-on="on"
                            :class="[
                                item.quantity < item.low_inventory
                                    ? 'yellow--text text--darken-4'
                                    : '',
                            ]"
                        >
                            {{ item.quantity }}
                        </span>
                    </template>
                    <span>Low inventory warning: {{ item.low_inventory }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.location="{ item }">
                <div class="text-truncate" style="max-width: 100px">
                    {{ item.location }}
                </div>
            </template>
            <template v-slot:item.length="{ item }">
                {{ item.length | decimalFormat(4) }}
            </template>
            <template v-slot:item.width="{ item }">
                {{ item.width | decimalFormat(4) }}
            </template>
            <template v-slot:item.thickness="{ item }">
                {{ item.thickness | decimalFormat(4) }}
            </template>
            <template v-slot:item.weight="{ item }">
                {{ item.weight | decimalFormat }}
            </template>
            <template v-slot:item.attachment="{ item }">
                <a
                    v-if="item.attachment"
                    :href="'/storage/' + item.attachment"
                    target="__blank"
                >
                    <span v-if="$isPDF(item.attachment)">
                        <v-icon color="red">mdi-file-pdf-outline</v-icon>
                        PDF
                    </span>
                    <span v-else>
                        <v-icon color="blue">mdi-file-image-outline</v-icon>
                        Image
                    </span>
                </a>
            </template>
            <template v-slot:item.actions="{ item, index }">
                <v-menu offset-y :close-on-click="true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="white"
                            v-bind="attrs"
                            v-on="on"
                            :elevation="1"
                            text
                            rounded
                            x-small
                            fab
                        >
                            <v-icon color="primary" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense class="text-left">
                        <v-list-item
                            @click="createOffcut(item)"
                            :disabled="$denies('write-inventory')"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-shape-polygon-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    >Create offcut</v-list-item-title
                                >
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                            v-if="!item.deleted_at"
                            @click="selectedInventoryItem = item"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-application-export</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Jobs</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                            v-if="!item.deleted_at"
                            @click="editedAttachmentItem = item"
                            :disabled="$denies('write-inventory')"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-attachment</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    >Add attachment</v-list-item-title
                                >
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                            v-if="!item.deleted_at"
                            @click="editInventoryItem(item)"
                            :disabled="$denies('write-inventory')"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-pencil</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Edit</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                            v-if="item.deleted_at"
                            @click="hardDestroy(item, index)"
                            :disabled="$denies('write-inventory')"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-archive-alert</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title
                                    >Delete Archive</v-list-item-title
                                >
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                            v-if="item.deleted_at || item.isTrashing"
                            @click="restore(item, index)"
                            :disabled="$denies('write-inventory')"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-backup-restore</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Unarchive</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                            v-else
                            @click.stop="softDestroy(item, index)"
                            :disabled="$denies('write-inventory')"
                        >
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-archive</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Archive</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

        <v-snackbar v-model="infoMessage.show" max-width="400">
            {{ infoMessage.message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="primary"
                    text
                    v-bind="attrs"
                    @click="infoMessage.show = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <inventory-item-editor-modal
            v-if="showInventoryEditor"
            :inventory-item="editedInventoryItem"
            :is-new-item="editedIndex === -1"
            :inventory="inventory"
            @saved="savedInventoryItem"
            @close="closeInventoryItemEditor"
        >
        </inventory-item-editor-modal>

        <inventory-item-attachment
            v-if="editedAttachmentItem"
            :inventory-item="editedAttachmentItem"
            @close="editedAttachmentItem = null"
        >
        </inventory-item-attachment>

        <inventory-item-add-jobs-dialog
            :inventoryItem="selectedInventoryItem"
            @close="selectedInventoryItem = null"
            @useInventoryItem="useInventoryItem"
            @restockInventoryItem="restockInventoryItem"
        >
        </inventory-item-add-jobs-dialog>

        <mobile-actions
            v-if="touchedInventoryItem"
            :inventory-item="touchedInventoryItem"
            :inventory-item-index="touchedInventoryItemIndex"
            @close="touchedInventoryItem = null"
            @more-details="(item) => (moreDetailsInventoryItem = item)"
            @create-offcut="createOffcut"
            @edit-jobs="(item) => (selectedInventoryItem = item)"
            @edit-attachment="(item) => (editedAttachmentItem = item)"
            @edit="editInventoryItem"
            @archive="softDestroy"
        ></mobile-actions>

        <mobile-more-details-modal
            v-if="moreDetailsInventoryItem"
            :inventory-item="moreDetailsInventoryItem"
            @close="moreDetailsInventoryItem = null"
        ></mobile-more-details-modal>
    </div>
</template>

<script>
import currencyFormat from "Filters/currencyFormat";
import decimalFormat from "Filters/decimalFormat";
import inventoryItemAddJobsDialog from "./inventory-item-add-jobs-dialog.vue";
import permissions from "Mixins/permissions.mixin";
import inventoryItemEditorModal from "Components/inventory/inventory-item-editor-modal";
import inventoryItemAttachment from "Components/inventory/inventory-item-attachment";
import inventorySearchInput from "Components/inventory/inventory-search-input";
import isPdf from "Mixins/isPdf.mixin";
import mobileActions from "Components/inventory/mobile-actions";
import offcutsOfInventoryItem from "Mixins/offcutsOfInventoryItem.mixin";
import mobileMoreDetailsModal from "Components/inventory/mobile-more-details-modal";

export default {
    mixins: [permissions, isPdf, offcutsOfInventoryItem],
    components: {
        inventoryItemAddJobsDialog,
        inventoryItemEditorModal,
        inventoryItemAttachment,
        inventorySearchInput,
        mobileActions,
        mobileMoreDetailsModal,
    },
    data: data,
    created: created,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        headers() {
            const vm = this;
            let noCustomSearch =
                !this.search.column || this.search.column.value === "all";

            if (this.$vuetify.breakpoint.smAndDown) {
                return [
                    {
                        text: "Name",
                        align: "start",
                        sortable: false,
                        value: "name",
                        class: "grey--text text--darken-3",
                        filterable:
                            noCustomSearch ||
                            this.search.column.value === "name",
                    },
                    {
                        text: "Description",
                        align: "start",
                        sortable: false,
                        value: "description",
                        class: "grey--text text--darken-3",
                        filterable:
                            noCustomSearch ||
                            this.search.column.value === "description",
                    },
                    {
                        text: "Thickness",
                        align: "center",
                        sortable: false,
                        value: "thickness",
                        class: "grey--text text--darken-3",
                        filterable:
                            noCustomSearch ||
                            this.search.column.value === "thickness",
                    },
                ];
            }

            let headers = [
                {
                    text: "Id",
                    align: "start",
                    sortable: true,
                    value: "id",
                    class: "grey--text text--darken-3",
                    width: 56,
                    filterable:
                        noCustomSearch || this.search.column.value === "id",
                },
                {
                    text: "Name",
                    align: "start",
                    sortable: true,
                    value: "name",
                    class: "grey--text text--darken-3",
                    width: 150,
                    filterable:
                        noCustomSearch || this.search.column.value === "name",
                },
                {
                    text: "Description",
                    align: "start",
                    sortable: false,
                    value: "description",
                    class: "grey--text text--darken-3",
                    width: 150,
                    filterable:
                        noCustomSearch ||
                        this.search.column.value === "description",
                },
                {
                    text: "Category",
                    align: "center",
                    sortable: true,
                    value: "category.name",
                    class: "grey--text text--darken-3",
                    width: 85,
                    filterable:
                        noCustomSearch ||
                        this.search.column.value === "category.name",
                },
                {
                    text: "Units",
                    align: "center",
                    sortable: true,
                    value: "unit.name",
                    class: "grey--text text--darken-3",
                    width: 100,
                    filterable:
                        noCustomSearch ||
                        this.search.column.value === "unit.name",
                },
                {
                    text: "Cost",
                    align: "center",
                    sortable: true,
                    value: "cost",
                    class: "grey--text text--darken-3",
                    width: 105,
                    filterable:
                        noCustomSearch || this.search.column.value === "cost",
                },
                {
                    text: "Sale price",
                    align: "start",
                    sortable: true,
                    value: "sale_price",
                    class: "grey--text text--darken-3",
                    width: 105,
                    filterable:
                        noCustomSearch ||
                        this.search.column.value === "sale_price",
                },
                {
                    text: "Availability",
                    align: "center",
                    sortable: true,
                    value: "quantity",
                    class: "grey--text text--darken-3",
                    width: 98,
                    filterable:
                        noCustomSearch ||
                        this.search.column.value === "quantity",
                },
                {
                    text: "Thickness",
                    align: "center",
                    sortable: true,
                    value: "thickness",
                    class: "grey--text text--darken-3",
                    width: 96,
                    filterable:
                        noCustomSearch ||
                        this.search.column.value === "thickness",
                },
                {
                    text: "Width",
                    align: "center",
                    sortable: true,
                    value: "width",
                    class: "grey--text text--darken-3",
                    width: 78,
                    filterable:
                        noCustomSearch || this.search.column.value === "width",
                },
                {
                    text: "Length",
                    align: "center",
                    sortable: true,
                    value: "length",
                    class: "grey--text text--darken-3",
                    width: 78,
                    filterable:
                        noCustomSearch || this.search.column.value === "length",
                },
                {
                    text: "Weight",
                    align: "center",
                    sortable: true,
                    value: "weight",
                    class: "grey--text text--darken-3",
                    width: 88,
                    filterable:
                        noCustomSearch || this.search.column.value === "weight",
                },
                {
                    text: "Location",
                    align: "start",
                    sortable: true,
                    value: "location",
                    class: "grey--text text--darken-3",
                    width: 130,
                    filterable:
                        noCustomSearch ||
                        this.search.column.value === "location",
                },
                {
                    text: "Attachment",
                    align: "start",
                    sortable: false,
                    filterable: false,
                    value: "attachment",
                    class: "grey--text text--darken-3",
                    width: 99,
                },
                {
                    text: "Actions",
                    value: "actions",
                    align: "center",
                    sortable: false,
                    filterable: false,
                    class: "grey--text text--darken-3",
                    width: 60,
                },
            ];

            if (vm.$denies("view-inventory-prices")) {
                headers = headers.filter((header) => {
                    let isPrice = ["cost", "sale_price"].includes(header.value);
                    return !isPrice;
                });
            }
            return headers;
        },
    },
    filters: { currencyFormat, decimalFormat },
};

function data() {
    return {
        touchedInventoryItem: null,
        moreDetailsInventoryItem: null,
        touchedInventoryItemIndex: -1,
        options: {},
        totalInventory: 0,
        page: parseInt(this.$route.query.page) || 1,
        totalPages: 1,
        pageSize: parseInt(this.$route.query.size) || 10,
        orderBy: this.$route.query.orderBy,
        orderType: this.$route.query.orderType,
        deletingTimeoutId: null,
        searchTimer: null,
        search: {
            query: "",
            column: {
                value: "all",
                text: "All",
            },
            range: [],
        },
        infoMessage: {
            show: false,
            message: "",
        },
        showInventoryEditor: false,
        editedAttachmentItem: null,
        selectedInventoryItem: null,
        editedIndex: -1,
        editedInventoryItem: {
            id: "new-inventory-id",
            name: "",
            location: "",
            low_inventory: 0,
            thickness: "",
            width: "",
            length: "",
            serial_numbers: [],
        },
        defaultInventoryItem: {
            id: "new-inventory-id",
            name: "",
            location: "",
            low_inventory: 0,
            thickness: "",
            width: "",
            length: "",
            serial_numbers: [],
        },
        inventory: [],
        isWithTrashed: false,
        onlyLowInventoryItems: false,
        loading: false,
    };
}

function getWatchers() {
    return {
        isWithTrashed: {
            handler: "getInventory",
        },
        onlyLowInventoryItems: {
            handler: "getInventory",
        },
        search: {
            deep: true,
            handler: "handleSearch",
        },
        options: {
            handler() {
                let orderBy =
                    this.options.sortBy.length > 0
                        ? this.options.sortBy[0].split(".")[0]
                        : "";
                this.orderBy = orderBy;

                let orderType =
                    this.options.sortDesc[0] === true ? "desc" : "asc";
                this.orderType = orderType;

                const { page, itemsPerPage } = this.options;
                this.pageSize = itemsPerPage;
                this.page = page;
                this.$router.push({
                    name: this.routeName,
                    query: {
                        ...this.$route.query,
                        searchQuery: this.search.query,
                        searchColumn: this.search.column.value,
                        page: this.page,
                        size: this.pageSize,
                        orderBy: this.orderBy,
                        orderType: this.orderType,
                    },
                    params: this.$route.params,
                });
                this.getInventory();
            },
            deep: true,
        },
    };
}

function getMethods() {
    return {
        getInventory: getInventory,
        editInventoryItem: editInventoryItem,
        useInventoryItem: useInventoryItem,
        restockInventoryItem: restockInventoryItem,
        createOffcut: createOffcut,
        closeInventoryItemEditor: closeInventoryItemEditor,
        savedInventoryItem: savedInventoryItem,
        showInfoMessage: showInfoMessage,
        hardDestroy: hardDestroy,
        softDestroy: softDestroy,
        restore: restore,
        rowClasses: rowClasses,
        getRequestParams: getRequestParams,
        handleSearch: handleSearch,
        clickOnItem: clickOnItem,
    };

    function handleSearch() {
        const vm = this;
        this.$router.push({
            name: this.routeName,
            query: {
                ...this.$route.query,
                searchQuery: this.search.query,
                page: 1,
            },
            params: this.$route.params,
        });
        vm.getInventory();
    }

    function clickOnItem(inventoryItem, props) {
        if (this.$vuetify.breakpoint.mdAndUp) {
            return;
        }
        this.touchedInventoryItem = inventoryItem;
        this.touchedInventoryItemIndex = props.index;
    }

    function getRequestParams() {
        let params = {};

        if (this.search.query) {
            if (this.search.column.value) {
                params["searchColumn"] = this.search.column.value.split(".")[0];
            }
            if (this.search.range.length > 0) {
                params["searchRange"] = this.search.range;
            } else {
                params["searchQuery"] = this.search.query;
            }
        }
        if (this.onlyLowInventoryItems) {
            params["onlyLowInventoryItems"] = this.onlyLowInventoryItems;
        }
        if (this.page) {
            params["page"] = this.page;
        }
        if (this.pageSize) {
            params["size"] = this.pageSize;
        }
        if (this.orderBy) {
            params["orderBy"] = this.orderBy;
            params["orderType"] = this.orderType;
        }
        if (this.isWithTrashed) {
            params["showAll"] = true;
        }
        return params;
    }

    function getInventory() {
        const vm = this;
        vm.loading = true;
        clearTimeout(vm.searchTimer);

        const params = vm.getRequestParams();
        vm.searchTimer = setTimeout(() => {
            Vue.backendApi().inventory().index(params).then(success, fail);
        }, 250);

        function success(response) {
            vm.loading = false;
            const { total, data, last_page } = response.data;

            Vue.set(vm, "inventory", Object.values(data));
            vm.totalPages = last_page;
            vm.totalInventory = total;

            if (vm.$route.params.id) {
                let inventoryItem = vm.inventory.find((item) => {
                    return item.id === parseInt(vm.$route.params.id);
                });
                if (inventoryItem) {
                    vm.editInventoryItem(inventoryItem);
                }
            }
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get inventory");
        }
    }

    function editInventoryItem(inventoryItem) {
        let inventoryItemIndex = this.inventory.findIndex((item) => {
            return item.id === inventoryItem.id;
        });
        this.editedIndex = inventoryItemIndex;
        this.editedInventoryItem = Object.assign({}, inventoryItem);
        this.showInventoryEditor = true;
        this.$router.push({
            name: "inventory-item",
            params: { id: inventoryItem.id },
        });
    }

    function useInventoryItem(quantity) {
        this.selectedInventoryItem.quantity -= parseInt(quantity);
    }

    function restockInventoryItem(quantity) {
        this.selectedInventoryItem.quantity += parseInt(quantity);
    }

    function createOffcut(inventoryItem) {
        let offcut = {
            ...inventoryItem,
            id: "new-inventory-id",
            parent_id: inventoryItem.id,
            parent_item: inventoryItem,
            quantity: 1,
            length: null,
            width: null,
            thickness: null,
            weight: null,
        };
        this.editedInventoryItem = Object.assign({}, offcut);
        this.showInventoryEditor = true;
    }

    function closeInventoryItemEditor() {
        this.$nextTick(() => {
            this.editedInventoryItem = Object.assign(
                {},
                this.defaultInventoryItem
            );
            this.editedIndex = -1;
        });
        this.showInventoryEditor = false;
        this.$router.push({ name: "inventory" }).catch(() => {});
    }

    function savedInventoryItem(inventoryItem) {
        const vm = this;
        if (vm.touchedInventoryItem) {
            vm.touchedInventoryItem = null;
        }

        if (vm.editedIndex > -1) {
            Object.assign(vm.inventory[vm.editedIndex], inventoryItem);
        } else if (inventoryItem.parent_id) {
            let originalItemIndex = vm.inventory.findIndex(function (item) {
                return item.id === inventoryItem.parent_id;
            });
            vm.inventory.splice(originalItemIndex + 1, 0, inventoryItem);
        } else {
            vm.inventory.unshift(inventoryItem);
        }
    }

    function showInfoMessage(message) {
        const vm = this;
        vm.infoMessage.message = message;
        vm.infoMessage.show = true;

        setTimeout(function () {
            vm.infoMessage.message = "";
            vm.infoMessage.show = false;
        }, 6000);
    }

    function softDestroy(inventoryItem, index) {
        const vm = this;

        inventoryItem.isTrashing = false;
        Vue.set(vm.inventory, index, inventoryItem);
        Vue.backendApi()
            .inventory(inventoryItem.id)
            .softDestroy()
            .then(success, fail);

        function success(response) {
            if (vm.touchedInventoryItem) {
                vm.touchedInventoryItem = null;
            }

            inventoryItem.deleted_at = response.data.deleted_at;
            if (vm.isWithTrashed) {
                return;
            }

            let hasSomeOffcut = vm.inventory.some(function (item) {
                return item.parent_id === inventoryItem.id;
            });

            if (hasSomeOffcut) {
                vm.showInfoMessage(
                    "The inventory item has been archived, but it will continue being shown since has offcuts"
                );
                return;
            }
            inventoryItem.isTrashing = true;
            Vue.set(vm.inventory, index, inventoryItem);
            vm.deletingTimeoutId = setTimeout(() => {
                if (inventoryItem.parent_id) {
                    let originalItemIndex = vm.inventory.findIndex(function (
                        item
                    ) {
                        return item.id === inventoryItem.parent_id;
                    });

                    let originalItemOffcuts = vm.$getOffcutsOfInventoryItem(
                        inventoryItem.parent_id
                    );
                    const isDeleted =
                        vm.inventory[originalItemIndex].deleted_at &&
                        originalItemOffcuts.length <= 1;
                    if (isDeleted) {
                        vm.inventory.splice(originalItemIndex, 1);
                        index--;
                    }
                }
                vm.inventory.splice(index, 1);
            }, 2000);
        }

        function fail(error) {
            console.log(
                "An error occurred trying to archive an inventory item"
            );
        }
    }

    function hardDestroy(inventoryItem, index) {
        const vm = this;
        let hasSomeOffcut = vm.inventory.some(function (item) {
            return item.parent_id === inventoryItem.id;
        });

        if (hasSomeOffcut) {
            vm.showInfoMessage(
                "You cannot delete an item with offcuts. If you want to remove it, you should remove their offcuts first"
            );
            return;
        }

        if (confirm("Delete permanently? \nAre you sure?")) {
            Vue.backendApi()
                .inventory(inventoryItem.id)
                .hardDestroy()
                .then(success, fail);
        }

        function success(response) {
            vm.inventory.splice(index, 1);
        }

        function fail(error) {
            console.log(
                "An error occurred trying to archive an inventory item"
            );
        }
    }

    function restore(inventoryItem, index) {
        const vm = this;
        clearTimeout(vm.deletingTimeoutId);
        Vue.backendApi()
            .inventory(inventoryItem.id)
            .restore()
            .then(success, fail);

        function success(response) {
            inventoryItem.isTrashing = false;
            Vue.set(vm.inventory, index, inventoryItem);
            inventoryItem.deleted_at = null;
        }

        function fail(error) {
            console.log(
                "An error occurred trying to restore an inventory item"
            );
        }
    }

    function rowClasses(inventoryItem) {
        if (inventoryItem.deleted_at || inventoryItem.isTrashing) {
            return "item-archived";
        }
        return "";
    }
}

function created() {
    this.getInventory();
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep label {
    margin-bottom: 0px;
}

::v-deep .item-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep .search-input .v-text-field.v-input--dense {
    padding-top: 2px;
    width: 120px;
}
.bigIcon {
    font-size: 90px;
}
::v-deep.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 0 10px;
}
::v-deep.v-list-item--disabled .v-icon {
    color: rgba(0, 0, 0, 0.38);
}
::v-deep .search-input .v-input__prepend-inner {
    flex: 0.07 1 auto;
}
.table-toolbar {
    height: auto !important;
}
::v-deep .v-toolbar__content {
    height: auto !important;
}

@media (min-width: 531px) {
    .table-toolbar {
        padding-top: 10px;
    }
}

@media (max-width: 600px) {
    ::v-deep .v-data-table.elevation-1 {
        box-shadow: none !important;
    }

    ::v-deep .v-data-table > .v-data-table__wrapper > table {
        background-color: #f5f5f5;
        border-radius: 5px;
    }

    ::v-deep .v-data-table--mobile > .v-data-table__wrapper tbody {
        padding: 10px;
    }
}

.searchbar-container {
    flex: 1 1 auto;
}

@media (min-width: 960px) {
    .searchbar-container {
        flex: 0 0 auto;
    }
}

::v-deep
    .v-data-table
    > .v-data-table__wrapper
    .v-data-table__mobile-table-row {
    border: thin solid rgba(0, 0, 0, 0.12);
    margin: 10px 0px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;

    .v-data-table__mobile-row:first-child > .v-data-table__mobile-row__header,
    .v-data-table__mobile-row:nth-child(2) > .v-data-table__mobile-row__header {
        display: none;
    }

    .v-data-table__mobile-row:nth-child(3) {
        justify-content: flex-end;
    }

    .v-data-table__mobile-row:first-child {
        justify-content: center;
        font-size: 16px;
        font-weight: bold;
    }
}

::v-deep .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: 35px;
}
</style>
