<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="12" md="4">
                    <buildings-list></buildings-list>
                </v-col>
                <v-col cols="12" md="8">
                    <buildings-stations
                        v-if="$route.params.id"
                    ></buildings-stations>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import buildingsList from "Components/buildings/buildings-list";
import buildingsStations from "Components/buildings/building-stations";

export default {
    name: "buildings-layout",
    components: { pageHeaderVuetify, buildingsList, buildingsStations },
};
</script>
