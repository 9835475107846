export default {
    computed: {
        $isActive() {
            const vm = this;
            const filtersInactive = vm.filters.types.length === 0;
            if (filtersInactive) {
                return true;
            }

            return vm.filters.types.some(function (filter) {
                return vm.myStation.process.id === filter.id;
            });
        },
    },
};
