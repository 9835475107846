<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="12" md="8" lg="6">
                    <inventory-category-list></inventory-category-list>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import inventoryCategoryList from "./inventory-category-list";

export default {
    name: "inventory-categories-layout",
    components: { pageHeaderVuetify, inventoryCategoryList },
};
</script>
