<template>
    <v-card :class="{ 'd-none': !job.id }">
        <v-toolbar flat>
            <v-toolbar-title :title="`${activeReport.name} - ${job.name}`">
                {{ activeReport.name }} - {{ job.name }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn
                color="white"
                small
                class="mr-2"
                title="Edit Estimates"
                :disabled="!!job.deleted_at"
                @click="
                    $router.push({
                        name: 'job-estimates',
                        params: {
                            id: job.id,
                        },
                    })
                "
                ><v-icon color="primary">mdi-progress-clock</v-icon>
            </v-btn>
            <v-btn
                color="primary"
                :disabled="!!job.deleted_at"
                small
                @click="
                    $router.push({
                        name: 'job-builder',
                        params: {
                            id: job.id,
                            activeTab: 'steps',
                        },
                    })
                "
            >
                <v-icon small title="Build" class="mr-1">
                    mdi-hammer-wrench
                </v-icon>
                Build Job
            </v-btn>
            <v-btn small icon @click="$emit('close')" class="ml-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <template v-slot:extension>
                <v-tabs
                    v-model="currentTab"
                    dark
                    background-color="primary"
                    v-if="jobHasHistory"
                >
                    <v-tab
                        v-for="report in reports"
                        :key="report.tab"
                        @click="
                            $router.push({
                                name: 'job-reports',
                                params: {
                                    id: job.id,
                                    activeTab: report.route,
                                },
                            })
                        "
                    >
                        {{ report.name }}
                    </v-tab>
                </v-tabs>
                <p v-else class="text-left">
                    No job history found. Add job history on the overview page.
                </p>
            </template>
        </v-toolbar>

        <v-tabs-items v-if="jobHasHistory">
            <v-card flat>
                <v-card-text
                    :style="$vuetify.breakpoint.smAndUp ? 'height: 250px;' : ''"
                >
                    <component
                        v-if="jobHasHistory"
                        :key="uniqueKey"
                        :is="activeTab"
                        :job="job"
                        @processes-updated="$emit('processes-updated')"
                        @job-work-hours-updated="$emit('job-work-hours-updated')"
                    ></component>
                </v-card-text>
            </v-card>
        </v-tabs-items>
    </v-card>
</template>

<script>
import jobProcesses from "Components/jobs/job-reports/job-processes";
import stationJobReport from "Components/jobs/job-reports/station-job-report";
import stationJobHistory from "Components/jobs/job-reports/station-job-history";
import employeeReport from "Components/jobs/job-reports/employee-report";

export default {
    data: data,
    components: {
        jobProcesses,
        stationJobReport,
        stationJobHistory,
        employeeReport
    },
    computed: getComputed(),
    watch: getWatched(),
    props: {
        job: Object,
    },
};

function data() {
    return {
        showEstimatesEditor: false,
        currentTab: null,
        uniqueKey: new Date().toISOString(),
        reports: [
            { tab: 0, name: "Job Processes", route: "job-processes" },
            { tab: 1, name: "Station Report", route: "station-job-report" },
            { tab: 2, name: "Employee Report", route: "employee-report" },
            { tab: 3, name: "Station History", route: "station-job-history" },
        ],
    };
}

function getWatched() {
    return {
        job: {
            immediate: true,
            handler: function () {
                const vm = this;
                vm.showEstimatesEditor = vm.jobHasHistory ? false : true;
            },
        },
        "$route.params.activeTab": {
            immediate: true,
            handler: function () {
                const vm = this;
                let currentTab = this.reports.find((report) => {
                    return report.route === vm.$route.params.activeTab;
                });
                if (currentTab) {
                    vm.currentTab = currentTab.tab;
                }
            },
        },
    };
}

function getComputed() {
    return {
        activeTab: activeTab,
        activeReport: activeReport,
        jobHasHistory: jobHasHistory,
    };

    function activeTab() {
        return this.$route.params.activeTab;
    }

    function activeReport() {
        const vm = this;
        let activeReport = this.reports.find(function (report) {
            return report.route === vm.activeTab;
        });
        if (activeReport) {
            return activeReport;
        } else {
            return {};
        }
    }

    function jobHasHistory() {
        return this.job.all_hours > 0;
    }
}
</script>

<style lang="scss" scoped>
::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep .v-card__text {
    overflow-y: scroll;
    scrollbar-width: thin;
}
</style>
