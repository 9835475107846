<template>
    <div
        v-custom-click-outside="clickOutside"
        class="machine-job-menu dnd-no-drag"
    >
        <ul>
            <li>
                <router-link
                    :to="`/app/jobs/${jobHistory.job.id}/reports/job-processes`"
                >
                    <i class="fas fa-newspaper"></i>Go to job
                </router-link>
            </li>
            <li @click="$emit('showJobHoursEditor')">
                <i class="fas fa-edit"></i>Edit
            </li>
            <li @click="$emit('removeJobHistory', jobHistory)">
                <i class="fa fa-trash"></i>Delete
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        jobHistory: Object,
    },

    data() {
        return {
            isClickOutsideChecked: false,
        };
    },

    methods: {
        clickOutside() {
            if (!this.isClickOutsideChecked) {
                this.isClickOutsideChecked = true;
                return;
            }
            this.$emit("close");
            this.isClickOutsideChecked = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.machine-job-menu {
    width: 128px;
    box-shadow: 0px 6px 12px 4px #555;
    position: absolute;
    background: white;
    z-index: 8;
    border-radius: 4px;
    padding: 10px 5px;

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0px;
        margin-left: 2px;
        cursor: initial;

        li {
            padding: 2px 5px;
            transition: all 0.3s;

            i {
                margin-right: 5px;
            }

            &:hover {
                cursor: pointer;
                background-color: #e5e5e5;
                padding-left: 10px;
            }

            a {
                color: rgb(33, 37, 41);

                &:hover {
                    color: rgb(33, 37, 41);
                }
            }
        }
    }
}
</style>
