<template>
    <v-navigation-drawer
        permanent
        width="auto"
        class="text-left mt-5 mt-md-0"
        :floating="$vuetify.breakpoint.smAndDown"
    >
        <v-row class="px-md-4">
            <v-col sm="7" class="pb-0">
                <h5>Find a Sales Order</h5>
            </v-col>
            <v-col sm="5">
                <v-switch
                    class="float-right"
                    label="Show all"
                    v-model="showArchived"
                    @change="query"
                    hide-details
                ></v-switch>
            </v-col>
        </v-row>

        <v-row class="mt-0 px-md-4">
            <v-col class="pt-0">
                <v-form
                    @submit.prevent="query"
                    @keyup.native.enter="query"
                    class="d-flex flex-nowrap"
                >
                    <v-text-field
                        label="Search"
                        v-model="salesOrders.query"
                        prepend-inner-icon="mdi-magnify"
                        hide-details
                    >
                        <template v-slot:append>
                            <v-btn class="ml-4" depressed type="submit">
                                Search
                            </v-btn>
                        </template>
                    </v-text-field>
                </v-form>
            </v-col>
        </v-row>

        <v-divider class="mb-0"></v-divider>
        <v-bottom-navigation
            scroll-target="#searchResults"
            hide-on-scroll
            absolute
            height="100px"
        >
            <v-pagination
                v-model="salesOrders.page"
                class="mt-2"
                color="primary"
                :length="salesOrders.pagesLength"
                :total-visible="7"
                @input="pageChange"
            />
        </v-bottom-navigation>
        <v-card scrollable flat class="overflow-y-auto" id="searchResults">
            <v-list nav class="search-result-list">
                <template v-for="order in search.results">
                    <v-list-item
                        ripple
                        three-line
                        :key="order.id"
                        @click="
                            $router.push({
                                name: 'sales-orders',
                                params: { id: order.id },
                            })
                        "
                        :class="{
                            'v-list-item--active':
                                $route.params.id === order.id,
                        }"
                    >
                        <v-list-item-avatar
                            v-if="order.deleted_at"
                            color="grey"
                            large
                            class="mr-4"
                        >
                            <v-icon color="white"> mdi-archive-alert </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-avatar
                            v-else
                            color="primary"
                            large
                            class="mr-4"
                        >
                            <span class="white--text headline">{{
                                order.client.name.substring(1, -1).toUpperCase()
                            }}</span>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>
                                {{
                                    `${order.id}
                                    | ${order.purchase_order_number}
                                    | ${order.client.name}`
                                }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                                v-for="item in order.items
                                    ? order.items.slice(0, 2)
                                    : []"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
export default {
    props: {
        search: Object,
        value: Boolean,
    },
    data() {
        return {
            salesOrders: {},
            showArchived: this.value,
        };
    },
    watch: {
        search: {
            deep: true,
            handler(val) {
                const vm = this;
                vm.$set(vm, "salesOrders", JSON.parse(JSON.stringify(val)));
            },
        },
        $route: {
            handler(to, from) {
                const vm = this;
                vm.getSalesOrder();
            },
        },
    },
    methods: {
        query() {
            const vm = this;
            vm.$emit("input", vm.showArchived);
            const payload = {
                query: vm.salesOrders.query,
                showArchived: vm.showArchived,
            };
            vm.$emit("query", payload);
        },
        pageChange() {
            const vm = this;
            vm.$emit("pageChange", vm.salesOrders.page);
        },
        getSalesOrder() {
            const vm = this;
            vm.$emit("getSalesOrder");
        },
    },
    mounted() {
        const vm = this;
        vm.$set(vm, "salesOrders", JSON.parse(JSON.stringify(vm.search)));
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input--switch {
    margin-top: 0;
}

::v-deep .v-input--selection-controls__input {
    top: -3px;
}

::v-deep .v-input--selection-controls {
    padding-top: 0px;
}

::v-deep .v-navigation-drawer__content {
    scrollbar-width: thin;
}

::v-deep .mdi-magnify {
    padding-top: 10px;
}

.search-result-list {
    padding-bottom: 100px;
}
</style>
