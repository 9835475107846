<template>
    <v-app>
        <v-dialog v-model="show" width="550" @click:outside="$emit('close')">
            <v-card>
                <card-title
                    @close="$emit('close')"
                    :preferences="preferences"
                    >{{ machine.name }}</card-title
                >

                <v-card-text class="machine-container p-0">
                    <div
                        v-for="(shift, i) in stationShifts"
                        :key="shift.id"
                        class="shift-container"
                    >
                        <machine-shift-job-list
                            :shift="shift"
                            :shift-index="i"
                            :machine="machine"
                            :machineDowntimes="machineDowntimes"
                            :current-machine-downtime="currentMachineDowntime"
                            :has-downtime="hasDowntime"
                        >
                        </machine-shift-job-list>

                        <machine-shift-employee-list
                            :shift="shift"
                            :machine="machine"
                            :current-machine-downtime="currentMachineDowntime"
                        >
                        </machine-shift-employee-list>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import machineShiftEmployeeList from "Components/overview/building/machine/machine-modal/machine-shift-employee-list";
import machineShiftJobList from "Components/overview/building/machine/machine-modal/machine-shift-job-list";
import cardTitle from "Components/overview/building/machine/machine-modal/card-title";

export default {
    components: { machineShiftEmployeeList, machineShiftJobList, cardTitle },

    data() {
        return {
            show: true,
        };
    },

    methods: {},

    props: {
        machine: Object,
        preferences: Object,
        stationShifts: Array,
        hasDowntime: Boolean,
        currentMachineDowntime: Object,
        machineDowntimes: Array,
    },
};
</script>

<style lang="scss" scoped>
$morningColor: floralwhite;
$eveningColor: #e9ecef;
::v-deep .v-application--wrap {
    min-height: auto;
}
::v-deep .v-card__title {
    background-color: #a8a9ad;
    border-bottom: 2px solid #231f20;
    border-radius: 0px;
    .headline {
        width: 100%;
        text-align: center;
        padding: 4px 5px 6px 5px;
        font-weight: 700;
        border-radius: 0 20px;
        border: 1px solid #fff;
        box-shadow: 1px 1px 2px #000;
    }
}

::v-deep .v-dialog {
    overflow: initial;
}

::v-deep .theme--light.v-sheet.v-card {
    border: 2px solid #231f20;
}

.machine-container {
    .shift-container {
        padding: 10px;
    }
    & .shift-container:nth-child(even) {
        background-color: $morningColor;
        min-height: 53px;
    }
    & .shift-container:nth-child(odd) {
        background-color: $eveningColor;
        min-height: 53px;
    }
}
</style>

<style lang="scss">
.jobs-list-container {
    z-index: 9 !important;
}

.employee-list {
    z-index: 9 !important;
}
</style>
