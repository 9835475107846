<template>
    <v-card class="d-flex">
        <v-navigation-drawer permanent width="130">
            <v-list dense nav>
                <v-list-item-group v-model="selectedBuilding">
                    <v-list-item
                        v-for="building in buildings"
                        :key="building.id"
                        link
                        class="text-left"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{
                                building.name
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>

        <v-expansion-panels v-if="buildings.length > 0">
            <machine
                v-for="machine in buildings[selectedBuilding].stations"
                :key="machine.id"
                :my-station="machine"
                :machineHistory="getMachineHistory(machine)"
                :filters="filters"
            ></machine>
        </v-expansion-panels>
    </v-card>
</template>

<script>
import machine from "Components/overview/mobile/machine";

export default {
    components: { machine },

    data() {
        return {
            selectedBuilding: 0,
        };
    },

    props: {
        buildings: Array,
        allShifts: Array,
        filters: Object,
    },

    methods: {
        getMachineHistory(machine) {
            return this.allShifts.filter(
                (shift) => shift.station_id === machine.id
            );
        },
    },
};
</script>
