<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                label="Duration"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-card class="mx-auto" max-width="290px">
            <v-toolbar
                color="primary"
                dark
                flat
                height="90"
                class="d-flex justify-center align-items-center"
            >
                <v-toolbar-title class="align-self-center">
                    <div class="h1 mb-0">
                        {{ seconds | secondsToReadableDuration }}
                    </div>
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text class="pt-9">
                <v-slider
                    v-model="hours"
                    label="Hours"
                    track-color="primary lighten-4"
                    min="0"
                    max="23"
                    thumb-label="always"
                ></v-slider>

                <v-slider
                    v-model="minutes"
                    label="Minutes"
                    track-color="primary lighten-4"
                    thumb-label="always"
                    min="0"
                    max="59"
                    @mouseup="menu = false"
                ></v-slider>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import secondsToReadableDuration from "Filters/secondsToReadableDuration";

export default {
    data: data,
    computed: getComputed(),
    watch: getWatchers(),
    props: {
        value: Number,
    },
    filters: { secondsToReadableDuration },
};

function data() {
    return {
        menu: false,
        hours: 0,
        minutes: 0,
    };
}

function getWatchers() {
    return {
        seconds: seconds,
        value: {
            immediate: true,
            handler: value,
        },
    };

    function seconds() {
        this.$emit("input", this.seconds);
    }

    function value() {
        let duration = Vue.moment.duration(this.value, "seconds");
        this.hours = duration.hours();
        this.minutes = duration.minutes();
    }
}

function getComputed() {
    return {
        seconds: seconds,
        time: time,
    };

    function time() {
        return secondsToReadableDuration(this.seconds);
    }

    function seconds() {
        let hoursInSeconds = this.hours * 60 * 60;
        let minutesInSeconds = this.minutes * 60;
        let timeInSeconds = hoursInSeconds + minutesInSeconds;
        return timeInSeconds;
    }
}
</script>
