<template>
    <v-data-table
        :headers="headers"
        :items="units"
        class="elevation-1"
        :loading="loading"
        disable-pagination
        hide-default-footer
        :item-class="rowClasses"
    >
        <template v-slot:top>
            <v-toolbar
                flat
                color="white"
                :prominent="$vuetify.breakpoint.width < 394"
            >
                <v-row class="justify-content-between">
                    <v-col cols="auto" class="vertical-centered pr-0 mr-auto">
                        <v-toolbar-title>Units</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                    </v-col>
                    <v-col cols="auto" class="vertical-centered">
                        <v-switch
                            label="Show all"
                            v-model="isWithTrashed"
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="auto">
                        <v-dialog
                            persistent
                            v-model="showUnitEditor"
                            max-width="500px"
                            @click:outside="confirmClose"
                            @keydown.esc="confirmClose"
                            @keydown.enter="save"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >New Unit
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="primary white--text">
                                    <span class="headline">
                                        {{ formTitle }}
                                    </span>
                                </v-card-title>

                                <v-card-text>
                                    <v-form
                                        ref="editUnitForm"
                                        onSubmit="return false;"
                                    >
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedUnit.name
                                                        "
                                                        label="Name"
                                                        @input="isDirty = true"
                                                        :rules="[
                                                            () =>
                                                                !!editedUnit.name ||
                                                                'Required.',
                                                        ]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="confirmClose"
                                        >Cancel</v-btn
                                    >
                                    <v-btn color="primary" @click="save"
                                        >Save</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item, index }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editUnit(item)"
                title="Edit"
                v-if="!item.deleted_at"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="hardDestroy(item, index)"
                v-if="item.deleted_at"
                title="Delete Archive "
            >
                mdi-archive-alert
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="restore(item, index)"
                v-if="item.deleted_at || item.isTrashing"
                title="Unarchive "
            >
                mdi-backup-restore
            </v-icon>
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                @click="softDestroy(item, index)"
                v-else
                title="Archive"
            >
                mdi-archive
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: data,
    mounted: mounted,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Unit" : "Edit Unit";
        },
    },
};

function data() {
    return {
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        isWithTrashed: false,
        deletingTimeoutId: null,
        loading: false,
        units: [],
        showUnitEditor: false,
        isDirty: false,
        editedIndex: -1,
        editedUnit: {
            id: "new-unit-id",
            name: "",
        },
        defaultUnit: {
            id: "new-unit-id",
            name: "",
        },
    };
}

function getWatchers() {
    return {
        showUnitEditor: {
            handler: dialog,
        },
        isWithTrashed: {
            handler: getLists,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function getLists() {
        this.getLists.apply(this);
    }
}

function getMethods() {
    return {
        editUnit: editUnit,
        hardDestroy: hardDestroy,
        softDestroy: softDestroy,
        restore: restore,
        getLists: getLists,
        close: close,
        confirmClose: confirmClose,
        rowClasses: rowClasses,
        save: save,
    };

    function rowClasses(unit) {
        if (unit.deleted_at || unit.isTrashing) {
            return "user-archived";
        }
        return "";
    }

    function restore(unit, index) {
        const vm = this;
        clearTimeout(vm.deletingTimeoutId);
        Vue.backendApi().units(unit.id).restore().then(success, fail);

        function success(response) {
            unit.isTrashing = false;
            Vue.set(vm.units, index, unit);
            unit.deleted_at = null;
        }

        function fail(error) {
            console.log("An error occurred trying to restore a unit");
        }
    }

    function getLists() {
        const vm = this;
        const isWithTrashed = vm.isWithTrashed || false;
        vm.loading = true;

        Vue.backendApi().units().index(isWithTrashed).then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "units", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get units");
        }
    }

    function editUnit(unit) {
        this.editedIndex = this.units.indexOf(unit);
        this.editedUnit = Object.assign({}, unit);
        this.showUnitEditor = true;
    }

    function softDestroy(unit, index) {
        const vm = this;

        unit.isTrashing = false;
        Vue.set(vm.units, index, unit);
        Vue.backendApi().units(unit.id).softDestroy().then(success, fail);

        function success(response) {
            if (vm.isWithTrashed) {
                unit.deleted_at = response.data.deleted_at;
            } else {
                unit.isTrashing = true;
                Vue.set(vm.units, index, unit);
                vm.deletingTimeoutId = setTimeout(() => {
                    vm.units.splice(index, 1);
                }, 2000);
            }
        }

        function fail(error) {
            console.log("An error occurred trying to archive an unit");
        }
    }

    function hardDestroy(unit, index) {
        const vm = this;

        if (confirm("Delete permanently? \nAre you sure?")) {
            Vue.backendApi().units(unit.id).hardDestroy().then(success, fail);
        }

        function success(response) {
            unit.deleted_at = response.data.deleted_at;
            vm.units.splice(index, 1);
        }

        function fail(error) {
            console.log("An error occurred trying to archive an unit");
        }
    }

    function close() {
        this.showUnitEditor = false;
        this.$nextTick(() => {
            this.editedUnit = Object.assign({}, this.defaultUnit);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editUnitForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editUnitForm.validate()) {
            return false;
        }

        if (vm.editedUnit.id === "new-unit-id") {
            Vue.backendApi().units().store(vm.editedUnit).then(success, fail);
        } else {
            Vue.backendApi()
                .units(vm.editedUnit.id)
                .store(vm.editedUnit)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.units[vm.editedIndex], response.data);
            } else {
                vm.units.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save unit");
        }
    }
}

function mounted() {
    this.getLists();
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep .user-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep label {
    margin-bottom: 0px;
}
</style>
