<template>
    <span v-if="existsSerialNumber" class="mr-2">
        <span class="badge badge-light">
            <i class="fas fa-barcode"></i>
            <slot></slot>
        </span>
    </span>
</template>

<script>
export default {
    computed: {
        existsSerialNumber() {
            return !!this.$slots.default[0].text;
        },
    },
};
</script>
