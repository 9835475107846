<template>
    <div class="employee-list">
        <div class="employee-list-inner border border-radius">
            <h6 class="employee-title d-flex justify-content-center">
                EMPLOYEE LIST

                <general-info-modal
                    :employee-history-map="$employeeHistoryMap"
                ></general-info-modal>
            </h6>
            <div class="employee-menu">
                <waiting-spinner :loading="isLoading"></waiting-spinner>
                <div v-if="!isLoading">
                    <shop-searcher v-model="search"></shop-searcher>

                    <div class="add-employee">
                        <shop-employee
                            :employee="newEmployee"
                            @employee-updated="addNewEmployee"
                        >
                        </shop-employee>
                    </div>

                    <drag
                        v-for="employee in employees"
                        :data="employee"
                        class="item"
                        :key="employee.id"
                        @cut="onCut"
                        @dragend="checkDragEndOnMachineInDowntime"
                    >
                        <shop-employee
                            :class="{
                                filtered:
                                    $isFiltered(employee) ||
                                    $isFilteredBySearch(employee),
                            }"
                            :employee="employee"
                            @employee-updated="updateEmployee"
                            @employee-destroyed="destroyEmployee"
                            @absence-destroyed="removeAbsence"
                            @absence-added="addAbsence"
                            @absence-updated="updateAbsence"
                            :key="`employee-id-${employee.id}`"
                            :employee-history-map="$employeeHistoryMap"
                            :absence="getAbsenceStatus(employee)"
                        ></shop-employee>

                        <template v-slot:drag-image>
                            <div class="employee-dragging">
                                {{ employee.name | trucate(20) }}
                            </div>
                        </template>
                    </drag>
                </div>
            </div>
        </div>
        <alert-modal
            v-if="alertModal"
            :alert="alertModal"
            @close="alertModal = null"
        ></alert-modal>
    </div>
</template>

<script>
import shopEmployee from "Components/overview/employees/employee";
import shopSearcher from "Components/overview/shop-overview-searcher";
import generalInfoModal from "Components/overview/employees/general-info-modal.vue";
import trucate from "Filters/truncate";
import { Drag } from "vue-easy-dnd";
import overviewEmployees from "Mixins/overview/overview-employees.mixin.js";
import alertModal from "Components/overview/alert-modal";

export default {
    name: "shop-overview-employee-list",
    mixins: [overviewEmployees],
    data: data,
    props: getProps(),
    computed: getComputed(),
    components: {
        shopEmployee,
        shopSearcher,
        Drag,
        generalInfoModal,
        alertModal,
    },
    methods: getMethods(),
    watch: getWatchers(),
    mounted: mounted,
    filters: { trucate },
};

function data() {
    return {
        newEmployee: {},
        employees: [],
        absences: [],
        loading: [],
        search: "",
        alertModal: null,
    };
}

function getProps() {
    return {
        filters: Object,
        allShifts: Array,
        overviewDate: Object,
    };
}

function getComputed() {
    return {
        date: date,
        isLoading: isLoading,
    };

    function date() {
        let date = {
            year: this.overviewDate.format("YYYY"),
            month: this.overviewDate.format("MM"),
            day: this.overviewDate.format("DD"),
        };
        return `${date.year}-${date.month}-${date.day}`;
    }

    function isLoading() {
        return this.loading.length > 0;
    }
}

function mounted() {
    const vm = this;

    getEmployees();
    vm.getAbsences();
    vm.resetNewEmployee();

    function getEmployees() {
        vm.loading.push("employees");

        Vue.backendApi().employees().index().then(success, fail);

        function success(response) {
            vm.loading = vm.loading.filter((element) => {
                return element !== "employees";
            });
            Vue.set(vm, "employees", response.data);
        }

        function fail(error) {
            vm.loading = vm.loading.filter((element) => {
                return element !== "employees";
            });
        }
    }
}

function getMethods() {
    return {
        checkDragEndOnMachineInDowntime: checkDragEndOnMachineInDowntime,
        resetNewEmployee: resetNewEmployee,
        addNewEmployee: addNewEmployee,
        updateEmployee: updateEmployee,
        destroyEmployee: destroyEmployee,
        onCut: onCut,
        getAbsences: getAbsences,
        getAbsenceStatus: getAbsenceStatus,
        removeAbsence: removeAbsence,
        addAbsence: addAbsence,
        updateAbsence: updateAbsence,
    };

    function checkDragEndOnMachineInDowntime(ev) {
        let dropElement = ev.native.target;
        if (dropElement.classList.contains("machine-in-downtime")) {
            this.alertModal = {
                title: "Action not allowed",
                message: "You cannot add an employee on a machine in downtime",
                type: "error",
            };
        }
    }

    function resetNewEmployee() {
        this.newEmployee = {
            newEmployeeModel: true,
            name: "",
            email: "email@example.com",
            type: "user",
            created_at: "",
            updated_at: "",
            deleted_at: null,
            processes: [],
        };
    }

    function addNewEmployee(newEmployee) {
        const vm = this;
        vm.employees.push(newEmployee);
        this.employees = this.employees.sort((a, b) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
        vm.$emit("employee-updated", newEmployee);
        this.resetNewEmployee();
    }

    function updateEmployee(unsavedEmployee) {
        const vm = this;
        let employeeIndex = vm.employees.findIndex((employee) => {
            return employee.id === unsavedEmployee.id;
        });
        if (employeeIndex >= 0) {
            vm.employees.splice(employeeIndex, 1, unsavedEmployee);
        } else {
            vm.employees.push(unsavedEmployee);
        }
        vm.$emit("employee-updated", unsavedEmployee);
    }

    function destroyEmployee(destroyedEmployeeId) {
        const vm = this;
        let destroyedIndex = vm.employees.findIndex((employee) => {
            return destroyedEmployeeId === employee.id;
        });
        vm.employees.splice(destroyedIndex, 1);
    }

    function onCut() {
        // It's necessary to declare this listener for the cut event
        // since the drop component declares a cut mode
        // https://github.com/rlemaigre/Easy-DnD#modes
    }

    function getAbsences() {
        const vm = this;
        vm.loading.push("absences");

        Vue.backendApi().employees().absences().on(vm.date).then(success, fail);

        function success(response) {
            Vue.set(vm, "absences", response.data);
            vm.loading = vm.loading.filter((element) => {
                return element !== "absences";
            });
        }

        function fail(error) {
            console.log("Error trying to get employee absences");
            vm.loading = vm.loading.filter((element) => {
                return element !== "absences";
            });
        }
    }

    function getAbsenceStatus(employee) {
        return this.absences.find((absence) => absence.user_id === employee.id);
    }

    function removeAbsence(absence) {
        let absenceIndex = this.absences.findIndex((myAbsence) => {
            myAbsence.id === absence.id;
        });
        this.absences.splice(absenceIndex, 1);
    }

    function addAbsence(absence) {
        this.absences.push(absence);
    }

    function updateAbsence(absence) {
        let absenceIndex = this.absences.findIndex((myAbsence) => {
            myAbsence.id === absence.id;
        });
        this.absences.splice(absenceIndex, 1, absence);
    }
}

function getWatchers() {
    return {
        date: getAbsences,
    };

    function getAbsences() {
        this.getAbsences();
    }
}
</script>

<style lang="scss">
@import "~@/_variables.scss";

.employee-title {
    i {
        cursor: pointer;
    }
}

.employee .fa-plus {
    line-height: 35px;
}

.add-employee {
    cursor: cell;
    color: #95969a;
}

.employee-dragging {
    width: 120px;
    background-color: lightsalmon;
    padding: 2px 4px;
    border-radius: 4px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
}

.employee-placeholder {
    background-color: #95999c;
    border-color: #761b18;
}

.employee-dropping {
    background-color: yellow;
}

.employee-menu {
    margin: 0;
    padding: 0 10px;
    overflow-y: auto;
    min-height: $listInnerHeight;
    max-height: $listInnerHeight;
    scrollbar-width: thin;
    & .employee {
        position: relative;
        line-height: 35px;
        font-weight: 700;
        font-size: 12px;
        width: 100%;
        border-bottom: 1px solid #a6a7a9;
        padding-left: 10px;
        & i.fa-pencil-alt {
            display: none;
        }
    }
}

.employee {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        white-space: normal;
    }
}
</style>
