<template>
    <select v-model="selectedItemId" @change="updateParent">
        <option v-for="item in options" :value="item.id">
            {{ item.name }}
        </option>
    </select>
</template>

<script>
export default {
    name: "editable-item-input",
    data: data,
    props: {
        value: Object,
        meta: Object,
        originalValue: Object,
    },
    methods: getMethods(),
    watch: getWatchers(),
    computed: getComputed(),
};

function data() {
    return {
        selectedItemId: "",
    };
}

function getComputed() {
    return {
        options: options,
    };

    function options() {
        if (this.meta.hasTrashedOption && this.originalValue) {
            let existsOriginalValue = this.meta.options.find((item) => {
                return item.id == this.originalValue.id;
            });

            return existsOriginalValue
                ? this.meta.options
                : [this.originalValue, ...this.meta.options];
        } else {
            return this.meta.options;
        }
    }
}

function getMethods() {
    return {
        updateParent: updateParent,
    };

    function updateParent(event) {
        let selectedItem = this.options.find((item) => {
            return item.id == this.selectedItemId;
        });

        if (selectedItem) {
            this.$emit("change", selectedItem);
        }
    }
}

function getWatchers() {
    return {
        value: {
            immediate: true,
            handler: setSelectedItemId,
        },
        meta: {
            immediate: true,
            handler: setSelectedItemId,
            deep: true,
        },
    };

    function setSelectedItemId() {
        if (!this.value) {
            return;
        }
        if (this.options && this.options.length > 0) {
            let isValid = this.options.find((item) => {
                return item.id == this.value.id;
            });
            if (!isValid) {
                this.selectedItemId = this.options[0].id;
                this.updateParent();
            } else {
                this.selectedItemId = this.value.id;
                this.updateParent();
            }
        }
    }
}
</script>

<style scoped>
option {
    padding: revert;
}
</style>
