<template>
    <v-row>
        <v-col cols="12" sm="7">
            <svg class="pie">
                <circle
                    v-for="item in dataObjects"
                    v-bind:style="{
                        strokeDasharray: `${item.relativeSize} ${circleLength}`,
                        strokeDashoffset: item.offset,
                    }"
                    r="25%"
                    cx="50%"
                    cy="50%"
                />
            </svg>
        </v-col>

        <v-col cols="12" sm="5" class="column-labels">
            <div class="labels">
                <div v-for="item in items">
                    <span class="item-label">{{ item.label }}</span>
                    <span class="item-percentage">{{ item.percentage }}%</span>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "PieChart",
    mounted() {
        setTimeout(() => {
            this.hasMounted = true;
        }, 0);
    },

    props: {
        items: Array,
    },

    data() {
        return {
            circleLength: 371.9451599121094,
            hasMounted: false,
        };
    },
    methods: {},
    computed: {
        dataTotal() {
            return this.items.reduce((acc, item) => acc + item.value, 0);
        },
        dataObjects() {
            let startingPoint = 0;
            return this.items.map((item) => {
                let relativeSize =
                    (item.value / this.dataTotal) * this.circleLength;
                let dataObject = {
                    relativeSize: this.hasMounted ? relativeSize : 0,
                    offset: -startingPoint,
                };
                startingPoint += relativeSize;
                return dataObject;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.pie {
    margin-left: -33%;
}

.pie circle {
    fill: none;
    stroke-width: 32;
    transition: stroke-dasharray 0.3s ease-in-out,
        stroke-dashoffset 0.3s ease-in-out;
}

$colors: #7ca6ad, #8a95ba, #ffe8b5, #ffd9b5;
@for $i from 1 through length($colors) {
    .pie circle:nth-child(#{$i}) {
        stroke: nth($colors, $i);
    }
}

@for $i from 1 through length($colors) {
    .labels div:nth-child(#{$i}) {
        &::before {
            background-color: nth($colors, $i);
            content: "";
            width: 20px;
            height: 10px;
            border-radius: 10px;
            position: absolute;
            top: 5px;
            bottom: 0;
            left: -30px;
        }
    }
}

.labels {
    text-align: left;

    div {
        position: relative;
        display: flex;

        .item-percentage {
            flex: 1 auto;
            text-align: right;
            color: black;
        }
    }
}

@media (max-width: 600px) {
    .pie {
        margin-left: 0;
    }

    .item-label {
        margin-left: 30px;
    }

    @for $i from 1 through length($colors) {
        .labels div:nth-child(#{$i}) {
            &::before {
                left: 0px;
            }
        }
    }
}

@media (max-width: 370px) {
    .col-5 .column-labels {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        padding: 12px;
    }
}
</style>
