export default {
    methods: {
        $emitEstimatedHoursUpdated(steps) {
            const estimated_hours = steps.reduce(
                (sum, step) => sum + parseFloat(step.estimated_hours),
                0
            );

            this.$emit("estimated-hours-updated", estimated_hours);
        },
    },
};
