var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.inventoryCategories,"loading":_vm.loading,"item-class":_vm.rowClasses},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white","prominent":_vm.$vuetify.breakpoint.width < 566}},[_c('v-row',{staticClass:"justify-content-between"},[_c('v-col',{staticClass:"vertical-centered pr-0 mr-auto",attrs:{"cols":"auto"}},[_c('v-toolbar-title',[_vm._v("Inventory Categories")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1),_vm._v(" "),_c('v-col',{staticClass:"vertical-centered",attrs:{"cols":"auto"}},[_c('v-switch',{attrs:{"label":"Show all","hide-details":""},model:{value:(_vm.isWithTrashed),callback:function ($$v) {_vm.isWithTrashed=$$v},expression:"isWithTrashed"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},on:{"click:outside":_vm.confirmClose,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.confirmClose.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save.apply(null, arguments)}]},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":"","small":_vm.$vuetify.breakpoint.width >= 320 &&
                                    _vm.$vuetify.breakpoint.width <= 338}},'v-btn',attrs,false),on),[_vm._v("New Category\n                            ")])]}}]),model:{value:(_vm.showInventoryCategoryEditor),callback:function ($$v) {_vm.showInventoryCategoryEditor=$$v},expression:"showInventoryCategoryEditor"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"headline"},[_vm._v("\n                                    "+_vm._s(_vm.formTitle)+"\n                                ")])]),_vm._v(" "),_c('v-card-text',[_c('v-form',{ref:"editInventoryCategoryForm",attrs:{"onSubmit":"return false;"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Name","rules":[
                                                        () =>
                                                            !!_vm.editedInventoryCategory.name ||
                                                            'Required.',
                                                    ]},on:{"input":function($event){_vm.isDirty = true}},model:{value:(
                                                        _vm.editedInventoryCategory.name
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedInventoryCategory, "name", $$v)},expression:"\n                                                        editedInventoryCategory.name\n                                                    "}})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.confirmClose}},[_vm._v("Cancel\n                                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function({ item, index }){return [(!item.deleted_at)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":_vm.$vuetify.breakpoint.smAndUp,"title":"Edit"},on:{"click":function($event){return _vm.editInventoryCategory(item)}}},[_vm._v("\n            mdi-pencil\n        ")]):_vm._e(),_vm._v(" "),(item.deleted_at)?_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.smAndUp,"title":"Delete Archive "},on:{"click":function($event){return _vm.hardDestroy(item, index)}}},[_vm._v("\n            mdi-archive-alert\n        ")]):_vm._e(),_vm._v(" "),(item.deleted_at || item.isTrashing)?_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.smAndUp,"title":"Unarchive "},on:{"click":function($event){return _vm.restore(item, index)}}},[_vm._v("\n            mdi-backup-restore\n        ")]):_c('v-icon',{attrs:{"small":_vm.$vuetify.breakpoint.smAndUp,"title":"Archive"},on:{"click":function($event){return _vm.softDestroy(item, index)}}},[_vm._v("\n            mdi-archive\n        ")])]}}])})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }