<template>
    <v-card color="primary" dark class="profit-card">
        <v-list-item two-line>
            <v-list-item-content>
                <v-list-item-title class="text-h5">
                    Earnings
                </v-list-item-title>
                <v-list-item-subtitle>Profits of period</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="8">
                    <div class="text-overline white--text">Profit</div>
                    <div class="text-h3 text-md-h4 text-lg-h3 white--text">
                        {{ report.profit | currencyFormat }}
                    </div>
                    <div class="summary-comparison-info">
                        <report-comparator
                            :value="report['profit']"
                            :previous-value="comparedReport['profit']"
                        ></report-comparator>
                        <div class="mt-2">
                            Compared with the same previous period
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="4" class="text-center">
                    <div class="text-overline white--text">Margin profit</div>
                    <v-progress-circular
                        dark
                        :rotate="360"
                        :size="80"
                        :width="10"
                        :value="report.profit_margin"
                        color="teal"
                    >
                        <div class="text-h6 white--text profit-margin">
                            {{ report.profit_margin }}%
                        </div>
                    </v-progress-circular>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import currencyFormat from "Filters/currencyFormat";
import round from "Filters/round";
import reportComparator from "Components/sales-reports/profitability-report/report-comparator";

export default {
    components: { reportComparator },

    props: {
        report: Object,
        comparedReport: Object,
    },

    filters: { currencyFormat, round },
};
</script>

<style scoped lang="scss">
.text-h3 {
    margin-bottom: 5px;
}

.profit-card {
    min-height: 263px;
}
</style>
