<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <h4>PROJECT OVERVIEW</h4>
            <div class="d-flex justify-content-center align-items-center">
                <v-icon large @click="previousDate">mdi-chevron-left</v-icon>
                <h5 class="mb-0 mx-6 font-weight-normal">
                    {{ overviewDate | shortDate }}
                </h5>
                <v-icon large @click="nextDate">mdi-chevron-right</v-icon>
            </div>

            <processes v-model="filters" :processes="processReport"></processes>

            <v-bottom-navigation
                v-model="selectedNavigationButton"
                background-color="primary"
                dark
                shift
            >
                <v-btn
                    v-for="navigationButton in navigationButtons"
                    :key="navigationButton.title"
                >
                    <span>{{ navigationButton.title }}</span>
                    <v-icon>{{ navigationButton.icon }}</v-icon>
                </v-btn>
            </v-bottom-navigation>

            <buildings
                v-if="selectedNavigationButton === 0"
                :buildings="buildings"
                :all-shifts="allShifts"
                :filters="filters"
            ></buildings>
            <jobs
                v-else-if="selectedNavigationButton === 1"
                :filters="filters"
            ></jobs>
            <employees
                v-else
                :filters="filters"
                :all-shifts="allShifts"
            ></employees>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import buildings from "Components/overview/mobile/buildings";
import jobs from "Components/overview/mobile/jobs/jobs";
import employees from "Components/overview/mobile/employees/employees";
import overview from "Mixins/overview/overview.mixin";
import shortDate from "Filters/shortDate";
import processes from "Components/overview/mobile/processes";

export default {
    mixins: [overview],

    components: { pageHeaderVuetify, buildings, jobs, employees, processes },

    data() {
        return {
            selectedNavigationButton: 0,
            navigationButtons: [
                {
                    title: "Buildings",
                    icon: "mdi-view-dashboard",
                },
                {
                    title: "Jobs",
                    icon: "mdi-tools",
                },
                {
                    title: "Employees",
                    icon: "mdi-account-group-outline",
                },
            ],
        };
    },

    filters: { shortDate },
};
</script>
