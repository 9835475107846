export default {
    methods: {
        $can(permission) {
            let userPermissions = this.$store.state.user.permissions;
            if (!userPermissions) {
                return false;
            }
            return userPermissions.includes(permission);
        },

        $denies(permission) {
            return !this.$can(permission);
        },

        $isAdmin() {
            let userGroups = this.$store.state.user.groups;
            if (!userGroups) {
                return false;
            }
            let adminGroup = userGroups.find((group) => group.name === "Admin");
            return adminGroup ? true : false;
        },
    },
};
