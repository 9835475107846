<template>
    <v-expansion-panel v-if="$isActive">
        <v-expansion-panel-header>
            {{ myStation.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="text-left">
            <v-list dense>
                <v-subheader>JOBS</v-subheader>

                <v-list-item
                    v-for="jobHistoryShift in jobHistory"
                    :key="jobHistoryShift.id"
                >
                    <v-list-item-content>
                        <v-list-item-title
                            @click="selectedJobHistoryShift = jobHistoryShift"
                            >{{ jobHistoryShift.job.name }}</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list dense>
                <v-subheader>EMPLOYEES</v-subheader>

                <v-list-item
                    v-for="employeeHistoryShift in employeeHistory"
                    :key="employeeHistoryShift.id"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{
                            employeeHistoryShift.user.name
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-expansion-panel-content>

        <job-history-shift-modal
            v-if="selectedJobHistoryShift"
            :job-history-shift="selectedJobHistoryShift"
            @close="selectedJobHistoryShift = null"
        ></job-history-shift-modal>
    </v-expansion-panel>
</template>

<script>
import overviewMachine from "Mixins/overview/overview-machine.mixin";
import jobHistoryShiftModal from "Components/overview/mobile/job-history-shift-modal";

export default {
    components: { jobHistoryShiftModal },

    mixins: [overviewMachine],

    data() {
        return {
            selectedJobHistoryShift: null,
        };
    },

    props: {
        myStation: Object,
        machineHistory: Array,
        filters: Object,
    },

    computed: {
        jobHistory() {
            return this.machineHistory.map((shift) => shift.job_history).flat();
        },

        employeeHistory() {
            return this.machineHistory
                .map((shift) => shift.user_history)
                .flat();
        },
    },

    methods: {
        showJobHistoryShift(jobHistoryShift) {},
    },
};
</script>
