<template>
    <v-card>
        <v-card-title>
            Build Job Collection - {{ jobCollection.name }}
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-subtitle class="text-left">
            <v-icon color="primary" class="pb-1">mdi-web</v-icon>
            Building all jobs matching {{ jobCollection.name }}
        </v-card-subtitle>

        <v-tabs dark background-color="primary" v-model="currentTab">
            <v-tab
                v-for="tab in tabs"
                :key="tab.id"
                @click="
                    $router.push({
                        name: 'job-collection-builder',
                        params: {
                            jobCollection: jobCollection.id,
                            activeTab: tab.route,
                        },
                    })
                "
            >
                {{ tab.name }}
            </v-tab>
        </v-tabs>

        <v-tabs-items>
            <v-card flat>
                <v-card-text class="card-with-scroll" id="steps-container">
                    <component
                        :is="activeTab"
                        :jobCollection="jobCollection"
                        @newStep="$scrollDown()"
                        @estimated-hours-updated="
                            (estimated_hours) =>
                                $emit(
                                    'estimated-hours-updated',
                                    estimated_hours
                                )
                        "
                        @close="$emit('close')"
                    ></component>
                </v-card-text>
            </v-card>
        </v-tabs-items>
    </v-card>
</template>

<script>
import steps from "Components/jobs/job-collection/job-collection-steps";
import automaticScrollDown from "Mixins/automaticScrollDown.mixin";

export default {
    mixins: [automaticScrollDown],
    components: { steps },
    data() {
        return {
            currentTab: null,
            tabs: [{ id: 0, name: "Steps", route: "steps" }],
        };
    },
    props: {
        jobCollection: Object,
    },
    computed: {
        activeTab: function () {
            return this.$route.params.activeTab;
        },
    },
    watch: {
        "$route.params.activeTab": {
            immediate: true,
            handler: function () {
                const vm = this;
                let currentTab = this.tabs.find((tab) => {
                    return tab.route === vm.$route.params.activeTab;
                });
                if (currentTab) {
                    vm.currentTab = currentTab.id;
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.card-with-scroll {
    max-height: calc(100vh - 180px);
    overflow-y: scroll;
    scrollbar-width: thin;
    padding-bottom: 0px;
}
</style>
