<template>
    <div>
        <v-chip
            v-for="item in visibleItems"
            :key="item.id"
            class="ma-1"
            small
            :color="chipColor"
        >
            <v-icon left small color="grey darken-2">{{ icon }}</v-icon>
            {{ item.name }}
        </v-chip>

        <span v-if="hiddenItems.length > 0" class="grey--text text-caption">
            <v-tooltip
                bottom
                max-width="400"
                color="white"
                content-class="custom-tooltip"
            >
                <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"
                        >({{ hiddenItems.length }} more)</span
                    >
                </template>
                <span v-for="item in hiddenItems" :key="item.id">
                    <v-chip class="ma-2" small :color="chipColor">
                        <v-icon left small color="grey darken-2">{{
                            icon
                        }}</v-icon>
                        {{ item.name }}
                    </v-chip>
                </span>
            </v-tooltip>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        items: Array,
        numberItemsToShow: Number,
        chipColor: String,
        icon: String,
    },
    computed: {
        visibleItems: function () {
            return this.items.slice(0, this.numberItemsToShow);
        },
        hiddenItems: function () {
            return this.items.slice(this.numberItemsToShow);
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-tooltip {
    opacity: 1 !important;
    text-align: left;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>
