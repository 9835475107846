var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.organizations,"loading":_vm.loading,"item-class":_vm.rowClasses},on:{"click:row":_vm.focusOnOrganization},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Organizations")]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},on:{"click:outside":_vm.confirmClose,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.confirmClose.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save.apply(null, arguments)}]},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("New Organization")])]}}]),model:{value:(_vm.showOrganizationEditor),callback:function ($$v) {_vm.showOrganizationEditor=$$v},expression:"showOrganizationEditor"}},[_vm._v(" "),_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_vm._v(" "),_c('v-card-text',[_c('v-form',{ref:"editOrganizationForm",attrs:{"onSubmit":"return false;"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Name","rules":[
                                                () =>
                                                    !!_vm.editedOrganization.name ||
                                                    'Required.',
                                            ]},on:{"input":function($event){_vm.isDirty = true}},model:{value:(
                                                _vm.editedOrganization.name
                                            ),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "name", $$v)},expression:"\n                                                editedOrganization.name\n                                            "}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Limit of user accounts","rules":[
                                                _vm.rules.required,
                                                _vm.rules.integer,
                                                _vm.rules.min(1),
                                            ]},on:{"input":function($event){_vm.isDirty = true}},model:{value:(
                                                _vm.editedOrganization.limit_number_user_accounts
                                            ),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "limit_number_user_accounts", $$v)},expression:"\n                                                editedOrganization.limit_number_user_accounts\n                                            "}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Limit of customer accounts","rules":[
                                                _vm.rules.required,
                                                _vm.rules.integer,
                                                _vm.rules.min(1),
                                            ]},on:{"input":function($event){_vm.isDirty = true}},model:{value:(
                                                _vm.editedOrganization.limit_number_customer_accounts
                                            ),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "limit_number_customer_accounts", $$v)},expression:"\n                                                editedOrganization.limit_number_customer_accounts\n                                            "}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-switch',{attrs:{"label":"Access","hide-details":""},model:{value:(
                                                _vm.editedOrganization.has_access
                                            ),callback:function ($$v) {_vm.$set(_vm.editedOrganization, "has_access", $$v)},expression:"\n                                                editedOrganization.has_access\n                                            "}})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.confirmClose}},[_vm._v("Cancel")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)]},proxy:true},{key:"item.has_access",fn:function({ item }){return [_c('v-switch',{attrs:{"hide-details":""},on:{"change":function($event){return _vm.updateOrganizationAccess(item)}},model:{value:(item.has_access),callback:function ($$v) {_vm.$set(item, "has_access", $$v)},expression:"item.has_access"}})]}},{key:"item.actions",fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editOrganization(item)}}},[_vm._v("\n            mdi-pencil\n        ")])]}}])})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }