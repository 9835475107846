<template>
    <v-app>
        <v-container fluid class="text-left">
            <page-header-vuetify></page-header-vuetify>

            <v-row>
                <v-col cols="10" sm="5" md="4" lg="3">
                    <v-dialog
                        ref="dialog"
                        v-model="showDatePicker"
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                label="Period"
                                :value="periodString"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                class="date-input"
                            ></v-text-field>
                        </template>
                        <v-calendar
                            :is-range="true"
                            :attributes="[
                                {
                                    highlight: {
                                        backgroundColor: '#ddd',
                                    },
                                },
                            ]"
                            mode="single"
                            v-model="period"
                            is-inline
                            is-expanded
                            @input="periodChanged"
                        ></v-calendar>
                    </v-dialog>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <profitability-report-summary
                        :report="report"
                        :comparedReport="comparedReport"
                    >
                    </profitability-report-summary>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <profitability-report-earnings
                        :report="report"
                        :comparedReport="comparedReport"
                    >
                    </profitability-report-earnings>
                </v-col>

                <v-col cols="12" md="6" lg="4" class="text-center">
                    <profitability-report-costs :report="report">
                    </profitability-report-costs>
                </v-col>

                <v-col cols="12" md="12" lg="4">
                    <profitability-sales-order-button
                        :start="start"
                        :end="end"
                        :number-sales-orders="report.number_sales_orders"
                    ></profitability-sales-order-button>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import pieChart from "Components/utils/pie-chart";
import profitabilitySalesOrderButton from "Components/sales-reports/profitability-report/profitability-sales-order-button";
import profitabilityReportSummary from "Components/sales-reports/profitability-report/profitability-report-summary";
import profitabilityReportCosts from "Components/sales-reports/profitability-report/profitability-report-costs";
import profitabilityReportEarnings from "Components/sales-reports/profitability-report/profitability-report-earnings";
import VCalendar from "v-calendar/lib/components/date-picker.umd";

export default {
    components: {
        pageHeaderVuetify,
        pieChart,
        profitabilitySalesOrderButton,
        profitabilityReportSummary,
        profitabilityReportCosts,
        profitabilityReportEarnings,
        VCalendar,
    },

    data() {
        return {
            report: {},
            comparedReport: {},
            showDatePicker: false,
            period: {
                start: Vue.moment(this.$route.params.from).toDate(),
                end: Vue.moment(this.$route.params.to).toDate(),
            },
        };
    },

    computed: {
        start() {
            const start = this.period.start;
            return Vue.moment(start).format("YYYY-MM-DD");
        },

        end() {
            const end = this.period.end;
            return Vue.moment(end).format("YYYY-MM-DD");
        },

        periodString() {
            return `${this.start} - ${this.end}`;
        },

        previousPeriod() {
            const vm = this;
            let start = Vue.moment(vm.start, "YYYY-MM-DD");
            let end = Vue.moment(vm.end, "YYYY-MM-DD");

            let previousEnd = start.subtract(1, "days");
            let previousEndString = previousEnd.format("YYYY-MM-DD");
            let diffInDays = end.diff(start, "days") - 1;
            let previousStart = previousEnd.subtract(diffInDays, "days");

            return {
                start: previousStart.format("YYYY-MM-DD"),
                end: previousEndString,
            };
        },
    },

    created: function () {
        const vm = this;
        vm.getReports();
    },

    methods: {
        getReports: function () {
            const vm = this;
            const previousStart = vm.previousPeriod.start;
            const previousEnd = vm.previousPeriod.end;

            const reportRequests = [
                vm.getProfitabilityReport(vm.start, vm.end),
                vm.getProfitabilityReport(previousStart, previousEnd),
            ];

            Promise.all(reportRequests).then((reports) => {
                Vue.set(vm, "report", reports[0]);
                Vue.set(vm, "comparedReport", reports[1]);
            });
        },

        getProfitabilityReport: function (from, to) {
            return new Promise((resolve, reject) => {
                Vue.backendApi()
                    .reports()
                    .salesOrders()
                    .profitabilityReport({
                        from,
                        to,
                    })
                    .then(success, fail);

                function success(response) {
                    resolve(response.data);
                }

                function fail(error) {
                    console.log("An error occurred");
                }
            });
        },

        periodChanged: function () {
            const vm = this;
            vm.$router.push({
                name: "profitability-report",
                params: {
                    from: vm.start,
                    to: vm.end,
                },
            });
            vm.showDatePicker = false;
            vm.getReports();
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .v-progress-circular__underlay {
    stroke: rgba(0, 0, 0, 0.4);
}

.summary-comparison-info {
    display: flex;
    align-items: center;
    color: white;
}
</style>
