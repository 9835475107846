<template>
    <v-col cols="12">
        <v-toolbar flat color="primary" dark>
            <v-toolbar-title>{{ organization.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card>
            <v-card-text class="pb-0" v-if="!organization.has_access">
                <v-alert class="mb-0 text-left" outlined type="warning"
                    >This organization is currently disabled.</v-alert
                >
            </v-card-text>
            <v-card-title class="pb-0">Summary</v-card-title>
            <v-row class="text-left">
                <v-col cols="3">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Created at</v-list-item-title>
                            <v-list-item-subtitle>{{
                                organization.created_at | date
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="3">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Updated at</v-list-item-title>
                            <v-list-item-subtitle>{{
                                organization.updated_at | date
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
        </v-card>
    </v-col>
</template>

<script>
import date from "Filters/compactDateTime";

export default {
    data: data,
    methods: getMethods(),
    props: {
        organization: Object,
    },
    filters: {
        date,
    },
};

function data() {
    return {};
}

function getMethods() {
    return {
        save: save,
    };

    function save() {
        const vm = this;
        vm.$emit("save", vm.organization);
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--switch label {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0px;
}
</style>
