import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: "#7CA6AD",
            },
        },
    },
};

export default new Vuetify(opts);
