<template>
    <drop-list
        :items="myUserHistory"
        class="employee-container"
        @insert="onDrop"
        @reorder="$event.apply(myUserHistory)"
        mode="cut"
        :accepts-data="shouldAcceptDrop"
    >
        <template v-slot:item="{ item: userHistory }">
            <drag
                class="employee"
                @cut="removeUserHistory(userHistory)"
                :data="userHistory.user"
                :class="{
                    removing: isRemoving(userHistory),
                }"
                :key="userHistory.user.id"
                @dragstart="draggingEmployee = true"
                @dragend="draggingEmployee = false"
            >
                <i class="fa fa-user" aria-hidden="true"></i>
                {{ userHistory.user.name }}
                <i
                    class="fa fa-trash"
                    aria-hidden="true"
                    @click="removeUserHistory(userHistory)"
                ></i>
            </drag>
        </template>
        <template v-slot:feedback="{ data: user }">
            <div class="employee ghost" :key="user.id">{{ user.name }}</div>
        </template>
    </drop-list>
</template>

<script>
import machineShiftEmployeeListMixin from "Components/overview/building/machine/mixins/machine-shift-employee-list.mixin";

export default {
    mixins: [machineShiftEmployeeListMixin],

    watch: getWatchers(),

    data() {
        return {
            myUserHistory: []
        };
    },

    mounted: mounted,

    methods: {
        onDrop(dropResult) {
            const vm = this;
            const user = dropResult.data;
            if (userOnShift(user) || vm.machine.down_time) {
                return;
            }

            let pendingId = getPendingId();
            vm.myUserHistory.splice(dropResult.index, 0, {
                id: pendingId,
                user,
            });

            Vue.backendApi()
                .stationShift(vm.shift.id)
                .userHistory(user.id, {
                    started_at: vm.shift.started_at,
                    ended_at: vm.shift.ended_at,
                })
                .then(success, failure);

            function userOnShift(user) {
                return vm.myUserHistory.some(
                    (userHistory) => userHistory.user.id === user.id
                );
            }

            function getPendingId() {
                return "pending-" + Math.floor(Math.random() * 2000);
            }

            function success(response) {
                const userHistory = vm.myUserHistory.find(
                    (userHistory) => userHistory.id === pendingId
                );
                Object.assign(userHistory, response.data);
            }

            function failure(error) {
                let index = vm.myUserHistory.findIndex(function (userHistory) {
                    return userHistory.id === pendingId;
                });
                vm.myUserHistory.splice(index, 1);
                console.log("Error trying to add employee to machine");
            }
        },

        removeUserHistory(userHistory) {
            const vm = this;
            vm.removing.push(userHistory.id);

            Vue.backendApi()
                .stationShift(vm.shift.id)
                .destroyUserHistory(userHistory.id)
                .then(success, failure);

            function success(response) {
                vm.$spliceById(vm.removing, userHistory.id);
                vm.$spliceById(vm.myUserHistory, userHistory.id, (i) => {
                    return i.id === userHistory.id;
                });
            }

            function failure(error) {
                vm.$spliceById(vm.removing, userHistory.id);
                console.log("An error occurred trying to remove user history");
            }
        },
    },
};

function getWatchers() {
    return {
        shift: {
            handler: shift,
            immediate: true,
            deep: true,
        },
    };

    function shift() {
        const vm = this;
        let myUserHistory =
            vm.shift.user_history.length > 1
                ? vm.shift.user_history.slice(0, 1)
                : vm.shift.user_history;
        Vue.set(vm, "myUserHistory", myUserHistory);
    }
}

function mounted() {
    const vm = this;

    vm.$eventHub.$on("showAllInShift", (shift) => {
        if (vm.shift.id === shift.id) {
            Vue.set(vm, "myUserHistory", vm.shift.user_history);
        }
    });

    vm.$eventHub.$on("showOneInShift", (shift) => {
        if (vm.shift.id === shift.id && !this.draggingEmployee) {
            let myUserHistory =
                vm.shift.user_history.length > 1
                    ? vm.shift.user_history.slice(0, 1)
                    : vm.shift.user_history;
            Vue.set(vm, "myUserHistory", myUserHistory);
        }
    });
}
</script>

<style scoped lang="scss">
.employee-container {
    min-height: 22px;
}

.employee {
    position: relative;
    padding: 2px;

    &.ghost {
        background-color: rgb(255, 220, 220);
        border: 2px dashed black;
    }

    & .fa.fa-user {
        font-weight: 400;
        font-size: 10px;
    }

    & .fa.fa-trash {
        border-radius: 50%;
        right: 2px;
        padding: 4px;
        position: absolute;
        background-color: burlywood;
        display: none;
    }

    &:hover {
        & .fa.fa-trash {
            display: inline-block;
            cursor: pointer;
        }
    }
}

.removing {
    opacity: 0.5;
    text-decoration: line-through;
}
</style>
