<template>
    <date-picker
        lang="en"
        format="DD / MM / YYYY"
        :value="value"
        @change="emitChange"
    ></date-picker>
</template>

<script>
import datePicker from "vue2-datepicker";
export default {
    name: "editable-item-date-picker",
    props: {
        value: String,
    },
    components: { datePicker },
    methods: getMethods(),
};

function getMethods() {
    return {
        emitChange: emitChange,
    };

    function emitChange(value) {
        var d = Vue.moment(value);
        this.$emit("change", d.format("YYYY-MM-DD HH:mm:ss"));
    }
}
</script>

<style scoped></style>
