import axios from "axios";
import store from "./store";

export default {
    install: install,
};

function install(Vue, options) {
    const axiosInstance = axios.create({});
    axiosInstance.interceptors.response.use(success, error);

    function success(response) {
        return response;
    }

    function error(error) {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }
        if (error.response.status === 422) {
            return Promise.reject(error);
        }
        if (error.response.status === 401) {
            window.location = "/login";
        } else {
            store.commit("addError", error.response.data.message);
            console.log(error.response);
        }
        return Promise.reject(error);
    }

    Vue.backendApi = _backendApi;

    function _backendApi() {
        return {
            organizations: organizations,
            user: user,
            blockers: blockers,
            users: users,
            groups: groups,
            employees: employees,
            processes: processes,
            stationDowntimes: stationDowntimes,
            unavailableReasons: unavailableReasons,
            units: units,
            typeOfHours: typeOfHours,
            buildings: buildings,
            matrix: matrix,
            labels: labels,
            jobs: jobs,
            jobCollections: jobCollections,
            workHistory: workHistory,
            stationShift: stationShift,
            schedules: schedules,
            reports: reports,
            clients: clients,
            salesOrders: salesOrders,
            menuOptions: menuOptions,
            permissions: permissions,
            inventory: inventory,
            inventoryCategories: inventoryCategories,
        };

        function blockers(blockerId) {
            return {
                index: index,
                create: create,
                destroy: destroy,
            };

            function index() {
                return axiosInstance.get("/api/blockers");
            }

            function create(blocker) {
                const payload = { name: blocker };
                return axiosInstance.post("/api/blockers", payload);
            }

            function destroy() {
                return axiosInstance.delete(`/api/blockers/${blockerId}`);
            }
        }

        function organizations(organizationId) {
            return {
                index: index,
                store: store,
                admins: admins,
                buildings: buildings,
            };

            function store(payload) {
                if (organizationId) {
                    return axiosInstance.put(
                        `/api/organizations/${organizationId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(`/api/organizations`, payload);
                }
            }

            function index() {
                return axiosInstance.get("/api/organizations");
            }

            function admins(adminId) {
                return {
                    index: index,
                    store: store,
                };

                function index() {
                    return axiosInstance.get(
                        `/api/organizations/${organizationId}/admins`
                    );
                }

                function store(payload) {
                    if (adminId) {
                        return axiosInstance.put(
                            `/api/organizations/${organizationId}/admins/${adminId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/organizations/${organizationId}/admins`,
                            payload
                        );
                    }
                }
            }

            function buildings(buildingId) {
                return {
                    index: index,
                    store: store,
                };

                function index() {
                    return axiosInstance.get(
                        `/api/organizations/${organizationId}/buildings`
                    );
                }

                function store(payload) {
                    if (buildingId) {
                        return axiosInstance.put(
                            `/api/organizations/${organizationId}/buildings/${buildingId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/organizations/${organizationId}/buildings`,
                            payload
                        );
                    }
                }
            }
        }

        function user() {
            return axiosInstance.get("/api/user");
        }

        function users(userId) {
            return {
                index: index,
                store: store,
                update: update,
                destroy: destroy,
                restore: restore,
            };

            function index(withTrashed) {
                if (withTrashed) {
                    return axiosInstance.get("/api/users/withTrashed");
                } else {
                    return axiosInstance.get("/api/users");
                }
            }

            function store(payload) {
                return axiosInstance.post("/api/users", payload);
            }

            function update(payload) {
                return axiosInstance.put(`/api/users/${userId}`, payload);
            }

            function destroy() {
                return axiosInstance.delete(`/api/users/${userId}`);
            }

            function restore() {
                return axiosInstance.post(`/api/users/${userId}/restore`);
            }
        }

        function groups(groupId) {
            return {
                index: index,
                store: store,
                permissions: permissions,
            };

            function index() {
                return axiosInstance.get("/api/groups");
            }

            function store(payload) {
                if (groupId) {
                    return axiosInstance.put(`/api/groups/${groupId}`, payload);
                } else {
                    return axiosInstance.post(`/api/groups`, payload);
                }
            }

            function permissions(permissionId) {
                return {
                    index: index,
                    store: store,
                    destroy: destroy,
                };

                function index() {
                    return axiosInstance.get(
                        `/api/groups/${groupId}/permissions`
                    );
                }

                function store() {
                    return axiosInstance.post(
                        `/api/groups/${groupId}/permissions/${permissionId}`
                    );
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/groups/${groupId}/permissions/${permissionId}`
                    );
                }
            }
        }

        function employees(employeeId) {
            return {
                index: index,
                store: store,
                update: update,
                destroy: destroy,
                absences: absences,
            };

            function index() {
                return axiosInstance.get("/api/employees");
            }

            function store(payload) {
                return axiosInstance.post("/api/employees", payload);
            }

            function update(payload) {
                return axiosInstance.put(
                    `/api/employees/${employeeId}`,
                    payload
                );
            }

            function destroy(payload) {
                return axiosInstance.delete(`/api/employees/${employeeId}`);
            }

            function absences(absentId) {
                return {
                    on: on,
                    store: store,
                    destroy: destroy,
                };

                function on(date) {
                    return axiosInstance.get(
                        `/api/employees/absences/on/${date}`
                    );
                }

                function store(payload) {
                    if (absentId) {
                        return axiosInstance.put(
                            `/api/employees/${employeeId}/absences/${absentId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/employees/${employeeId}/absences`,
                            payload
                        );
                    }
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/employees/${employeeId}/absences/${absentId}`
                    );
                }
            }
        }

        function processes(processId) {
            return {
                index: index,
                store: store,
            };

            function index() {
                return axiosInstance.get("/api/processes");
            }

            function store(payload) {
                if (processId) {
                    return axiosInstance.put(
                        `/api/processes/${processId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(`/api/processes`, payload);
                }
            }
        }

        function stationDowntimes(stationDowntimeId) {
            return {
                on: on,
                store: store,
                destroy: destroy,
            };

            function on(date) {
                return axiosInstance.get(`/api/station-downtimes/on/${date}`);
            }

            function store(payload) {
                if (stationDowntimeId) {
                    return axiosInstance.put(
                        `/api/station-downtimes/${stationDowntimeId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(
                        `/api/station-downtimes`,
                        payload
                    );
                }
            }

            function destroy() {
                return axiosInstance.delete(
                    `/api/station-downtimes/${stationDowntimeId}`
                );
            }
        }

        function unavailableReasons() {
            return {
                index: index,
            };

            function index() {
                return axiosInstance.get("/api/unavailable-reasons");
            }
        }

        function typeOfHours(typeOfHourId) {
            return {
                index: index,
                store: store,
                makeDefault: makeDefault,
                destroy: destroy,
            };

            function index() {
                return axiosInstance.get("/api/type-of-hours");
            }

            function store(payload) {
                if (typeOfHourId) {
                    return axiosInstance.put(
                        `/api/type-of-hours/${typeOfHourId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(`/api/type-of-hours`, payload);
                }
            }

            function makeDefault() {
                return axiosInstance.post(
                    `/api/type-of-hours/${typeOfHourId}/make-default`
                );
            }

            function destroy() {
                return axiosInstance.delete(
                    `/api/type-of-hours/${typeOfHourId}`
                );
            }
        }

        function units(unitId) {
            return {
                index: index,
                store: store,
                restore: restore,
                softDestroy: softDestroy,
                hardDestroy: hardDestroy,
            };

            function index(withTrashed) {
                if (withTrashed) {
                    return axiosInstance.get("/api/units?type=trashed");
                } else {
                    return axiosInstance.get("/api/units");
                }
            }

            function restore() {
                return axiosInstance.post(`/api/units/${unitId}/restore`);
            }

            function store(payload) {
                if (unitId) {
                    return axiosInstance.put(`/api/units/${unitId}`, payload);
                } else {
                    return axiosInstance.post(`/api/units`, payload);
                }
            }

            function softDestroy() {
                return axiosInstance.delete(
                    `/api/units/${unitId}/soft-destroy`
                );
            }

            function hardDestroy() {
                return axiosInstance.delete(
                    `/api/units/${unitId}/hard-destroy`
                );
            }
        }

        function buildings(buildingId) {
            return {
                index: index,
                store: store,
                stations: stations,
            };

            function store(payload) {
                if (buildingId) {
                    return axiosInstance.put(
                        `/api/buildings/${buildingId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(`/api/buildings`, payload);
                }
            }

            function index() {
                return axiosInstance.get("/api/buildings");
            }

            function stations(stationId) {
                return {
                    index: index,
                    store: store,
                    reorder: reorder,
                    createStationShifts: createStationShifts,
                    destroyStationShifts: destroyStationShifts,
                };

                function index() {
                    return axiosInstance.get(
                        `/api/buildings/${buildingId}/stations`
                    );
                }

                function store(payload) {
                    if (stationId) {
                        return axiosInstance.put(
                            `/api/buildings/${buildingId}/stations/${stationId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/buildings/${buildingId}/stations`,
                            payload
                        );
                    }
                }

                function reorder(payload) {
                    return axiosInstance.post(
                        `/api/buildings/${buildingId}/stations/reorder`,
                        payload
                    );
                }

                function createStationShifts(payload) {
                    return axiosInstance.post(
                        `/api/buildings/${buildingId}/stations/${stationId}/create-station-shifts`,
                        payload
                    );
                }

                function destroyStationShifts(payload) {
                    return axiosInstance.post(
                        `/api/buildings/${buildingId}/stations/${stationId}/destroy-station-shifts`,
                        payload
                    );
                }
            }
        }

        function matrix() {
            return {
                columns: columns,
                jobCollection: jobCollection,
            };

            function columns(columnId) {
                return {
                    index: index,
                    store: store,
                    destroy: destroy,
                    movement: movement,
                    jobCollection: jobCollection,
                };

                function index() {
                    return axiosInstance.get("/api/matrix/columns");
                }

                function store(payload) {
                    if (columnId) {
                        return axiosInstance.put(
                            `/api/matrix/columns/${columnId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/matrix/columns`,
                            payload
                        );
                    }
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/matrix/columns/${columnId}`
                    );
                }

                function movement(payload) {
                    return axiosInstance.post(
                        `/api/matrix/columns/${columnId}/movement`,
                        payload
                    );
                }

                function jobCollection(jobCollectionId) {
                    return {
                        movement: movement,
                    };

                    function movement(payload) {
                        return axiosInstance.post(
                            `/api/matrix/columns/${columnId}/job-collection/${jobCollectionId}/movement`,
                            payload
                        );
                    }
                }
            }

            function jobCollection(jobCollectionId) {
                return {
                    movement: movement,
                };

                function movement(oldColumnId, newColumnId, payload) {
                    return axiosInstance.post(
                        `/api/matrix/job-collection/${jobCollectionId}/movement/from/column/${oldColumnId}/to/column/${newColumnId}`,
                        payload
                    );
                }
            }
        }

        function labels() {
            return {
                index: index,
            };

            function index() {
                return axiosInstance.get(`/api/labels`);
            }
        }

        function jobs(jobId) {
            return {
                stationJobHistory: stationJobHistory,
                index: index,
                store: store,
                destroy: destroy,
                restore: restore,
                processes: processes,
                find: find,
                reportsOn: reportsOn,
                steps: steps,
                materials: materials,
                deleteOwnSteps: deleteOwnSteps,
                journals: journals,
            };

            function stationJobHistory() {
                return axiosInstance.get(
                    `/api/jobs/${jobId}/station-job-history`
                );
            }

            function index() {
                return axiosInstance.get("/api/jobs");
            }

            function store(payload) {
                if (jobId) {
                    return axiosInstance.put(`/api/jobs/${jobId}`, payload);
                } else {
                    return axiosInstance.post("/api/jobs", payload);
                }
            }

            function destroy() {
                return axiosInstance.delete(`/api/jobs/${jobId}`);
            }

            function restore() {
                return axiosInstance.post(`/api/jobs/${jobId}/restore`);
            }

            function processes(jobProcessId) {
                return {
                    put: put,
                };

                function put(payload) {
                    return axiosInstance.put(
                        `/api/jobs/${jobId}/processes`,
                        payload
                    );
                }
            }

            function find() {
                return axiosInstance.post(`/api/jobs/${jobId}`);
            }

            function reportsOn(payload) {
                return axiosInstance.post(`/api/jobsWithTrashed`, payload);
            }

            function steps(stepId) {
                return {
                    index: index,
                    store: store,
                    destroy: destroy,
                    updatePositions: updatePositions,
                    updateEstimates: updateEstimates,
                    copyCollectionSteps: copyCollectionSteps,
                };

                function index() {
                    return axiosInstance.get(`/api/jobs/${jobId}/steps`);
                }

                function store(payload) {
                    if (stepId) {
                        return axiosInstance.put(
                            `/api/jobs/${jobId}/steps/${stepId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/jobs/${jobId}/steps`,
                            payload
                        );
                    }
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/jobs/${jobId}/steps/${stepId}`
                    );
                }

                function updateEstimates(payload) {
                    return axiosInstance.post(
                        `/api/jobs/${jobId}/steps/estimates`,
                        payload
                    );
                }

                function updatePositions(payload) {
                    return axiosInstance.post(
                        `/api/jobs/${jobId}/steps/positions`,
                        payload
                    );
                }

                function copyCollectionSteps(payload) {
                    return axiosInstance.post(
                        `/api/jobs/${jobId}/steps/copy-collection-steps`,
                        payload
                    );
                }
            }

            function deleteOwnSteps(payload) {
                return axiosInstance.put(
                    `/api/jobs/${jobId}/delete-own-steps`,
                    payload
                );
            }

            function materials(materialId) {
                return {
                    index: index,
                    store: store,
                    destroy: destroy,
                    fromInventory: fromInventory,
                };

                function index() {
                    return axiosInstance.get(`/api/jobs/${jobId}/materials`);
                }

                function store(payload) {
                    if (materialId) {
                        return axiosInstance.put(
                            `/api/jobs/${jobId}/materials/${materialId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/jobs/${jobId}/materials`,
                            payload
                        );
                    }
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/jobs/${jobId}/materials/${materialId}`
                    );
                }

                function fromInventory(inventoryItemId) {
                    return {
                        store: store,
                    };

                    function store(payload) {
                        return axiosInstance.post(
                            `/api/jobs/${jobId}/materials/from-inventory/${inventoryItemId}`,
                            payload
                        );
                    }
                }
            }

            function journals(journalId) {
                return {
                    index: index,
                    store: store,
                    destroy: destroy,
                };

                function index(page) {
                    if (page) {
                        return axiosInstance.get(
                            `/api/jobs/${jobId}/job-journal?page=${page}`
                        );
                    }
                    return axiosInstance.get(`/api/jobs/${jobId}/job-journal`);
                }

                function store(payload) {
                    if (journalId) {
                        return axiosInstance.put(
                            `/api/jobs/${jobId}/job-journal/${journalId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/jobs/${jobId}/job-journal`,
                            payload
                        );
                    }
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/jobs/${jobId}/job-journal/${journalId}`
                    );
                }
            }
        }

        function jobCollections(jobCollectionId) {
            return {
                index: index,
                search: search,
                find: find,
                withLabels: withLabels,
                withNoLabel: withNoLabel,
                steps: steps,
            };

            function index(payload = {}) {
                return axiosInstance.post(`/api/job-collections`, payload);
            }

            function search(payload, cancelToken) {
                return axiosInstance.post(
                    `/api/job-collections/search`,
                    payload,
                    {
                        cancelToken: cancelToken,
                    }
                );
            }

            function find(payload) {
                return axiosInstance.post(
                    `/api/job-collections/${jobCollectionId}`,
                    payload
                );
            }

            function withLabels(payload = {}) {
                return axiosInstance.post(
                    `/api/job-collections/with-labels`,
                    payload
                );
            }

            function withNoLabel() {
                return axiosInstance.get(`/api/job-collections/with-no-label`);
            }

            function steps(stepId) {
                return {
                    index: index,
                    destroy: destroy,
                    store: store,
                    updateEstimates: updateEstimates,
                    updatePositions: updatePositions,
                };

                function index() {
                    return axiosInstance.get(
                        `/api/job-collections/${jobCollectionId}/steps`
                    );
                }

                function store(payload) {
                    if (stepId) {
                        return axiosInstance.put(
                            `/api/job-collections/${jobCollectionId}/steps/${stepId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/job-collections/${jobCollectionId}/steps`,
                            payload
                        );
                    }
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/job-collections/${jobCollectionId}/steps/${stepId}`
                    );
                }

                function updateEstimates(payload) {
                    return axiosInstance.post(
                        `/api/job-collections/${jobCollectionId}/steps/estimates`,
                        payload
                    );
                }

                function updatePositions(payload) {
                    return axiosInstance.post(
                        `/api/job-collections/${jobCollectionId}/steps/positions`,
                        payload
                    );
                }
            }
        }

        function workHistory(id) {
            return {
                copyADay: copyADay,
            };

            function copyADay(payload) {
                return axiosInstance.post(
                    `/api/work-history/copy-a-day`,
                    payload
                );
            }
        }

        function stationShift(stationId) {
            return {
                all: all,
                create: create,
                userHistory: userHistory,
                addJobToShift: addJobToShift,
                destroyUserHistory: destroyUserHistory,
                updateJobHistory: updateJobHistory,
                destroyJobHistory: destroyJobHistory,
            };

            function all(payload) {
                return axiosInstance.post(`/api/station-shift/all`, payload);
            }

            function create(payload) {
                return axiosInstance.post("/api/station-shift/create", payload);
            }

            function userHistory(userId, payload) {
                return axiosInstance.post(
                    `/api/station-shift/${stationId}/user-history/${userId}`,
                    payload
                );
            }

            function destroyUserHistory(userHistoryId) {
                return axiosInstance.delete(
                    `/api/station-shift/${stationId}/user-history/${userHistoryId}`
                );
            }

            function updateJobHistory(payload) {
                return axiosInstance.put(
                    `/api/station-shift/${stationId}/job-history/${payload.id}`,
                    payload
                );
            }

            function addJobToShift(jobId, payload) {
                return axiosInstance.post(
                    `/api/station-shift/${stationId}/job-history/job/${jobId}`,
                    payload
                );
            }

            function destroyJobHistory(jobHistoryId) {
                return axiosInstance.delete(
                    `/api/station-shift/${stationId}/job-history/${jobHistoryId}`
                );
            }
        }

        function schedules(scheduleId) {
            return {
                index: index,
                store: store,
                softDestroy: softDestroy,
                hardDestroy: hardDestroy,
                shifts: shifts,
                restore: restore,
            };

            function index(withTrashed) {
                if (withTrashed) {
                    return axiosInstance.get("/api/schedules?type=trashed");
                } else {
                    return axiosInstance.get("/api/schedules");
                }
            }

            function store(payload) {
                if (scheduleId) {
                    return axiosInstance.put(
                        `/api/schedules/${scheduleId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(`/api/schedules`, payload);
                }
            }

            function softDestroy() {
                return axiosInstance.delete(
                    `/api/schedules/${scheduleId}/soft-destroy`
                );
            }

            function hardDestroy() {
                return axiosInstance.delete(
                    `/api/schedules/${scheduleId}/hard-destroy`
                );
            }

            function shifts(shiftId) {
                return {
                    index: index,
                    store: store,
                    softDestroy: softDestroy,
                    hardDestroy: hardDestroy,
                    restore: restore,
                };

                function restore() {
                    return axiosInstance.post(
                        `/api/schedules/${scheduleId}/shifts/${shiftId}/restore`
                    );
                }

                function index(withTrashed) {
                    if (withTrashed) {
                        return axiosInstance.get(
                            `/api/schedules/${scheduleId}/shifts?type=trashed`
                        );
                    } else {
                        return axiosInstance.get(
                            `/api/schedules/${scheduleId}/shifts`
                        );
                    }
                }

                function store(payload) {
                    if (shiftId) {
                        return axiosInstance.put(
                            `/api/schedules/${scheduleId}/shifts/${shiftId}`,
                            payload
                        );
                    } else {
                        return axiosInstance.post(
                            `/api/schedules/${scheduleId}/shifts`,
                            payload
                        );
                    }
                }

                function softDestroy() {
                    return axiosInstance.delete(
                        `/api/schedules/${scheduleId}/shifts/${shiftId}/soft-destroy`
                    );
                }

                function hardDestroy() {
                    return axiosInstance.delete(
                        `/api/schedules/${scheduleId}/shifts/${shiftId}/hard-destroy`
                    );
                }
            }

            function restore() {
                return axiosInstance.post(
                    `/api/schedules/${scheduleId}/restore`
                );
            }
        }

        function reports() {
            return {
                processes: processes,
                jobs: jobs,
                jobCollections: jobCollections,
                salesOrders: salesOrders,
                manufacturing: manufacturing,
                machineTime: machineTime,
                salesOrderItems: salesOrderItems,
            };

            function processes(processId) {
                return {
                    index: index,
                    jobs: jobs,
                    utilization: utilization,
                };

                function index() {
                    return axiosInstance.get(`/api/reports/processes`);
                }

                function jobs() {
                    return axiosInstance.get(
                        `/api/reports/processes/${processId}/jobs`
                    );
                }

                function utilization(from, to) {
                    return axiosInstance.get(
                        `/api/reports/processes/utilization/from/${from}/to/${to}`
                    );
                }
            }

            function jobs(jobId) {
                return {
                    jobProcesses: jobProcesses,
                    stationJobReport: stationJobReport,
                    employeeReport: employeeReport,
                    progress: progress,
                    costCalculations: costCalculations,
                };

                function jobProcesses() {
                    return {
                        index: index,
                        nonEfficientHours: nonEfficientHours
                    }

                    function index() {
                        return axiosInstance.get(
                            `/api/reports/jobs/${jobId}/job-processes`
                        );
                    }

                    function nonEfficientHours(processId) {
                        return axiosInstance.get(
                            `/api/reports/jobs/${jobId}/job-processes/${processId}`
                        );
                    }
                }

                function stationJobReport() {
                    return {
                        index: index,
                        nonEfficientHours: nonEfficientHours
                    }

                    function index() {
                        return axiosInstance.get(
                            `/api/reports/jobs/${jobId}/station-job-report`
                        );
                    }

                    function nonEfficientHours(stationId) {
                        return axiosInstance.get(
                            `/api/reports/jobs/${jobId}/station-job-report/${stationId}`
                        );
                    }
                }


                function employeeReport() {
                    return {
                        index: index,
                        nonEfficientHours: nonEfficientHours,
                        nonEfficientHoursWithoutEmployee: nonEfficientHoursWithoutEmployee
                    }

                    function index() {
                        return axiosInstance.get(
                            `/api/reports/jobs/${jobId}/employee-report`
                        );
                    }

                    function nonEfficientHours(userId) {
                        return axiosInstance.get(
                            `/api/reports/jobs/${jobId}/employee-report/${userId}`
                        );
                    }

                    function nonEfficientHoursWithoutEmployee() {
                        return axiosInstance.get(
                            `/api/reports/jobs/${jobId}/employee-report/non-efficient-hours-Without-employee`
                        );
                    }
                }

                function progress() {
                    return axiosInstance.get(
                        `/api/reports/jobs/${jobId}/progress`
                    );
                }

                function costCalculations() {
                    return axiosInstance.get(
                        `/api/reports/jobs/${jobId}/cost-calculations`
                    );
                }
            }

            function jobCollections(jobCollectionId) {
                return {
                    progress: progress
                }

                function progress() {
                    return axiosInstance.get(
                        `/api/reports/job-collections/${jobCollectionId}/progress`
                    );
                }
            }

            function salesOrders(salesOrderId) {
                return {
                    on: on,
                    show: show,
                    profitabilityReport: profitabilityReport,
                };

                function on(payload) {
                    return axiosInstance.post(
                        `/api/reports/sales-orders`,
                        payload
                    );
                }

                function show() {
                    return axiosInstance.get(
                        `/api/reports/sales-orders/${salesOrderId}`
                    );
                }

                function profitabilityReport(payload) {
                    return axiosInstance.post(
                        `/api/reports/sales-orders/profitability-report`,
                        payload
                    );
                }
            }

            function salesOrderItems(salesOrderItemId) {
                return {
                    index: index,
                };

                function index() {
                    return axiosInstance.get(
                        `/api/reports/sales-order-items/${salesOrderItemId}`
                    );
                }
            }

            function manufacturing() {
                return {
                    on: on,
                };

                function on(payload) {
                    return axiosInstance.post(
                        `/api/reports/manufacturing`,
                        payload
                    );
                }
            }

            function machineTime(stationId) {
                return {
                    index: index,
                    downtimes: downtimes,
                };

                function index(payload) {
                    return axiosInstance.post(
                        `/api/reports/station-time`,
                        payload
                    );
                }

                function downtimes(payload) {
                    return axiosInstance.post(
                        `/api/reports/station-time/${stationId}/downtimes`,
                        payload
                    );
                }
            }
        }

        function clients(clientId) {
            return {
                index: index,
                store: store,
                softDestroy: softDestroy,
                hardDestroy: hardDestroy,
                restore: restore,
                search: search,
            };

            function softDestroy() {
                return axiosInstance.delete(
                    `/api/clients/${clientId}/soft-destroy`
                );
            }

            function hardDestroy() {
                return axiosInstance.delete(
                    `/api/clients/${clientId}/hard-destroy`
                );
            }

            function restore() {
                return axiosInstance.post(`/api/clients/${clientId}/restore`);
            }

            function index(payload) {
                if (clientId) {
                    return axiosInstance.get(`/api/clients/${clientId}`);
                } else {
                    return axiosInstance.get(`/api/clients`, {
                        params: payload,
                    });
                }
            }

            function store(payload) {
                if (clientId) {
                    return axiosInstance.put(
                        `/api/clients/${clientId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(`/api/clients`, payload);
                }
            }

            function search(payload) {
                return axiosInstance.post(`/api/clients/search`, payload);
            }
        }

        function salesOrders(salesId) {
            return {
                index: index,
                store: store,
                items: items,
                show: show,
                search: search,
                destroy: destroy,
                reports: reports,
            };

            function index(payload) {
                if (payload) {
                    return axiosInstance.get(`/api/sales-orders`, {
                        params: payload,
                    });
                } else {
                    return axiosInstance.get(`/api/sales-orders`);
                }
            }

            function store(payload) {
                if (salesId) {
                    return axiosInstance.put(
                        `/api/sales-orders/${salesId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(`/api/sales-orders`, payload);
                }
            }

            function items(itemId) {
                return {
                    add: add,
                    put: put,
                    destroy: destroy,
                };

                function add(payload) {
                    return axiosInstance.post(
                        `/api/sales-orders/${salesId}/items/add`,
                        payload
                    );
                }

                function put(payload) {
                    return axiosInstance.put(
                        `/api/sales-orders/${salesId}/items/${itemId}`,
                        payload
                    );
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/sales-orders/${salesId}/items/${itemId}`
                    );
                }
            }

            function show() {
                return axiosInstance.get(`/api/sales-orders/${salesId}`);
            }

            function search(payload, page) {
                if (page) {
                    return axiosInstance.post(
                        `/api/sales-orders/search?page=${page}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(
                        `/api/sales-orders/search`,
                        payload
                    );
                }
            }

            function destroy() {
                return axiosInstance.delete(`/api/sales-orders/${salesId}`);
            }
        }

        function menuOptions() {
            return {
                index: index,
            };

            function index() {
                return axiosInstance.get("/api/menu-options");
            }
        }

        function permissions() {
            return {
                index: index,
            };

            function index() {
                return axiosInstance.get(`/api/permissions`);
            }
        }

        function inventory(inventoryId) {
            return {
                index: index,
                indexWithTrashed: indexWithTrashed,
                store: store,
                search: search,
                addJobs: addJobs,
                softDestroy: softDestroy,
                hardDestroy: hardDestroy,
                jobHistory: jobHistory,
                attachment: attachment,
                restore: restore,
                serialNumber: serialNumber
            };

            function index(payload) {
                return axiosInstance.get("/api/inventory", {
                    params: payload,
                });
            }

            function indexWithTrashed(payload) {
                return axiosInstance.get("/api/inventory/withTrashed", {
                    params: payload,
                });
            }

            function store(payload) {
                if (inventoryId) {
                    return axiosInstance.put(
                        `/api/inventory/${inventoryId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(`/api/inventory`, payload);
                }
            }

            function search(payload, cancelToken) {
                return axiosInstance.post(`/api/inventory/search`, payload, {
                    cancelToken: cancelToken,
                });
            }

            function addJobs(payload) {
                return axiosInstance.post(
                    `/api/inventory/${inventoryId}/add-jobs`,
                    payload
                );
            }

            function softDestroy() {
                return axiosInstance.delete(
                    `/api/inventory/${inventoryId}/soft-destroy`
                );
            }

            function hardDestroy() {
                return axiosInstance.delete(
                    `/api/inventory/${inventoryId}/hard-destroy`
                );
            }

            function jobHistory() {
                return axiosInstance.get(
                    `/api/inventory/${inventoryId}/job-history`
                );
            }

            function attachment() {
                return {
                    store: store,
                    destroy: destroy,
                };

                function store(payload) {
                    return axiosInstance.post(
                        `/api/inventory/${inventoryId}/attachment`,
                        payload
                    );
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/inventory/${inventoryId}/attachment`
                    );
                }
            }

            function restore() {
                return axiosInstance.post(
                    `/api/inventory/${inventoryId}/restore`
                );
            }

            function serialNumber(inventoryItemSerialNumberId) {
                return {
                    // serialNumbers are created using inventoryItem endpoint
                    destroy: destroy
                }

                function destroy() {
                    return axiosInstance.delete(
                        `/api/inventory/${inventoryId}/serial-number/${inventoryItemSerialNumberId}`
                    );
                }
            }
        }

        function inventoryCategories(inventoryCategoryId) {
            return {
                index: index,
                store: store,
                restore: restore,
                softDestroy: softDestroy,
                hardDestroy: hardDestroy,
            };

            function restore() {
                return axiosInstance.post(
                    `/api/inventory-categories/${inventoryCategoryId}/restore`
                );
            }

            function index(withTrashed) {
                if (withTrashed) {
                    return axiosInstance.get(
                        "/api/inventory-categories?type=trashed"
                    );
                } else {
                    return axiosInstance.get("/api/inventory-categories");
                }
            }

            function store(payload) {
                if (inventoryCategoryId) {
                    return axiosInstance.put(
                        `/api/inventory-categories/${inventoryCategoryId}`,
                        payload
                    );
                } else {
                    return axiosInstance.post(
                        `/api/inventory-categories`,
                        payload
                    );
                }
            }

            function softDestroy() {
                return axiosInstance.delete(
                    `/api/inventory-categories/${inventoryCategoryId}/soft-destroy`
                );
            }

            function hardDestroy() {
                return axiosInstance.delete(
                    `/api/inventory-categories/${inventoryCategoryId}/hard-destroy`
                );
            }
        }
    }
}
