<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="500px"
    >
        <v-card class="mx-auto text-left" tile>
            <v-card-title
                >{{ jobHistoryShift.job.name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-card-title>
            <v-card-subtitle>{{
                jobHistoryShift.job.serial_number
            }}</v-card-subtitle>
            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Hours</td>
                                <td>{{ jobHistoryShift.all_hours }}</td>
                            </tr>
                            <tr>
                                <td>Type of Hours</td>
                                <td>{{ jobHistoryShift.type_of_hour.name }}</td>
                            </tr>
                            <tr>
                                <td>From</td>
                                <td>{{ jobHistoryShift.started_at | time }}</td>
                            </tr>
                            <tr>
                                <td>To</td>
                                <td>{{ jobHistoryShift.ended_at | time }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import time from "Filters/timeFromDateTime";

export default {
    data() {
        return {
            show: true,
        };
    },

    props: {
        jobHistoryShift: Object,
    },

    filters: { time },
};
</script>
