<template>
    <v-container fluid>
        <v-combobox
            v-model="blockers_c"
            :items="allBlockers"
            @change="selectedBlockersChanged"
            item-text="name"
            item-value="id"
            :hide-no-data="!search"
            :search-input.sync="search"
            return-object
            label="Blockers"
            multiple
            small-chips
            ref="combobox"
            :menu-props="{ maxHeight: '180' }"
        >
            <template v-slot:item="{ active, item, attrs, on, index }">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-row no-gutters align="start">
                                <span>{{ item.name }}</span>
                                <v-spacer></v-spacer>
                                <v-icon
                                    small
                                    @click.stop="archiveBlocker(item)"
                                    title="Remove this option"
                                    class="mx-1"
                                >
                                    mdi-archive
                                </v-icon>
                            </v-row>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:no-data>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            Press <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="`cyan lighten-3`"
                    :input-value="selected"
                    label
                    small
                >
                    <span class="pr-2">
                        {{ item.name }}
                    </span>
                    <v-icon small @click="deleteBlocker(item)"
                        >mdi-close
                    </v-icon>
                </v-chip>
            </template>
        </v-combobox>
    </v-container>
</template>

<script>
import getTemporaryId from "Mixins/getTemporaryId.mixin";

export default {
    data: data,
    mixins: [getTemporaryId],
    methods: getMethods(),
    watch: getWatchers(),
    mounted: mounted,
    props: {
        blockers: Array,
    },
};

function data() {
    return {
        blockers_c: [],
        allBlockers: [],
        search: null,
    };
}

function getWatchers() {
    return {
        blockers: {
            handler: value,
            immediate: true,
        },
    };

    function value() {
        this.blockers_c = JSON.parse(JSON.stringify(this.blockers));
    }
}

function getMethods() {
    return {
        selectedBlockersChanged: selectedBlockersChanged,
        archiveBlocker: archiveBlocker,
        deleteBlocker: deleteBlocker,
    };

    function selectedBlockersChanged(selectedItems) {
        let vm = this;

        let newBlocker = findNewBlocker();
        if (newBlocker === undefined) {
            return;
        }

        if (typeof newBlocker === "string") {
            createBlocker(newBlocker);
        } else {
            vm.$emit("addBlocker", newBlocker);
        }

        function findNewBlocker() {
            return selectedItems.find((blocker) => {
                // if blocker is a string they typed it so we found the new one!
                if (typeof blocker === "string") {
                    return true;
                }

                const isFound = vm.blockers.some(
                    (item) => blocker.id === item.id
                );
                return !isFound;
            });
        }

        function createBlocker(item) {
            Vue.backendApi().blockers(item).create(item).then(success, fail);

            function success(response) {
                let newBLocker = response.data;

                vm.allBlockers.push(newBLocker);
                let index = selectedItems.findIndex(
                    (searchItem) => searchItem === item
                );
                selectedItems.splice(index, 1, newBLocker);

                vm.$emit("addBlocker", newBLocker);

                vm.loading = false;
            }

            function fail() {
                vm.loading = false;
                console.log("An error occurred trying to save a new blocker");
            }
        }
    }

    function archiveBlocker(item) {
        const vm = this;

        Vue.backendApi().blockers(item.name).destroy().then(success, fail);

        function success(response) {
            const allBlockers = vm.allBlockers.filter(
                (blocker) => blocker.id !== item.id
            );
            Vue.set(vm, "allBlockers", allBlockers);
            vm.loading = false;
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to archive blockers");
        }
    }

    function deleteBlocker(blockerToDelete) {
        const vm = this;
        let blockerIndex = this.blockers.findIndex((blocker) => {
            return blocker.id === blockerToDelete.id;
        });
        vm.$emit("deleteBlocker", blockerIndex);
    }
}

function mounted() {
    const vm = this;
    getAllBlockers();

    function getAllBlockers() {
        Vue.backendApi().blockers().index().then(success, fail);

        function success(response) {
            Vue.set(vm, "allBlockers", response.data);
            vm.loading = false;
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get blockers");
        }
    }
}
</script>
