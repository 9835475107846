<template>
    <div>
        {{ applyFilters(value) }}
    </div>
</template>

<script>
import shortDate from "Filters/shortDate";
import compactDateTime from "Filters/compactDateTime";
import percent from "Filters/percent";
import round from "Filters/round";
import truncate from "Filters/truncate";
import arrayToString from "Filters/arrayToString";
import secondsToTime from "Filters/secondsToTime";
import secondsToReadableDuration from "Filters/secondsToReadableDuration";
import duration from "Filters/duration";
import showNegativeAsZero from "Filters/showNegativeAsZero";

export default {
    name: "editable-item-default-viewer",
    methods: getMethods(),
    props: {
        value: [Number, String, Array, Object],
        meta: Object,
    },
    filters: getFilters(),
};

function getFilters() {
    return {
        // filter: filter,
        shortDate,
        percent,
        round,
        truncate,
        arrayToString,
        compactDateTime,
        secondsToTime,
        secondsToReadableDuration,
        duration,
        showNegativeAsZero,
    };
}
function getMethods() {
    return {
        applyFilters: applyFilters,
    };

    function applyFilters(value) {
        const vm = this;
        vm.meta["filters"].forEach(function (filter) {
            const delimiterPosition = filter.indexOf(".");
            let filterName;
            let filterParameter;
            if (delimiterPosition > -1) {
                filterName = filter.substring(0, delimiterPosition);
                filterParameter = filter.substring(delimiterPosition + 1);
            } else {
                filterName = filter;
            }
            value = vm.$options.filters[filterName](value, filterParameter);
        });
        return value;
    }
}
</script>

<style scoped></style>
