var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:{ 'd-none': !_vm.job.id }},[_c('v-toolbar',{attrs:{"flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [(_vm.jobHasHistory)?_c('v-tabs',{attrs:{"dark":"","background-color":"primary"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.reports),function(report){return _c('v-tab',{key:report.tab,on:{"click":function($event){return _vm.$router.push({
                            name: 'job-reports',
                            params: {
                                id: _vm.job.id,
                                activeTab: report.route,
                            },
                        })}}},[_vm._v("\n                    "+_vm._s(report.name)+"\n                ")])}),1):_c('p',{staticClass:"text-left"},[_vm._v("\n                No job history found. Add job history on the overview page.\n            ")])]},proxy:true}])},[_c('v-toolbar-title',{attrs:{"title":`${_vm.activeReport.name} - ${_vm.job.name}`}},[_vm._v("\n            "+_vm._s(_vm.activeReport.name)+" - "+_vm._s(_vm.job.name)+"\n        ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"white","small":"","title":"Edit Estimates","disabled":!!_vm.job.deleted_at},on:{"click":function($event){return _vm.$router.push({
                    name: 'job-estimates',
                    params: {
                        id: _vm.job.id,
                    },
                })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-progress-clock")])],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":!!_vm.job.deleted_at,"small":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'job-builder',
                    params: {
                        id: _vm.job.id,
                        activeTab: 'steps',
                    },
                })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","title":"Build"}},[_vm._v("\n                mdi-hammer-wrench\n            ")]),_vm._v("\n            Build Job\n        ")],1),_vm._v(" "),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),(_vm.jobHasHistory)?_c('v-tabs-items',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{style:(_vm.$vuetify.breakpoint.smAndUp ? 'height: 250px;' : '')},[(_vm.jobHasHistory)?_c(_vm.activeTab,{key:_vm.uniqueKey,tag:"component",attrs:{"job":_vm.job},on:{"processes-updated":function($event){return _vm.$emit('processes-updated')},"job-work-hours-updated":function($event){return _vm.$emit('job-work-hours-updated')}}}):_vm._e()],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }