<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row class="text-left">
                <v-col
                    v-if="$vuetify.breakpoint.smAndUp"
                    cols="auto"
                    class="vertical-centered pr-0"
                >
                    <v-btn color="primary" @click="openColumnEditor"
                        >Create List</v-btn
                    >
                    <v-divider class="ml-4 mr-1" inset vertical></v-divider>
                </v-col>
                <v-col cols="auto" class="vertical-centered flex-grow-1">
                    <v-form
                        class="d-flex flex-nowrap flex-grow-1 flex-sm-grow-0"
                    >
                        <v-text-field
                            label="Search"
                            v-model="searchQuery"
                            hide-details
                            class="pt-0"
                        >
                            <template v-slot:append>
                                <v-icon>mdi-magnify</v-icon>
                            </template>
                        </v-text-field>
                    </v-form>
                </v-col>
            </v-row>
            <hr />
            <v-row class="text-left">
                <v-col
                    cols="12"
                    class="d-flex align-start flex-column flex-sm-row"
                >
                    <jobs-with-no-label-vue :search="searchQuery">
                    </jobs-with-no-label-vue>
                    <div class="wrapper">
                        <draggable
                            v-model="temporalMatrix"
                            v-bind="dragOptions"
                            @end="moveColumn"
                            group="columns"
                        >
                            <transition-group
                                type="transition"
                                name="flip-matrix"
                                class="matrix-container"
                            >
                                <matrix-column
                                    v-for="column in matrix"
                                    :key="column.id"
                                    :column="column"
                                    @edit="editColumn"
                                    @destroyed="destroyColumn"
                                    :search="searchQuery"
                                ></matrix-column>
                            </transition-group>
                        </draggable>
                    </div>
                </v-col>
            </v-row>

            <v-dialog
                persistent
                v-model="showColumnEditor"
                @click:outside="confirmClose"
                @keydown.esc="confirmClose"
                @keydown.enter="saveColumn"
                max-width="500px"
            >
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="editColumnForm" onSubmit="return false;">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                            @input="isDirty = true"
                                            v-model="editedColumn.label.name"
                                            label="Name"
                                            :rules="[
                                                () =>
                                                    !!editedColumn.label.name ||
                                                    'Required.',
                                            ]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="confirmClose"
                            >Cancel</v-btn
                        >
                        <v-btn color="primary" @click="saveColumn">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import matrixColumn from "Components/matrix/matrix-column";
import jobsWithNoLabelVue from "./jobs-with-no-label.vue";
import draggable from "vuedraggable";

export default {
    data: data,
    mounted: mounted,
    components: {
        pageHeaderVuetify,
        matrixColumn,
        draggable,
        jobsWithNoLabelVue,
    },
    computed: {
        formTitle() {
            return this.editedColumnIndex === -1 ? "New List" : "Edit List";
        },
    },
    watch: getWatchers(),
    methods: getMethods(),
};

function data() {
    return {
        showColumnEditor: false,
        editedColumnIndex: -1,
        editedColumn: {
            id: "new-column-id",
            label: {
                name: "",
            },
        },
        defaultColumnPayload: {
            id: "new-column-id",
            label: {
                name: "",
            },
        },
        dragOptions: {
            ghostClass: "ghost",
            filter: ".ignored",
            disabled: this.$vuetify.breakpoint.smAndDown,
        },
        matrix: [],
        temporalMatrix: [],
        searchQuery: "",
    };
}

function mounted() {
    const vm = this;

    Vue.backendApi().matrix().columns().index().then(success, fail);

    function success(response) {
        Vue.set(vm, "matrix", response.data);
    }

    function fail(error) {
        console.log("An error occurred trying to get matrix");
    }
}

function getWatchers() {
    return {
        matrix: {
            handler: cloneMatrix,
            deep: false,
        },
    };

    function cloneMatrix() {
        this.temporalMatrix = [...this.matrix];
    }
}

function getMethods() {
    return {
        openColumnEditor: openColumnEditor,
        editColumn: editColumn,
        close: close,
        confirmClose: confirmClose,
        saveColumn: saveColumn,
        destroyColumn: destroyColumn,
        moveColumn: moveColumn,
    };

    function openColumnEditor() {
        this.showColumnEditor = true;
        this.$nextTick(() => {
            this.$refs.editColumnForm.resetValidation();
        });
    }

    function editColumn(column) {
        this.editedColumnIndex = this.matrix.indexOf(column);
        this.editedColumn = Object.assign({}, column);
        this.showColumnEditor = true;
    }

    function close() {
        this.showColumnEditor = false;
        this.$nextTick(() => {
            this.editedColumn = JSON.parse(
                JSON.stringify(this.defaultColumnPayload)
            );
            this.editedColumnIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editColumnForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function saveColumn() {
        const vm = this;

        if (!this.$refs.editColumnForm.validate()) {
            return false;
        }

        if (vm.editedColumn.id === "new-column-id") {
            Vue.backendApi()
                .matrix()
                .columns()
                .store(vm.editedColumn)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .matrix()
                .columns(vm.editedColumn.id)
                .store(vm.editedColumn)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedColumnIndex > -1) {
                Object.assign(vm.matrix[vm.editedColumnIndex], response.data);
            } else {
                let column = response.data;
                vm.matrix.push(column);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save column");
        }
    }

    function destroyColumn(column) {
        const vm = this;
        let columnIndex = vm.matrix.findIndex((matrixColumn) => {
            return matrixColumn.id === column.id;
        });
        vm.matrix.splice(columnIndex, 1);
    }

    function moveColumn(evt) {
        if (evt.oldIndex === evt.newIndex) {
            return;
        }

        const vm = this;
        const column = evt.item._underlying_vm_;

        Vue.backendApi()
            .matrix()
            .columns(column.id)
            .movement({ newPosition: evt.newIndex })
            .then(success, fail);

        function success(response) {
            let reorderedColumns = [...vm.matrix];
            reorderedColumns.splice(evt.oldIndex, 1);
            reorderedColumns.splice(evt.newIndex, 0, column);
            vm.matrix = reorderedColumns;
        }

        function fail(error) {
            console.log("An error occurred trying to move column");
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: 20px;
}
.flip-matrix-move {
    transition: transform 0.5s;
}

.flip-matrix-enter-active,
.flip-matrix-leave-active {
    transition: opacity 0.5s;
}

.flip-matrix-enter,
.flip-matrix-leave-to {
    opacity: 0;
}
</style>
