<template>
    <div>
        <slot v-if="!loading"></slot>
        <div v-else class="waiting-spinner">
            <i class="fas fa-spinner"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "waiting-spinner",
    props: {
        loading: {
            default: true,
            type: Boolean,
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/_variables.scss";

div.button div {
    display: inline-block;
    & div.waiting-spinner {
        font-size: 12px;
        margin-top: 0;
        display: inline-block;
        width: auto;
    }
}

.extra-large .waiting-spinner {
    margin-top: 10%;
    width: 100%;
    text-align: center;
    font-size: 75px;
}

.waiting-spinner {
    color: $red;
    display: inline-block;
    -webkit-animation: spin 2s linear infinite;
    animation: spinner 2s linear infinite;
    border-radius: 50%;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
