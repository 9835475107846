<template>
    <div>
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left grey--text text--darken-3">
                            Process
                        </th>
                        <th class="text-left grey--text text--darken-3">
                            Station
                        </th>
                        <th class="text-center grey--text text--darken-3">
                            Hours
                        </th>
                        <th class="text-center grey--text text--darken-3">
                            Non efficient
                        </th>
                    </tr>
                </thead>
                <tbody class="text-center">
                    <tr v-for="row in stationJobReport">
                        <td class="text-left">{{ row.process_name }}</td>
                        <td class="text-left">{{ row.station_name }}</td>
                        <td>{{ row.all_hours | round(1) }}</td>
                        <td>
                            <a
                                href="javascript:void(0);"
                                @click="showNonEfficientReport(row)"
                                >{{
                                    row.non_efficient_hours | round(1)
                                }}</a
                            >
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <non-efficient-report
            v-if="nonEfficientReport.show"
            :is-loading="nonEfficientReport.isLoading"
            :subtitle="nonEfficientReport.subtitle"
            :report="nonEfficientReport.report"
            @close="$closeNonEfficientReport"
        ></non-efficient-report>
    </div>
</template>

<script>
import round from "Filters/round";
import nonEfficientReport from "Components/jobs/non-efficient-report-modal.vue";
import nonEfficientHoursMixin from "Mixins/nonEfficientHours.mixin";

export default {
    name: "station-job-report",
    mixins: [nonEfficientHoursMixin],
    components: { nonEfficientReport },
    data: data,
    methods: getMethods(),
    mounted: mounted,
    props: {
        job: Object,
    },
    filters: { round },
};

function data() {
    return {
        stationJobReport: []
    };
}

function getMethods() {
    return {
        getStationJobReport: getStationJobReport,
        showNonEfficientReport: showNonEfficientReport
    };

    function getStationJobReport(jobId) {
        const vm = this;

        Vue.backendApi()
            .reports()
            .jobs(jobId)
            .stationJobReport()
            .index()
            .then(success, fail);

        function success(response) {
            Vue.set(vm, "stationJobReport", response.data);
        }

        function fail(error) {
            console.log("An error occurred trying to get station job report");
        }
    }

    function showNonEfficientReport(stationRow) {
        const vm = this;
        vm.nonEfficientReport.subtitle = `Station - ${stationRow.station_name}`;
        vm.nonEfficientReport.isLoading = true;
        vm.nonEfficientReport.show = true;

        Vue.backendApi()
            .reports()
            .jobs(vm.$route.params.id)
            .stationJobReport()
            .nonEfficientHours(stationRow.station_id)
            .then(success, fail)
            .finally(() => vm.nonEfficientReport.isLoading = false);

        function success(response) {
            Vue.set(vm.nonEfficientReport, "report", response.data);
        }

        function fail(error) {
            console.log("An error occurred trying to get non efficient hours report");
            vm.closeNonEfficientReport();
        }
    }
}

function mounted() {
    const vm = this;
    if (vm.$route.params.id) {
        vm.getStationJobReport.call(vm, vm.$route.params.id);
    }
}

function getWatchers() {
    return {
        $route: watchRoute,
    };

    function watchRoute(to, from) {
        const vm = this;
        if (to.params.id) {
            vm.getStationJobReport.call(vm, to.params.id);
        }
    }
}
</script>
