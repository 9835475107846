<template>
    <v-data-iterator
        :items="building.stations"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        hide-default-footer
    >
        <template v-slot:header>
            <v-toolbar dark color="primary" class="mb-3">
                <h5 class="mr-5 mb-0">{{ building.name }}</h5>
                <v-text-field
                    v-model="search"
                    clearable
                    flat
                    solo-inverted
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                ></v-text-field>
                <template v-if="$vuetify.breakpoint.mdAndUp">
                    <v-spacer></v-spacer>
                    <v-select
                        v-model="sortBy"
                        flat
                        solo-inverted
                        hide-details
                        :items="keys"
                        item-text="text"
                        item-value="value"
                        prepend-inner-icon="mdi-magnify"
                        label="Sort by"
                    ></v-select>
                    <v-spacer></v-spacer>
                    <v-btn-toggle v-model="sortDesc" mandatory>
                        <v-btn
                            large
                            depressed
                            color="primary darken-3"
                            :value="false"
                        >
                            <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                            large
                            depressed
                            color="primary darken-3"
                            :value="true"
                        >
                            <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </template>
            </v-toolbar>
        </template>

        <template v-slot:default="props">
            <modal-downtime-report
                v-if="machineDetailedDowntime"
                :machine="machineDetailedDowntime"
                :dates="dates"
                @close="machineDetailedDowntime = null"
            ></modal-downtime-report>

            <v-row>
                <v-col
                    v-for="station in props.items"
                    :key="station.id"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="3"
                >
                    <v-card>
                        <v-card-title class="subheading font-weight-bold pb-0">
                            <span
                                class="text-truncate"
                                style="max-width: 330px"
                                >{{ station.name }}</span
                            >
                        </v-card-title>

                        <v-divider></v-divider>

                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content
                                    :class="{
                                        'blue--text':
                                            sortBy === 'possible_hours',
                                    }"
                                    >Possible Hours:</v-list-item-content
                                >
                                <v-list-item-content
                                    class="align-end"
                                    :class="{
                                        'blue--text':
                                            sortBy === 'possible_hours',
                                    }"
                                >
                                    {{ station.possible_hours | round }}
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content
                                    :class="{
                                        'blue--text': sortBy === 'work_hours',
                                    }"
                                    >Work Hours:</v-list-item-content
                                >
                                <v-list-item-content
                                    class="align-end"
                                    :class="{
                                        'blue--text': sortBy === 'work_hours',
                                    }"
                                >
                                    {{ station.work_hours | round }}
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content
                                    :class="{
                                        'blue--text':
                                            sortBy === 'total_downtime_hours',
                                    }"
                                    ><span
                                        class="downtime-hours-link"
                                        @click="
                                            machineDetailedDowntime = station
                                        "
                                        >Downtime hours:</span
                                    ></v-list-item-content
                                >
                                <v-list-item-content
                                    class="align-end"
                                    :class="{
                                        'blue--text':
                                            sortBy === 'total_downtime_hours',
                                    }"
                                >
                                    <span
                                        class="downtime-hours-link"
                                        @click="
                                            machineDetailedDowntime = station
                                        "
                                        >{{
                                            station.total_downtime_hours | round
                                        }}</span
                                    >
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content
                                    :class="{
                                        'blue--text':
                                            sortBy === 'unassigned_hours',
                                    }"
                                    >Unassigned Hours:</v-list-item-content
                                >
                                <v-list-item-content
                                    class="align-end"
                                    :class="{
                                        'blue--text':
                                            sortBy === 'unassigned_hours',
                                    }"
                                >
                                    {{ station.unassigned_hours | round }}
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content
                                    :class="{
                                        'blue--text':
                                            sortBy === 'not_used_hours',
                                    }"
                                    >Not Used Hours:</v-list-item-content
                                >
                                <v-list-item-content
                                    class="align-end"
                                    :class="{
                                        'blue--text':
                                            sortBy === 'not_used_hours',
                                    }"
                                >
                                    {{ station.not_used_hours | round }}
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content
                                    :class="{
                                        'blue--text':
                                            sortBy === 'performance_percentage',
                                    }"
                                    >Performance
                                    Percentage:</v-list-item-content
                                >
                                <v-list-item-content
                                    class="align-end"
                                    :class="{
                                        'blue--text':
                                            sortBy === 'performance_percentage',
                                    }"
                                >
                                    {{ station.performance_percentage | round }}
                                    %
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <template v-slot:footer>
            <v-row class="mt-2">
                <v-col cols="12" class="d-flex align-items-center">
                    <span class="grey--text">Items per page</span>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                dark
                                text
                                color="primary"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                {{ itemsPerPage === -1 ? "All" : itemsPerPage }}
                                <v-icon>mdi-chevron-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(number, index) in itemsPerPageArray"
                                :key="index"
                                @click="updateItemsPerPage(number)"
                            >
                                <v-list-item-title>{{
                                    number === -1 ? "All" : number
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-spacer></v-spacer>

                    <span class="mr-4 grey--text">
                        Page {{ page }} of {{ numberOfPages }}
                    </span>
                    <v-btn
                        fab
                        dark
                        color="primary darken-3"
                        class="mr-1"
                        @click="formerPage"
                    >
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        dark
                        color="primary darken-3"
                        class="ml-1"
                        @click="nextPage"
                    >
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-iterator>
</template>

<script>
import modalDowntimeReport from "Components/machine-time-report/modal-downtime-report";
import round from "Filters/round";

export default {
    components: { modalDowntimeReport },

    props: {
        building: Object,
        dates: Object,
    },

    data: () => ({
        machineDetailedDowntime: null,
        itemsPerPage: 4,
        itemsPerPageArray: [4, 8, 12, -1],
        page: 1,
        search: "",
        filter: {},
        sortDesc: false,
        sortBy: "name",
        keys: [
            {
                value: "name",
                text: "Name",
            },
            {
                value: "possible_hours",
                text: "Possible Hours",
            },
            {
                value: "work_hours",
                text: "Work Hours",
            },
            {
                value: "total_downtime_hours",
                text: "Down Time Hours",
            },
            {
                value: "unassigned_hours",
                text: "Unassigned Hours",
            },
            {
                value: "not_used_hours",
                text: "Not Used Hours",
            },
            {
                value: "performance_percentage",
                text: "Performance Percentage",
            },
        ],
    }),

    computed: {
        numberOfPages() {
            return Math.ceil(this.building.stations.length / this.itemsPerPage);
        },
    },

    methods: {
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1;
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1;
        },
        updateItemsPerPage(number) {
            this.itemsPerPage = number;
        },
    },

    filters: { round },
};
</script>

<style lang="scss" scoped>
.downtime-hours-link {
    text-decoration: underline;
    text-align: left;

    &:hover {
        cursor: pointer;
    }
}
</style>
