<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="5">
                    <organization-list v-model="organization">
                    </organization-list>
                </v-col>

                <v-col
                    cols="7"
                    :class="{
                        'fade-organization':
                            organization && !organization.has_access,
                    }"
                >
                    <v-row>
                        <organization-additionals
                            v-if="organization"
                            :organization="organization"
                        ></organization-additionals>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <organization-admin-list v-if="$route.params.id">
                            </organization-admin-list>
                        </v-col>
                        <v-col cols="6">
                            <organization-buildings
                                v-if="$route.params.id"
                            ></organization-buildings>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import organizationList from "Components/organizations/organization-list";
import organizationAdminList from "Components/organizations/organization-admin-list";
import organizationBuildings from "Components/organizations/organization-buildings";
import organizationAdditionals from "Components/organizations/organization-additionals";

export default {
    data: data,
    components: {
        pageHeaderVuetify,
        organizationList,
        organizationAdminList,
        organizationBuildings,
        organizationAdditionals,
    },
};

function data() {
    return {
        organization: null,
    };
}
</script>

<style lang="scss" scoped>
::v-deep .fade-organization {
    opacity: 0.5;
    transition: all 0.5s linear;

    &:hover {
        opacity: 1;
    }
}
</style>
