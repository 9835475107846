<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-row>
                <v-col cols="12">
                    <inventory-list></inventory-list>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import inventoryList from "./inventory-list";

export default {
    components: { pageHeaderVuetify, inventoryList },
};
</script>
