export default {
    methods: {
        $matchJobItem(item, searchQuery) {
            if (matchString(item.name)) {
                return true;
            }
            if (matchSerialNumber(item.serial_number)) {
                return true;
            }
            return false;

            function matchString(string) {
                return string.toLowerCase().indexOf(searchQuery) >= 0;
            }

            function matchSerialNumber(serialNumber) {
                if (!serialNumber) {
                    return false;
                }
                if (matchString(serialNumber)) {
                    return true;
                }

                let serialNumberParts = serialNumber.split("-");
                let match = serialNumberParts.some((serialPart) => {
                    return matchString(serialPart);
                });
                return match;
            }
        },
    },
};
