<template>
    <v-card>
        <v-card-title class="mb-2">
            <v-icon color="primary" class="mr-1">mdi-animation-outline</v-icon>
            Job Collection - {{ jobCollection.name }}

            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-subtitle class="text-left">
            <span v-if="numberChangedJobs === 1"
                ><b>1 Job</b> is being changed</span
            >
            <span v-else
                ><b>{{ numberChangedJobs }} Jobs</b> are being changed</span
            >
        </v-card-subtitle>

        <v-tabs dark background-color="primary">
            <v-tab>Estimates</v-tab>
        </v-tabs>

        <v-form ref="form" class="pt-4 px-2">
            <v-col cols="12" v-for="step in steps" :key="step.id">
                <v-text-field
                    :label="$processNames[step.process_id]"
                    :placeholder="$processNames[step.process_id]"
                    dense
                    hide-details="auto"
                    v-model="step.estimated_hours"
                    @input="isDirty = true"
                    :rules="[
                        () => !!step.estimated_hours || 'Required.',
                        $checkStepHours,
                    ]"
                ></v-text-field>
            </v-col>
        </v-form>
        <v-card-actions class="pb-4">
            <v-btn
                class="mb-3"
                color="grey"
                :disabled="!isDirty"
                @click="undoChanges"
            >
                Undo
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="save"
                class="mr-2"
                v-if="steps.length > 0"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import checkStepHours from "Mixins/checkStepHours.mixin";
import processNames from "Mixins/processNames.mixin";
import emitEstimatedHoursUpdated from "Mixins/emitEstimatedHoursUpdated.mixin";

export default {
    data() {
        return {
            isDirty: false,
            steps: [],
        };
    },
    mixins: [checkStepHours, processNames, emitEstimatedHoursUpdated],
    props: {
        jobCollection: Object,
    },
    computed: {
        numberChangedJobs() {
            return this.jobCollection.jobs.reduce((acc, job) => {
                return job.steps_from === "collection" ? acc + 1 : acc;
            }, 0);
        },
    },
    watch: {
        "$route.params.jobCollection": {
            handler: function () {
                this.getSteps();
            },
        },
    },
    methods: {
        undoChanges() {
            this.isDirty = false;
            this.getSteps();
        },

        getSteps() {
            const vm = this;

            Vue.backendApi()
                .jobCollections(vm.jobCollection.id)
                .steps()
                .index()
                .then(success, fail);

            function success(response) {
                vm.steps = response.data;
            }

            function fail(error) {
                console.log("An error occurred trying to get steps");
            }
        },

        save() {
            const vm = this;

            if (vm.$checkEstimatedHours(vm.steps)) {
                Vue.backendApi()
                    .jobCollections(vm.jobCollection.id)
                    .steps()
                    .updateEstimates({
                        steps: vm.steps,
                    })
                    .then(success, fail);

                function success(response) {
                    vm.$emitEstimatedHoursUpdated(vm.steps);
                }

                function fail(error) {
                    console.log(
                        "An error occurred trying to update estimated hours"
                    );
                }
            }
        },
    },
    mounted() {
        this.getSteps();
    },
};
</script>

<style lang="scss" scoped>
::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
