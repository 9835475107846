<template>
    <v-app>
        <v-dialog v-model="show" width="550" @click:outside="$emit('close')">
            <v-card>
                <v-card-title class="white--text warning">
                    <span class="headline">Job edit warning</span>
                </v-card-title>

                <v-card-text class="pt-4">
                    The selected job has been split. If you edit it only would be modified the first job shift
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="default" @click="$emit('close')"
                        >Accept</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            show: true,
        };
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    min-height: auto;
}
</style>
