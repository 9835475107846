<template>
    <v-data-table
        :headers="headers"
        :items="buildings"
        class="elevation-1 row-pointer"
        :loading="loading"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>List of buildings</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="showBuildingEditor = true"
                    >New Building</v-btn
                >
                <v-dialog
                    persistent
                    v-model="showBuildingEditor"
                    @click:outside="confirmClose"
                    @keydown.esc="confirmClose"
                    @keydown.enter="save"
                    max-width="500px"
                >
                    <v-card>
                        <v-card-title class="primary white--text">
                            <span class="headline">
                                {{ formTitle }}
                            </span>
                        </v-card-title>

                        <v-card-text>
                            <v-form
                                ref="editOrganizationBuildingForm"
                                onSubmit="return false;"
                            >
                                <v-container>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                v-model="editedBuilding.name"
                                                label="Name"
                                                @input="isDirty = true"
                                                :rules="[
                                                    () =>
                                                        !!editedBuilding.name ||
                                                        'Required.',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" class="pt-0">
                                            <v-select
                                                label="Colour"
                                                @input="isDirty = true"
                                                :rules="[
                                                    () =>
                                                        !!editedBuilding.colour ||
                                                        'Required.',
                                                ]"
                                                v-model="editedBuilding.colour"
                                                :items="colours"
                                                item-text="label"
                                                item-value="colour"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.colour="{ item }">
            {{ getColourLabel(item.colour) }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editBuilding(item)">
                mdi-pencil
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: data,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Building" : "Edit Building";
        },
    },
};

function data() {
    return {
        showBuildingEditor: false,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Colour",
                align: "start",
                sortable: false,
                value: "colour",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedBuilding: {
            id: "new-building-id",
            name: "",
            colour: "",
        },
        defaultBuilding: {
            id: "new-building-id",
            name: "",
            colour: "",
        },
        buildings: [],
        colours: [
            {
                label: "Blue",
                colour: "building-blue",
            },
            {
                label: "Green",
                colour: "building-green",
            },
            {
                label: "Yellow",
                colour: "building-yellow",
            },
        ],
        loading: false,
    };
}

function getWatchers() {
    return {
        showBuildingEditor: {
            handler: dialog,
        },
        "$route.params.id": {
            handler: getOrganizationBuildings,
            immediate: true,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function getOrganizationBuildings() {
        const vm = this;
        vm.loading = true;

        Vue.backendApi()
            .organizations(this.$route.params.id)
            .buildings()
            .index()
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "buildings", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log(
                "An error occurred trying to get organization buildings"
            );
        }
    }
}

function getMethods() {
    return {
        editBuilding: editBuilding,
        close: close,
        confirmClose: confirmClose,
        save: save,
        getColourLabel: getColourLabel,
    };

    function editBuilding(admin) {
        this.editedIndex = this.buildings.indexOf(admin);
        this.editedBuilding = Object.assign({}, admin);
        this.showBuildingEditor = true;
    }

    function close() {
        this.showBuildingEditor = false;
        this.$nextTick(() => {
            this.editedBuilding = Object.assign({}, this.defaultBuilding);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editOrganizationBuildingForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editOrganizationBuildingForm.validate()) {
            return false;
        }

        if (vm.editedBuilding.id === "new-building-id") {
            Vue.backendApi()
                .organizations(vm.$route.params.id)
                .buildings()
                .store(vm.editedBuilding)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .organizations(vm.$route.params.id)
                .buildings(vm.editedBuilding.id)
                .store(vm.editedBuilding)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.buildings[vm.editedIndex], response.data);
            } else {
                vm.buildings.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save building");
        }
    }

    function getColourLabel(colour) {
        let item = this.colours.find((item) => item.colour === colour);
        return item.label;
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
