<template>
    <drop-list
        :items="shift.user_history"
        class="employee-container"
        @insert="onDrop"
        @reorder="$event.apply(shift)"
        mode="cut"
        :accepts-data="shouldAcceptDrop"
    >
        <template v-slot:item="{ item: userHistory }">
            <drag
                class="employee"
                @cut="removeUserHistory(userHistory)"
                :data="userHistory.user"
                :class="{
                    removing: isRemoving(userHistory),
                }"
                :key="userHistory.user.id"
                @dragstart="draggingEmployee = true"
                @dragend="draggingEmployee = false"
            >
                <i class="fa fa-user" aria-hidden="true"></i>
                {{ userHistory.user.name }}
                <i
                    class="fa fa-trash"
                    aria-hidden="true"
                    @click="removeUserHistory(userHistory)"
                ></i>
            </drag>
        </template>
        <template v-slot:feedback="{ data: user }">
            <div class="employee ghost" :key="user.id">{{ user.name }}</div>
        </template>
    </drop-list>
</template>

<script>
import machineShiftEmployeeListMixin from "Components/overview/building/machine/mixins/machine-shift-employee-list.mixin";

export default {
    mixins: [machineShiftEmployeeListMixin],

    methods: {
        onDrop(dropResult) {
            const vm = this;
            const user = dropResult.data;
            if (userOnShift(user) || vm.machine.down_time) {
                return;
            }

            let pendingId = getPendingId();
            vm.shift.user_history.splice(dropResult.index, 0, {
                id: pendingId,
                user,
            });

            Vue.backendApi()
                .stationShift(vm.shift.id)
                .userHistory(user.id, {
                    started_at: vm.shift.started_at,
                    ended_at: vm.shift.ended_at,
                })
                .then(success, failure);

            function userOnShift(user) {
                return vm.shift.user_history.some(
                    (userHistory) => userHistory.user.id === user.id
                );
            }

            function getPendingId() {
                return "pending-" + Math.floor(Math.random() * 2000);
            }

            function success(response) {
                const userHistory = vm.shift.user_history.find(
                    (userHistory) => userHistory.id === pendingId
                );
                Object.assign(userHistory, response.data);
            }

            function failure(error) {
                let index = vm.shift.findIndex(function (userHistory) {
                    return userHistory.id === pendingId;
                });
                vm.shift.splice(index, 1);
                console.log("Error trying to add employee to machine");
            }
        },

        removeUserHistory(userHistory) {
            const vm = this;
            vm.removing.push(userHistory.id);

            Vue.backendApi()
                .stationShift(vm.shift.id)
                .destroyUserHistory(userHistory.id)
                .then(success, failure);

            function success(response) {
                vm.$spliceById(vm.removing, userHistory.id);
                vm.$spliceById(vm.shift.user_history, userHistory.id, (i) => {
                    return i.id === userHistory.id;
                });
            }

            function failure(error) {
                vm.$spliceById(vm.removing, userHistory.id);
                console.log("An error occurred trying to remove user history");
            }
        },
    },
};
</script>

<style scoped lang="scss">
.employee-container {
    min-height: 22px;
    margin-left: 8px;
}

.drop-list > .employee {
    transition: all .5s;
}

.employee {
    position: relative;
    padding: 2px;

    &.ghost {
        background-color: rgb(255, 220, 220);
        border: 2px dashed black;
    }

    & .fa.fa-user {
        font-weight: 400;
        font-size: 15px;
        margin-right: 8px;
    }

    & .fa.fa-trash {
        border-radius: 50%;
        right: 2px;
        padding: 4px;
        position: absolute;
        background-color: burlywood;
        display: none;
    }

    &:hover {
        background-color: rgba(0,0,0,0.07);
        padding-left: 8px;

        & .fa.fa-trash {
            display: inline-block;
            cursor: pointer;
        }
    }
}

.removing {
    opacity: 0.5;
    text-decoration: line-through;
}
</style>
