<template>
    <div>
        <span v-if="diff >= 0" class="green--text text--darken-3 comparator">
            <v-icon color="green darken-3" small class="mr-1"
                >mdi-arrow-up</v-icon
            >+ {{ diff | currencyFormat }} ({{ diffPercentage }}%)
        </span>
        <span v-else class="red--text text--darken-3 comparator">
            <v-icon color="red darken-3" small class="mr-1"
                >mdi-arrow-down</v-icon
            >
            {{ diff | currencyFormat }} ({{ diffPercentage }}%)
        </span>
    </div>
</template>

<script>
import currencyFormat from "Filters/currencyFormat";

export default {
    props: {
        value: Number,
        previousValue: Number,
    },

    filters: { currencyFormat },

    computed: {
        diff() {
            return this.getReportDiff();
        },

        diffPercentage() {
            return this.getReportDiffPercentage();
        },
    },

    methods: {
        getReportDiff() {
            return this.value - this.previousValue;
        },

        getReportDiffPercentage() {
            return this.getDiffPercentage(this.getReportDiff(), this.value);
        },

        getDiffPercentage(val1, val2) {
            if (val2 === 0) {
                return 0;
            }
            return Math.round((val1 / val2) * 100);
        },
    },
};
</script>

<style scoped>
.comparator {
    background-color: #d1dfe1;
    border-radius: 5px;
    padding: 3px;
    font-weight: bold;
    margin-top: 5px;
}
</style>
