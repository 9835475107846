<template>
    <v-app>
        <v-dialog v-model="show" width="550" @click:outside="$emit('close')">
            <v-card>
                <v-card-title class="white--text warning">
                    <span class="headline">Overlapping warning</span>
                </v-card-title>

                <v-card-text class="pt-4">
                    The machine downtime and work added are overlapping between
                    <b>{{ overlappingTime }}</b>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="default" @click="$emit('close')"
                        >Ignore</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="$emit('removeJobHistory', jobHistory)"
                        >Remove</v-btn
                    >
                    <v-btn color="primary" @click="edit">Edit</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    data() {
        return {
            show: true,
        };
    },

    computed: {
        overlappingTime() {
            const vm = this;
            let downtimeStart = Vue.moment(vm.machineDowntime.started_at);
            let downtimeEnd = Vue.moment(vm.machineDowntime.ended_at);
            let jobHistoryStart = Vue.moment(
                vm.jobHistory.started_at,
                "YYYY-MM-DD HH:mm:ss"
            );
            let jobHistoryEnd = Vue.moment(
                vm.jobHistory.ended_at,
                "YYYY-MM-DD HH:mm:ss"
            );
            let start = Vue.moment.max([downtimeStart, jobHistoryStart]);
            let end = Vue.moment.min([downtimeEnd, jobHistoryEnd]);
            return `${start.format("HH:mm")} - ${end.format("HH:mm")}`;
        },
    },

    methods: {
        edit() {
            this.$eventHub.$emit("editJobHoursShift", this.jobHistory);
            this.$emit("close");
        },
    },

    props: {
        machineDowntime: Object,
        jobHistory: Object,
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    min-height: auto;
}
</style>
