<template>
    <v-text-field
        v-model="search.query"
        label="Search"
        hide-details
        class="search-input"
        @input="performSearch"
    >
        <template v-slot:prepend-inner>
            <v-select
                v-model="search.column"
                :items="filterableColumns"
                item-text="text"
                item-value="value"
                label="In Column"
                hide-details
                dense
                return-object
                :clearable="search.column.value !== 'all'"
                @change="setNewSearch"
            ></v-select>
        </template>

        <template v-slot:append>
            <v-fade-transition v-if="searchHelpText === ''" leave-absolute>
                <v-icon>mdi-magnify</v-icon>
            </v-fade-transition>
            <v-fade-transition v-else leave-absolute>
                <v-icon @click="showHelpTextModal = true">
                    mdi-help-circle-outline
                </v-icon>
            </v-fade-transition>
            <v-dialog v-model="showHelpTextModal" width="500">
                <v-card>
                    <v-card-title class="text-h5 primary white--text">
                        How to search in: {{ search.column.text }}
                    </v-card-title>

                    <v-card-text class="pt-5 text-left" v-html="searchHelpText">
                    </v-card-text>

                    <v-divider class="my-0"></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="showHelpTextModal = false"
                        >
                            Close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-text-field>
</template>

<script>
export default {
    data() {
        return {
            search: {
                query: "",
                column: {
                    value: "all",
                    text: "All",
                },
                range: [],
            },
            defaultColumn: {
                value: "all",
                text: "All",
            },
            showHelpTextModal: false,
            searchHelpText: "",
            numericColumns: [
                "id",
                "cost",
                "sale_price",
                "quantity",
                "thickness",
                "width",
                "length",
                "weight",
            ],
            noFilterableColumns: ["attachment", "actions"],
        };
    },

    props: {
        value: Object,
        headers: Array,
    },

    computed: {
        filterableColumns() {
            const vm = this;
            let columns = vm.headers.filter((header) => {
                return !vm.noFilterableColumns.includes(header.value);
            });
            columns.splice(0, 0, vm.defaultColumn);
            return columns;
        },
    },

    watch: {
        value: {
            immediate: true,
            handler: function () {
                this.search = this.value;
            },
        },
    },

    methods: {
        setNewSearch() {
            const vm = this;
            vm.search.query = "";

            if (!vm.search.column) {
                vm.search.column = vm.defaultColumn;
            }

            vm.searchHelpText = vm.numericColumns.includes(
                vm.search.column.value
            )
                ? "<b>Searching ranges examples:</b><br><code>8 - 20</code><br><code>6.25 - 8.30</code><br><code>1/2 - 1/4</code><br><code>1 1/2 - 3 1/4</code>"
                : "";
        },

        performSearch() {
            const vm = this;
            let shouldTrySearchRange =
                vm.numericColumns.includes(vm.search.column.value) ||
                vm.search.column.value === "all";

            vm.search.range = shouldTrySearchRange
                ? this.transformRangeSearchQuery(
                      vm.search.query,
                      vm.search.column.value
                  )
                : [];

            vm.$emit("input", vm.search);
        },

        transformRangeSearchQuery(searchQuery) {
            if (!searchQuery) {
                return [];
            }
            /**
             * Expresion regular to check a valid range
             * E.g: 8 - 20 | 6.25 - 8.30 | 1/2 - 1/4 | 1 1/2 - 3 1/4
             */
            let regExpRange =
                /^((((\d+)\s)?((\d+)\/(\d+)))|((\d+)(\.[\d]{1,2})?))\s\-\s((((\d+)\s)?((\d+)\/(\d+)))|((\d+)(\.[\d]{1,2})?))$/;
            if (regExpRange.test(searchQuery)) {
                // Convert fractions into floats. E.x: 1/4 -> 0.25
                let regExpFractions = /(?:\d+)\/(?:\d+)/g;
                searchQuery = searchQuery.replace(
                    regExpFractions,
                    (fraction) => {
                        let fractionNumbers = fraction.split("/");
                        return fractionNumbers[0] / fractionNumbers[1];
                    }
                );

                // Sum number next to number. E.x: 1 0.25 -> 1.25
                let regExpNumberNextToNumber =
                    /(\d+(?:\.[0-9]{1,2})?)\s(\d+(?:\.[0-9]{1,2})?)/g;
                searchQuery = searchQuery.replace(
                    regExpNumberNextToNumber,
                    (adding) => {
                        let numbers = adding.split(" ");
                        return parseFloat(numbers[0]) + parseFloat(numbers[1]);
                    }
                );

                let regExpDecimalNumbers =
                    /(?:(?:\d(?:,(?=\d))?)+(?:\.\d*)?|\.\d+)/g;
                return searchQuery.match(regExpDecimalNumbers).map((each) => {
                    return parseFloat(each).toFixed(4);
                });
            }
            return [];
        },
    },
};
</script>
