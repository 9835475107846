<template>
    <div>
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left grey--text text--darken-3">
                            Employee
                        </th>
                        <th
                            class="text-left grey--text text--darken-3 text-center"
                        >
                            Efficient
                        </th>
                        <th
                            class="text-left grey--text text--darken-3 text-center"
                        >
                            Non efficient
                        </th>
                        <th
                            class="text-left grey--text text--darken-3 text-center"
                        >
                            Total
                        </th>
                    </tr>
                </thead>
                <tbody class="text-left">
                    <tr v-for="row in workHours">
                        <td>{{ row.user_name }}</td>
                        <td class="text-center">
                            {{ row.efficient_hours | round(1) }}
                        </td>
                        <td class="text-center">
                            <a
                                href="javascript:void(0);"
                                @click="showNonEfficientReport(row)"
                                >{{ row.non_efficient_hours | round(1) }}</a
                            >
                        </td>
                        <td class="text-center">
                            {{
                                (parseFloat(row.efficient_hours) +
                                    parseFloat(row.non_efficient_hours))
                                    | round(1)
                            }}
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <non-efficient-report
            v-if="nonEfficientReport.show"
            :is-loading="nonEfficientReport.isLoading"
            :subtitle="nonEfficientReport.subtitle"
            :report="nonEfficientReport.report"
            @close="$closeNonEfficientReport"
        ></non-efficient-report>
    </div>
</template>

<script>
import round from "Filters/round";
import nonEfficientReport from "Components/jobs/non-efficient-report-modal.vue";
import nonEfficientHoursMixin from "Mixins/nonEfficientHours.mixin";

export default {
    name: "employee-report",
    mixins: [nonEfficientHoursMixin],
    components: { nonEfficientReport },
    data: data,
    props: {
        job: Object,
    },
    methods: getMethods(),
    watch: getWatchers(),
    filters: { round },
};

function data() {
    return {
        workHours: []
    };
}

function getMethods() {
    return {
        showNonEfficientReport: showNonEfficientReport
    };

    function showNonEfficientReport(employeeRow) {
        const vm = this;
        let hasUserId = employeeRow.user_id ? true : false;

        vm.nonEfficientReport.subtitle = hasUserId
            ? `Employee - ${employeeRow.user_name}`
            : "Without employee";
        vm.nonEfficientReport.isLoading = true;
        vm.nonEfficientReport.show = true;

        if (hasUserId) {
            Vue.backendApi()
                .reports()
                .jobs(vm.$route.params.id)
                .employeeReport()
                .nonEfficientHours(employeeRow.user_id)
                .then(success, fail)
                .finally(() => (vm.nonEfficientReport.isLoading = false));
        } else {
            Vue.backendApi()
                .reports()
                .jobs(vm.$route.params.id)
                .employeeReport()
                .nonEfficientHoursWithoutEmployee()
                .then(success, fail)
                .finally(() => (vm.nonEfficientReport.isLoading = false));
        }

        function success(response) {
            Vue.set(vm.nonEfficientReport, "report", response.data);
        }

        function fail(error) {
            console.log(
                "An error occurred trying to get non efficient hours report"
            );
            vm.closeNonEfficientReport();
        }
    }
}

function getWatchers() {
    return {
        job: {
            handler: job,
            immediate: true,
        },
    };

    function job() {
        const vm = this;

        Vue.backendApi()
            .reports()
            .jobs(this.job.id)
            .employeeReport()
            .index()
            .then(success, fail);

        function success(response) {
            Vue.set(vm, "workHours", response.data);
        }

        function fail(error) {
            console.log("An error occurred trying to get job cost report");
        }
    }
}
</script>
