<template>
    <v-data-table
        :headers="headers"
        :items="buildings"
        class="elevation-1 row-pointer"
        :loading="loading"
        :item-class="rowClasses"
        @click:row="focusOnBuilding"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Buildings</v-toolbar-title>
                <v-dialog
                    persistent
                    v-model="showBuildingEditor"
                    @click:outside="confirmClose"
                    @keydown.esc="confirmClose"
                    @keydown.enter="save"
                    max-width="500px"
                >
                    <v-card>
                        <v-card-title class="primary white--text">
                            <span class="headline">Edit Building</span>
                        </v-card-title>

                        <v-card-text>
                            <v-form
                                ref="editBuildingForm"
                                onSubmit="return false;"
                            >
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                                @input="isDirty = true"
                                                v-model="editedBuilding.name"
                                                label="Name"
                                                :rules="[
                                                    () =>
                                                        !!editedBuilding.name ||
                                                        'Required.',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editBuilding(item)"
            >
                mdi-pencil
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: data,
    created: created,
    methods: getMethods(),
    watch: getWatchers(),
};

function data() {
    return {
        showBuildingEditor: false,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedBuilding: {
            id: "new-building-id",
            name: "",
        },
        defaultBuilding: {
            id: "new-building-id",
            name: "",
        },
        buildings: [],
        loading: false,
    };
}

function getWatchers() {
    return {
        showBuildingEditor: {
            handler: dialog,
        },
    };

    function dialog(val) {
        val || this.close();
    }
}

function getMethods() {
    return {
        focusOnBuilding: focusOnBuilding,
        editBuilding: editBuilding,
        close: close,
        confirmClose: confirmClose,
        save: save,
        rowClasses: rowClasses,
    };

    function focusOnBuilding(building) {
        if (building.id === "new-building-id") {
            this.$router.push({ name: "buildings" });
        } else {
            this.$router.push({
                name: "buildings",
                params: { id: building.id },
            });
        }
    }

    function editBuilding(building) {
        this.editedIndex = this.buildings.indexOf(building);
        this.editedBuilding = Object.assign({}, building);
        this.showBuildingEditor = true;
    }

    function close() {
        this.showBuildingEditor = false;
        this.$nextTick(() => {
            this.editedBuilding = Object.assign({}, this.defaultBuilding);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editBuildingForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editBuildingForm.validate()) {
            return false;
        }

        if (vm.editedBuilding.id === "new-building-id") {
            Vue.backendApi()
                .buildings()
                .store(vm.editedBuilding)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .buildings(vm.editedBuilding.id)
                .store(vm.editedBuilding)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.buildings[vm.editedIndex], response.data);
            } else {
                vm.buildings.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save building");
        }
    }

    function rowClasses(building) {
        if (building.id === parseInt(this.$route.params.id)) {
            return "v-data-table__selected";
        }
    }
}

function created() {
    const vm = this;
    this.loading = true;

    Vue.backendApi().buildings().index().then(success, fail);

    function success(response) {
        vm.loading = false;
        Vue.set(vm, "buildings", response.data);
    }

    function fail(error) {
        vm.loading = false;
        console.log("An error occurred trying to get buildings");
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
