<template>
    <v-data-table
        :headers="headers"
        :items="processes"
        class="elevation-1 row-pointer"
        :item-class="rowClasses"
        :loading="loading"
        @click:row="focusOnProcess"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Processes</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                    persistent
                    v-model="showProcessEditor"
                    max-width="500px"
                    @click:outside="confirmClose"
                    @keydown.esc="confirmClose"
                    @keydown.enter="save"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            >New Process</v-btn
                        >
                    </template>
                    <v-card>
                        <v-card-title class="primary white--text">
                            <span class="headline">
                                {{ formTitle }}
                            </span>
                        </v-card-title>

                        <v-card-text>
                            <v-form
                                ref="editProcessForm"
                                onSubmit="return false;"
                            >
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                                v-model="editedProcess.name"
                                                label="Name"
                                                @input="isDirty = false"
                                                :rules="[
                                                    () =>
                                                        !!editedProcess.name ||
                                                        'Required.',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.all_hours="{ item }">
            {{ item.all_hours | round }}
        </template>
        <template v-slot:item.estimated_hours="{ item }">
            {{ item.estimated_hours | round }}
        </template>
        <template v-slot:item.hours_remaining="{ item }">
            {{ item.hours_remaining | showNegativeAsZero }}
        </template>
        <template v-slot:item.percent_complete="{ item }">
            {{ item.percent_complete | round | percent }}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editProcess(item)"
            >
                mdi-pencil
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
import round from "Filters/round";
import percent from "Filters/percent";
import showNegativeAsZero from "Filters/showNegativeAsZero";

export default {
    data: data,
    mounted: mounted,
    methods: getMethods(),
    watch: getWatchers(),
    filters: { round, percent, showNegativeAsZero },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Process" : "Edit Process";
        },
    },
};

function data() {
    return {
        showProcessEditor: false,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Hours",
                align: "center",
                sortable: false,
                value: "all_hours",
                class: "grey--text text--darken-3",
            },
            {
                text: "Estimated hours",
                align: "center",
                sortable: false,
                value: "estimated_hours",
                class: "grey--text text--darken-3",
            },
            {
                text: "Hours remaining",
                align: "center",
                sortable: false,
                value: "hours_remaining",
                class: "grey--text text--darken-3",
            },
            {
                text: "Percent complete",
                align: "center",
                sortable: false,
                value: "percent_complete",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedProcess: {
            id: "new-process-id",
            name: "",
        },
        defaultProcess: {
            id: "new-process-id",
            name: "",
        },
        processes: this.$store.state.processes,
        loading: false,
    };
}

function getWatchers() {
    return {
        showProcessEditor: {
            handler: dialog,
        },
    };

    function dialog(val) {
        val || this.close();
    }
}

function getMethods() {
    return {
        focusOnProcess: focusOnProcess,
        editProcess: editProcess,
        close: close,
        confirmClose: confirmClose,
        save: save,
        rowClasses: rowClasses,
    };

    function focusOnProcess(process) {
        if (process.id === "new-process-id") {
            this.$router.push({ name: "processes" });
        } else {
            this.$emit("selected", process);
            this.$router.push({
                name: "processes",
                params: { id: process.id },
            });
        }
    }

    function editProcess(process) {
        this.editedIndex = this.processes.indexOf(process);
        this.editedProcess = Object.assign({}, process);
        this.showProcessEditor = true;
    }

    function close() {
        this.showProcessEditor = false;
        this.$nextTick(() => {
            this.editedProcess = Object.assign({}, this.defaultProcess);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editProcessForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editProcessForm.validate()) {
            return false;
        }

        if (vm.editedProcess.id === "new-process-id") {
            Vue.backendApi()
                .processes()
                .store(vm.editedProcess)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .processes(vm.editedProcess.id)
                .store(vm.editedProcess)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.processes[vm.editedIndex], response.data);
            } else {
                vm.processes.unshift({
                    ...response.data,
                    estimated_hours: 0,
                    hours_remaining: 0,
                    all_hours: 0,
                    percent_complete: 0,
                });
            }
            vm.$store.commit("processes", vm.processes);
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save process");
        }
    }

    function rowClasses(process) {
        if (process.id === parseInt(this.$route.params.id)) {
            return "v-data-table__selected";
        }
    }
}

function mounted() {
    const vm = this;
    vm.loading = true;

    Vue.backendApi().reports().processes().index().then(success, fail);

    function success(response) {
        vm.loading = false;
        Vue.set(vm, "processes", response.data);

        if (vm.$route.params.id) {
            let process = vm.processes.find((process) => {
                return process.id === parseInt(vm.$route.params.id);
            });
            vm.$emit("selected", process);
        }
    }

    function fail(error) {
        vm.loading = false;
        console.log("An error occurred trying to get processes");
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
