import { render, staticRenderFns } from "./client-sales-order-layout.vue?vue&type=template&id=3f0d7524&scoped=true&"
import script from "./client-sales-order-layout.vue?vue&type=script&lang=js&"
export * from "./client-sales-order-layout.vue?vue&type=script&lang=js&"
import style0 from "./client-sales-order-layout.vue?vue&type=style&index=0&id=3f0d7524&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0d7524",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www2/lean.nuevoalmaden.com/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f0d7524')) {
      api.createRecord('3f0d7524', component.options)
    } else {
      api.reload('3f0d7524', component.options)
    }
    module.hot.accept("./client-sales-order-layout.vue?vue&type=template&id=3f0d7524&scoped=true&", function () {
      api.rerender('3f0d7524', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/sales-orders/client-sales-order-layout.vue"
export default component.exports