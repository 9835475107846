<template>
    <v-app>
        <v-dialog v-model="show" width="500" @click:outside="$emit('close')">
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="headline">Machine Downtimes</span>
                </v-card-title>

                <v-card-text class="pb-0 pt-4">
                    <v-data-table
                        :headers="headers"
                        :items="machineDowntimes"
                        class="elevation-1"
                        dense
                    >
                        <template v-slot:item.started_at="{ item }">
                            {{ item.started_at | compactDateTime }}
                        </template>
                        <template v-slot:item.ended_at="{ item }">
                            <span v-if="item.ended_at">
                                {{ item.ended_at | compactDateTime }}
                            </span>
                            <span v-else>--</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <button
                                class="btn-trash-downtime"
                                @click="destroyMachineDowntime(item)"
                                title="Delete"
                            >
                                <i class="fas fa-trash"></i>
                            </button>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="currentMachineDowntime"
                        color="primary"
                        @click="$emit('makeAvailable')"
                        >Make Available</v-btn
                    >
                    <v-btn color="primary" @click="$emit('close')"
                        >Cancel</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import compactDateTime from "Filters/compactDateTime";

export default {
    data() {
        return {
            show: true,
            headers: [
                {
                    text: "Reason",
                    align: "start",
                    value: "unavailable_reason.name",
                },
                {
                    text: "Started at",
                    value: "started_at",
                    align: "center",
                },
                {
                    text: "Ended at",
                    value: "ended_at",
                    align: "center",
                },
                {
                    text: "Actions",
                    value: "actions",
                    align: "center",
                },
            ],
        };
    },

    methods: {
        destroyMachineDowntime(machineDowntime) {
            const vm = this;

            Vue.backendApi()
                .stationDowntimes(machineDowntime.id)
                .destroy()
                .then(success, fail);

            function success(response) {
                vm.$store.commit("destroyMachineDowntime", machineDowntime);
            }

            function fail(error) {
                console.log(
                    "An error has occurred trying to delete station downtime"
                );
            }
        },
    },

    props: {
        machine: Object,
        machineDowntimes: Array,
        currentMachineDowntime: Object,
    },

    filters: { compactDateTime },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    min-height: auto;
}
</style>
