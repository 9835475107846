<template>
    <v-app>
        <v-dialog v-model="show" width="500" @click:outside="$emit('close')">
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="headline">Edit bulk work</span>
                </v-card-title>

                <v-card-text v-if="isLoading" class="pt-4 text-center">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                        :size="30"
                        :width="5"
                    ></v-progress-circular>
                </v-card-text>

                <v-card-text v-else class="pb-0 pt-4">
                    <v-alert v-if="showErrorMessage" type="error">
                        An error has occurred. Please try again.
                    </v-alert>

                    <v-form ref="editBulkWorkForm" onSubmit="return false;">
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-select
                                    v-model="defaultWork.type_of_hour_id"
                                    :items="typeOfHours"
                                    item-text="name"
                                    item-value="id"
                                    label="Type"
                                    height="25px"
                                    :rules="[
                                        () =>
                                            defaultWork.type_of_hour_id > 0 ||
                                            'Required.',
                                    ]"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <time-picker
                                    v-model="defaultWork.started_at"
                                    @previousTime="updatePreviousDuration"
                                    @input="keepDuration"
                                ></time-picker>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field
                                    :value="defaultWork.ended_at | time"
                                    label="End at"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <duration-picker
                                    :startedAt="defaultWork.started_at"
                                    v-model="defaultWork.ended_at"
                                ></duration-picker>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-card-actions v-if="!isLoading">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="$emit('close')"
                        >Cancel</v-btn
                    >
                    <v-btn color="primary" @click="save()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import timePicker from "Components/utils/vuetify-time-picker-datetime";
import durationPicker from "Components/utils/vuetify-duration-picker-datetime";
import time from "Filters/timeFromDateTime";

export default {
    components: { timePicker, durationPicker },

    props: {
        shift: Object,
        selectedJobHistories: Array,
    },

    data() {
        return {
            show: true,
            defaultWork: {
                type_of_hour_id: 0,
                started_at: this.shift.started_at,
                ended_at: this.shift.ended_at,
            },
            typeOfHours: [],
            previousDuration: 0.5,
            isLoading: false,
            showErrorMessage: false,
        };
    },

    methods: {
        save() {
            const vm = this;
            const form = vm.$refs.editBulkWorkForm;

            if (!form.validate()) {
                return false;
            }

            vm.isLoading = true;
            let requests = vm.selectedJobHistories.map((jobHistory) => {
                return vm.updateShiftJob(jobHistory);
            });

            Promise.all(requests).then(
                () => {
                    vm.defaultWork = {
                        type_of_hour_id: 0,
                        started_at: vm.shift.started_at,
                        ended_at: vm.shift.ended_at,
                    };
                    vm.isLoading = false;
                    form.resetValidation();
                    vm.$emit("close");
                    vm.showErrorMessage = false;
                },
                () => {
                    console.log("Error during bulk work update");
                    vm.showErrorMessage = true;
                    vm.isLoading = false;
                }
            );
        },

        updateShiftJob(shiftJob) {
            const vm = this;

            let payload = {
                ...shiftJob,
                ...vm.defaultWork,
            };

            return new Promise((resolve, reject) => {
                Vue.backendApi()
                    .stationShift(shiftJob.station_shift_id)
                    .updateJobHistory(payload)
                    .then(success, fail);

                function success(response) {
                    vm.$emit("updatedStationShiftJob", response.data);
                    vm.$eventHub.$emit(
                        "job-history-updated",
                        shiftJob,
                        response.data
                    );
                    resolve();
                }

                function fail(error) {
                    console.log("An error occurred update shiftJob");
                    reject();
                }
            });
        },

        updatePreviousDuration(previousTime) {
            let { ended_at } = this.defaultWork;
            let startTime = Vue.moment(previousTime, "YYYY-MM-DD HH:mm:ss");
            let endTime = Vue.moment(ended_at, "YYYY-MM-DD HH:mm:ss");
            let duration = Math.abs(startTime.diff(endTime, "minutes")) / 60;
            this.previousDuration = duration;
        },

        keepDuration() {
            let startTime = Vue.moment(
                this.defaultWork.started_at,
                "YYYY-MM-DD HH:mm:ss"
            );
            let endTime = startTime.add(this.previousDuration, "hours");
            endTime = Vue.moment(endTime).format("YYYY-MM-DD HH:mm:ss");
            this.defaultWork.ended_at = endTime;
        },
    },

    created() {
        if (!this.selectedJobHistories[0]) {
            return;
        }

        let defaultType = this.selectedJobHistories[0].type_of_hour_id;
        let hasDifferentTypes = this.selectedJobHistories.some(
            (jobHistory) => jobHistory.type_of_hour_id !== defaultType
        );

        if (!hasDifferentTypes) {
            this.defaultWork.type_of_hour_id = defaultType;
        }
    },

    mounted() {
        const vm = this;

        Vue.backendApi().typeOfHours().index().then(success, fail);

        function success(response) {
            Vue.set(vm, "typeOfHours", response.data);
        }

        function fail(error) {
            console.log("An error has occurred trying to get type of hours");
        }
    },

    filters: { time },
};
</script>
