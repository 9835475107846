export default function (value) {
    const duration = Vue.moment.duration(parseInt(value), "seconds");
    if (Vue.moment.isDuration(duration)) {
        let hours = duration.hours().toString().padStart(2, "0");
        let minutes = duration.minutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    } else {
        return "";
    }
}
