<template>
    <v-app>
        <v-dialog v-model="show" width="700" @click:outside="$emit('close')">
            <v-card :loading="loading">
                <template slot="progress">
                    <v-progress-linear
                        color="primary darken-3"
                        height="8"
                        indeterminate
                    ></v-progress-linear>
                </template>

                <v-card-title class="primary white--text">
                    <span class="headline">
                        {{ machine.name }} | Downtime Hours</span
                    >
                </v-card-title>

                <v-card-text class="pb-0 pt-4">
                    <v-data-table
                        :headers="headers"
                        :items="machineDowntimes"
                        class="elevation-1"
                        dense
                    >
                        <template v-slot:item.hours="{ item }">
                            {{ item.hours | round }}
                        </template>
                        <template v-slot:item.started_at="{ item }">
                            {{ item.started_at | compactDateTime }}
                        </template>
                        <template v-slot:item.ended_at="{ item }">
                            {{ item.ended_at | compactDateTime }}
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="$emit('close')"
                        >Cancel</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import compactDateTime from "Filters/compactDateTime";
import round from "Filters/round";

export default {
    data() {
        return {
            show: true,
            loading: true,
            machineDowntimes: [],
            headers: [
                {
                    text: "Hours",
                    align: "center",
                    value: "hours",
                },
                {
                    text: "Reason",
                    value: "unavailable_reason.name",
                    align: "left",
                },
                {
                    text: "Started at",
                    value: "started_at",
                    align: "center",
                },
                {
                    text: "Ended at",
                    value: "ended_at",
                    align: "center",
                },
            ],
        };
    },

    props: {
        dates: Object,
        machine: Object,
    },

    filters: {
        compactDateTime,
        round,
    },

    created() {
        const vm = this;

        Vue.backendApi()
            .reports()
            .machineTime(vm.machine.id)
            .downtimes(vm.dates)
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "machineDowntimes", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log(
                "Has ocurred an errror trying to get machine down times"
            );
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    min-height: auto;
}
</style>
