<template>
    <v-app>
        <v-dialog v-model="show" width="980" @click:outside="$emit('close')">
            <v-card :loading="isLoading">
                <v-card-title class="primary white--text">
                    <span class="headline">Processes</span>
                </v-card-title>

                <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="5"
                        indeterminate
                    ></v-progress-linear>
                </template>

                <v-card-text class="pb-0 pt-4">
                    <v-row>
                        <v-col cols="12">
                            <v-row>
                                <v-dialog
                                    ref="dialog"
                                    v-model="showDatePicker"
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-col cols="auto">
                                            <v-btn
                                                class="ma-2"
                                                :outlined="
                                                    isButtonActived('today')
                                                "
                                                @click="setDateRange('today')"
                                                color="primary"
                                            >
                                                Today
                                            </v-btn>
                                            <v-btn
                                                class="ma-2"
                                                :outlined="
                                                    isButtonActived('week')
                                                "
                                                @click="setDateRange('week')"
                                                color="primary"
                                            >
                                                Week
                                            </v-btn>
                                            <v-btn
                                                class="ma-2"
                                                :outlined="
                                                    isButtonActived('month')
                                                "
                                                @click="setDateRange('month')"
                                                color="primary"
                                            >
                                                Month
                                            </v-btn>
                                            <v-btn
                                                class="ma-2"
                                                :outlined="
                                                    isButtonActived('year')
                                                "
                                                @click="setDateRange('year')"
                                                color="primary"
                                            >
                                                Year
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="3">
                                            <v-text-field
                                                v-if="withCustomDates"
                                                label="Report Date Range"
                                                :value="customDatesString"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details
                                                class="date-input"
                                            ></v-text-field>
                                        </v-col>
                                    </template>
                                    <v-calendar
                                        v-show="withCustomDates"
                                        :is-range="true"
                                        :attributes="[
                                            {
                                                highlight: {
                                                    backgroundColor: '#ddd',
                                                },
                                            },
                                        ]"
                                        mode="single"
                                        v-model="customDates"
                                        is-inline
                                        is-expanded
                                        @input="customDateChanged"
                                    ></v-calendar>
                                </v-dialog>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-data-table
                                v-model="value.types"
                                :headers="headers"
                                :items="
                                    hasCustomDate
                                        ? customProcessesReport
                                        : processes
                                "
                                item-key="name"
                                show-select
                                class="elevation-1"
                                dense
                            >
                                <template v-slot:item.percent="{ item }">
                                    {{ $percentCommitted(item) }}%
                                </template>
                                <template
                                    v-slot:item.utilization_percentage="{
                                        item,
                                    }"
                                >
                                    {{ item.utilization_percentage }}%
                                </template>
                                <template
                                    v-slot:item.maintenance_percentage="{
                                        item,
                                    }"
                                >
                                    {{ item.maintenance_percentage }}%
                                </template>
                                <template
                                    v-slot:item.idle_percentage="{ item }"
                                >
                                    {{ item.idle_percentage }}%
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="$emit('close')">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import processPercentCommitted from "Mixins/processPercentCommitted.mixin";
import VCalendar from "v-calendar/lib/components/date-picker.umd";

export default {
    components: { VCalendar },

    mixins: [processPercentCommitted],

    data() {
        return {
            hasCustomDate: false,
            isLoading: false,
            customProcessesReport: [],
            showDatePicker: false,
            withCustomDates: true,
            customDates: {
                start: Vue.moment(
                    `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day}`
                ).toDate(),
                end: Vue.moment(
                    `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day}`
                ).toDate(),
            },
            showProcessesModal: false,
            singleSelect: false,
            selected: [],
            headers: [
                {
                    text: "Process",
                    align: "start",
                    value: "name",
                },
                {
                    text: "Possible Hours",
                    align: "center",
                    value: "possible_hours",
                },
                {
                    text: "Mantenance Hours",
                    align: "center",
                    value: "machine_downtime_hours",
                },
                {
                    text: "Actual Hours",
                    value: "booked_machine_hours",
                    align: "center",
                },
                {
                    text: "Utilization",
                    value: "utilization_percentage",
                    align: "center",
                },
                {
                    text: "Mantenance",
                    value: "maintenance_percentage",
                    align: "center",
                },
                {
                    text: "Idle",
                    value: "idle_percentage",
                    align: "center",
                },
            ],
        };
    },

    methods: {
        getProcessUtilizationReport() {
            const vm = this;
            this.hasCustomDate = true;
            vm.isLoading = true;
            let from = Vue.moment(vm.customDates.start).format("YYYY-MM-DD");
            let end = Vue.moment(vm.customDates.end).format("YYYY-MM-DD");

            Vue.backendApi()
                .reports()
                .processes()
                .utilization(from, end)
                .then(success, fail);

            function success(response) {
                vm.customProcessesReport = response.data;
                vm.isLoading = false;
            }

            function fail(error) {
                console.log(
                    "An error occurred trying to get processes report with custom dates"
                );
                vm.isLoading = false;
            }
        },

        customDateChanged() {
            const vm = this;
            vm.showDatePicker = false;
            vm.getProcessUtilizationReport();
        },

        isButtonActived(button) {
            let from = Vue.moment(this.customDates.start).format("YYYY-MM-DD");
            let end = Vue.moment(this.customDates.end).format("YYYY-MM-DD");

            if (
                this.buttonRanges[button].from === from &&
                this.buttonRanges[button].to === end
            ) {
                return false;
            }
            return true;
        },

        setDateRange(button) {
            let start = this.buttonRanges[button].from;
            let end = this.buttonRanges[button].to;
            this.customDates = {
                start: Vue.moment(start).toDate(),
                end: Vue.moment(end).toDate(),
            };
            this.getProcessUtilizationReport();
        },
    },

    computed: {
        customDatesString() {
            const start = this.customDates.start;
            const end = this.customDates.end;
            return `${Vue.moment(start).format("DD/MM/YYYY")} - ${Vue.moment(
                end
            ).format("DD/MM/YYYY")}`;
        },

        buttonRanges() {
            const date = Vue.moment();
            return {
                today: {
                    from: date.format("YYYY-MM-DD"),
                    to: date.format("YYYY-MM-DD"),
                },
                week: {
                    from: date.clone().startOf("week").format("YYYY-MM-DD"),
                    to: date.format("YYYY-MM-DD"),
                },
                month: {
                    from: date.clone().startOf("month").format("YYYY-MM-DD"),
                    to: date.format("YYYY-MM-DD"),
                },
                year: {
                    from: date.clone().startOf("year").format("YYYY-MM-DD"),
                    to: date.format("YYYY-MM-DD"),
                },
            };
        },
    },

    props: {
        show: Boolean,
        processes: Array,
        value: Object,
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-application--wrap {
    min-height: auto;
}
</style>
