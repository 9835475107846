<template>
    <v-data-table
        :headers="headers"
        :items="building.stations"
        class="elevation-1 row-pointer"
        :loading="loading"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Stations</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="showStationEditor = true"
                    >New Station</v-btn
                >
                <v-dialog
                    v-model="showStationEditor"
                    @keydown.esc="confirmClose"
                    @click:outside="confirmClose"
                    persistent
                    max-width="500px"
                >
                    <v-card>
                        <v-card-title class="primary white--text">
                            <span class="headline">
                                {{ formTitle }}
                            </span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                            @input="isDirty = true"
                                            v-model="editedStation.name"
                                            label="Name"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select
                                            v-model="editedStation.process.id"
                                            @input="isDirty = true"
                                            :items="processes"
                                            label="Process"
                                            item-text="name"
                                            item-value="id"
                                            :rules="[
                                                () =>
                                                    !!editedStation.process
                                                        .id || 'Required.',
                                            ]"
                                            ref="process"
                                        >
                                            <template slot="no-data">
                                                <no-select-data
                                                    message="No available processes"
                                                    link="/app/processes"
                                                    link-title="Create processes"
                                                >
                                                </no-select-data>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            @input="isDirty = true"
                                            v-model="editedStation.cost"
                                            label="Cost"
                                            :rules="[checkCost]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-select
                                            @input="isDirty = true"
                                            v-model="editedStation.schedule.id"
                                            :items="schedules"
                                            label="Schedule"
                                            item-text="name"
                                            item-value="id"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                            @input="isDirty = true"
                                            v-model="editedStation.position"
                                            label="Position"
                                            :rules="[checkPosition]"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:body="props">
            <draggable
                v-model="props.items"
                v-bind="dragOptions"
                id="draggable-div"
                @end="moveStation"
            >
                <transition-group
                    type="transition"
                    name="flip-list"
                    :tag="'tbody'"
                    :class="{
                        'mobile-table-visible': $vuetify.breakpoint.xsOnly,
                    }"
                >
                    <tr
                        :class="{
                            'v-data-table__mobile-table-row':
                                $vuetify.breakpoint.xsOnly,
                        }"
                        v-for="(station, index) in props.items"
                        :key="station.id"
                    >
                        <td
                            :class="{
                                'v-data-table__mobile-row':
                                    $vuetify.breakpoint.xsOnly,
                                'text-start': $vuetify.breakpoint.smAndUp,
                            }"
                        >
                            <div
                                class="v-data-table__mobile-row__header d-flex d-sm-none"
                            >
                                Name
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell text-right text-sm-left"
                            >
                                {{ station.name }}
                            </div>
                        </td>
                        <td
                            :class="{
                                'v-data-table__mobile-row':
                                    $vuetify.breakpoint.xsOnly,
                                'text-start': $vuetify.breakpoint.smAndUp,
                            }"
                        >
                            <div
                                class="v-data-table__mobile-row__header d-flex d-sm-none"
                            >
                                Process
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell text-right text-sm-left"
                            >
                                {{ station.process | arrayToString("name") }}
                            </div>
                        </td>
                        <td
                            :class="{
                                'v-data-table__mobile-row':
                                    $vuetify.breakpoint.xsOnly,
                                'text-start': $vuetify.breakpoint.smAndUp,
                            }"
                        >
                            <div
                                class="v-data-table__mobile-row__header d-flex d-sm-none"
                            >
                                Cost
                            </div>
                            <div class="v-data-table__mobile-row__cell">
                                {{ station.cost }}
                            </div>
                        </td>
                        <td
                            :class="{
                                'v-data-table__mobile-row':
                                    $vuetify.breakpoint.xsOnly,
                                'text-start': $vuetify.breakpoint.smAndUp,
                            }"
                        >
                            <div
                                class="v-data-table__mobile-row__header d-flex d-sm-none"
                            >
                                Schedule
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell text-right text-sm-left"
                            >
                                {{ station.schedule | arrayToString("name") }}
                            </div>
                        </td>
                        <td
                            :class="{
                                'v-data-table__mobile-row':
                                    $vuetify.breakpoint.xsOnly,
                                'text-start': $vuetify.breakpoint.smAndUp,
                            }"
                        >
                            <div
                                class="v-data-table__mobile-row__header d-flex d-sm-none"
                            >
                                Position
                            </div>
                            <div class="v-data-table__mobile-row__cell">
                                {{ station.position }}
                            </div>
                        </td>
                        <td
                            :class="{
                                'v-data-table__mobile-row':
                                    $vuetify.breakpoint.xsOnly,
                                'text-start': $vuetify.breakpoint.smAndUp,
                            }"
                        >
                            <div
                                class="v-data-table__mobile-row__header d-flex d-sm-none"
                            >
                                Actions
                            </div>
                            <div
                                class="v-data-table__mobile-row__cell text-right text-sm-right"
                            >
                                <v-icon
                                    :small="$vuetify.breakpoint.smAndUp"
                                    class="mr-2"
                                    @click="editStation(station)"
                                >
                                    mdi-pencil
                                </v-icon>
                            </div>
                        </td>
                    </tr>
                </transition-group>
            </draggable>
        </template>
    </v-data-table>
</template>

<script>
import draggable from "vuedraggable";
import arrayToString from "Filters/arrayToString";
import reorder from "Mixins/reorder.mixin";
import noSelectData from "Components/utils/no-select-data";

export default {
    components: { draggable, noSelectData },
    data: data,
    mixins: [reorder],
    methods: getMethods(),
    watch: getWatchers(),
    mounted: mounted,
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Station" : "Edit Station";
        },
    },
    filters: { arrayToString },
};

function data() {
    return {
        showStationEditor: false,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Process",
                align: "start",
                sortable: false,
                value: "process",
                class: "grey--text text--darken-3",
            },
            {
                text: "Cost",
                align: "start",
                sortable: false,
                value: "cost",
                class: "grey--text text--darken-3",
            },
            {
                text: "Schedule",
                align: "start",
                sortable: false,
                value: "schedule",
                class: "grey--text text--darken-3",
            },
            {
                text: "Position",
                align: "start",
                sortable: false,
                value: "position",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedStation: {
            id: "new-station-id",
            name: "",
            process: {
                name: "",
                id: "",
            },
            cost: null,
            schedule: {
                name: "",
                id: "",
            },
            position: null,
        },
        defaultStation: {
            id: "new-station-id",
            name: "",
            process: {
                name: "",
                id: "",
            },
            cost: null,
            schedule: {
                name: "",
                id: "",
            },
            position: null,
        },
        building: {
            stations: [],
        },
        loading: false,
        schedules: [],
        processes: [],
        dragOptions: {
            ghostClass: "ghost",
        },
    };
}

function getWatchers() {
    return {
        showStationEditor: {
            handler: dialog,
        },
        "$route.params.id": {
            handler: loadStations,
            immediate: true,
        },
    };

    function dialog(val) {
        val || this.close();
    }

    function loadStations() {
        const vm = this;
        vm.loading = true;

        Vue.backendApi()
            .buildings(vm.$route.params.id)
            .stations()
            .index()
            .then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "building", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get stations");
        }
    }
}

function getMethods() {
    return {
        editStation: editStation,
        close: close,
        confirmClose: confirmClose,
        save: save,
        checkStation: checkStation,
        checkCost: checkCost,
        checkPosition: checkPosition,
        moveStation: moveStation,
    };

    function moveStation(evt) {
        const vm = this;
        let reorderedStations = vm.$reorder(evt, vm.building.stations);

        if (reorderedStations) {
            Vue.backendApi()
                .buildings(vm.$route.params.id)
                .stations()
                .reorder({
                    stations: reorderedStations,
                })
                .then(success, fail);
        }

        function success(response) {
            vm.building.stations = response.data;
        }

        function fail(error) {
            console.log("An error occurred trying to move station");
        }
    }

    function editStation(station) {
        this.editedIndex = this.building.stations.indexOf(station);
        if (!station.schedule) {
            station.schedule = {
                id: "",
                name: "",
            };
        }
        if (!station.process) {
            station.process = {
                id: "",
                name: "",
            };
        }
        this.editedStation = Object.assign({}, station);
        this.showStationEditor = true;
    }

    function close() {
        this.showStationEditor = false;
        this.$nextTick(() => {
            this.editedStation = JSON.parse(
                JSON.stringify(this.defaultStation)
            );
            this.editedIndex = -1;
        });
        Object.keys(this.editedStation).forEach((field) => {
            if (this.$refs[field]) {
                this.$refs[field].resetValidation();
            }
        });
        this.isDirty = false;
    }

    function confirmClose() {
        const vm = this;

        if (!vm.isDirty) {
            vm.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            vm.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.checkStation()) {
            return false;
        }

        if (vm.editedStation.id === "new-station-id") {
            Vue.backendApi()
                .buildings(vm.$route.params.id)
                .stations()
                .store(vm.editedStation)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .buildings(vm.$route.params.id)
                .stations(vm.editedStation.id)
                .store(vm.editedStation)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(
                    vm.building.stations[vm.editedIndex],
                    response.data
                );
            } else {
                vm.building.stations.unshift(response.data);
            }
            vm.isDirty = false;
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save station");
        }
    }

    function checkStation() {
        let stationHasErrors = false;

        if (!this.editedStation.process.id) {
            this.$refs.process.validate(true);
            stationHasErrors = true;
        }
        if (stationHasErrors) {
            return false;
        }
        if (this.checkCost(this.editedStation.cost) !== true) {
            return false;
        }
        if (this.checkPosition(this.editedStation.position) !== true) {
            return false;
        }
        return true;
    }

    function checkCost(cost) {
        const pattern = /^[0-9]{1,9}(\.[0-9]{1,2})?$/;
        if (!cost) {
            return true;
        }
        if (pattern.test(cost)) {
            return true;
        }
        return "Invalid format";
    }

    function checkPosition(position) {
        const pattern = /^\-?[0-9]{1,}$/;
        if (!position) {
            return true;
        }
        if (pattern.test(position)) {
            return true;
        }
        return "Must be an integer";
    }
}

function mounted() {
    const vm = this;
    vm.loading = true;

    // Extracts tbody node from draggable div in order to keep vuetify styles
    const draggableDiv = document.getElementById("draggable-div");
    draggableDiv.parentElement.appendChild(
        draggableDiv.removeChild(draggableDiv.firstChild)
    );

    Vue.set(vm, "processes", this.$store.state.processes);
    this.$store.watch(this.$store.getters.processes, (processes) => {
        Vue.set(vm, "processes", processes);
    });

    Vue.backendApi().schedules().index().then(success, fail);

    function success(response) {
        vm.loading = false;
        Vue.set(vm, "schedules", response.data);
    }

    function fail(error) {
        vm.loading = false;
        console.log("An error occurred trying to get schedules");
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: move;

    button {
        cursor: pointer;
    }
}

tbody > tr > td > span {
    display: none;
}

.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody.mobile-table-visible
    > tr {
    td {
        padding-top: 13px;
        text-align: right;

        span {
            display: block;
            float: left;
            font-weight: bold;
            font-size: 0.875rem;
        }
    }
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

.flip-list-move {
    transition: transform 0.5s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
