<template>
    <v-dialog
        persistent
        v-model="show"
        @click:outside="confirmClose"
        @keydown.esc="confirmClose"
        @keydown.enter="save"
        max-width="550px"
    >
        <v-card>
            <v-card-title class="primary white--text">
                <span class="headline">
                    {{ formTitle }}
                </span>
            </v-card-title>

            <v-card-text>
                <v-form ref="editInventoryForm" onSubmit="return false;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="9">
                                <v-text-field
                                    v-model="inventoryItem.name"
                                    label="Name"
                                    @input="isDirty = true"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-select
                                    label="Unit Name"
                                    @input="isDirty = true"
                                    :rules="[rules.required]"
                                    v-model="inventoryItem.unit_id"
                                    :items="units"
                                    item-text="name"
                                    item-value="id"
                                >
                                    <template slot="no-data">
                                        <no-select-data
                                            message="No available units"
                                            link="/app/units"
                                            link-title="Create units"
                                        >
                                        </no-select-data>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="8">
                                <v-text-field
                                    v-model="inventoryItem.description"
                                    label="Description"
                                    @input="isDirty = true"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-model="inventoryItem.category_id"
                                    @input="isDirty = true"
                                    :items="inventoryCategories"
                                    label="Category"
                                    item-text="name"
                                    item-value="id"
                                    clearable
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="3">
                                <v-text-field
                                    v-model="inventoryItem.cost"
                                    label="Cost"
                                    @input="isDirty = true"
                                    :rules="[
                                        rules.required,
                                        rules.decimalNumber,
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <v-text-field
                                    v-model="inventoryItem.sale_price"
                                    label="Sale price"
                                    @input="isDirty = true"
                                    :rules="[rules.decimalNumber]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <v-text-field
                                    type="number"
                                    min="0"
                                    v-model="inventoryItem.quantity"
                                    label="Quantity"
                                    @input="isDirty = true"
                                    :rules="[rules.required, rules.min(0)]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <v-text-field
                                    type="number"
                                    min="0"
                                    v-model="inventoryItem.low_inventory"
                                    label="Low Inventory"
                                    @input="isDirty = true"
                                    :rules="[rules.required, rules.min(0)]"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <serial-numbers-editor
                                    :is-new-item="isNewItem"
                                    v-model="inventoryItem.serial_numbers"
                                ></serial-numbers-editor>
                            </v-col>
                        </v-row>
                        <v-row class="py-0">
                            <v-col cols="12" class="py-0">
                                <v-checkbox
                                    @change="handleChangeOnWeightParam"
                                    hide-details
                                    dense
                                    v-model="isPlateEditedInventoryItem"
                                    label="It's a plate. Calculate weight automatically"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="3">
                                <v-text-field
                                    v-if="inventoryItem.parent_item"
                                    v-model="inventoryItem.thickness"
                                    label="Thickness (inches)"
                                    @input="handleChangeOnWeightParam"
                                    :rules="[
                                        rules.decimalNumber(
                                            inventoryItem.thickness,
                                            4
                                        ),
                                        rules.greaterThan(0),
                                        checkMaxSize(inventoryItem),
                                    ]"
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="inventoryItem.thickness"
                                    label="Thickness (inches)"
                                    @input="handleChangeOnWeightParam"
                                    :rules="[
                                        rules.decimalNumber(
                                            inventoryItem.thickness,
                                            4
                                        ),
                                        rules.greaterThan(0),
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <v-text-field
                                    v-if="inventoryItem.parent_item"
                                    v-model="inventoryItem.width"
                                    label="Width (inches)"
                                    @input="handleChangeOnWeightParam"
                                    :rules="[
                                        rules.decimalNumber(
                                            inventoryItem.width,
                                            4
                                        ),
                                        rules.greaterThan(0),
                                        checkMaxSize(inventoryItem),
                                    ]"
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="inventoryItem.width"
                                    label="Width (inches)"
                                    @input="handleChangeOnWeightParam"
                                    :rules="[
                                        rules.decimalNumber(
                                            inventoryItem.width,
                                            4
                                        ),
                                        rules.greaterThan(0),
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <v-text-field
                                    v-if="inventoryItem.parent_item"
                                    v-model="inventoryItem.length"
                                    label="Length (inches)"
                                    @input="handleChangeOnWeightParam"
                                    :rules="[
                                        rules.decimalNumber(
                                            inventoryItem.length,
                                            4
                                        ),
                                        rules.greaterThan(0),
                                        checkMaxSize(inventoryItem),
                                    ]"
                                    hint="inches"
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="inventoryItem.length"
                                    label="Length (inches)"
                                    @input="handleChangeOnWeightParam"
                                    :rules="[
                                        rules.decimalNumber(
                                            inventoryItem.length,
                                            4
                                        ),
                                        rules.greaterThan(0),
                                    ]"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="3">
                                <v-text-field
                                    v-if="inventoryItem.parent_item"
                                    :max="getMaxForWeight(inventoryItem)"
                                    v-model="inventoryItem.weight"
                                    label="Weight (kg)"
                                    @input="isDirty = true"
                                    :rules="[
                                        rules.decimalNumber(
                                            inventoryItem.weight
                                        ),
                                        rules.greaterThan(0),
                                        rules.max(
                                            getMaxForWeight(inventoryItem)
                                        ),
                                    ]"
                                    persistent-hint
                                    :hint="
                                        inventoryItem.automaticFillAlert
                                            ? 'Auto-filled!'
                                            : ''
                                    "
                                ></v-text-field>
                                <v-text-field
                                    v-else
                                    v-model="inventoryItem.weight"
                                    label="Weight (kg)"
                                    @input="isDirty = true"
                                    :rules="[
                                        rules.decimalNumber(
                                            inventoryItem.weight
                                        ),
                                        rules.greaterThan(0),
                                    ]"
                                    persistent-hint
                                    :hint="
                                        inventoryItem.automaticFillAlert
                                            ? 'Auto-filled!'
                                            : ''
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="inventoryItem.location"
                                    label="Location"
                                    @input="isDirty = true"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="confirmClose">Cancel</v-btn>
                <v-btn color="primary" @click="save">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import offcutsOfInventoryItem from "Mixins/offcutsOfInventoryItem.mixin";
import noSelectData from "Components/utils/no-select-data";
import round from "Filters/round";
import serialNumbersEditor from "Components/inventory/serial-numbers-editor";

export default {
    mixins: [offcutsOfInventoryItem],

    components: { noSelectData, offcutsOfInventoryItem, serialNumbersEditor },

    data() {
        return {
            show: true,
            isDirty: false,
            units: [],
            inventoryCategories: [],
            isPlateEditedInventoryItem:
                this.inventoryItem.id === "new-inventory-id" ? true : false,
            rules: {
                required: (value) => {
                    if (value === undefined || value === null || value === "") {
                        return "Required.";
                    }
                    return true;
                },
                min(min) {
                    return (value) => {
                        if (
                            value === undefined ||
                            value === null ||
                            value === ""
                        ) {
                            return true;
                        }
                        return (
                            parseFloat(value) >= parseFloat(min) || "Min " + min
                        );
                    };
                },
                greaterThan(min) {
                    return (value) => {
                        if (
                            value === undefined ||
                            value === null ||
                            value === ""
                        ) {
                            return true;
                        }
                        return (
                            parseFloat(value) > parseFloat(min) ||
                            "It should be greater than " + min
                        );
                    };
                },
                max(max) {
                    return (value) => {
                        if (
                            value === undefined ||
                            value === null ||
                            value === ""
                        ) {
                            return true;
                        }
                        return (
                            parseFloat(value) <= parseFloat(max) || "Max " + max
                        );
                    };
                },
                decimalNumber(value, places = 2) {
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }
                    const pattern = new RegExp(
                        `^([0-9]{1,9})?(\.[0-9]{1,${places}})?$`
                    );
                    return pattern.test(value) || "Invalid format.";
                },
            },
        };
    },

    props: {
        inventoryItem: Object,
        isNewItem: Boolean,
        inventory: Array,
    },

    computed: {
        formTitle() {
            if (this.isNewItem && this.inventoryItem.parent_id) {
                return "New offcut";
            }
            if (this.isNewItem && !this.inventoryItem.parent_id) {
                return "New Inventory Item";
            }
            if (this.inventoryItem.parent_id) {
                return "Edit offcut";
            }
            return "Edit Inventory Item";
        },

        editedItemPlateWeight() {
            const item = this.inventoryItem;
            if (!item.thickness || !item.width || !item.length) {
                return "";
            }

            let weight = item.thickness * item.width * item.length * 0.13;
            if (isNaN(weight)) {
                return "";
            }
            return round(weight, 2);
        },
    },

    methods: {
        confirmClose() {
            if (!this.isDirty) {
                this.close();
                return;
            }
            if (confirm("Unsaved changes!\nDo you really want to close?")) {
                this.close();
            }
        },

        close() {
            this.isDirty = false;
            this.$refs.editInventoryForm.resetValidation();
            this.$emit("close");
        },

        checkMaxSize(offcutItem) {
            const sizeAttrs = ["length", "width", "thickness"];
            let parentItemSizeAttrs = [];

            sizeAttrs.forEach((size) => {
                if (offcutItem.parent_item[size]) {
                    parentItemSizeAttrs.push(size);
                }
            });
            if (parentItemSizeAttrs.length <= 1) {
                return true;
            }

            let parentItemOffcuts = this.$getOffcutsOfInventoryItem(
                offcutItem.parent_id
            );
            if (offcutItem.id === "new-inventory-id") {
                parentItemOffcuts.push(offcutItem);
            }

            let sumOfOffcutSizes = getSumOfOffcutSizes(
                parentItemOffcuts,
                parentItemSizeAttrs
            );
            let parentItemTotalSize = getTotalItemSize(
                offcutItem.parent_item,
                parentItemSizeAttrs
            );

            if (parentItemTotalSize >= sumOfOffcutSizes) {
                return true;
            }
            return "The size sum of offcuts is greater than the original item";

            function getSumOfOffcutSizes(offcuts, parentItemSizeAttrs) {
                let sumOfOffcutSizes = 0;
                offcuts.forEach((offcut) => {
                    if (offcutHasAllSizesAttrs(offcut, parentItemSizeAttrs)) {
                        sumOfOffcutSizes += getTotalItemSize(
                            offcut,
                            parentItemSizeAttrs
                        );
                    }
                });
                return sumOfOffcutSizes;
            }

            function offcutHasAllSizesAttrs(item, sizeAttrs) {
                for (const size of sizeAttrs) {
                    if (!item[size]) {
                        return false;
                    }
                }
                return true;
            }

            function getTotalItemSize(item, sizeAttrs) {
                return sizeAttrs.reduce(function (sum, size) {
                    return sum * parseFloat(item[size]);
                }, 1);
            }
        },

        getMaxForWeight(editedOffcut) {
            let max = editedOffcut.parent_item.weight;
            let originalItemId = editedOffcut.parent_id;
            let originalItemOffcuts =
                this.$getOffcutsOfInventoryItem(originalItemId);

            originalItemOffcuts.forEach((offcut) => {
                if (offcut.id !== editedOffcut.id) {
                    max -= offcut.weight;
                }
            });
            return max;
        },

        handleChangeOnWeightParam() {
            const vm = this;
            vm.isDirty = true;

            if (!vm.isPlateEditedInventoryItem) {
                return;
            }

            if (vm.editedItemPlateWeight !== "") {
                vm.inventoryItem.weight = vm.editedItemPlateWeight;
                Vue.set(vm.inventoryItem, "automaticFillAlert", true);
                setTimeout(() => {
                    vm.inventoryItem.automaticFillAlert = false;
                }, 2000);
            }
        },

        save() {
            const vm = this;
            if (!this.$refs.editInventoryForm.validate()) {
                return false;
            }
            decimalsWithEmptyStringToNull();

            if (vm.inventoryItem.id === "new-inventory-id") {
                Vue.backendApi()
                    .inventory()
                    .store(vm.inventoryItem)
                    .then(success, fail);
            } else {
                Vue.backendApi()
                    .inventory(vm.inventoryItem.id)
                    .store(vm.inventoryItem)
                    .then(success, fail);
            }

            function success(response) {
                vm.$emit("saved", response.data);
                vm.close();
            }

            function fail(error) {
                console.log("An error occurred trying to save inventory item");
            }

            function decimalsWithEmptyStringToNull() {
                const sizes = [
                    "length",
                    "width",
                    "thickness",
                    "weight",
                    "sale_price",
                ];
                for (const size of sizes) {
                    if (vm.inventoryItem[size] === "") {
                        vm.inventoryItem[size] = null;
                    }
                }
            }
        },

        getUnits() {
            const vm = this;

            Vue.backendApi().units().index().then(success, fail);

            function success(response) {
                Vue.set(vm, "units", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to get units");
            }
        },

        getInventoryCategories() {
            const vm = this;

            Vue.backendApi().inventoryCategories().index().then(success, fail);

            function success(response) {
                Vue.set(vm, "inventoryCategories", response.data);
            }

            function fail(error) {
                console.log(
                    "An error occurred trying to get inventory categories"
                );
            }
        },
    },

    created() {
        this.getUnits();
        this.getInventoryCategories();
    },

    filters: { round },
};
</script>

<style lang="scss" scoped>
::v-deep
    .v-input:not(.textarea)
    > .v-input__control
    > .v-input__slot
    > .v-text-field__slot,
::v-deep .v-input > .v-input__control > .v-input__slot > .v-select__slot {
    height: 28px;
}
</style>
