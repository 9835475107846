<template>
    <div>
        <div
            class="row-job"
            @click="clickOnItem(item)"
            :class="{
                'job-collection': item.isJobCollection,
                'is-expanded-job-collection': item.isExpandedJobCollection,
                'belongs-to-expanded-collection':
                    item.belongsToExpandedCollection,
                'is-latest-job-in-collection': item.isLatestJobInCollection,
            }"
        >
            <div class="hrs">
                <div class="actual-hours-text">
                    {{ item.progress | round }}%
                </div>
            </div>
            <div class="job-text jobsbg" :key="'view-employee-${job.id}'">
                {{ item.name }}
                <span v-if="dueIn.as('days') < 1">
                    <i class="fas fa-hourglass-half"></i>
                </span>
                <div
                    v-for="(blocker, i) in item.blockers"
                    :key="i"
                    class="blocker-text"
                >
                    {{ blocker.blocker }} &lt;
                </div>
                <div class="clearfix"></div>

                <div class="job-info" v-if="item.serial_number || item.due_at">
                    <span v-if="item.serial_number" class="job-serial">
                        <i class="fas fa-barcode"></i> {{ item.serial_number }}
                    </span>
                    <div class="job-delivery-date">
                        {{ item.due_at | shortDate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import round from "Filters/round";
import shortDate from "Filters/shortDate";

export default {
    name: "job.vue",
    props: {
        item: Object,
    },
    filters: getFilters(),
    computed: getComputed(),
    methods: getMethods(),
    data: data,
};

function data() {
    return {
        showNotes: false,
    };
}

function getComputed() {
    return {
        dueIn: dueIn,
    };

    function dueIn() {
        const vm = this;
        let dueAt = Vue.moment(vm.item.due_at, "YYYY-MM-DD HH:mm");
        let now = Vue.moment();
        let dueIn = Vue.moment.duration(dueAt.diff(now));
        return dueIn;
    }
}

function getFilters() {
    return {
        round,
        shortDate,
    };
}

function getMethods() {
    return {
        clickOnItem: clickOnItem,
    };

    function clickOnItem(item) {
        this.showNotes = !this.showNotes;
        if (item.isJobCollection) {
            this.$emit("clickOnJobCollection", item);
        }
        this.$eventHub.$emit("onSelectJob", item);
    }
}
</script>

<style lang="scss">
@import "~@/_variables.scss";
.jobs-list .jobs-list-inner-container div[draggable].job-list-chosen {
    transform: rotate(5deg);

    & .job-text.jobsbg {
        background-color: $draggableListBackground;
    }
}
</style>

<style scoped lang="scss">
@import "~@/_variables.scss";

.row-job {
    display: inline;
    list-style: none;
    float: left;
    width: 100%;
    border-bottom: 1px solid #a6a7a9;
    position: relative;
    z-index: 99;
    max-width: 95%;
    font-weight: 700;

    &.job-collection {
        &::after {
            position: absolute;
            font-family: "Font Awesome 5 Free";
            content: "\f078";
            font-weight: 900;
            left: 65px;
            top: 13px;
            opacity: 0.5;
            font-size: 10px;
        }
    }

    &.job-collection.is-expanded-job-collection {
        border: 3px solid #333;
        border-left: 2px solid #333;

        &::after {
            content: "\f077";
        }
    }

    &.belongs-to-expanded-collection {
        border-left: 1px solid #333;
        border-right: 3px solid #333;
    }

    &.is-latest-job-in-collection {
        border-bottom: 3px solid #333;
    }

    & .hrs {
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 0;
        padding-top: 10px;
    }

    & .job-text {
        float: right;
        width: calc(100% - 60px);
        text-align: center;
        min-height: 40px;
        position: relative;
        padding-top: 10px;
        &:hover {
            .job-info {
                transform: scaleY(1);
                max-height: 500px;
            }
        }

        & .job-info {
            transform: scaleY(0);
            background-color: #d49498;
            transform-origin: bottom;
            transition: transform 0.7s;
            overflow: hidden;
            font-weight: normal;
            padding: 5px;
            margin-top: 3px;
            max-height: 0px;
            text-align: justify;

            .job-serial {
                background-color: #f5f5f5;
                padding: 2px 4px;
                border-radius: 8px;
            }

            .job-delivery-date {
                font-weight: bold;
                text-align: right;
            }
        }

        & .blocker-text {
            right: 0;
            background: #c9949a;
            font-size: 10px;
            width: 100%;
            text-align: right;
            padding-right: 8px;
        }

        & .fa-hourglass-half {
            margin-left: 10px;
            position: absolute;
            right: 5px;
            top: 14px;
            opacity: 0.5;
        }
    }
}

div[draggable].job-list-drag {
    width: 100%;

    & .row-job {
        max-width: 100%;

        & .hrs {
            display: none;
        }

        & .job-text {
            opacity: 0.6;
            width: 100%;
            margin-right: 0;
            background: $orange;
            border-style: solid;
            border-color: darkblue;
            border-width: 2px;

            & .blocker-text {
                visibility: hidden;
            }
        }
    }
}
</style>
