<template>
    <div class="menu-list" v-if="showMenu">
        <div
            v-for="item in menuOptions"
            :key="item.title"
            v-on:mouseleave="mouseleave(item)"
            v-on:mouseover="mouseover(item)"
        >
            <a v-if="item.is_parent" class="menu-list-item position-relative">
                <i class="fa fa-angle-left submenu-icon"></i>
                {{ item.title }}

                <div class="submenu" :class="menuToggle(item)">
                    <router-link
                        class="menu-list-item"
                        v-for="item in item.sub_options"
                        :key="item.title"
                        :to="item.link"
                    >
                        {{ item.title }}
                    </router-link>
                </div>
            </a>

            <router-link v-else class="menu-list-item" :to="item.link">
                {{ item.title }}
            </router-link>
        </div>

        <a
            class="menu-list-item border-bottom-0"
            href="/logout"
            @click.prevent="$logout"
            >Logout</a
        >
        <logout-form></logout-form>
    </div>
</template>

<script>
import menuItems from "Mixins/menuItems.mixin";
import logout from "Mixins/logout.mixin";
import logoutForm from "Components/logout-form";

export default {
    mixins: [menuItems, logout],
    components: { logoutForm },
    methods: getMethods(),
    data: data,
    name: "cinasa-menu",
    props: {
        showMenu: {
            type: Boolean,
            default: false,
        },
    },
};

function data() {
    return {
        currentItem: null,
        hideFast: false,
        mouseState: null,
    };
}

function getMethods() {
    return {
        mouseleave: mouseleave,
        mouseover: mouseover,
        menuToggle: menuToggle,
    };

    /**
     * Method Toggles between 4 styles
     * 1. if you toggle between a menu that has submenus it has instant transition
     * 2. if you 'mouseleave' to a menu that has no submenu , ... there is a transition delay
     *
     */
    function menuToggle(item) {
        let isCurrentItem = this.currentItem && this.currentItem.id === item.id;
        let isMouseOver = this.mouseState === "mouseover";
        let isMouseOverCurrentItem = isCurrentItem && isMouseOver;

        if (isMouseOverCurrentItem && this.hideFast) {
            return "display";
        } else if (isMouseOverCurrentItem) {
            return "transition-display";
        } else if (this.hideFast) {
            return "hide";
        } else {
            return "transition-hide";
        }
    }

    function mouseleave(item) {
        this.hideFast = false;
        this.currentItem = item;
        this.mouseState = "mouseleave";
    }

    function mouseover(item) {
        this.hideFast = false;
        let isMouseLeaveToSubMenu =
            this.mouseState === "mouseleave" &&
            this.currentItem.is_parent &&
            item.is_parent;

        if (isMouseLeaveToSubMenu) {
            this.hideFast = true;
        }
        this.mouseState = "mouseover";
        this.currentItem = item;
    }
}
</script>

<style lang="scss" scoped>
.transition-display {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.1s;
    transition-delay: 0s;
}

.display {
    pointer-events: auto;
    opacity: 1;
}

.transition-hide {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.1s;
    transition-delay: 1s;
}

.hide {
    pointer-events: none;
    opacity: 0;
}

.submenu {
    width: 220px;
    position: absolute;
    background-color: white;
    left: -220px;
    margin-top: -32px;
    border: 2px solid;
}

.submenu > .menu-list-item {
    padding-left: 10px;
    padding-right: 10px;
}

.submenu-icon {
    position: absolute;
    left: 8px;
    top: 5px;
}
</style>
