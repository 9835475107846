<template>
    <div>
        <select name="hours" v-model="hours">
            <option v-for="(foo, hour) in 23" :value="hour">{{ hour }}</option>
        </select>
        hour and
        <select name="minutes" v-model="minutes">
            <option v-for="(foo, minute) in 59" :value="minute">
                {{ minute }}
            </option>
        </select>
        minutes
    </div>
</template>

<script>
export default {
    name: "editable-item-duration-picker",
    data: () => {
        return {
            hours: 0,
            minutes: 0,
        };
    },
    props: {
        value: Number,
    },
    computed: getComputed(),
    watch: getWatchers(),
};

function getComputed() {
    return {
        seconds,
    };

    function seconds() {
        let hoursInSeconds = this.hours * 60 * 60;
        let minutesInSeconds = this.minutes * 60;
        let timeInSeconds = hoursInSeconds + minutesInSeconds;
        return timeInSeconds;
    }
}

function getWatchers() {
    return {
        seconds: seconds,
        value: {
            immediate: true,
            handler: value,
        },
    };

    function seconds() {
        this.$emit("change", this.seconds);
    }

    function value() {
        let duration = Vue.moment.duration(this.value, "seconds");
        this.hours = duration.hours();
        this.minutes = duration.minutes();
    }
}
</script>

<style scoped>
select {
    border-style: revert;
    background-color: revert;
    border-radius: revert;
    -moz-appearance: revert;
    -webkit-appearance: revert;
}

option {
    padding: revert;
}
</style>
