export default {
    methods: {
        $percentCommitted(process) {
            let possibleHours = parseFloat(
                process.possible_machine_shift_hours
            );
            if (possibleHours === 0) {
                return 0;
            }

            let bookedHours = parseFloat(process.booked_machine_hours);
            return Math.round((bookedHours / possibleHours) * 100);
        },
    },
};
