<template>
    <v-data-table
        :headers="headers"
        :items="loading ? [] : permissions"
        class="elevation-1 row-pointer"
        :loading="loading"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Ungrouped Specialized</v-toolbar-title>
            </v-toolbar>
        </template>

        <template v-slot:item.isCheck="{ item: permission }">
            <permission-checkbox
                :isAdmin="isAdmin"
                :permission="getCheckedPermission(permission)"
                @change="permissionChanged"
            >
            </permission-checkbox>
        </template>
    </v-data-table>
</template>

<script>
import permissionCheckbox from "./permission-checkbox.vue";

export default {
    components: { permissionCheckbox },
    data: data,
    props: {
        isAdmin: Boolean,
        groupPermissions: Array,
        loading: Boolean,
        permissions: Array,
    },
    methods: getMethods(),
};

function data() {
    return {
        headers: [
            {
                text: "Permission",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Allowed",
                value: "isCheck",
                align: "center",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
    };
}

function getMethods() {
    return {
        permissionChanged: permissionChanged,
        getCheckedPermission: getCheckedPermission,
    };

    function permissionChanged(permission) {
        const vm = this;
        vm.$emit("permissionChanged", permission);
    }

    function getCheckedPermission(permission) {
        let isCheck =
            this.groupPermissions.findIndex((groupPermission) => {
                return groupPermission.name === permission.name;
            }) > -1;

        permission.isCheck = isCheck;
        return permission;
    }
}
</script>

<style lang="scss" scoped>
::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
