import HoursControl from "Components/overview/building/machine/shift/machine-shift-hours-control";

export default {
    components: { HoursControl },

    props: {
        jobHistory: Object,
    },

    data() {
        return {
            showJobHoursEditor: false,
            showMenuOptions: false,
        };
    },

    watch: {
        showJobHoursEditor: function () {
            this.$emit("showModal", this.showJobHoursEditor, "jobHours");
        },

        showMenuOptions: function () {
            this.$emit("showModal", this.showMenuOptions, "menuOptions");
        },
    }
};
