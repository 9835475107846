export default {
    data: function () {
        return {
            overviewDate: Vue.moment(
                `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day}`,
                "YYYY-MM-DD"
            ),
            copy: {
                fromDay: `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day} 00:00:00`,
                toDay: Vue.moment(
                    `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day} 00:00:00`
                )
                    .add(1, "day")
                    .format("YYYY-MM-DD 00:00:00"),
            },
            loading: [],
            processReport: [],
            buildings: [],
            allShifts: [],
            stationDowntimes: [],
            filters: {
                types: [],
            },
        };
    },

    beforeCreate() {
        const hasNoRouteParams = Object.keys(this.$route.params).length === 0;
        if (hasNoRouteParams) {
            let newDate = Vue.moment();
            this.$route.params.year = newDate.format("YYYY");
            this.$route.params.month = newDate.format("MM");
            this.$route.params.day = newDate.format("DD");
        }
    },

    created() {
        const vm = this;
        vm.$eventHub.$on(
            "machine-station-shifts-created",
            vm.addNewStationShifts
        );
        vm.$eventHub.$on(
            "machine-station-shifts-removed",
            vm.removeMachineStationShifts
        );
        vm.$eventHub.$on(
            "stored-machine-downtime",
            vm.getProcessUtilizationReport
        );

        if (!vm.overviewDate.isValid()) {
            vm.overviewDate = Vue.moment();
        }
        vm.getDay();
        vm.getBuildings();
        vm.getAllShifts();
    },

    methods: {
        getDay() {
            const vm = this;
            vm.$router
                .push({
                    name: "overview-on",
                    params: {
                        year: vm.overviewDate.format("YYYY"),
                        month: vm.overviewDate.format("MM"),
                        day: vm.overviewDate.format("DD"),
                    },
                })
                .catch(() => {});

            Vue.set(vm, "overviewDate", vm.overviewDate.clone());
            vm.getProcessUtilizationReport.apply(vm);
        },

        getProcessUtilizationReport() {
            const vm = this;
            let date = vm.getOverviewShortDate();

            Vue.backendApi()
                .reports()
                .processes()
                .utilization(date, date)
                .then(success, fail);

            function success(response) {
                Vue.set(vm, "processReport", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to load processes");
            }
        },

        getOverviewShortDate() {
            const vm = this;
            let date = {
                year: vm.overviewDate.format("YYYY"),
                month: vm.overviewDate.format("MM"),
                day: vm.overviewDate.format("DD"),
            };
            return `${date.year}-${date.month}-${date.day}`;
        },

        getBuildings() {
            const vm = this;
            vm.loading.push("buildings");

            Vue.backendApi()
                .buildings()
                .index()
                .then(success, fail)
                .finally(() => {
                    vm.loading = vm.loading.filter((element) => {
                        return element !== "buildings";
                    });
                });

            function success(response) {
                Vue.set(vm, "buildings", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to load buildings");
            }
        },

        getAllShifts() {
            const vm = this;
            vm.loading.push("all shifts");
            let date = vm.getOverviewShortDate();
            let payload = {
                startedAfter: date + " 00:00:00",
                startedBefore: date + " 23:59:59",
            };

            Vue.backendApi()
                .stationShift()
                .all(payload)
                .then(success, fail)
                .finally(() => {
                    vm.loading = vm.loading.filter((element) => {
                        return element !== "all shifts";
                    });
                });

            function success(response) {
                Vue.set(vm, "allShifts", response.data);
                vm.$eventHub.$emit("update-shifts", response.data);
                Vue.nextTick(function () {
                    vm.$forceUpdate();
                });
            }

            function fail(error) {
                console.log("An error occurred trying to load all shifts");
            }
        },

        previousDate() {
            const vm = this;
            vm.overviewDate.subtract(1, "day");
            vm.getDay();
            vm.getAllShifts();
        },

        nextDate() {
            const vm = this;
            vm.overviewDate.add(1, "day");
            vm.getDay();
            vm.getAllShifts();
        },

        getStationDowntimes() {
            const vm = this;
            vm.loading.push("downTimes");
            let date = `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day}`;

            Vue.backendApi()
                .stationDowntimes()
                .on(date)
                .then(success, fail)
                .finally(() => {
                    vm.loading = vm.loading.filter((element) => {
                        return element !== "downTimes";
                    });
                });

            function success(response) {
                vm.$store.commit("machineDowntimes", response.data);
            }

            function fail(error) {
                console.log("Error trying to get station downtimes");
            }
        },
    },

    watch: {
        overviewDate() {
            const vm = this;
            vm.copy.fromDay = `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day} 00:00:00`;
            let aMoment = Vue.moment(
                `${this.$route.params.year}-${this.$route.params.month}-${this.$route.params.day} 00:00:00`
            )
                .add(1, "day")
                .format("YYYY-MM-DD 00:00:00");
            vm.copy.toDay = aMoment;
            vm.getStationDowntimes();
        },
    },
};
