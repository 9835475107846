<template>
    <v-data-table
        :headers="headers"
        :items="groups"
        class="elevation-1 row-pointer"
        :loading="loading"
        :item-class="rowClasses"
        @click:row="focusOnGroup"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Groups</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                    persistent
                    v-model="showGroupEditor"
                    @click:outside="confirmClose"
                    @keydown.esc="confirmClose"
                    @keydown.enter="trySave"
                    max-width="500px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on"
                            >New Group</v-btn
                        >
                    </template>
                    <v-card>
                        <v-card-title class="primary white--text">
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-form
                                ref="editGroupForm"
                                onSubmit="return false;"
                            >
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                                @input="isDirty = true"
                                                v-model="editedGroup.name"
                                                label="Name"
                                                :rules="[
                                                    () =>
                                                        !!editedGroup.name ||
                                                        'Required.',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                :small="$vuetify.breakpoint.smAndUp"
                class="mr-2"
                @click="editGroup(item)"
                v-if="item.name !== 'Admin'"
            >
                mdi-pencil
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: data,
    created: created,
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Group" : "Edit Group";
        },
    },
    methods: getMethods(),
    watch: getWatchers(),
};

function data() {
    return {
        showGroupEditor: false,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedGroup: {
            id: "new-group-id",
            name: "",
        },
        defaultGroup: {
            id: "new-group-id",
            name: "",
        },
        groups: [],
        loading: false,
    };
}

function getWatchers() {
    return {
        showGroupEditor: {
            handler: dialog,
        },
    };

    function dialog(val) {
        val || this.close();
    }
}

function getMethods() {
    return {
        focusOnGroup: focusOnGroup,
        editGroup: editGroup,
        close: close,
        confirmClose: confirmClose,
        trySave: trySave,
        save: save,
        rowClasses: rowClasses,
    };

    function focusOnGroup(group) {
        if (group.id === "new-group-id") {
            this.$router.push({ name: "groups" });
        } else {
            this.$emit("selected", group);
            this.$router.push({
                name: "groups",
                params: { id: group.id },
            });
        }
    }

    function editGroup(group) {
        this.editedIndex = this.groups.indexOf(group);
        this.editedGroup = Object.assign({}, group);
        this.showGroupEditor = true;
    }

    function close() {
        this.showGroupEditor = false;
        this.$nextTick(() => {
            this.editedGroup = Object.assign({}, this.defaultGroup);
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editGroupForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function trySave() {
        if (this.$store.state.errors.length === 0) {
            this.save();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editGroupForm.validate()) {
            return false;
        }

        if (vm.editedGroup.id === "new-group-id") {
            Vue.backendApi().groups().store(vm.editedGroup).then(success, fail);
        } else {
            Vue.backendApi()
                .groups(vm.editedGroup.id)
                .store(vm.editedGroup)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.groups[vm.editedIndex], response.data);
            } else {
                vm.groups.unshift(response.data);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save group");
        }
    }

    function rowClasses(Group) {
        if (Group.id === parseInt(this.$route.params.id)) {
            return "v-data-table__selected";
        }
    }
}

function created() {
    const vm = this;
    this.loading = true;

    Vue.backendApi().groups().index().then(success, fail);

    function success(response) {
        vm.loading = false;
        Vue.set(vm, "groups", response.data);

        if (vm.$route.params.id) {
            let group = vm.groups.find(
                (group) => group.id === parseInt(vm.$route.params.id)
            );
            if (group) {
                vm.$emit("selected", group);
            }
        }
    }

    function fail(error) {
        vm.loading = false;
        console.log("An error occurred trying to get groups");
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}
</style>
