<template>
    <v-data-table
        :headers="headers"
        class="elevation-1 job-list"
        :loading="loading"
        :items="filteredJobs"
        @click:row="(item) => (selectedItem = item)"
        :item-class="rowClasses"
    >
        <template v-slot:top>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Search..."
                        append-icon="mdi-magnify"
                        hide-details
                        class="p-3"
                        v-model="search"
                    ></v-text-field>

                    <job-menu-actions
                        v-if="selectedItem"
                        :item="selectedItem"
                        @close="selectedItem = null"
                        @details="(item) => (moreDetailsItem = item)"
                        @expand="
                            (jobCollection) =>
                                (expandedJobCollection = jobCollection)
                        "
                        @collapse="expandedJobCollection = null"
                        :expandedJobCollection="expandedJobCollection"
                    ></job-menu-actions>

                    <more-details-modal
                        v-if="moreDetailsItem"
                        :item="moreDetailsItem"
                        @close="moreDetailsItem = null"
                    ></more-details-modal>
                </v-col>
            </v-row>
        </template>

        <template v-slot:item.progress="{ item }">
            <v-progress-circular
                :rotate="-90"
                :size="40"
                :width="5"
                :value="item.progress"
                color="green"
            >
                {{ item.progress | round }}%
            </v-progress-circular>
        </template>

        <template v-slot:item.type="{ item }">
            {{ item.isJobCollection ? "Job Collection" : "Job" }}
        </template>
    </v-data-table>
</template>

<script>
import round from "Filters/round";
import jobMenuActions from "Components/overview/mobile/jobs/job-menu-actions";
import moreDetailsModal from "Components/overview/mobile/jobs/more-details-modal";
import overviewJobs from "Mixins/overview/overview-jobs.mixin";
import collapse from "Mixins/collapse.mixin";

export default {
    mixins: [overviewJobs, collapse],

    components: { jobMenuActions, moreDetailsModal },

    data() {
        return {
            loading: false,
            headers: [
                {
                    text: "PO Item number",
                    sortable: false,
                    align: "start",
                    value: "serial_number",
                    class: "grey--text text--darken-3",
                },
                {
                    text: "Progress",
                    align: "center",
                    sortable: false,
                    value: "progress",
                    class: "grey--text text--darken-3",
                },
                {
                    text: "Name",
                    sortable: false,
                    align: "start",
                    value: "name",
                    class: "grey--text text--darken-3",
                },
            ],
            search: "",
            jobCollections: [],
            collapsedJobs: [],
            filteredJobs: [],
            expandedJobCollection: null,
            selectedItem: null,
            moreDetailsItem: null,
        };
    },

    props: {
        filters: Object,
    },

    filters: { round },

    methods: {
        getJobCollections() {
            const vm = this;

            Vue.backendApi()
                .jobCollections()
                .index()
                .then(success, fail)
                .finally(() => (vm.loading = false));

            function success(response) {
                Vue.set(vm, "jobCollections", response.data);
            }

            function fail(error) {
                console.log("An error occurred trying to get job collections");
            }
        },

        collapseJobs() {
            const vm = this;
            let collapsedJobs = [];

            for (const jobCollection of vm.jobCollections) {
                if (jobCollection.job_count === 1) {
                    let job = jobCollection.jobs[0];
                    job.labels = jobCollection.labels;
                    collapsedJobs.push(job);
                    continue;
                }

                jobCollection.isJobCollection = true;
                Vue.set(jobCollection, "isExpandedJobCollection", false);
                collapsedJobs.push(jobCollection);

                if (
                    vm.expandedJobCollection &&
                    vm.expandedJobCollection.id === jobCollection.id
                ) {
                    jobCollection.isExpandedJobCollection = true;
                    let latestIndex = jobCollection.job_count - 1;
                    jobCollection.jobs[
                        latestIndex
                    ].isLatestJobInCollection = true;

                    jobCollection.jobs.forEach((job) => {
                        job.belongsToExpandedCollection = true;
                        collapsedJobs.push(job);
                    });
                }
            }
            Vue.set(vm, "collapsedJobs", collapsedJobs);
        },

        filterJobs() {
            let filteredJobs = [];
            for (const jobCollection of this.collapsedJobs) {
                if (
                    this.$isFiltered(jobCollection) ||
                    this.$isFilteredBySearch(jobCollection)
                ) {
                    continue;
                }
                filteredJobs.push(jobCollection);
            }
            this.filteredJobs = filteredJobs;
        },

        rowClasses(item) {
            const vm = this;
            let classes = vm.$rowClasses(item);
            if (item.isJobCollection) {
                classes.push("job-collection");
            } else {
                classes.push("job");
            }
            if (
                vm.expandedJobCollection &&
                vm.expandedJobCollection.id === item.id
            ) {
                classes.push("v-data-table__selected");
            }
            return classes;
        },
    },

    watch: {
        expandedJobCollection: function () {
            this.collapseJobs();
            this.selectedItem = null;
        },

        jobCollections: {
            immediate: true,
            handler: "collapseJobs",
        },

        collapsedJobs: {
            immediate: true,
            handler: "filterJobs",
        },

        search: {
            handler: "filterJobs",
        },

        filters: {
            handler: "filterJobs",
            deep: true,
        },
    },

    created() {
        this.getJobCollections();
    },
};
</script>

<style lang="scss" scoped>
div.job-list button.is-selected {
    border-color: darkgreen;
    color: darkgreen;
    border-style: none none solid none;
}

div.job-list tr.v-data-table__selected {
    border-color: darkgreen;
    border-top-width: 3px;
    border-top-style: solid;
    border-left-width: 6px;
    border-left-style: solid;
    font-weight: 500;
}

div.job-list tr.subItem {
    border-color: darkgreen;
    border-left-width: 6px;
    border-left-style: solid;
    border-top-width: thin;
    border-top-color: rgba(0, 0, 0, 0.12);
    font-weight: 500;
}

@media (max-width: 600px) {
    .v-application .v-data-table.elevation-1 {
        box-shadow: none !important;
    }

    ::v-deep
        .theme--light.v-data-table
        > .v-data-table__wrapper
        > table
        > tbody
        > tr:not(:last-child).subItem
        > td:last-child {
        padding-bottom: 25px;
    }

    ::v-deep
        .v-data-table
        > .v-data-table__wrapper
        > table
        > tbody
        > tr.subItem.latestSubItem {
        margin-bottom: 10px;
    }

    ::v-deep .job:not(.subItem, .v-data-table__selected),
    ::v-deep .job-collection:not(.subItem, .v-data-table__selected) {
        border: thin solid rgba(0, 0, 0, 0.12);
        margin: 10px 0px;
    }

    ::v-deep .job {
        .v-data-table__mobile-row:nth-child(3)
            > .v-data-table__mobile-row__header {
            visibility: hidden;
        }

        .v-data-table__mobile-row:nth-child(3)
            > .v-data-table__mobile-row__header::before {
            visibility: initial;
            content: "Job";
        }
    }

    ::v-deep .job-collection {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);

        .v-data-table__mobile-row:nth-child(3)
            > .v-data-table__mobile-row__header {
            visibility: hidden;
        }

        .v-data-table__mobile-row:nth-child(3)
            > .v-data-table__mobile-row__header::before {
            visibility: initial;
            content: "Job Collection";
            border-radius: 10px;
            padding: 2px 9px;
            background-color: #7ca6ad;
            color: white;
        }
    }
}
</style>
