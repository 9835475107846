var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('page-header-vuetify'),_vm._v(" "),_c('v-toolbar',{staticClass:"mb-3",attrs:{"flat":"","color":"primary darken-2","dark":""}},[_c('v-toolbar-title',[_vm._v("Machine Time Report")]),_vm._v(" "),_c('v-divider',{staticClass:"ml-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-switch',{staticClass:"ml-4",attrs:{"label":"Custom dates","hide-details":""},model:{value:(_vm.withCustomDates),callback:function ($$v) {_vm.withCustomDates=$$v},expression:"withCustomDates"}}),_vm._v(" "),_c('v-divider',{staticClass:"ml-4",attrs:{"inset":"","vertical":""}}),_vm._v(" "),_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-col',{attrs:{"cols":"2"}},[(_vm.withCustomDates)?_c('v-text-field',_vm._g(_vm._b({staticClass:"date-input",attrs:{"label":"Dates","value":_vm.customDatesString,"prepend-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on)):_c('v-text-field',_vm._g(_vm._b({staticClass:"date-input",attrs:{"label":"Month","value":_vm.monthFormatted,"prepend-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_vm._v(" "),_c('v-calendar',{directives:[{name:"show",rawName:"v-show",value:(_vm.withCustomDates),expression:"withCustomDates"}],attrs:{"is-range":true,"attributes":[
                        {
                            highlight: {
                                backgroundColor: '#ddd',
                            },
                        },
                    ],"mode":"single","is-inline":"","is-expanded":""},on:{"input":_vm.customDateChanged},model:{value:(_vm.customDates),callback:function ($$v) {_vm.customDates=$$v},expression:"customDates"}}),_vm._v(" "),_c('v-date-picker',{directives:[{name:"show",rawName:"v-show",value:(!_vm.withCustomDates),expression:"!withCustomDates"}],attrs:{"type":"month"},on:{"change":_vm.monthChanged},model:{value:(_vm.monthString),callback:function ($$v) {_vm.monthString=$$v},expression:"monthString"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showDatePicker = false}}},[_vm._v("\n                        Cancel\n                    ")])],1)],1)],1),_vm._v(" "),_c('hr'),_vm._v(" "),_vm._l((_vm.buildings),function(building){return _c('v-row',{key:building.id},[_c('v-col',{attrs:{"cols":"12"}},[_c('building-report',{attrs:{"building":building,"dates":_vm.datesForReport}}),_vm._v(" "),_c('hr')],1)],1)})],2)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }