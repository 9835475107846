<template>
    <v-toolbar class="toolbar py-3 py-sm-1" flat color="primary" dark>
        <v-row>
            <v-col cols="auto" class="pr-0 vertical-centered">
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-divider class="ml-4" inset vertical></v-divider>
            </v-col>

            <v-col
                v-if="useFilters"
                cols="auto"
                class="vertical-centered px-0 filters"
            >
                <v-tooltip v-model="noFilterChecked" top nudge-left="200">
                    <template v-slot:activator="{ on, attrs }">
                        <v-switch
                            label="Closed"
                            class="ml-3"
                            v-model="filters.closed"
                            hide-details
                        ></v-switch>
                        <v-switch
                            label="Worked on"
                            class="ml-3"
                            v-model="filters.workedOn"
                            hide-details
                        ></v-switch>
                        <v-switch
                            label="Opened"
                            class="ml-3"
                            v-model="filters.opened"
                            hide-details
                        ></v-switch>
                    </template>
                    <span>Please use at least one filter</span>
                </v-tooltip>
                <v-divider class="ml-4" inset vertical></v-divider>
            </v-col>

            <v-col cols="auto" class="vertical-centered pl-0">
                <v-switch
                    label="Custom dates"
                    class="ml-4"
                    v-model="withCustomDates"
                    hide-details
                ></v-switch>
            </v-col>

            <v-col cols="auto" class="vertical-centered">
                <v-dialog ref="dialog" v-model="showDatePicker" width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-if="withCustomDates"
                            label="Dates"
                            :value="customDatesString"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                            class="date-input"
                        ></v-text-field>
                        <v-text-field
                            v-else
                            label="Month"
                            :value="monthFormatted"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                            class="date-input"
                        ></v-text-field>
                    </template>
                    <v-calendar
                        v-show="withCustomDates"
                        :is-range="true"
                        :attributes="[
                            {
                                highlight: {
                                    backgroundColor: '#ddd',
                                },
                            },
                        ]"
                        mode="single"
                        v-model="customDates"
                        is-inline
                        is-expanded
                        @input="customDateChanged"
                    ></v-calendar>
                    <v-date-picker
                        v-show="!withCustomDates"
                        v-model="monthString"
                        type="month"
                        @change="monthChanged"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="showDatePicker = false"
                        >
                            Cancel
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <v-col
                v-if="Object.keys($slots).length > 0"
                cols="auto"
                class="px-0"
            >
                <slot></slot>
            </v-col>

            <v-col cols="auto" class="vertical-centered ml-lg-auto flex-grow-1">
                <v-text-field
                    v-model="search"
                    clearable
                    flat
                    solo-inverted
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-toolbar>
</template>

<script>
import toolbarDates from "Mixins/toolbarDates.mixin";
import VCalendar from "v-calendar/lib/components/date-picker.umd";

export default {
    mixins: [toolbarDates],
    components: { VCalendar },
    data: data,
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods(),
    props: {
        title: String,
        routeName: String,
        useFilters: {
            type: Boolean,
            default: false,
        },
    },
};

function data() {
    return {
        search: this.$route.query.search,
        filters: {
            opened: true,
            closed: false,
            workedOn: false,
        },
    };
}

function getComputed() {
    return {
        noFilterChecked: noFilterChecked,
    };

    function noFilterChecked() {
        return !Object.values(this.filters).some((filter) => filter);
    }
}

function getWatchers() {
    return {
        search: search,
        "$route.query.filters": {
            handler: filtersQuery,
            immediate: true,
        },
        filters: {
            handler: getReport,
            deep: true,
        },
    };

    function filtersQuery() {
        if (this.$route.query.filters) {
            this.filters = {
                opened: false,
                closed: false,
                workedOn: false,
            };
            let filters = this.$route.query.filters.split(",");
            filters.map((each) => {
                this.filters[each] = true;
            });
        }
    }

    function search() {
        this.$router.push({
            name: this.routeName,
            query: { ...this.$route.query, search: this.search },
            params: this.$route.params,
        });
    }

    function getReport() {
        this.allFilters();
        this.getReport();
    }
}

function getMethods() {
    return {
        getReport: getReport,
        allFilters: allFilters,
        getRouterQuery: getRouterQuery,
    };

    function allFilters() {
        let filterList = [];
        for (const key in this.filters) {
            if (this.filters[key]) {
                filterList.push(key);
            }
        }
        this.$router.push({
            name: this.routeName,
            query: { ...this.$route.query, filters: filterList.join(",") },
            params: this.$route.params,
        });
    }

    function getRouterQuery() {
        const vm = this;
        let filterURL = vm.$route.query.filters ?? "opened";
        let query = { ...vm.$route.query, filters: filterURL };
        return query;
    }

    function getReport() {
        const vm = this;
        if (!this.useFilters) {
            vm.$emit("get-reports-on", vm.getDatesForReport());
            return;
        }

        if (this.noFilterChecked) {
            vm.$emit("no-filters");
            return;
        }

        vm.$emit("get-reports-on", {
            ...vm.getDatesForReport(),
            ...this.filters,
        });
    }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--switch label {
    color: white;
    margin-bottom: 0px;
}

.toolbar {
    height: auto !important;
}

::v-deep .v-toolbar__content {
    height: auto !important;
}

::v-deep .theme--light {
    .v-text-field {
        .v-input__control > .v-input__slot:before {
            border-color: #e0e0e0;
        }

        .v-label {
            top: 4px;
        }
    }
}

::v-deep .theme--light .v-card__title > .v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

::v-deep .date-input .v-input__control {
    margin-top: 8px;
}

@media (max-width: 390px) {
    .filters {
        flex-direction: column;
        align-items: start;
    }
}
</style>
