<template>
    <div>
        <ul class="checkmenu">
            <process-filter
                v-for="process in processesSummary"
                :process="process"
                :key="process.id"
                v-model="value"
            ></process-filter>
        </ul>

        <div class="button-container">
            <a href="#" class="button" @click="showProcessesModal = true"
                >See Hidden ({{ numberHiddenProcesses }})</a
            >
        </div>

        <processes-modal
            :processes="processes"
            :show="showProcessesModal"
            v-model="value"
            @close="showProcessesModal = false"
        ></processes-modal>
    </div>
</template>

<script>
import processFilter from "Components/overview/processes/process-filter";
import processesModal from "Components/overview/processes/processes-modal";

export default {
    components: { processFilter, processesModal },
    data() {
        return {
            showProcessesModal: false,
            numberProcessesInSummary: 6,
        };
    },
    props: {
        value: Object,
        processes: Array,
    },
    computed: {
        processesSummary() {
            const vm = this;
            return this.processes.filter(
                (process, i) => i < vm.numberProcessesInSummary
            );
        },

        numberHiddenProcesses() {
            let hiddenProcesses =
                this.processes.length - this.numberProcessesInSummary;
            return hiddenProcesses >= 0 ? hiddenProcesses : 0;
        },
    },
    methods: {
        getStationShiftHours(stationShift) {
            let startedTime = Vue.moment(
                stationShift.started_at,
                "YYYY-MM-DD HH:mm:ss"
            );
            let endedTime = Vue.moment(
                stationShift.ended_at,
                "YYYY-MM-DD HH:mm:ss"
            );
            return Math.abs(startedTime.diff(endedTime, "minutes")) / 60;
        },
    },
    created() {
        const vm = this;
        vm.$eventHub.$on("job-history-added", addBookedHours);
        vm.$eventHub.$on("job-history-removed", removeBookedHours);
        vm.$eventHub.$on("job-history-updated", updateBookedHours);
        vm.$eventHub.$on("machine-station-shifts-created", addAvailableHours);
        vm.$eventHub.$on(
            "machine-station-shifts-removed",
            removeMachineAvailableHours
        );

        function findProcessReport(processId) {
            return vm.processes.find((process) => process.id === processId);
        }

        function addBookedHours(workHistory) {
            let processReport = findProcessReport(workHistory.process_id);
            processReport.booked_machine_hours =
                parseFloat(processReport.booked_machine_hours) +
                parseFloat(workHistory.all_hours);
        }

        function removeBookedHours(workHistory) {
            let processReport = findProcessReport(workHistory.process_id);
            processReport.booked_machine_hours =
                parseFloat(processReport.booked_machine_hours) -
                parseFloat(workHistory.all_hours);
        }

        function updateBookedHours(oldWorkHistory, newWorkHistory) {
            let processReport = findProcessReport(oldWorkHistory.process_id);
            let hoursDiff =
                parseFloat(newWorkHistory.all_hours) -
                parseFloat(oldWorkHistory.all_hours);
            processReport.booked_machine_hours =
                parseFloat(processReport.booked_machine_hours) + hoursDiff;
        }

        function addAvailableHours(stationShifts) {
            for (const stationShift of stationShifts) {
                let shiftHours = vm.getStationShiftHours(stationShift);
                let processReport = findProcessReport(
                    stationShift.station.process_id
                );
                processReport.possible_machine_shift_hours =
                    parseFloat(processReport.possible_machine_shift_hours) +
                    parseFloat(shiftHours);
            }
        }

        function removeMachineAvailableHours(machine, stationShifts) {
            for (const stationShift of stationShifts) {
                let shiftHours = vm.getStationShiftHours(stationShift);
                let processReport = findProcessReport(
                    stationShift.station.process_id
                );
                processReport.possible_machine_shift_hours =
                    parseFloat(processReport.possible_machine_shift_hours) -
                    parseFloat(shiftHours);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/_variables.scss";

.checkmenu label.custom-control-label {
    font-weight: 700;
}
ul.checkmenu {
    font-size: 8px;
}

.button-container {
    clear: both;
    display: flex;
    padding-top: 6px;

    & a.button {
        font-size: 12px;
        text-align: center;
        color: $buildingGreen;
        border-color: $buildingGreen;
        border-style: solid;
        border-radius: 10px;
        display: block;
        width: 100px;
        margin: 0 auto 0 auto;
    }
    & a.button:hover {
        color: white;
        font-weight: 700;
        background-color: $buildingGreen;
    }
}
</style>
