<template>
    <div>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title>
                    {{ message }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item :to="link" class="primary">
            <v-list-item-content>
                <v-list-item-title class="white--text">
                    {{ linkTitle }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
        link: String,
        linkTitle: String,
    },
};
</script>
