export default function (values, property) {
    if (property && values) {
        if (Array.isArray(values)) {
            return values
                .map((v) => {
                    return v[property];
                })
                .join(", ");
        } else {
            return values[property];
        }
    } else {
        return "";
    }
}
