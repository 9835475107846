<template>
    <div>
        <v-btn
            v-if="$vuetify.breakpoint.smAndUp"
            class="mb-2"
            color="primary"
            depressed
            dark
            @click="zeroEstimatedHours"
        >
            Show Unplanned Jobs
        </v-btn>
        <v-data-table
            :headers="headers"
            :items="collapsedJobs"
            :item-class="rowClasses"
            item-key="key"
            class="elevation-1 row-pointer job-list"
            :loading="loading"
            loading-text="Loading jobs..."
            @click:row="clickOnItem"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar class="toolbar" flat color="white">
                    <v-row class="justify-content-between">
                        <v-col cols="auto" class="pr-0 vertical-centered">
                            <v-toolbar-title
                                >LIST OF CURRENT JOBS
                                <small
                                    >({{ totalJobs }})</small
                                ></v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                        </v-col>

                        <v-col
                            cols="auto"
                            class="vertical-centered flex-grow-1 mb-5 mb-sm-0"
                        >
                            <v-form
                                @submit.prevent="search"
                                @keyup.native.enter="search"
                                class="d-flex flex-nowrap flex-grow-1 flex-sm-grow-0"
                            >
                                <v-text-field
                                    label="Search"
                                    v-model="searchQuery"
                                    prepend-inner-icon="mdi-magnify"
                                    hide-details
                                    @input="search"
                                >
                                </v-text-field>
                            </v-form>
                        </v-col>

                        <v-col
                            v-if="$vuetify.breakpoint.smAndUp"
                            cols="auto"
                            class="vertical-centered"
                        >
                            <v-switch
                                label="Show all"
                                v-model="isWithTrashed"
                                hide-details
                            ></v-switch>
                        </v-col>

                        <v-col
                            v-if="$vuetify.breakpoint.smAndUp"
                            cols="auto"
                            class="vertical-centered"
                        >
                            <v-btn
                                color="primary"
                                dark
                                @click="showJobEditor = true"
                                >New Job
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.hours="{ item }">
                {{ getHoursSummary(item) }}
            </template>
            <template v-slot:item.efficiency="{ item }">
                <v-progress-circular
                    :rotate="-90"
                    :size="40"
                    :width="5"
                    :value="item.efficiency"
                    color="green"
                >
                    {{ item.efficiency | round | percent }}
                </v-progress-circular>
            </template>
            <template v-slot:item.progress="{ item }">
                <progress-button :item="item"></progress-button>
            </template>
            <template v-slot:item.blockers="{ item }">
                {{ item.blockers | arrayToString("name") | truncate(12) }}
            </template>
            <template v-slot:item.due_at="{ item }">
                {{ item.due_at | duration }}
            </template>
            <template v-slot:item.feedback_meeting_completed_at="{ item }">
                <div
                    v-if="!item.isJobCollection"
                    @click.stop="selectedJobMeeting = item"
                    class="icon-actions"
                >
                    <v-icon
                        v-if="item.feedback_meeting_completed_at"
                        :small="$vuetify.breakpoint.smAndUp"
                    >
                        mdi-comment-check
                    </v-icon>
                    <v-icon v-else :small="$vuetify.breakpoint.smAndUp"
                        >mdi-comment-outline</v-icon
                    >
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="icon-actions">
                    <v-btn
                        title="Sales Order"
                        text
                        :small="$vuetify.breakpoint.smAndUp"
                        class="sales-order-btn px-0"
                        :disabled="!item.sales_order_id"
                        :to="`/app/sales-orders/${item.sales_order_id}`"
                    >
                        <v-icon :small="$vuetify.breakpoint.smAndUp"
                            >mdi-newspaper-variant-multiple</v-icon
                        >
                        SO
                    </v-btn>

                    <span v-if="item.isJobCollection">
                        <v-icon
                            :small="$vuetify.breakpoint.smAndUp"
                            @click.stop="buildJobCollection(item)"
                            title="Build"
                            class="ml-4 ml-lg-1 mr-lg-1"
                        >
                            mdi-hammer-wrench
                        </v-icon>

                        <v-icon
                            v-if="expandedJobCollection === item.id"
                            :large="$vuetify.breakpoint.xsOnly"
                            @click.stop="collapseJobCollection(item)"
                            class="ml-4 ml-lg-0 v-data-table__expand-icon--active"
                            :class="{
                                'is-selected':
                                    item.isJobCollection &&
                                    expandedJobCollection === item.id,
                            }"
                        >
                            mdi-chevron-down
                        </v-icon>
                        <v-icon
                            v-else
                            :large="$vuetify.breakpoint.xsOnly"
                            @click.stop="expand(item)"
                            class="ml-4 ml-lg-0"
                            :class="{
                                'is-selected':
                                    item.isJobCollection &&
                                    expandedJobCollection === item.id,
                            }"
                        >
                            mdi-chevron-down
                        </v-icon>
                    </span>
                    <span v-else>
                        <v-btn
                            v-if="$can('read-sales-report')"
                            title="Job Cost Calculations"
                            text
                            :small="$vuetify.breakpoint.smAndUp"
                            class="job-cost-btn px-0"
                            :to="`/app/sales-order-reports/job-cost-calculations/${item.id}`"
                            :disabled="isTrashing(item)"
                        >
                            <v-icon :small="$vuetify.breakpoint.smAndUp"
                                >mdi-cash-usd-outline</v-icon
                            >
                        </v-btn>

                        <v-icon
                            :small="$vuetify.breakpoint.smAndUp"
                            @click.stop="isTrashing(item) ? '' : editJob(item)"
                            title="Edit"
                            class="mr-4 ml-4 mx-lg-1"
                        >
                            mdi-pencil
                        </v-icon>

                        <v-icon
                            v-if="item.deleted_at || isTrashing(item)"
                            :small="$vuetify.breakpoint.smAndUp"
                            @click.stop="isTrashing(item) ? '' : restore(item)"
                            title="Unarchive"
                            class="ml-lg-1"
                        >
                            mdi-backup-restore
                        </v-icon>
                        <v-icon
                            v-else
                            :small="$vuetify.breakpoint.smAndUp"
                            @click.stop="destroy(item, $event)"
                            title="Archive"
                            class="ml-lg-1"
                        >
                            mdi-archive
                        </v-icon>
                    </span>
                </div>
            </template>
        </v-data-table>

        <meeting-editor
            v-if="selectedJobMeeting"
            :show="selectedJobMeeting ? true : false"
            :job="selectedJobMeeting"
            @close="selectedJobMeeting = null"
            @update-feedback-meeting="feedbackMeeting('update')"
            @remove-feedback-meeting="feedbackMeeting('remove')"
        ></meeting-editor>

        <job-editor-modal
            v-if="showJobEditor"
            :job="editedJob"
            :job-indexes="editedJobIndexes"
            @close="closeJobEditor"
            @saved="savedJob"
        ></job-editor-modal>

        <mobile-actions
            v-if="touchedItem"
            :item="touchedItem"
            :is-trashing="isTrashing(touchedItem)"
            :exists-expanded-job-collection="
                expandedJobCollection ? true : false
            "
            @build-job-collection="
                (jobCollection) => buildJobCollection(jobCollection)
            "
            @edit-job="(job) => editJob(job)"
            @expand="(jobCollection) => expand(jobCollection)"
            @collapse="(jobCollection) => collapseJobCollection(jobCollection)"
            @restore="(job) => restore(job)"
            @destroy="(event, job) => destroy(job, event)"
            @open-reports="(job) => openJobReports(job)"
            @close="touchedItem = null"
        ></mobile-actions>
    </div>
</template>

<script>
import duration from "Filters/duration";
import truncate from "Filters/truncate";
import arrayToString from "Filters/arrayToString";
import round from "Filters/round";
import percent from "Filters/percent";
import collapse from "Mixins/collapse.mixin";
import date from "Filters/shortDate";
import permissions from "Mixins/permissions.mixin";
import meetingEditor from "Components/jobs/meeting-editor";
import jobEditorModal from "Components/jobs/job-editor-modal";
import mobileActions from "Components/jobs/mobile-actions";
import progressButton from "Components/jobs/progress-button";

export default {
    components: {
        meetingEditor,
        jobEditorModal,
        mobileActions,
        progressButton,
    },
    data: data,
    created: created,
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods(),
    filters: { duration, truncate, arrayToString, round, percent, date },
    mixins: [collapse, permissions],
    props: {
        updatedJobCollection: Object,
    },
};

function data() {
    return {
        searchTimer: null,
        showJobEditor: false,
        showMeetingEditor: false,
        showJobProgress: null,
        selectedJobMeeting: null,
        deletingTimeoutId: null,
        searchQuery: "",
        salesOrderId: null,
        jobCollections: [],
        isWithTrashed: false,
        touchedItem: null,
        editedJobIndexes: null,
        editedJob: {
            id: "new-job-id",
            name: "",
            blockers: [],
            due_at: "",
            all_hours: 0,
            created_at: null,
            estimated_hours: 0,
            steps: [],
            updated_at: null,
            units: 1,
        },
        defaultJob: {
            id: "new-job-id",
            name: "",
            blockers: [],
            due_at: "",
            all_hours: 0,
            created_at: null,
            estimated_hours: 0,
            steps: [],
            updated_at: null,
            units: 1,
        },
        loading: false,
        trashingJobs: [],
    };
}

function getWatchers() {
    return {
        isWithTrashed: {
            handler: isWithTrashed,
        },
        jobCollections: {
            handler: collapseJobs,
            deep: true,
        },
        expandedJobCollection: {
            handler: collapseJobs,
        },
        updatedJobCollection: {
            handler: updateJobCollection,
        },
    };

    function isWithTrashed() {
        this.updateMyRoute.apply(this);
        this.getJobCollections.apply(this);
    }

    function collapseJobs() {
        this.$collapseJobs();
        this.focusJobCollection();
        this.focusJob();
    }

    function updateJobCollection(updatedJobCollection) {
        let jobCollectionIndex = this.jobCollections.findIndex(
            (jobCollection) => jobCollection.id === updatedJobCollection.id
        );
        this.jobCollections.splice(jobCollectionIndex, 1, updatedJobCollection);
    }
}

function getMethods() {
    return {
        getJobCollections: getJobCollections,
        rowClasses: rowClasses,
        findJobIndexes: findJobIndexes,
        editJob: editJob,
        destroy: destroy,
        restore: restore,
        closeJobEditor: closeJobEditor,
        savedJob: savedJob,
        emitClickOnJob: emitClickOnJob,
        openJobReports: openJobReports,
        clickOnItem: clickOnItem,
        expand: expand,
        collapseJobCollection: collapseJobCollection,
        editJobCollection: editJobCollection,
        buildJobCollection: buildJobCollection,
        editJobCollectionEstimates: editJobCollectionEstimates,
        focusJobCollection: focusJobCollection,
        focusJob: focusJob,
        feedbackMeeting: feedbackMeeting,
        getHoursSummary: getHoursSummary,
        startTrashing: startTrashing,
        stopTrashing: stopTrashing,
        isTrashing: isTrashing,
        zeroEstimatedHours: zeroEstimatedHours,
        updateMyRoute: updateMyRoute,
        search: search,
    };

    function startTrashing(job) {
        const vm = this;
        vm.trashingJobs.push(job.id);
    }

    function stopTrashing(job) {
        const vm = this;
        let index = vm.trashingJobs.indexOf(job.id);
        if (index > -1) {
            vm.trashingJobs.splice(index, 1);
        }
    }

    function isTrashing(job) {
        const vm = this;
        let index = vm.trashingJobs.indexOf(job.id);
        return index > -1;
    }

    function rowClasses(item) {
        const vm = this;
        let classes = vm.$rowClasses(item);
        if (vm.isTrashing(item) && !classes.includes("item-archived")) {
            classes.push("item-archived");
        }
        if (item.isJobCollection) {
            classes.push("job-collection");
        } else {
            classes.push("job");
        }
        return classes;
    }

    function getJobCollections() {
        const vm = this;
        vm.loading = true;
        clearTimeout(vm.searchTimer);

        vm.searchTimer = setTimeout(() => {
            Vue.backendApi()
                .jobCollections()
                .search({
                    searchQuery: vm.searchQuery,
                    isWithTrashed: vm.isWithTrashed,
                })
                .then(success, fail);
        }, 330);

        function success(response) {
            Vue.set(vm, "jobCollections", response.data);
            vm.loading = false;
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get jobs");
        }
    }

    function findJobIndexes(job) {
        let jobCollectionIndex = this.jobCollections.findIndex(
            (jobCollection) => jobCollection.id === job.job_collection_id
        );
        let jobIndex = this.jobCollections[jobCollectionIndex].jobs.findIndex(
            (jobInJobCollection) => jobInJobCollection.id === job.id
        );
        return jobCollectionIndex > -1 && jobIndex > -1
            ? { jobCollectionIndex, jobIndex }
            : null;
    }

    function editJob(job) {
        this.editedJobIndexes = this.findJobIndexes(job);
        this.editedJob = Object.assign({}, job);
        this.showJobEditor = true;
    }

    function restore(job) {
        const vm = this;
        clearTimeout(vm.deletingTimeoutId);
        Vue.backendApi().jobs(job.id).restore().then(success, fail);

        function success(response) {
            vm.stopTrashing(job);
            job.deleted_at = null;
        }

        function fail(error) {
            console.log("An error occurred trying to restore a job");
        }
    }

    function destroy(job, event) {
        const vm = this;
        vm.startTrashing(job);
        Vue.backendApi().jobs(job.id).destroy().then(success, fail);

        function success(response) {
            vm.touchedItem = null;
            const { jobCollectionIndex, jobIndex } = vm.findJobIndexes(job);
            let jobCollection = vm.jobCollections[jobCollectionIndex];

            if (vm.isWithTrashed) {
                job.deleted_at = response.data.deleted_at;
                vm.editJobCollectionEstimates(jobCollection);
                vm.$emit("jobArchived", jobCollection);
                return;
            }

            vm.deletingTimeoutId = setTimeout(() => {
                if (vm.$vuetify.breakpoint.smAndUp) {
                    const button = event.target;
                    const tr = button.parentElement.parentElement.parentElement;
                    tr.classList.add("hide");
                }

                setTimeout(() => {
                    if (jobCollection.job_count === 1) {
                        vm.jobCollections.splice(jobCollectionIndex, 1);
                    } else {
                        if (vm.$vuetify.breakpoint.smAndUp) {
                            vm.editJobCollectionEstimates(jobCollection);
                        }

                        jobCollection.jobs.splice(jobIndex, 1);
                        jobCollection.job_count--;

                        if (jobCollection.job_count === 1) {
                            let job = jobCollection.jobs[0];
                            job.isFirstJobInCollection = false;
                            job.isLatestJobInCollection = false;
                            job.belongsToExpandedCollection = false;
                            vm.clickOnItem(job);
                        }
                    }
                    vm.stopTrashing(job);
                    vm.$emit("jobArchived", jobCollection);
                }, 500);
            }, 2000);
            vm.$router.push({ name: "manage-jobs" });
        }

        function fail(error) {
            vm.stopTrashing(job);
            console.log("An error occurred trying to archive a job");
        }
    }

    function closeJobEditor() {
        this.showJobEditor = false;
        this.editedJob.blockers = [];
        this.$nextTick(() => {
            this.editedJob = Object.assign({}, this.defaultJob);
            this.editedJobIndexes = null;
        });
    }

    function savedJob(job) {
        const vm = this;
        vm.touchedItem = null;
        if (vm.editedJobIndexes) {
            const { jobCollectionIndex, jobIndex } = vm.editedJobIndexes;
            Object.assign(
                vm.jobCollections[jobCollectionIndex].jobs[jobIndex],
                job
            );
        } else {
            let jobCollection = job.job_collection;
            delete job.job_collection;
            jobCollection.jobs = [job];
            jobCollection.job_count = 1;
            vm.jobCollections.unshift(jobCollection);
            vm.clickOnItem.call(vm, jobCollection.jobs[0]);
        }
    }

    function openJobReports(job) {
        this.$router.push({
            name: "job-reports",
            params: { id: job.id, activeTab: "job-processes" },
        });
        this.emitClickOnJob(job);
    }

    function emitClickOnJob(job) {
        const vm = this;
        let jobCollection = vm.jobCollections.find(
            (jobCollection) => jobCollection.id === job.job_collection_id
        );
        this.$emit("clickedOnJob", { job, jobCollection });
    }

    function clickOnItem(item) {
        const vm = this;

        if (vm.isTrashing(item)) {
            return;
        }

        if (vm.$vuetify.breakpoint.smAndDown) {
            vm.touchedItem = item;
            return;
        }

        if (item.isJobCollection) {
            vm.editJobCollectionEstimates(item);
            return;
        }

        let job = item;
        vm.$router.push({
            name: "job-reports",
            params: { id: job.id, activeTab: "job-processes" },
        });
        vm.emitClickOnJob(job);

        // Close another expanded job collection
        if (!job.belongsToExpandedCollection) {
            this.expandedJobCollection = null;
        }
    }

    function expand(jobCollection) {
        this.expandedJobCollection = jobCollection.id;
        this.$router.push({
            name: "manage-job-collections",
            params: {
                jobCollection: this.expandedJobCollection,
            },
        });
    }

    function collapseJobCollection(jobCollection) {
        this.$router.push({ name: "manage-jobs" });
        this.$emit("unselectJobCollection", jobCollection);
        this.expandedJobCollection = null;
        if (this.touchedItem) {
            this.touchedItem = null;
        }
    }

    function editJobCollection(jobCollection) {
        const vm = this;
        vm.expandedJobCollection = jobCollection.id;
        vm.$emit("editJobCollection", jobCollection);
    }

    function buildJobCollection(jobCollection) {
        const vm = this;
        vm.$router.push({
            name: "job-collection-builder",
            params: {
                jobCollection: jobCollection.id,
                activeTab: "steps",
            },
        });
        vm.editJobCollection(jobCollection);
    }

    function editJobCollectionEstimates(jobCollection) {
        const vm = this;
        vm.$router
            .push({
                name: "job-collection-estimates",
                params: { jobCollection: jobCollection.id },
            })
            .catch(() => {});
        vm.editJobCollection(jobCollection);
    }

    function focusJobCollection() {
        const vm = this;
        let jobCollectionId = vm.$route.params.jobCollection;

        if (jobCollectionId) {
            let selectedJobCollection = vm.collapsedJobs.find((item) => {
                let matchId = parseInt(item.id) === parseInt(jobCollectionId);
                return matchId && item.isJobCollection;
            });

            if (selectedJobCollection) {
                vm.editJobCollection(selectedJobCollection);
            }
        }
    }

    function focusJob() {
        const vm = this;
        let jobId = parseInt(vm.$route.params.id);

        if (jobId) {
            let selectedJob = findJob(jobId);
            if (selectedJob) {
                if (selectedJob.jobBelongsToCollection) {
                    vm.expandedJobCollection = selectedJob.job_collection_id;
                }
                vm.emitClickOnJob(selectedJob);
            }
        }

        function findJob(jobId) {
            let foundJob = null;
            for (const item of vm.collapsedJobs) {
                if (item.isJobCollection) {
                    foundJob = item.jobs.find((job) => {
                        return job.id === jobId;
                    });
                    if (foundJob) {
                        foundJob.jobBelongsToCollection = true;
                        break;
                    }
                } else if (item.id === jobId) {
                    foundJob = item;
                    break;
                }
            }
            return foundJob;
        }
    }

    function feedbackMeeting(action) {
        const vm = this;
        const job = vm.selectedJobMeeting;
        let tempJob = {
            ...job,
            feedback_meeting_completed_at:
                action === "update"
                    ? Vue.moment().format("YYYY-MM-DD HH:mm:ss")
                    : null,
        };

        Vue.backendApi().jobs(job.id).store(tempJob).then(success, fail);

        function success(response) {
            const { jobCollectionIndex, jobIndex } = vm.findJobIndexes(job);
            Object.assign(
                vm.jobCollections[jobCollectionIndex].jobs[jobIndex],
                response.data
            );
        }

        function fail(error) {
            console.log(
                "An error occurred trying to update feedback meeting for job"
            );
        }
    }

    function getHoursSummary(item) {
        let all_hours = null;
        let estimated_hours = null;

        if (item.isJobCollection) {
            all_hours = round(item.job_all_hours);
            estimated_hours = round(item.job_estimated_hours);
        } else {
            all_hours = round(item.all_hours);
            estimated_hours = round(item.estimated_hours);
        }
        return `${all_hours} / ${estimated_hours}`;
    }

    function zeroEstimatedHours() {
        const vm = this;
        vm.searchQuery = "estimatedHours:0";
        this.updateMyRoute(this);
        this.getJobCollections();
    }

    function updateMyRoute() {
        const vm = this;
        let query = {};
        if (vm.searchQuery.length > 0) {
            query["searchQuery"] = vm.searchQuery;
        }

        if (vm.isWithTrashed === true) {
            query["isWithTrashed"] = true;
        }

        vm.$router.push({
            query: query,
        });
    }

    function search() {
        const vm = this;
        vm.updateMyRoute.apply(vm);
        vm.getJobCollections.apply(vm);
    }
}

function getComputed() {
    return {
        sortName: sortName,
        totalJobs: totalJobs,
        headers: headers,
    };

    function sortName() {
        return this.expandedJobCollection ? false : true;
    }

    function totalJobs() {
        return this.jobCollections.reduce(
            (sum, jobCollection) => sum + jobCollection.job_count,
            0
        );
    }

    function headers() {
        let headers = [
            {
                text: "PO Item Number",
                align: "start",
                sortable: false,
                value: "serial_number",
                class: "grey--text text--darken-3",
            },
            {
                text: "Qty",
                value: "job_count",
                align: "center",
                class: "grey--text text--darken-3",
                sortable: false,
            },
            {
                text: "Name",
                value: "name",
                class: "grey--text text--darken-3",
                sortable: false,
            },
        ];

        if (this.$vuetify.breakpoint.mdAndUp) {
            headers.push(
                {
                    text: "Hours",
                    value: "hours",
                    class: "grey--text text--darken-3",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Progress",
                    value: "progress",
                    class: "grey--text text--darken-3",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Efficiency",
                    value: "efficiency",
                    class: "grey--text text--darken-3",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Blockers",
                    value: "blockers",
                    class: "grey--text text--darken-3",
                    sortable: false,
                },
                {
                    text: "Due In",
                    value: "due_at",
                    class: "grey--text text--darken-3",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Meeting",
                    value: "feedback_meeting_completed_at",
                    class: "grey--text text--darken-3",
                    sortable: false,
                    align: "center",
                },
                {
                    text: "Description",
                    value: "description",
                    class: "grey--text text--darken-3",
                    sortable: false,
                    align: "start",
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                    align: "right",
                    class: "grey--text text--darken-3",
                    width: this.$vuetify.breakpoint.lgAndUp ? 145 : 203,
                }
            );
        }
        return headers;
    }
}

function created() {
    const vm = this;
    vm.searchQuery = vm.$route.query.searchQuery
        ? vm.$route.query.searchQuery
        : "";

    vm.isWithTrashed =
        vm.$route.query.isWithTrashed === "true" ||
        vm.$route.query.isWithTrashed === "1"
            ? true
            : false;

    vm.getJobCollections.apply(this);
}
</script>

<style>
div.job-list button.is-selected {
    border-color: darkgreen;
    color: darkgreen;
    border-style: none none solid none;
}

div.job-list tr.v-data-table__selected {
    border-color: darkgreen;
    border-top-width: 3px;
    border-top-style: solid;
    border-left-width: 6px;
    border-left-style: solid;
    font-weight: 500;
}

div.job-list tr.subItem {
    border-color: darkgreen;
    border-left-width: 6px;
    border-left-style: solid;
    border-top-width: thin;
    border-top-color: rgba(0, 0, 0, 0.12);
    font-weight: 500;
}
</style>
<style lang="scss" scoped>
::v-deep .v-input--switch label {
    margin-bottom: 0px;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    opacity: 1;
    transition: all 0.5s ease-in-out;
    line-height: 21px;
    padding: 10px;
}

::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr.hide > td {
    opacity: 0;
    line-height: 0px;
    padding: 0px;
    font-size: 0;
    height: 0 !important;
    border: none !important;
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.hide
    > td
    > .icon-actions {
    display: none;
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem
    > td {
    height: 30px;
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem:not(.latestSubItem)
    > td {
    border: none;
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.firstSubItem {
    box-shadow: inset 0 4px 4px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-top: 25px;
        padding-bottom: 5px;
    }
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.subItem.latestSubItem {
    box-shadow: inset 0 -4px 4px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-bottom: 25px;
        padding-top: 5px;
    }
}

::v-deep.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr.latestSubItem.firstSubItem {
    box-shadow: inset 0 4px 8px -5px rgba(50, 50, 50, 0.75),
        inset 0 -4px 8px -5px rgba(50, 50, 50, 0.75);

    td {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

::v-deep .item-archived {
    opacity: 0.5;
    background-color: #f5f5f5;
}

::v-deep.v-data-table tbody tr.v-data-table__selected.item-archived {
    background-color: #eee;
    opacity: 0.8;
}

::v-deep.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 0 10px;
}

::v-deep.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 10px;
}

.toolbar {
    height: auto !important;
}
::v-deep .v-toolbar__content {
    height: auto !important;
}

@media (min-width: 1264px) {
    .sales-order-btn.v-btn:not(.v-btn--round).v-size--small {
        min-width: 42px;
    }

    .job-cost-btn.v-btn:not(.v-btn--round).v-size--small {
        min-width: 25px;
    }
}

@media (max-width: 600px) {
    ::v-deep
        .theme--light.v-data-table
        > .v-data-table__wrapper
        > table
        > tbody
        > tr:not(:last-child).subItem
        > td:last-child {
        padding-bottom: 25px;
    }

    ::v-deep
        .v-data-table
        > .v-data-table__wrapper
        > table
        > tbody
        > tr.subItem.latestSubItem {
        margin-bottom: 10px;
    }

    ::v-deep
        .v-data-table
        > .v-data-table__wrapper
        > table
        > tbody
        > tr.subItem {
        background: #f5f5f5;
    }

    ::v-deep .v-data-table.elevation-1 {
        box-shadow: none !important;
    }
}

::v-deep
    .v-data-table
    > .v-data-table__wrapper
    .v-data-table__mobile-table-row:not(.subItem, .v-data-table__selected) {
    border: thin solid rgba(0, 0, 0, 0.12);
    margin: 10px 0px;
}

::v-deep .v-data-table__mobile-row > .v-data-table__mobile-row__header {
    padding-right: 3px;
}

::v-deep .v-toolbar__content,
.v-toolbar__extension {
    padding: 10px 16px;
}

::v-deep .v-progress-circular__info {
    font-size: 12px;
}

::v-deep
    .v-data-table
    > .v-data-table__wrapper
    .v-data-table__mobile-table-row {
    .v-data-table__mobile-row:nth-child(3) > .v-data-table__mobile-row__header {
        visibility: hidden;
    }

    .v-data-table__mobile-row:nth-child(3)
        > .v-data-table__mobile-row__header::before {
        visibility: initial;
    }
}

::v-deep
    .v-data-table
    > .v-data-table__wrapper
    .v-data-table__mobile-table-row.job {
    .v-data-table__mobile-row:nth-child(2) {
        display: none;
    }

    .v-data-table__mobile-row:nth-child(3)
        > .v-data-table__mobile-row__header::before {
        content: "Job";
    }
}

::v-deep
    .v-data-table
    > .v-data-table__wrapper
    .v-data-table__mobile-table-row.job-collection {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    .v-data-table__mobile-row:nth-child(3)
        > .v-data-table__mobile-row__header::before {
        visibility: initial;
        content: "Job Collection";
        border-radius: 10px;
        padding: 2px 9px;
        background-color: #7ca6ad;
        color: white;
    }
}
</style>
