<template>
    <v-card-text class="py-0">
        <v-form ref="addItemToJobForm">
            <v-container>
                <v-row>
                    <v-col>
                        <v-toolbar-title class="text-left py-0">
                            Add item to jobs
                        </v-toolbar-title>

                        <v-alert
                            outlined
                            type="info"
                            class="text-left mt-2"
                            dense
                            >If you want to attach serial numbers to a job,
                            please do it from the
                            <v-btn small text to="/app/jobs">jobs page</v-btn>
                        </v-alert>
                    </v-col>

                    <v-col cols="12" class="pt-0">
                        <job-autocomplete
                            v-model="selectedJobs"
                            :rules="[(v) => v.length > 0 || 'Required.']"
                        >
                        </job-autocomplete>
                    </v-col>
                </v-row>

                <v-row
                    v-if="selectedJobs.length > 0"
                    class="pb-0 mt-0"
                    transition="fade-transition"
                >
                    <v-col cols="6" md="3">
                        <v-text-field
                            v-model="quantity"
                            label="Quantity"
                            persistent-hint
                            :hint="availabilityMessage"
                            :rules="[checkQuantity]"
                            @input="$emit('isDirty')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            v-model="inventoryItem.unit.name"
                            label="Unit name"
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            v-model="cost"
                            label="Cost"
                            :rules="[rules.decimalNumber, rules.min(0)]"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field
                            v-model="total"
                            label="Total"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <v-card-actions class="pb-5">
            <v-btn text @click="close">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="save">Add</v-btn>
        </v-card-actions>
    </v-card-text>
</template>

<script>
import round from "Filters/round";
import jobAutocomplete from "Components/inventory/job-autocomplete";

export default {
    components: { jobAutocomplete },
    filters: { round },

    data: () => ({
        selectedJobs: [],
        quantity: 1,
        cost: 0,
        rules: {
            required: (value) => {
                if (value === undefined || value === null || value === "") {
                    return "Required.";
                }
                return true;
            },
            min(min) {
                return (value) => {
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }
                    return parseFloat(value) >= parseFloat(min) || "Min " + min;
                };
            },
            decimalNumber: (value) => {
                if (value === undefined || value === null || value === "") {
                    return true;
                }
                const pattern = /^[0-9]{1,9}(\.[0-9]{1,2})?$/;
                return pattern.test(value) || "Invalid format.";
            },
        },
    }),

    props: {
        inventoryItem: Object,
    },

    watch: {
        selectedJobs: {
            immediate: true,
            handler: function () {
                if (this.selectedJobs.length === 0) {
                    return;
                }
                this.$emit("isDirty");
            },
        },

        inventoryItem: {
            immediate: true,
            handler: function () {
                if (this.inventoryItem) {
                    this.cost = this.inventoryItem.cost;
                }
            },
        },
    },

    computed: {
        total: function () {
            if (isNaN(this.quantity) || isNaN(this.cost)) {
                return "";
            }
            return round(this.quantity * this.cost, 2);
        },

        availabilityMessage: function () {
            let availability = this.inventoryItem.quantity - this.quantity;
            return availability === -1
                ? "No availability"
                : `${availability} available`;
        },
    },

    methods: {
        save: function () {
            const vm = this;
            if (!vm.$refs.addItemToJobForm.validate()) {
                return;
            }

            vm.$emit("adding", vm.selectedJobs);
            Vue.backendApi()
                .inventory(vm.inventoryItem.id)
                .addJobs({
                    quantity: vm.quantity,
                    cost: vm.cost,
                    jobs: vm.selectedJobs,
                })
                .then(success, fail);

            function success(response) {
                vm.$emit("added", {
                    jobMaterials: response.data,
                    inventoryItemQuantityUsed: vm.quantity,
                });
                vm.clear();
                vm.$refs.addItemToJobForm.resetValidation();
            }

            function fail(error) {
                vm.$emit("fail", vm.selectedJobs);
                console.log(
                    "An error occurred trying to add inventory item to jobs"
                );
            }
        },

        checkQuantity: function (quantity) {
            if (
                quantity === undefined ||
                quantity === null ||
                quantity === ""
            ) {
                return "Required.";
            }

            const integerPattern = /^[0-9]+$/;
            if (!integerPattern.test(quantity)) {
                return "Invalid format.";
            }

            quantity = parseInt(quantity);
            const min = 1;
            if (quantity < min) {
                return `Min ${min}`;
            }

            const max = this.inventoryItem.quantity;
            if (quantity > max) {
                return `Exceed availability ${max}`;
            }
            return true;
        },

        clear: function () {
            this.selectedJobs = [];
            this.quantity = 1;
        },

        close: function () {
            const vm = this;
            vm.$emit("close");
        },
    },
};
</script>
