<template>
    <v-dialog v-model="show" persistent max-width="395">
        <v-card>
            <v-card-title class="text-h6">
                <slot name="title">
                    Are you sure do you want to delete it?
                </slot>
            </v-card-title>
            <v-card-text class="text-left pb-0">
                <slot name="message"></slot>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="cancel">
                    Cancel
                </v-btn>
                <v-btn color="green darken-1" text @click="confirm">
                    Agree
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        show: Boolean,
    },
    methods: getMethods(),
};

function getMethods() {
    return {
        cancel: cancel,
        confirm: confirm,
    };

    function cancel() {
        const vm = this;
        vm.$emit("cancelled");
    }

    function confirm() {
        const vm = this;
        vm.$emit("confirmed");
    }
}
</script>
