<template>
    <div>
        <v-progress-circular
            :rotate="-90"
            :size="40"
            :width="5"
            :value="item.progress"
            color="blue"
            class="progress-report"
            @click.stop="handleClickOnProgress(item)"
        >
            {{ item.progress | round | percent }}
        </v-progress-circular>

        <progress-report
            v-if="itemProgress"
            :item="itemProgress"
            @close="itemProgress = null"
        >
        </progress-report>

        <v-dialog
            v-model="showInfoMessage"
            width="550"
            @click:outside="showInfoMessage = false"
        >
            <v-card>
                <v-card-title class="text-h5 white--text primary">
                    Info
                </v-card-title>

                <v-card-text class="pt-3 pb-0 text-left">
                    <v-alert type="info"
                        >The selected
                        {{
                            item.isJobCollection ? "job collection" : "job"
                        }}
                        does not have history for showing report</v-alert
                    >
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="showInfoMessage = false"
                    >
                        Accept
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import round from "Filters/round";
import percent from "Filters/percent";
import progressReport from "Components/jobs/job-reports/progress-report";

export default {
    components: {
        progressReport,
    },

    data() {
        return {
            itemProgress: null,
            showInfoMessage: false,
        };
    },

    props: {
        item: Object,
    },

    methods: {
        handleClickOnProgress(item) {
            let workedHours = item.isJobCollection
                ? item.job_all_hours
                : item.all_hours;
            
            if (parseInt(workedHours) === 0) {
                this.showInfoMessage = true;
                return;
            }

            this.itemProgress = item;
        },
    },

    filters: {
        round,
        percent,
    },
};
</script>

<style lang="scss" scoped>
.progress-report:hover {
    &::before {
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        background-color: white;
        left: 5px;
        top: 5px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f201";
        border-radius: 14px;
        padding-top: 5px;
    }
}
</style>
