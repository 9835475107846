<template>
    <v-data-table
        :headers="headers"
        :items="organizations"
        class="elevation-1 row-pointer"
        :loading="loading"
        :item-class="rowClasses"
        @click:row="focusOnOrganization"
    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-toolbar-title>Organizations</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                    persistent
                    v-model="showOrganizationEditor"
                    @click:outside="confirmClose"
                    @keydown.esc="confirmClose"
                    @keydown.enter="save"
                    max-width="500px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on"
                            >New Organization</v-btn
                        >
                    </template>
                    <v-card>
                        <v-card-title class="primary white--text">
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-form
                                ref="editOrganizationForm"
                                onSubmit="return false;"
                            >
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-text-field
                                                @input="isDirty = true"
                                                v-model="
                                                    editedOrganization.name
                                                "
                                                label="Name"
                                                :rules="[
                                                    () =>
                                                        !!editedOrganization.name ||
                                                        'Required.',
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                @input="isDirty = true"
                                                v-model="
                                                    editedOrganization.limit_number_user_accounts
                                                "
                                                label="Limit of user accounts"
                                                :rules="[
                                                    rules.required,
                                                    rules.integer,
                                                    rules.min(1),
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                @input="isDirty = true"
                                                v-model="
                                                    editedOrganization.limit_number_customer_accounts
                                                "
                                                label="Limit of customer accounts"
                                                :rules="[
                                                    rules.required,
                                                    rules.integer,
                                                    rules.min(1),
                                                ]"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            md="12"
                                            class="py-0"
                                        >
                                            <v-switch
                                                label="Access"
                                                v-model="
                                                    editedOrganization.has_access
                                                "
                                                hide-details
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" @click="confirmClose"
                                >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.has_access="{ item }">
            <v-switch
                v-model="item.has_access"
                hide-details
                @change="updateOrganizationAccess(item)"
            ></v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editOrganization(item)">
                mdi-pencil
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: data,
    created: created,
    methods: getMethods(),
    watch: getWatchers(),
    computed: {
        formTitle() {
            return this.editedIndex === -1
                ? "New Organization"
                : "Edit Organization";
        },
    },
    props: {
        value: Object,
    },
};

function data() {
    return {
        showOrganizationEditor: false,
        isDirty: false,
        headers: [
            {
                text: "Name",
                align: "start",
                sortable: false,
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Limit user accounts",
                align: "center",
                sortable: false,
                value: "limit_number_user_accounts",
                class: "grey--text text--darken-3",
            },
            {
                text: "Limit customer accounts",
                align: "center",
                sortable: false,
                value: "limit_number_customer_accounts",
                class: "grey--text text--darken-3",
            },
            {
                text: "Access",
                align: "center",
                sortable: false,
                value: "has_access",
                class: "grey--text text--darken-3",
            },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false,
                class: "grey--text text--darken-3",
            },
        ],
        editedIndex: -1,
        editedOrganization: {
            id: "new-organization-id",
            name: "",
            limit_number_user_accounts: 100,
            limit_number_customer_accounts: 100,
            has_access: true,
        },
        defaultOrganization: {
            id: "new-organization-id",
            name: "",
            limit_number_user_accounts: 100,
            limit_number_customer_accounts: 100,
            has_access: true,
        },
        organizations: [],
        loading: false,
        rules: {
            required: (value) => {
                if (value === undefined || value === null || value === "") {
                    return "Required.";
                }
                return true;
            },
            integer: (value) => {
                if (value === undefined || value === null || value === "") {
                    return true;
                }
                const pattern = /^[0-9]{1,9}$/;
                return pattern.test(value) || "Invalid format.";
            },
            min(min) {
                return (value) => {
                    if (value === undefined || value === null || value === "") {
                        return true;
                    }
                    return parseFloat(value) >= parseFloat(min) || "Min " + min;
                };
            },
        },
    };
}

function getWatchers() {
    return {
        showOrganizationEditor: {
            handler: dialog,
        },
    };

    function dialog(val) {
        val || this.close();
    }
}

function getMethods() {
    return {
        focusOnOrganization: focusOnOrganization,
        editOrganization: editOrganization,
        close: close,
        confirmClose: confirmClose,
        save: save,
        updateOrganizationAccess: updateOrganizationAccess,
        rowClasses: rowClasses,
    };

    function focusOnOrganization(organization) {
        const vm = this;
        if (organization.id === "new-organization-id") {
            vm.$router.push({ name: "organizations" });
        } else {
            vm.$router.push({
                name: "organizations",
                params: { id: organization.id },
            });
        }
        vm.$emit("input", organization);
    }

    function editOrganization(organization) {
        this.editedIndex = this.organizations.indexOf(organization);
        this.editedOrganization = Object.assign({}, organization);
        this.showOrganizationEditor = true;
    }

    function close() {
        this.showOrganizationEditor = false;
        this.$nextTick(() => {
            this.editedOrganization = Object.assign(
                {},
                this.defaultOrganization
            );
            this.editedIndex = -1;
        });
        this.isDirty = false;
        this.$refs.editOrganizationForm.resetValidation();
    }

    function confirmClose() {
        if (!this.isDirty) {
            this.close();
            return;
        }

        if (confirm("Unsaved changes!\nDo you really want to close?")) {
            this.close();
        }
    }

    function save() {
        const vm = this;

        if (!this.$refs.editOrganizationForm.validate()) {
            return false;
        }

        if (vm.editedOrganization.id === "new-organization-id") {
            Vue.backendApi()
                .organizations()
                .store(vm.editedOrganization)
                .then(success, fail);
        } else {
            Vue.backendApi()
                .organizations(vm.editedOrganization.id)
                .store(vm.editedOrganization)
                .then(success, fail);
        }

        function success(response) {
            if (vm.editedIndex > -1) {
                Object.assign(vm.organizations[vm.editedIndex], response.data);
            } else {
                let organization = response.data;
                vm.organizations.unshift(organization);
                vm.$router.push({
                    name: "organizations",
                    params: { id: response.data.id },
                });
                vm.$emit("input", organization);
            }
            vm.close();
        }

        function fail(error) {
            console.log("An error occurred trying to save organization");
        }
    }

    function updateOrganizationAccess(organization) {
        const vm = this;

        Vue.backendApi()
            .organizations(organization.id)
            .store(organization)
            .then(success, fail);

        function success(response) {
            let organizationIndex = vm.organizations.indexOf(organization);
            Object.assign(vm.organizations[organizationIndex], response.data);
        }

        function fail(error) {
            organization.has_access = !organization.has_access;
            console.log(
                "An error occurred trying to update organization access"
            );
        }
    }

    function rowClasses(organization) {
        if (organization.id === parseInt(this.$route.params.id)) {
            return "v-data-table__selected";
        }

        if (!organization.has_access) {
            return "fade-organization";
        }
    }
}

function created() {
    const vm = this;
    this.loading = true;

    Vue.backendApi().organizations().index().then(success, fail);

    function success(response) {
        vm.loading = false;
        Vue.set(vm, "organizations", response.data);

        if (vm.$route.params.id) {
            let selectedOrganization = vm.organizations.find(
                (organization) =>
                    organization.id === parseInt(vm.$route.params.id)
            );
            if (selectedOrganization) {
                vm.$emit("input", selectedOrganization);
            }
        }
    }

    function fail(error) {
        vm.loading = false;
        console.log("An error occurred trying to get organizations");
    }
}
</script>

<style lang="scss" scoped>
.row-pointer ::v-deep tbody tr :hover {
    cursor: pointer;
}

::v-deep input,
.button {
    height: auto;
}

::v-deep
    .theme--light.v-text-field
    > .v-input__control
    > .v-input__slot:before {
    border-color: #e0e0e0;
}

::v-deep .v-input--switch label {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0px;
}

::v-deep table .v-input--selection-controls {
    margin-top: 0px;
}

::v-deep .fade-organization {
    opacity: 0.5;
    background-color: #f5f5f5;
}
</style>
