export default {
    data: () => ({
        collapsedJobs: [],
        expandedJobCollection: null,
    }),

    methods: {
        $collapseJobs() {
            const vm = this;
            let collapsedJobs = [];

            for (const jobCollection of vm.jobCollections) {
                if (jobCollection.job_count === 1) {
                    let job = jobCollection.jobs[0];
                    job.key = `j-${job.id}`;
                    collapsedJobs.push(job);
                    continue;
                }

                jobCollection.isJobCollection = true;
                jobCollection.key = `jc-${jobCollection.id}`;
                collapsedJobs.push(jobCollection);

                if (vm.expandedJobCollection === jobCollection.id) {
                    jobCollection.jobs[0].isFirstJobInCollection = true;
                    let latestIndex = jobCollection.job_count - 1;
                    jobCollection.jobs[
                        latestIndex
                    ].isLatestJobInCollection = true;

                    jobCollection.jobs.forEach((job) => {
                        job.key = `j-${job.id}`;
                        job.belongsToExpandedCollection = true;
                        collapsedJobs.push(job);
                    });
                }
            }
            Vue.set(vm, "collapsedJobs", collapsedJobs);
        },

        $rowClasses(item) {
            let classes = [];
            let selectedJobId = parseInt(this.$route.params.id);
            let isSelectedJob =
                !item.isJobCollection && item.id === selectedJobId;
            let isExpandedJobCollection =
                item.isJobCollection && this.expandedJobCollection === item.id;

            if (isExpandedJobCollection || isSelectedJob) {
                classes.push("v-data-table__selected");
            }
            if (item.belongsToExpandedCollection) {
                classes.push("subItem");

                if (item.isFirstJobInCollection) {
                    classes.push("firstSubItem");
                }
                if (item.isLatestJobInCollection) {
                    classes.push("latestSubItem");
                }
            }
            if (item.deleted_at) {
                classes.push("item-archived");
            }
            return classes;
        },

        $expand(jobCollection) {
            this.expandedJobCollection === jobCollection.id
                ? (this.expandedJobCollection = null)
                : (this.expandedJobCollection = jobCollection.id);
        },

        $clickOnItem(item) {
            if (item.isJobCollection) {
                this.$expand(item);
            }
        },
    },
};
