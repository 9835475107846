<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="500px"
    >
        <v-card class="mx-auto text-left" tile>
            <v-card-title class="pb-0">
                {{ employee.name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-card-title>
            <v-list class="pt-0">
                <v-subheader>Actions</v-subheader>
                <v-list-item @click="$emit('details', employee)">
                    <v-list-item-icon>
                        <v-icon>mdi-table-eye</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>More Details</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('edit', employee)">
                    <v-list-item-icon>
                        <v-icon>mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="$emit('destroy', employee)">
                    <v-list-item-icon>
                        <v-icon>mdi-archive</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Archive</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            show: true,
        };
    },
    props: {
        employee: Object,
    },
};
</script>
