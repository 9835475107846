<template>
    <v-dialog
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        v-model="show"
        max-width="375px"
    >
        <v-card max-width="375" class="mx-auto text-left">
            <v-card-title class="justify-content-center"
                >{{ employee.name }}
                <v-spacer></v-spacer>
                <v-btn icon @click="$emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </v-card-title>
            <v-card-subtitle>Details</v-card-subtitle>

            <v-card-text>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Processes</td>
                                <td>
                                    <v-chip
                                        small
                                        v-for="process in employee.processes"
                                        :key="process.id"
                                    >
                                        {{ process.name }}
                                    </v-chip>
                                </td>
                            </tr>
                            <tr>
                                <td>Last Worked Day</td>
                                <td>
                                    {{ employee.last_worked_at | shortDate }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <br />
                <h6 class="black--text">Machines</h6>
                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody v-if="employeeHistory.stations.length > 0">
                            <tr
                                v-for="station in employeeHistory.stations"
                                :key="station.id"
                            >
                                <td colspan="2">{{ station.name }}</td>
                            </tr>
                            <tr>
                                <td>Work hours</td>
                                <td>{{ employeeHistory.workHours }}h</td>
                            </tr>
                        </tbody>

                        <tbody v-else>
                            <tr>
                                <td>No work assigned</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import shortDate from "Filters/shortDate";

export default {
    data() {
        return {
            show: true,
        };
    },

    props: {
        employee: Object,
        employeeHistory: Object,
    },

    filters: { shortDate },
};
</script>
