<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="time"
                :label="label"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
        </template>
        <v-time-picker
            v-if="menu"
            v-model="time"
            full-width
            @click:minute="$refs.menu.save(time)"
        ></v-time-picker>
    </v-menu>
</template>

<script>
import padStart from "Filters/padStart";

export default {
    data: data,
    watch: getWatchers(),
    props: {
        value: Number,
        label: {
            type: String,
            default: "Start at",
        },
    },
};

function data() {
    return {
        menu: false,
        time: null,
    };
}

function getWatchers() {
    return {
        value: {
            immediate: true,
            handler: value,
        },
        time: {
            handler: timeChanged,
        },
    };

    function value() {
        let duration = Vue.moment.duration(this.value, "seconds");
        let hours = padStart(duration.hours(), 2, "0");
        let minutes = padStart(duration.minutes(), 2, "0");
        this.time = `${hours}:${minutes}`;
    }

    function timeChanged(time) {
        const vm = this;
        let timeArray = time.split(":");
        let hoursInSeconds = parseInt(timeArray[0]) * 60 * 60;
        let minutesInSeconds = parseInt(timeArray[1]) * 60;
        let seconds = hoursInSeconds + minutesInSeconds;
        vm.$emit("input", seconds);
    }
}
</script>
