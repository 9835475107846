<template>
    <shop-overview-desktop
        v-if="$vuetify.breakpoint.lgAndUp"
    ></shop-overview-desktop>
    <shop-overview-mobile v-else></shop-overview-mobile>
</template>

<script>
import shopOverviewDesktop from "Components/overview/shop-overview-desktop";
import shopOverviewMobile from "Components/overview/shop-overview-mobile";

export default {
    components: { shopOverviewDesktop, shopOverviewMobile },
};
</script>
