var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"text-left",attrs:{"fluid":""}},[_c('page-header-vuetify'),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"10","sm":"5","md":"4","lg":"3"}},[_c('v-dialog',{ref:"dialog",attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({staticClass:"date-input",attrs:{"label":"Period","value":_vm.periodString,"prepend-icon":"mdi-calendar","readonly":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_vm._v(" "),_c('v-calendar',{attrs:{"is-range":true,"attributes":[
                            {
                                highlight: {
                                    backgroundColor: '#ddd',
                                },
                            },
                        ],"mode":"single","is-inline":"","is-expanded":""},on:{"input":_vm.periodChanged},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('profitability-report-summary',{attrs:{"report":_vm.report,"comparedReport":_vm.comparedReport}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('profitability-report-earnings',{attrs:{"report":_vm.report,"comparedReport":_vm.comparedReport}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6","lg":"4"}},[_c('profitability-report-costs',{attrs:{"report":_vm.report}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"12","lg":"4"}},[_c('profitability-sales-order-button',{attrs:{"start":_vm.start,"end":_vm.end,"number-sales-orders":_vm.report.number_sales_orders}})],1)],1)],1)],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }