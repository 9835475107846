<template>
    <v-app>
        <v-container fluid>
            <page-header-vuetify></page-header-vuetify>
            <v-card class="mb-3">
                <toolbar-report
                    title="Job reports"
                    routeName="jobReports"
                    @search="(query) => (search = query)"
                    @get-reports-on="(payload) => getJobs(payload)"
                >
                </toolbar-report>
            </v-card>

            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <job-report
                        title="Opened"
                        :loading="loading"
                        :headers="openedJobHeaders"
                        :jobCollections="jobCollections.opened"
                        :querySearch="search"
                    >
                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at | shortDate }}
                        </template>
                    </job-report>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <job-report
                        title="Worked"
                        :loading="loading"
                        :headers="workedJobHeaders"
                        :jobCollections="jobCollections.worked"
                        :querySearch="search"
                    >
                        <template v-slot:item.all_hours="{ item }">
                            {{
                                item.all_hours
                                    ? item.all_hours
                                    : (parseFloat(item.efficient_hours) +
                                          parseFloat(item.non_efficient_hours))
                                      | round
                            }}
                        </template>
                        <template
                            v-slot:item.all_hours_between_period="{ item }"
                            class="p-0"
                        >
                            {{ item.all_hours_between_period | round }}
                        </template>
                    </job-report>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <job-report
                        title="Closed"
                        :loading="loading"
                        :headers="closedJobHeaders"
                        :jobCollections="jobCollections.closed"
                        :querySearch="search"
                    >
                        <template v-slot:item.deleted_at="{ item }">
                            {{ item.deleted_at | shortDate }}
                        </template>
                    </job-report>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import pageHeaderVuetify from "Components/page-header-vuetify";
import toolbarReport from "Components/utils/toolbarReport";
import jobReport from "Components/job-reports/job-report";
import shortDate from "Filters/shortDate";
import round from "Filters/round";

export default {
    components: { pageHeaderVuetify, toolbarReport, jobReport },
    methods: getMethods(),
    data: data,
    filters: { shortDate, round },
};

function data() {
    return {
        loading: false,
        search: "",
        jobCollections: {
            opened: [],
            worked: [],
            closes: [],
        },
        openedJobHeaders: [
            {
                text: "Job name",
                align: "start",
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Date",
                value: "created_at",
                class: "grey--text text--darken-3",
                width: "121px",
            },
            {
                text: "",
                value: "actions",
                align: "left",
                sortable: false,
                width: "175px",
            },
        ],
        closedJobHeaders: [
            {
                text: "Job name",
                align: "start",
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Date",
                value: "deleted_at",
                class: "grey--text text--darken-3",
                width: "121px",
            },
            {
                text: "",
                value: "actions",
                align: "left",
                sortable: false,
                width: "175px",
            },
        ],
        workedJobHeaders: [
            {
                text: "Job name",
                align: "start",
                value: "name",
                class: "grey--text text--darken-3",
            },
            {
                text: "Hours",
                value: "all_hours_between_period",
                align: "center",
                class: "grey--text text--darken-3",
            },
            {
                text: "Total Hours",
                value: "all_hours",
                align: "center",
                class: "grey--text text--darken-3",
            },
            {
                text: "",
                value: "actions",
                align: "left",
                sortable: false,
                width: "175px",
            },
        ],
    };
}

function getMethods() {
    return {
        getJobs: getJobs,
    };

    function getJobs(payload) {
        const vm = this;
        vm.loading = true;

        Vue.backendApi().jobs().reportsOn(payload).then(success, fail);

        function success(response) {
            vm.loading = false;
            Vue.set(vm, "jobCollections", response.data);
        }

        function fail(error) {
            vm.loading = false;
            console.log("An error occurred trying to get jobs");
        }
    }
}
</script>
